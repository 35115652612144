import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context as CheckoutContext } from '../../context/checkout';
import { Context as AlertContext } from '../../context/alert';
import { Context as AuthContext } from '../../context/auth';
import { Context as DeliveryContext } from '../../context/delivery';
import { Context as CartContext } from '../../context/cart';
import MainLayout from '../../layouts/mainLayout';
import { initiatePayment } from '../../api/payment';
import Loader from '../../components/misc/loader';
import { calculateCartSum, formatCartForBill } from '../../services/cartService';
import CheckoutBillDelivery from '../../features/checkout/components/delivery/bill';
import { useDeliveryPageInit } from '../../hooks/useDeliveryPageInit';
import { useDeliveryMenuInit } from '../../hooks/useDeliveryMenuInit';

const CheckoutDeliveryPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoadingDeliveryInit, isSelectedRestaurantInit, selectedRestaurant } =
    useDeliveryPageInit();

  useDeliveryMenuInit({
    isLoadingDeliveryInit,
    isSelectedRestaurantInit,
    selectedRestaurant,
  });

  const { restaurantSlug } = useParams();

  const { alertController } = useContext(AlertContext);
  const { state: checkoutState, changeBillDiscount, setOrderBill } = useContext(CheckoutContext);
  const { registerGuest } = useContext(AuthContext);
  const { state: deliveryState } = useContext(DeliveryContext);
  const { state: cartState } = useContext(CartContext);

  const [initiating, setInitiating] = useState(false);
  const [paymentKey, setPaymentKey] = useState(null);

  useEffect(() => {
    if (
      isLoadingDeliveryInit ||
      !isSelectedRestaurantInit ||
      !selectedRestaurant ||
      !cartState.fistDeliveryDiscountApplied
    ) {
      return;
    }

    // This should only on load, if browser refreshed in this page
    if (!checkoutState.bill) {
      const restaurantCartData = cartState.deliveryCart[deliveryState.selectedRestaurantId] || {};
      const { cart, totalDiscounted } = restaurantCartData;

      const total = calculateCartSum({ cart });

      if (!cart || cart.length === 0) {
        navigate(`/${restaurantSlug}`, { replace: true });
      } else {
        setOrderBill({
          subtotal: total,
          discount: totalDiscounted,
          cart,
          note: '',
          totalGuests: 1,
        });
      }
    }
  }, [
    cartState.deliveryCart,
    cartState.fistDeliveryDiscountApplied,
    isLoadingDeliveryInit,
    isSelectedRestaurantInit,
    selectedRestaurant,
  ]);

  const startPayment = async ({
    paymentType,
    tips,
    paymentMethodId,
    employeeId,
    delivery,
    dontDisable,
    paymentTrackId,
    paymentAccountId,
  }) => {
    if (!dontDisable) {
      setInitiating(true);
    }

    const { token } = await registerGuest();
    if (!token) {
      setInitiating(false);
      return;
    }

    const response = await initiatePayment({
      token,
      alertController,
      paymentType,
      subtotal: checkoutState.bill.subtotal,
      discount: checkoutState.bill.discount,
      items: checkoutState.bill.items,
      restaurantType: selectedRestaurant.data.type,
      restaurantId: selectedRestaurant.data.id,
      tableId: selectedRestaurant.table.id,
      totalGuests: checkoutState.bill.totalGuests,

      isTakeout: delivery.isTakeAway,
      isDelivery: !delivery.isTakeAway,
      deliveryName: delivery.name,
      deliveryEmail: delivery.email,
      deliveryPhone: delivery.phone,
      deliveryAddress: delivery.address,
      deliveryCity: delivery.city,
      deliveryZipCode: delivery.zipCode,
      noteForDriver: delivery.noteForDriver,
      roomNumber: delivery.roomNumber,
      isDeliveryNow: delivery.isDeliveryNow,
      deliveryDate: delivery.deliveryDate,
      deliveryDateDelta: delivery.deliveryDateDelta,
      prepareDate: delivery.prepareDate,
      prepareDateDelta: delivery.prepareDateDelta,
      deliveryId: deliveryState.settings.deliveryId,
      deliveryRestaurantId: selectedRestaurant.deliveryId,
      deliveryHost: deliveryState.slug,

      couponDiscount: delivery.couponDiscount,
      couponCode: delivery.couponCode,

      deliveryOrderId: delivery.deliveryOrderId,

      tips,
      paymentMethodId,
      paymentAccountId,
      cart: formatCartForBill({ cart: checkoutState.bill.cart }),
      note: checkoutState.bill.note,
      coupon: null,
      employeeId,
    });

    if (response && response.status === 'discountChange') {
      changeBillDiscount(response.actualDiscount);
      setInitiating(false);
      return;
    }

    if (response && response.status === 'couponDiscountChange') {
      setInitiating(false);
      return;
    }

    if (response && response.status === 'Created' && response.paymentLink) {
      // eslint-disable-next-line no-undef
      window.location.replace(response.paymentLink);
      return;
    }

    if (response && response.status === 'Created' && response.paymentKey) {
      if (paymentTrackId) {
        setPaymentKey(`${paymentTrackId}~${response.paymentKey || ''}`);
      } else {
        setPaymentKey(response.paymentKey);
      }

      return;
    }

    setInitiating(false);
  };

  if (isLoadingDeliveryInit || !isSelectedRestaurantInit || !selectedRestaurant) {
    return <Loader />;
  }

  if (!checkoutState.bill) {
    return <Loader />;
  }

  return (
    <MainLayout showPayments>
      <CheckoutBillDelivery
        bill={checkoutState.bill}
        paymentOption={selectedRestaurant.paymentOption}
        onPay={startPayment}
        onSetInitiating={(isInitiating) => {
          setInitiating(isInitiating);
        }}
        disabled={initiating}
        onFail={(errorMessage) => {
          setPaymentKey(null);
          setInitiating(false);

          alertController.error({ text: errorMessage || t('errors.unknown') });
        }}
        paymentKey={paymentKey}
        deliveryRestaurant={selectedRestaurant}
      />
    </MainLayout>
  );
};

export default CheckoutDeliveryPage;
