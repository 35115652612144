export const DomainConfig = {
  getHost: () => {
    const hostName = global.window.location.hostname;

    if (hostName === 'localhost') {
      return 'local.waitless.lt';
    }

    return global.window.location.hostname;
  },
};
