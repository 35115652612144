import React, { useState, useRef, useEffect, useContext } from 'react';
import { MdDeliveryDining } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { RiWalkFill } from 'react-icons/ri';
import { useCurrency } from '../../hooks/useCurrency';
import { Context as DeliveryContext } from '../../context/delivery';

const InfoTabs = ({ selectedRestaurant }) => {
  const { t } = useTranslation(['delivery']);
  const { currencySymbol } = useCurrency();

  const [inited, setInited] = useState(false);
  const [isTakeaway, setIsTakeaway] = useState(true);
  const isTakeawayRef = useRef({ value: true }).current;

  const { state: deliveryState, setDefaultDeliveryType } = useContext(DeliveryContext);

  const highlightRef = useRef();
  const tabsRef = useRef();

  useEffect(() => {
    if (inited || !selectedRestaurant || !highlightRef.current) {
      return;
    }

    let isTakeawayTemp = !!(
      deliveryState.defaultDeliveryType === 'takeaway' && selectedRestaurant.hasTakeaway
    );

    // If it should be delivery, but restaurant has no delivery, change to takeaway
    if (!isTakeawayTemp && !selectedRestaurant.hasDelivery) {
      isTakeawayTemp = true;
      setDefaultDeliveryType('takeaway');
    }

    isTakeawayRef.value = isTakeawayTemp;
    setIsTakeaway(isTakeawayTemp);
    setInited(true);
  }, [selectedRestaurant, highlightRef]);

  // This is needed as there is some weird size change after render (probably scrollbar)
  useEffect(() => {
    if (!tabsRef.current) return null;

    const resizeObserver = new global.ResizeObserver(() => {
      setTabSliderStyle(isTakeawayRef.value ? 'tab-takeaway' : 'tab-delivery');
    });

    resizeObserver.observe(tabsRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    setTabSliderStyle(
      deliveryState.defaultDeliveryType === 'takeaway' ? 'tab-takeaway' : 'tab-delivery',
    );
  }, [deliveryState.defaultDeliveryType]);

  const setTabSliderStyle = (sliderId) => {
    const parentElem = global.document.getElementById(sliderId);
    if (!parentElem) {
      return;
    }

    highlightRef.current.style.left = `${parentElem.offsetLeft}px`;
    highlightRef.current.style.width = `${parentElem.scrollWidth}px`;
    highlightRef.current.style.height = `${parentElem.scrollHeight}px`;
  };

  if (!selectedRestaurant.hasDelivery && !selectedRestaurant.hasTakeaway) {
    return null;
  }

  const getDeliveryStaticPrice = () => {
    const deliveryProvider =
      selectedRestaurant.deliveryOptions && selectedRestaurant.deliveryOptions.length > 0
        ? selectedRestaurant.deliveryOptions[0]
        : null;

    if (!deliveryProvider || !deliveryProvider.id) {
      return { showDeliveryPrice: false, deliveryPrice: 0 };
    }

    // Price will be dynamic
    if (!deliveryProvider.ignorePrice) {
      return { showDeliveryPrice: false, deliveryPrice: 0 };
    }

    // TODO: allow display custom text?
    return { showDeliveryPrice: false, deliveryPrice: 0 };
    // return { showDeliveryPrice: true, deliveryPrice: selectedRestaurant.deliveryPrice };
  };

  const { showDeliveryPrice, deliveryPrice } = getDeliveryStaticPrice();

  const renderOrderTypeSliderTab = (id, icon, text, hasOtherType) => {
    const IconComponent = icon;

    return (
      <div
        id={id}
        role="button"
        onClick={() => {
          setTabSliderStyle(id);
          setIsTakeaway(id === 'tab-takeaway');
          setDefaultDeliveryType(id === 'tab-takeaway' ? 'takeaway' : 'delivery');
        }}
        className={`flex flex-row items-center px-3 py-1 rounded-md z-[2]
          bg-transparent cursor-pointer ${
            hasOtherType ? 'max-w-[50%]' : 'max-w-[100%]'
          } xs:max-w-[100%] w-full`}
      >
        <div>
          <IconComponent className="font-primaryMedium text-lg text-primary" />
        </div>
        <div className="font-primaryMedium text-base text-primary ml-2 whitespace-nowrap text-ellipsis overflow-hidden">
          {text}
        </div>
      </div>
    );
  };

  const formatPreparationTime = () => {
    const fromMinutes = isTakeaway
      ? selectedRestaurant.takeawayNowMin
      : selectedRestaurant.deliveryNowMin;
    const toMinutes = isTakeaway
      ? selectedRestaurant.takeawayNowMax
      : selectedRestaurant.deliveryNowMax;

    if (!fromMinutes && !toMinutes) {
      return null;
    }

    const parts = [];

    if (fromMinutes) {
      parts.push(fromMinutes);
    }

    if (toMinutes) {
      parts.push(toMinutes);
    }

    return isTakeaway
      ? `${t('delivery:menu.preparedInTime')} ${parts.join(' - ')} ${t('delivery:menu.minutes')}`
      : `${t('delivery:menu.deliveredInTime')} ${parts.join(' - ')} ${t('delivery:menu.minutes')}`;
  };

  const renderPill = (i, text) => {
    return (
      <div
        key={i}
        className="font-primaryMedium text-primary text-sm px-4 py-1 bg-[#E7E7E7] rounded-3xl mr-2 xs:whitespace-nowrap mb-1 mt-1"
      >
        {text}
      </div>
    );
  };

  const renderPills = () => {
    const pills = [];
    // Delivery fee
    if (!isTakeaway && showDeliveryPrice) {
      if (deliveryPrice > 0) {
        pills.push(
          renderPill(
            0,
            `${t('delivery:menu.delivery')}: ${deliveryPrice.toFixed(2)} ${currencySymbol}`,
          ),
        );
      } else {
        pills.push(renderPill(0, t('delivery:menu.freeDelivery')));
      }
    }

    // Min order
    if (!isTakeaway) {
      if (selectedRestaurant.minDeliveryOrder > 0) {
        pills.push(
          renderPill(
            1,
            `${t(
              'delivery:menu.minimumOrderAmount',
            )}: ${selectedRestaurant.minDeliveryOrder.toFixed(2)} ${currencySymbol}`,
          ),
        );
      }
    } else if (selectedRestaurant.minTakeawayOrder > 0) {
      pills.push(
        renderPill(
          1,
          `${t('delivery:menu.minimumOrderAmount')}: ${selectedRestaurant.minTakeawayOrder.toFixed(
            2,
          )} ${currencySymbol}`,
        ),
      );
    }

    const preparationNowText = formatPreparationTime();

    // Time
    if (preparationNowText) {
      pills.push(renderPill(2, preparationNowText));
    }

    if (pills.length === 0) {
      return null;
    }

    return <div className="flex flex-row items-center flex-wrap mt-3 lg:mt-0">{pills}</div>;
  };

  const hasBothTabs = selectedRestaurant.hasDelivery && selectedRestaurant.hasTakeaway;

  return (
    <div className="mt-6 block lg:flex flex-row items-center">
      <div
        ref={tabsRef}
        className={`bg-[#E7E7E7] inline-flex flex-row items-center rounded-md h-10 px-[0.38rem] py-4 relative xs:mr-8 ${
          hasBothTabs ? 'w-full' : ''
        } xs:w-auto`}
      >
        <div className="inline-flex flex-row items-center w-full">
          {selectedRestaurant.hasTakeaway &&
            renderOrderTypeSliderTab(
              'tab-takeaway',
              RiWalkFill,
              t('delivery:menu.takeaway'),
              selectedRestaurant.hasDelivery,
            )}
          {selectedRestaurant.hasDelivery &&
            renderOrderTypeSliderTab(
              'tab-delivery',
              MdDeliveryDining,
              t('delivery:menu.delivery'),
              selectedRestaurant.hasTakeaway,
            )}
        </div>
        <div
          ref={highlightRef}
          className="transition-all duration-200 absolute min-h-full rounded-md py-1"
        >
          <div className="bg-white h-full w-full rounded-md" />
        </div>
      </div>
      {renderPills()}
    </div>
  );
};

export default InfoTabs;
