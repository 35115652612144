import React, { useEffect, useRef } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useScrollbarHide } from '../../hooks/useScrollbarHide';

const BottomModal = ({ title, content, onClose, onOutClick, open, noHeader, noPadding }) => {
  useScrollbarHide(open);

  const contentRef = useRef(null);

  useEffect(() => {
    if (open && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [open]);

  return (
    <div>
      <div
        ref={contentRef}
        className={`fixed bg-white w-full left-1/2 transform -translate-x-1/2 rounded-t-2xl
          transition-all z-10 ${open ? 'bottom-0 ease-out' : '-bottom-[100vh] ease-in'}
          overflow-y-auto overflow-x-hidden z-30 max-w-4xl max-h-[92%] scrollbar-v
          ${noPadding ? '' : 'pt-10 px-5'}`}
      >
        {!noHeader && (
          <div className="flex justify-between mb-7">
            <h4 className="font-primaryMedium text-base text-primary">{title}</h4>
            <button type="button" onClick={onClose}>
              <MdOutlineClose className="text-primary text-xl" />
            </button>
          </div>
        )}
        {content}
      </div>
      <div
        className={`bg-black top-0 bottom-0 left-0 right-0 fixed z-5
          opacity-50 ${open ? 'block' : 'hidden'} z-20`}
        onClick={onOutClick}
      />
    </div>
  );
};

export default BottomModal;
