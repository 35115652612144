import React from 'react';

const ContentWrapper = ({ children }) => {
  return (
    <div className="px-5 ">
      <div className="max-w-6xl m-auto w-full">{children}</div>
    </div>
  );
};

export default ContentWrapper;
