import { useEffect } from 'react';

// Needed for modals, when pressed browser back, closes modal instead of navigating back
export function useBackPress(currentRoute, onBackPress, queryToAdd) {
  const handleBackOnModalOpen = () => {
    global.window.history.pushState(
      {},
      undefined,
      `${currentRoute}${queryToAdd ? `?${queryToAdd}` : ''}`,
    ); // global.window.location.search
  };

  const handleBackOnModalClose = () => {
    global.window.history.back();
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();

    const currentLocation = global.window.location.pathname;

    if (currentLocation === currentRoute && onBackPress) {
      if (!queryToAdd || `?${queryToAdd}` !== global.window.location.search) {
        onBackPress();
      }
    }
  };

  useEffect(() => {
    global.window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      global.window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  return { handleBackOnModalOpen, handleBackOnModalClose };
}
