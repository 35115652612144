import { useEffect } from 'react';

export const useScript = (url) => {
  useEffect(() => {
    if (!url) {
      return () => {};
    }

    const script = global.document.createElement('script');

    script.src = url;
    script.async = true;

    global.document.body.appendChild(script);

    return () => {
      global.document.body.removeChild(script);
    };
  }, [url]);
};
