import { useContext, useMemo } from 'react';
import { Context as CartContext } from '../context/cart';
import { Context as DeliveryContext } from '../context/delivery';
import { calculateCartSum, calculateTotalItemsInCart } from '../services/cartService';

export function useDeliveryCartTotal() {
  const { state: cartState } = useContext(CartContext);
  const { state: deliveryState } = useContext(DeliveryContext);

  const { total, totalAfterDiscount, totalInCart } = useMemo(() => {
    if (!deliveryState.selectedRestaurantId) {
      return { totalInCart: 0 };
    }

    const restaurantCartData = cartState.deliveryCart[deliveryState.selectedRestaurantId] || {};

    const totalTemp = calculateCartSum({ cart: restaurantCartData.cart });
    const totalAfterDiscountTemp = totalTemp - (restaurantCartData.totalDiscounted || 0);

    const totalInCartTemp = calculateTotalItemsInCart({ cart: restaurantCartData.cart });

    return {
      total: totalTemp,
      totalAfterDiscount: totalAfterDiscountTemp,
      totalInCart: totalInCartTemp,
    };
  }, [cartState.deliveryCart]);

  return { total, totalAfterDiscount, totalInCart };
}
