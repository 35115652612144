import createDataContext from './_init_/createDataContext';

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_is_delivery_layout':
      return {
        ...state,
        isDeliveryLayout: action.payload,
      };
    default:
      return state;
  }
};

const setIsDeliveryLayout = (dispatch) => (isDeliveryLayout) => {
  dispatch({
    type: 'set_is_delivery_layout',
    payload: isDeliveryLayout,
  });
};

const initialData = {
  isDeliveryLayout: false,
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    setIsDeliveryLayout,
  },
  { ...initialData },
);
