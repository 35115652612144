import { useEffect, useRef } from 'react';

// for dontRun, need to pass refresher as [dontRun]
export function useInterval(func, time, callOnStart, refresher, dontRun) {
  const intervalRef = useRef({ value: null }).current;

  const intervalFuncWrapper = async () => {
    if (dontRun) {
      return;
    }

    const stop = await func();
    if (stop && intervalRef.value) {
      clearInterval(intervalRef.value);
    }
  };

  const firstFuncWrapper = async () => {
    if (dontRun) {
      return;
    }

    if (callOnStart) {
      const stop = await func();
      if (stop) {
        return;
      }
    }

    intervalRef.value = setInterval(() => {
      intervalFuncWrapper();
    }, time);
  };

  useEffect(() => {
    firstFuncWrapper();

    return () => {
      if (intervalRef.value) {
        clearInterval(intervalRef.value);
      }
    };
  }, refresher || []);
}
