import { code, iam } from 'codemash';
import { Config } from '../configs';
import { makeId } from '../utils/stringHelper';
import { mapApiToDataGuestUser } from './mappers/auth';
import { manageApiUnavailableError, manageFunctionResponse } from './error/errors';
import { apiActionChooser } from '../utils/apiActionChooser';
import { getGuestRequest, registerGuestRequest } from './client/requests';
import { DomainConfig } from '../configs/domain';

export const getUser = async ({ token }) => {
  try {
    let response;
    let responseObject;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await iam.getProfile({
          secretKey: token,
        });

        responseObject = {
          user: mapApiToDataGuestUser({ user: response }),
        };
      },
      async () => {
        response = await getGuestRequest({ token });

        responseObject = {
          user: mapApiToDataGuestUser({
            user: {
              id: response.id,
              displayName: response.id,
            },
          }),
        };
      },
    );

    return responseObject;
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get user', e);
    }
  }

  return {};
};

export const registerGuest = async () => {
  try {
    let response;
    let responseObject;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await iam.registerGuest({
          displayName: makeId(10),
        });

        responseObject = {
          token: response.bearerToken,
          user: mapApiToDataGuestUser({ user: response.result }),
        };
      },
      async () => {
        response = await registerGuestRequest();

        responseObject = {
          token: response.accessToken,
          user: mapApiToDataGuestUser({
            user: {
              id: response.id,
              displayName: response.id,
            },
          }),
        };
      },
    );

    return responseObject;
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to register guest user', e);
    }
  }

  return {};
};

export const testFeaturesLogin = async ({ accessToken, restaurantId }) => {
  try {
    let managedResponse;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        const response = await code.executeFunction({
          functionId: Config.DATA.FUNCTIONS.CHECK_TEST_ACCESS_TOKEN,
          data: {
            accessToken,
            restaurantId,
          },
        });

        managedResponse = manageFunctionResponse({
          response,
        });

        if (managedResponse.isError) {
          if (!managedResponse.handled) {
            //
          }
          managedResponse = null;
        }
      },
      async () => {
        // v2 not supported
      },
    );

    return managedResponse;
  } catch (e) {
    if (Config.SHOW_LOGS) {
      console.log('Error while trying to login', e);
    }
  }

  return null;
};
