import * as server from './server';

export async function resourceRequest({ data, action }) {
  const response = await executeBodyRequest({
    method: 'POST',
    url: `${server.Config.apiUrl}${`/resources/v1/${action}`}`,
    body: JSON.stringify(data || {}),
  });

  if (!response || !response.result) {
    return null;
  }

  if (typeof response.result === 'string') {
    if (action === 'find') {
      return {
        ...response,
        result: JSON.parse(response.result),
      };
    }

    return JSON.parse(response.result);
  }

  return response.result;
}

export async function functionRequest({ functionName, data }) {
  const response = await executeBodyRequest({
    method: 'POST',
    url: `${server.Config.apiUrl}/functions/invoke`,
    body: JSON.stringify({
      functionName,
      data,
    }),
  });

  if (!response) {
    return null;
  }

  if (response.result) {
    return response.result;
  }

  return response;
}

export async function registerGuestRequest() {
  const response = await executeBodyRequest({
    method: 'POST',
    url: `${server.Config.apiUrl}/users/v1/guests/register`,
    body: JSON.stringify({}),
  });

  return response;
}

export async function getGuestRequest({ token }) {
  const response = await executeBodyRequest({
    method: 'GET',
    url: `${server.Config.apiUrl}/users/v1/guests?token=${token}`,
  });

  return response;
}

export async function postFile({ file, resource, fieldPath }) {
  const formData = new FormData();

  if (fieldPath != null && fieldPath !== undefined) {
    formData.append('fieldPath', fieldPath);
  }

  formData.append('data', file);
  formData.append('resource', resource);

  const response = await executeBodyRequest({
    method: 'POST',
    url: `${server.Config.apiUrl}/files`,
    body: formData,
  });

  return response;
}

function getAuthToken() {
  const tokenV2 = global.localStorage.getItem('@UserTokenV2');
  if (!tokenV2) {
    return null;
  }

  return tokenV2;
}

async function executeBodyRequest({ url, body, method }) {
  const token = getAuthToken();

  const headers = {
    Accept: 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (typeof body === 'string') {
    headers['Content-Type'] = 'application/json';
  }

  const response = await server.loadJson(url, {
    method,
    headers,
    body,
  });

  return response;
}
