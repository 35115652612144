export const fetchData = async (apiUrl) => {
  try {
    const response = await fetch(apiUrl);

    if (response.ok) {
      const data = await response.json();
      return { response: data, error: null };
    } else {
      const errorData = await response.json();
      console.error('Error making POST request:', response.status);

      return { response: null, error: errorData };
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }

  return null;
};

export const postData = async (apiUrl, requestData) => {
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    if (response.ok) {
      const data = await response.json();
      return { response: data, error: null };
    } else {
      const errorData = await response.json();
      console.error('Error making POST request:', response.status);

      return { response: null, error: errorData };
    }
  } catch (error) {
    console.error('Error making POST request:', error);
  }

  return {};
};
