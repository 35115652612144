import { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Context as RestaurantContext } from '../context/restaurant';
import { Context as CartContext } from '../context/cart';
import { restaurantTypes } from '../enums/restaurantTypes';

export function useDeliveryMenuInit({
  isLoadingDeliveryInit,
  isSelectedRestaurantInit,
  selectedRestaurant,
}) {
  const navigate = useNavigate();
  const { slug } = useParams();

  const { state: restaurantState, getMenuWithCustomParams } = useContext(RestaurantContext);
  const { applyDeliveryDiscounts } = useContext(CartContext);

  const getMenuAsync = async (restaurant, table) => {
    const { discountCartService, locked } = await getMenuWithCustomParams({
      tableId: table.id,
      tableSection: table.section,
      timezone: restaurant.timeZone,
      companyId: restaurant.companyId,
      integrationId: restaurant.integrationId,
      provider: restaurant.provider,
      restaurantId: restaurant.id,
      useMenuOptimizations: restaurant.useMenuOptimizations,
      restaurantType: restaurantTypes.OrderAndPay,
    });

    if (locked) {
      return;
    }

    applyDeliveryDiscounts(restaurant.id, discountCartService);
  };

  useEffect(() => {
    if (
      restaurantState.menuGot &&
      selectedRestaurant &&
      restaurantState.menuRestaurantId === selectedRestaurant.id
    ) {
      return;
    }

    if (isLoadingDeliveryInit || !isSelectedRestaurantInit) {
      return;
    }

    if (selectedRestaurant) {
      if (!selectedRestaurant.data || !selectedRestaurant.table) {
        navigate(`/${slug}`, { replace: true });
        return;
      }

      getMenuAsync(selectedRestaurant.data, selectedRestaurant.table);
    } else {
      navigate(`/${slug}`, { replace: true });
    }
  }, [selectedRestaurant, isLoadingDeliveryInit, isSelectedRestaurantInit]);
}
