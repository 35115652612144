import {
  currentDayMinutes,
  getDayInCustomTimezone,
  getFullDateInCustomTimezone,
  unspecifiedDateToMillis,
} from '../../../utils/dateHelpers';

export const isProductInSchedule = (product, timeZone) => {
  if (!product.schedule) {
    return true;
  }

  const currentMillis = unspecifiedDateToMillis(getFullDateInCustomTimezone(new Date(), timeZone));

  if (product.schedule.start_sale_from > 0) {
    const startFromMillis = product.schedule.start_sale_from;

    if (startFromMillis > currentMillis) {
      return false;
    }
  }

  if (product.schedule.stop_sale_at > 0) {
    const stopAtMillis = product.schedule.stop_sale_at;

    if (stopAtMillis < currentMillis) {
      return false;
    }
  }

  // check time periods if set
  if (product.schedule.day_times && product.schedule.day_times.length > 0) {
    let currentDay = parseInt(getDayInCustomTimezone(undefined, timeZone, 'd') || '0', 10);
    // sunday is returned as 0, we need 7
    if (currentDay === 0) {
      currentDay = 7;
    }

    const currentMinutes = currentDayMinutes(timeZone);

    const dayTimes = product.schedule.day_times;
    let isInPeriod = false;

    for (let i = 0; i < dayTimes.length; i += 1) {
      // if day is set and not included in this day, continue
      if (dayTimes[i].day && dayTimes[i].day !== currentDay) {
        continue;
      }

      // if included in this time
      if (isProductInTimeRange(dayTimes[i], currentMinutes)) {
        isInPeriod = true;
      }
    }

    return isInPeriod;
  }

  return true;
};

const isProductInTimeRange = (schedule, currentMinutes) => {
  const fromMinutes = schedule.from_hour * 60 + (schedule.from_minute || 0);
  const toMinutes = schedule.to_hour * 60 + (schedule.to_minute || 0);

  if (Number.isNaN(fromMinutes) && Number.isNaN(toMinutes)) {
    return true;
  }

  if (Number.isNaN(toMinutes) && fromMinutes <= currentMinutes) {
    return true;
  }

  if (Number.isNaN(fromMinutes) && toMinutes >= currentMinutes) {
    return true;
  }

  if (fromMinutes <= currentMinutes && toMinutes >= currentMinutes) {
    return true;
  }

  return false;
};
