import React from 'react';

export const PriceCrossed = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="20"
      viewBox="0 0 45 20"
      fill="none"
      preserveAspectRatio="none"
      className="absolute w-3/4 left-1/2 -translate-x-1/2 -bottom-[2px]"
    >
      <path
        d="M1.69473 18.2623C11.2069 13.4578 32.8889 3.46331 43.5199 1.92057"
        className="stroke-primaryOrange"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PriceUnderline = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="9"
      viewBox="0 0 74 9"
      fill="none"
      preserveAspectRatio="none"
      className="w-full absolute -bottom-2 left-0"
    >
      <path
        d="M2 6.83116C18.5 3.83116 55.6 -0.868845 72 4.33116"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-primaryOrange"
      />
    </svg>
  );
};
