/* eslint-disable no-nested-ternary */
import i18next from 'i18next';
// import { navigateRef } from '../../services/navigationService';

export function manageFunctionResponse({ response, alertController }) {
  let errorMessage;
  const managedError = {
    code: 0,
    message: '',
    handled: false,
    isError: true,
  };

  if (response) {
    const parsedResponse =
      typeof response === 'string'
        ? JSON.parse(response)
        : response && typeof response.response === 'string'
        ? JSON.parse(response.response)
        : response;
    if (parsedResponse.statusCode >= 200 && parsedResponse.statusCode <= 299) {
      if (!parsedResponse.body || !parsedResponse.body.errorCode) {
        return parsedResponse.body || {};
      }

      if (parsedResponse.body.errorCode) {
        parsedResponse.statusCode = 400;
      }
    }

    if (parsedResponse.name === 'Validation') {
      managedError.code = '400';
      managedError.message =
        parsedResponse.errors && parsedResponse.errors.length > 0
          ? parsedResponse.errors[0].message
          : null;
    }

    const tempErrorCode =
      parsedResponse.statusCode ||
      parsedResponse.code ||
      (parsedResponse.body ? parsedResponse.body.code : '');
    const tempErrorMessage =
      parsedResponse.message ||
      (parsedResponse.body ? parsedResponse.body.message || parsedResponse.body.errorCode : '');

    if (tempErrorCode >= 400 && tempErrorMessage) {
      if (tempErrorMessage === 'bad_data') {
        errorMessage = i18next.t('errors.invalidRequestData');
      }

      managedError.code = tempErrorCode;
      managedError.message = tempErrorMessage;
      managedError.raw = { ...parsedResponse };
    }
  }

  // global error
  if (alertController && errorMessage) {
    alertController.error({
      text: errorMessage,
    });
  }

  // if not globabl error
  if (!errorMessage) {
    return managedError;
  }
  managedError.handled = true;
  return managedError;
}

export function manageApiUnavailableError(e) {
  if (e.name === 'TypeError' && e.message === 'Failed to fetch') {
    // navigateRef.navigate('/unavailable'); // This sometimes navigates unexpectedly, i think when i try to fetch in a background
    // throw e;
  }
}
