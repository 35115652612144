import React, { useEffect, useMemo, useState } from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import RestaurantSelectCard from './restaurantSelectCard';
import CategorySelectCard from './categorySelectCard';
import { getTranslatedData } from '../../localization';

const RestaurantSelectOptions = ({ categories, restaurants, onSelect }) => {
  const { t } = useTranslation(['delivery']);

  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const paramCategoryId = new URLSearchParams(search).get('category');

  const [selectedCategory, setSelectedCategory] = useState(
    paramCategoryId && categories ? categories.find((x) => x.id === paramCategoryId) : null,
  );

  useEffect(() => {
    if (paramCategoryId && categories) {
      setSelectedCategory(categories.find((x) => x.id === paramCategoryId));
    } else if (!paramCategoryId) {
      setSelectedCategory(null);
    }
  }, [paramCategoryId, categories]);

  const renderRestaurantsHeader = () => {
    if (selectedCategory) {
      return getTranslatedData(selectedCategory.name);
    }

    return t('delivery:home.allRestaurants');
  };

  const restaurantsToShow = useMemo(() => {
    if (selectedCategory) {
      return restaurants && selectedCategory.restaurants
        ? restaurants.filter((x) => selectedCategory.restaurants.includes(x.id))
        : null;
    }

    return restaurants;
  }, [selectedCategory]);

  return (
    <div>
      <h2 className="font-primarySemiBold text-primary text-2xl md:text-3xl md:text-center">
        {t('delivery:home.chooseRestaurant')}
      </h2>
      {!!selectedCategory && (
        <button
          type="button"
          onClick={() => {
            navigate(-1);
          }}
          className="flex flex-row justify-center items-center py-2 cursor-pointer mt-1"
        >
          <MdArrowBackIos />{' '}
          <div className="ml-4 font-primaryMedium text-primary text-base">
            {t('delivery:home.allCategories')}
          </div>
        </button>
      )}
      <div className="mb-8" />
      {!selectedCategory && categories && categories.length > 0 && (
        <>
          <h2 className="font-primarySemiBold text-primary text-xl md:text-2xl mb-6">
            {t('delivery:home.categories')}
          </h2>
          <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-4 gap-y-4 mb-10">
            {categories.map((category) => (
              <CategorySelectCard
                key={category.id}
                category={category}
                onSelect={() => {
                  navigate({ pathname, search: `?category=${category.id}` });
                }}
              />
            ))}
          </div>
        </>
      )}
      {restaurants && restaurants.length > 0 && (
        <>
          <h2 className="font-primarySemiBold text-primary text-xl md:text-xl mb-6">
            {renderRestaurantsHeader()}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-6 mb-10">
            {restaurantsToShow.map((restaurant) => (
              <RestaurantSelectCard
                key={restaurant.id}
                restaurant={restaurant}
                onSelect={() => onSelect(restaurant, selectedCategory)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default RestaurantSelectOptions;
