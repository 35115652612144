import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../layouts/mainLayout';
import Loader from '../../components/misc/loader';
import { Context as DeliveryContext } from '../../context/delivery';
import { Context as RestaurantContext } from '../../context/restaurant';
import TopBar from '../../layouts/delivery/topBar';
import RestaurantSelectOptions from '../../features/restaurant/restaurantSelectOptions';
import ContentWrapper from '../../layouts/delivery/contentWrapper';
import { useDeliveryPageInit } from '../../hooks/useDeliveryPageInit';

const RestaurantSelectPage = () => {
  const navigate = useNavigate();

  const { isLoadingDeliveryInit } = useDeliveryPageInit();

  const { state: deliveryState, setSelectedRestaurant } = useContext(DeliveryContext);
  const { setDeliveryRestaurant } = useContext(RestaurantContext);

  if (isLoadingDeliveryInit) {
    return <Loader />;
  }

  return (
    <MainLayout>
      <TopBar showLogo />
      <div className="mt-8" />
      <ContentWrapper>
        <RestaurantSelectOptions
          categories={deliveryState.settings.categories}
          restaurants={deliveryState.restaurants}
          onSelect={(restaurant, selectedCategory) => {
            setSelectedRestaurant(restaurant.id, selectedCategory ? selectedCategory.id : null);
            setDeliveryRestaurant(restaurant.data);
            navigate(`/${restaurant.slug || restaurant.id}`);
          }}
        />
      </ContentWrapper>
    </MainLayout>
  );
};

export default RestaurantSelectPage;
