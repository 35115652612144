import { getTranslatedData } from '../../../localization';

export const getComboItemName = (item) => {
  // Choose correct option name
  let itemName = getTranslatedData(item.name, { dontUseOtherLanguage: true });
  if (item.comboProduct && !itemName) {
    itemName = getTranslatedData(item.comboProduct.name, { dontUseOtherLanguage: true });
  }
  if (!itemName) {
    itemName = getTranslatedData(item.name);

    if (item.comboProduct && !itemName) {
      itemName = getTranslatedData(item.comboProduct.name);
    }
  }

  return itemName;
};
