import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import RaitingButton from '../../../components/buttons/rating';
import BottomModal from '../../../components/modal/bottomModal';
import { useBackPress } from '../../../hooks/useBackPress';
import TextInput, { inputType } from '../../../components/inputs/textInput';
import ConfirmButton from '../../../components/buttons/confirmButton';

const PaymentRating = forwardRef(
  ({ payment, restaurantName, reviewLink, onUpdateRating, fromHome }, ref) => {
    const { t } = useTranslation();

    const [ratingModalVisible, setRatingModalVisible] = useState(false);
    const [displayRating, setDisplayRating] = useState(
      payment && payment.rating ? payment.rating.rating || 0 : 0,
    );

    const [editRating, setEditRating] = useState(
      payment && payment.rating ? payment.rating.rating || 0 : 0,
    );
    const [comment, setComment] = useState(
      payment && payment.rating ? payment.rating.comment || '' : '',
    );

    const hasExternalReview = !!reviewLink;

    useImperativeHandle(ref, () => ({
      openReviewForm() {
        openReviewForm();
      },
    }));

    const { handleBackOnModalOpen, handleBackOnModalClose } = useBackPress(
      fromHome ? '/' : '/payment',
      () => {
        setRatingModalVisible(false);
      },
    );

    const onReviewConfirm = (newRating, newComment) => {
      setDisplayRating(newRating);
      onUpdateRating(newRating, newComment);
      setRatingModalVisible(false);

      if (fromHome) {
        setTimeout(() => {
          setDisplayRating(0);
          setEditRating(0);
          setComment('');
        }, 1000);
      }
    };

    const renderRatingEdit = () => {
      return (
        <div>
          <RaitingButton
            rating={editRating}
            onChange={(val) => {
              setEditRating(val);
            }}
          />
          <div className="mt-7" />
          <TextInput
            id="review-comment"
            onChange={setComment}
            value={comment}
            type={inputType.longText}
            placeholder={t('payment.leaveComment')}
          />
          <ConfirmButton
            onClick={() => {
              onReviewConfirm(editRating, comment);
            }}
            disabled={!editRating}
          />
        </div>
      );
    };

    const openReviewForm = () => {
      if (hasExternalReview) {
        global.window.open(reviewLink, '_blank');
      } else {
        setRatingModalVisible(true);
        handleBackOnModalOpen();
      }
    };

    return (
      <>
        {!fromHome && (
          <div className="flex justify-center">
            <div className="bg-successLight rounded-3xl px-8 sm:px-20 py-8 mt-5 flex justify-center items-center flex-col max-w-xl">
              <a
                className="font-primaryMedium text-success text-xl text-center mb-2 cursor-pointer"
                onClick={() => {
                  openReviewForm();
                }}
              >
                {t('payment.rateYourExperience', {
                  restaurantName: restaurantName || '',
                })}
              </a>
              <div
                role="button"
                onClick={() => {
                  openReviewForm();
                }}
              >
                {!hasExternalReview && <RaitingButton rating={displayRating} disabled />}
                {hasExternalReview && (
                  <div className="font-primaryMedium text-base underline mt-3">
                    {t('buttons.leaveReview')}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <BottomModal
          title={t('payment.reviewTitle')}
          content={renderRatingEdit()}
          onClose={() => {
            setRatingModalVisible(false);
            handleBackOnModalClose();
          }}
          onOutClick={() => {
            setRatingModalVisible(false);
            handleBackOnModalClose();
          }}
          open={ratingModalVisible}
        />
      </>
    );
  },
);

export default PaymentRating;
