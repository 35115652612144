import React from 'react';
import { useDeliveryColors } from '../../../../../hooks/useDeliveryColors';
import { getTranslatedData } from '../../../../../localization';

const ProductCustomTags = ({ tags, isDelivery }) => {
  const colors = useDeliveryColors();

  if (!tags || tags.length === 0) {
    return null;
  }

  const renderSingleTag = (tag, ind) => {
    const text = getTranslatedData(tag.tag);
    if (!text) {
      return null;
    }

    return (
      <div
        key={`c-tag-${ind}`}
        className={`mr-1 ${
          isDelivery ? 'text-xs font-primaryMedium' : 'text-sm font-primaryMedium'
        } border-[1px] border-primary px-2 py-[1px]
           text-primary rounded-full inline-block mt-2`}
        style={{
          color: colors.hasCustomPrimary ? colors.primary : undefined,
          borderColor: colors.hasCustomPrimary ? colors.primary : undefined,
        }}
      >
        {text}
      </div>
    );
  };

  return <>{tags.map((tag, ind) => renderSingleTag(tag, ind))}</>;
};

export default ProductCustomTags;
