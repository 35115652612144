import React, { useContext } from 'react';
import { Context as AlertContext } from '../../context/alert';
import Alert from '../../components/misc/alert';
import FooterPlace from './footer';

const LayoutPlace = ({ children, noFooter, showPayments }) => {
  const { state: alertState, alertController } = useContext(AlertContext);

  return (
    <div className="flex flex-col justify-between mx-auto w-full max-w-4xl h-screen px-5">
      <div className="flex flex-col w-full flex-1">{children}</div>
      {alertState.isVisible && (
        <Alert
          message={alertState.text}
          type={alertState.type}
          onClose={() => alertController.hide()}
        />
      )}
      {!noFooter && (
        <div className="mt-9 pb-4">
          <FooterPlace showPayments={showPayments} />
        </div>
      )}
    </div>
  );
};

export default LayoutPlace;
