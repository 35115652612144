import i18next from 'i18next';
import createDataContext from './_init_/createDataContext';
import { rateOrder as rateOrderApi, rateRestaurant as rateRestaurantApi } from '../api/order';
import { getPaymentInvoice } from '../api/payment';

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_bill':
      return { ...state, bill: action.payload };
    case 'set_bill_discount': {
      const { bill } = state;
      const newBill = { ...(bill || {}), discount: action.payload };

      return { ...state, bill: newBill };
    }
    case 'set_loading':
      return { ...state, [action.key]: action.payload };
    default:
      return state;
  }
};

const setFullBill =
  (dispatch) =>
  async ({ subtotal, orderId }) => {
    dispatch({ type: 'set_bill', payload: { type: 'full', subtotal, orderId } });
  };

const setSplitBill =
  (dispatch) =>
  async ({ splitType, data }) => {
    dispatch({ type: 'set_bill', payload: { type: 'split', splitType, ...data } });
  };

const setOrderBill =
  (dispatch) =>
  async ({ subtotal, discount, cart, note, totalGuests }) => {
    dispatch({
      type: 'set_bill',
      payload: {
        type: 'order',
        subtotal,
        discount,
        cart,
        note,
        totalGuests,
      },
    });
  };

const autoRefreshOrderBill =
  (dispatch, state) =>
  ({ subtotal, discount, cart }) => {
    if (!state.bill || state.bill.type !== 'order') {
      return;
    }

    dispatch({
      type: 'set_bill',
      payload: {
        type: state.bill.type,
        subtotal,
        discount,
        cart,
        note: state.bill.note,
        totalGuests: state.bill.totalGuests,
      },
    });
  };

const changeBillDiscount = (dispatch) => async (discount) => {
  dispatch({
    type: 'set_bill_discount',
    payload: discount,
  });
};

const rateOrder =
  () =>
  async ({ token, restaurantId, orderId, paymentId, userId, rating, comment }) => {
    await rateOrderApi({
      token,
      restaurantId,
      orderId,
      paymentId,
      userId,
      rating,
      comment,
    });
  };

const rateRestaurant =
  () =>
  async ({ token, restaurantId, userId, rating, comment, alertController }) => {
    await rateRestaurantApi({
      token,
      restaurantId,
      userId,
      rating,
      comment,
      alertController,
    });
  };

const lockedDownload = {
  value: false,
};

const downloadPaymentInvoice =
  () =>
  async ({ isDownload, paymentId, email, alertController }) => {
    if (lockedDownload.value) {
      return;
    }

    if (!isDownload && !email) {
      return;
    }

    alertController.success({ text: i18next.t('payment.invoiceSoonSend') });

    lockedDownload.value = true;

    const response = await getPaymentInvoice({
      paymentId,
      email,
      isDownload,
      alertController,
    });

    if (response && response.emailSent) {
      alertController.success({ text: i18next.t('payment.invoiceSent') });
    } else if (response && response.downloadLink) {
      downloadFileFromUrl(response.downloadLink);
    }

    lockedDownload.value = false;
  };

const downloadFileFromUrl = (url) => {
  // eslint-disable-next-line no-undef
  const aTag = document.createElement('a');

  aTag.href = url;
  aTag.setAttribute('download', '');

  // eslint-disable-next-line no-undef
  document.body.appendChild(aTag);

  aTag.click();
  aTag.remove();
};

const initialData = {
  bill: null,
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    setFullBill,
    setSplitBill,
    setOrderBill,
    changeBillDiscount,
    rateOrder,
    rateRestaurant,
    downloadPaymentInvoice,
    autoRefreshOrderBill,
  },
  { ...initialData },
);
