import React, { useContext, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { appLanguages } from '../../localization';
import { Context as SettingsContext } from '../../context/settings';
import { Context as DeliveryContext } from '../../context/delivery';
import { Config } from '../../configs';

const LanguageDropDown = () => {
  const { i18n } = useTranslation();

  const selectedLanguage = appLanguages.find((lang) => lang.langCode === i18n.language);

  const [availableLanguages, setAvailableLanguages] = useState(appLanguages);
  const [areLanguagesFiltered, setAreLanguagesFiltered] = useState(false);

  const { state: settingsState } = useContext(SettingsContext);
  const { state: deliveryState } = useContext(DeliveryContext);

  useEffect(() => {
    if (!deliveryState.restaurants || !deliveryState.selectedRestaurantId) {
      if (areLanguagesFiltered) {
        setAvailableLanguages(appLanguages);
        setAreLanguagesFiltered(false);
      }

      return;
    }

    const selectedRestaurant = deliveryState.restaurants.find(
      (x) => x.id === deliveryState.selectedRestaurantId,
    );

    if (
      selectedRestaurant &&
      selectedRestaurant.data &&
      selectedRestaurant.data.availableAppLanguages &&
      selectedRestaurant.data.availableAppLanguages.length > 0
    ) {
      const sortedFilteredAppLanguages = [];
      selectedRestaurant.data.availableAppLanguages.forEach((langCode) => {
        const foundAppLanguage = appLanguages.find((x) => x.langCode === langCode);
        if (foundAppLanguage) {
          sortedFilteredAppLanguages.push(foundAppLanguage);
        }
      });

      setAvailableLanguages(sortedFilteredAppLanguages);
      setAreLanguagesFiltered(true);
    }
  }, [deliveryState.restaurants, deliveryState.selectedRestaurantId]);

  const renderLanguageItem = (langCode, langName, flagName) => {
    return (
      <Menu.Item key={langCode}>
        <button
          type="button"
          className={`${
            selectedLanguage.langCode === langCode
              ? 'bg-gray-100 text-gray-900'
              : 'bg-white text-gray-700'
          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
          onClick={() => {
            i18n.changeLanguage(langCode);
            if (langCode) {
              global.localStorage.setItem(Config.CACHE_KEYS.SELECTED_APP_LANGUAGE, langCode);
            }
          }}
        >
          <div className="flex flex-row items-center ">
            <img
              alt={langCode}
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${flagName}.svg`}
              className="h-3 mr-3"
            />
            <div>{langName}</div>
          </div>
        </button>
      </Menu.Item>
    );
  };

  const renderLanguagePicker = () => {
    return (
      <div className="flex justify-end relative z-10">
        <div className="relative inline-block text-left">
          <Menu>
            {({ open }) => (
              <>
                <span className="rounded-md shadow-sm">
                  <Menu.Button
                    className="inline-flex justify-center w-full text-sm font-medium leading-5 text-gray-700
                    transition duration-150 ease-in-out bg-white rounded-md hover:text-gray-500 focus:outline-none
                    focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                  >
                    <span className={settingsState.isDeliveryLayout ? 'text-base' : 'text-sm'}>
                      {selectedLanguage.name}
                    </span>
                    <svg
                      className={`${
                        settingsState.isDeliveryLayout ? 'w-6 h-6' : 'w-5 h-5'
                      } ml-2 -mr-1`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Menu.Button>
                </span>

                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="absolute right-0 w-24 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100
                    rounded-md shadow-lg outline-none max-h-64 overflow-auto"
                  >
                    <div className="">
                      {availableLanguages.map((item) =>
                        renderLanguageItem(item.langCode, item.name, item.flagName),
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    );
  };

  if (settingsState.isDeliveryLayout) {
    return (
      <div className="px-4 py-[6px] bg-white rounded-full max-h-9 shadow-md">
        {renderLanguagePicker()}
      </div>
    );
  }

  return renderLanguagePicker();
};

export default LanguageDropDown;
