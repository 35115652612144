import React from 'react';
import { FaCheck } from 'react-icons/fa';

export const CheckBox = ({
  checked,
  onClick,
  title,
  disabled,
  hideCheck,
  labelTop,
  customColor,
  isSquare,
}) => {
  return (
    <div
      role={hideCheck ? undefined : 'button'}
      onClick={() => {
        if (onClick && !disabled) {
          onClick(!checked);
        }
      }}
      className={`flex items-center py-1 ${labelTop ? 'flex-col-reverse' : 'flex-row'}`}
    >
      {!hideCheck && (
        <div
          className={`${
            isSquare ? 'rounded-sm' : 'rounded-full'
          } w-5 h-5 border flex justify-center items-center shrink-0`}
          style={{
            background: checked ? customColor || '#000000' : '#ffffff',
            borderColor: checked ? customColor || '#000000' : '#000000',
          }}
        >
          {checked && !isSquare && <div className="w-2 h-2 bg-white rounded-full" />}
          {checked && !!isSquare && <FaCheck className="text-sm" color="#ffffff" />}
        </div>
      )}
      {title && (
        <div
          className={`${
            hideCheck ? 'font-primaryRegular' : 'font-primaryMedium'
          } text-black text-base ${
            // eslint-disable-next-line no-nested-ternary
            labelTop ? 'mb-2' : hideCheck ? '' : 'ml-4'
          }`}
        >
          {title}
        </div>
      )}
    </div>
  );
};
