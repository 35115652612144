import { useTranslation } from 'react-i18next';

export const hasProductPreparationTime = (product) => {
  if (product.isLongDelivery) {
    return product.longDeliveryPrepare > 0;
  }

  return product.prepareFrom > 0 || product.prepareTo > 0;
};

const ProductPreparationTime = ({ product }) => {
  const { t } = useTranslation();

  if (product.isLongDelivery) {
    if (!product.longDeliveryPrepare) {
      return '';
    }

    return `${product.longDeliveryPrepare} d.`;
  }

  const prepareFrom = !product.prepareFrom || product.prepareFrom < 0 ? 0 : product.prepareFrom;
  const prepareTo = !product.prepareTo || product.prepareTo < 0 ? 0 : product.prepareTo;

  if (!prepareFrom && !prepareTo) {
    return '';
  }

  if (prepareFrom && !prepareTo) {
    return `${prepareFrom} ${t('products.minutesAbbreviation')}`;
  }

  if (!prepareFrom && prepareTo) {
    return `${prepareTo} ${t('products.minutesAbbreviation')}`;
  }

  return `${prepareFrom} - ${prepareTo} ${t('products.minutesAbbreviation')}`;
};

export default ProductPreparationTime;
