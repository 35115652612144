/* eslint-disable no-undef */

import { parseSafeJSON } from '../utils/jsonHelpers';

const tableDataKey = '@ActiveTable';

export const activeTable = {
  id: null,
  qr: null,
  currency: null,
};

export const initTableOnLoad = () => {
  const tableData = localStorage.getItem(tableDataKey);

  if (!tableData) {
    return;
  }

  const tableDataJson = parseSafeJSON(tableData, true);
  if (tableDataJson === null) {
    localStorage.removeItem(tableDataKey);
    return;
  }

  activeTable.id = tableDataJson.id;
  activeTable.qr = tableDataJson.qr;
  activeTable.currency = tableDataJson.currency;
};

export const cacheTable = ({ tableId, tableQr, currency }) => {
  localStorage.setItem(
    tableDataKey,
    JSON.stringify({
      id: tableId,
      qr: tableQr,
      currency,
    }),
  );

  activeTable.id = tableId;
  activeTable.qr = tableQr;
  activeTable.currency = currency;
};

export const removeTableFromCache = () => {
  localStorage.removeItem(tableDataKey);
};
