import React, { useContext, useEffect } from 'react';
import { Context as RestaurantContext } from '../../../../context/restaurant';
import EventCard from './eventCard';
import Loader from '../../../../components/misc/loader';

const EventsList = ({ restaurantId }) => {
  const { state: restaurantState, getEvents } = useContext(RestaurantContext);

  useEffect(() => {
    getEvents({ restaurantId });
  }, []);

  return (
    <div className="px-5 xl:px-0 pb-20 md:pb-24 pt-28 md:pt-8 max-w-6xl mx-auto">
      {!restaurantState.loadingEvents &&
        !!restaurantState.events &&
        restaurantState.events.length > 0 && (
          <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10">
            {restaurantState.events.map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
          </div>
        )}
      {restaurantState.loadingEvents && <Loader customHeight="h-96" />}
    </div>
  );
};

export default EventsList;
