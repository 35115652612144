/* eslint-disable no-undef */

import { useRef, useState, useEffect } from 'react';

export function useMenuPositions(menu) {
  const categoriesRef = useRef({});

  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [categoriesY, setCategoriesY] = useState({});

  const [sreenRefresher, setScreenRefresher] = useState(0);

  const onImagePositionChange = () => {
    getPosition();
  };

  const getPosition = () => {
    const newY = {};

    Object.keys(categoriesRef.current).forEach((categoryId) => {
      if (categoriesRef.current[categoryId]) {
        newY[categoryId] = categoriesRef.current[categoryId].offsetTop;
      }
    });

    setCategoriesY(newY);

    const { innerHeight: height } = window;
    setWindowHeight(height);
  };

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    getPosition();
    handleScroll();

    setTimeout(() => {
      setScreenRefresher((prev) => prev + 1);
    }, 150);
  }, [menu]);

  useEffect(() => {
    window.addEventListener('resize', getPosition);

    return () => {
      window.removeEventListener('resize', getPosition);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    windowHeight,
    scrollPosition,
    categoriesY,
    categoriesRef,
    onImagePositionChange,
    canShowMenu: sreenRefresher > 0,
  };
}
