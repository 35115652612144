import React from 'react';
import { formFileUrlFromFileArrayWithExternalResized } from '../../../../../utils/fileHelper';

const ProductImage = ({ product, isDelivery, shouldContainImage }) => {
  const { listImage, listImageResized } = product || {};
  if (!listImage && !listImageResized) {
    return null;
  }

  const imageUrl = formFileUrlFromFileArrayWithExternalResized(listImage, listImageResized);
  if (!imageUrl) {
    return null;
  }

  return (
    <div
      className={`${isDelivery ? 'w-28 h-28' : 'w-24 h-24'} ${
        shouldContainImage ? 'bg-contain' : 'bg-cover'
      } rounded-lg bg-no-repeat bg-center`}
      style={{ backgroundImage: `url(${imageUrl})` }}
    />
  );
};

export default ProductImage;
