export const mapApiToDataGuestUser = ({ user }) => {
  if (!user) {
    return null;
  }

  const formedUser = {
    id: user.id,
    displayName: user.displayName,
  };

  return formedUser;
};
