import React from 'react';
import { MdClose } from 'react-icons/md';
import { BsArrowReturnRight } from 'react-icons/bs';
import { getTranslatedData } from '../../../localization';
import { useCurrency } from '../../../hooks/useCurrency';
import { getComboItemName } from '../../menu/services/products';

const CartItem = ({ product, onRemove, fromCheckout }) => {
  const { currencySymbol } = useCurrency();

  const renderVariations = () => {
    const itemNames = [];

    if (product.variations) {
      product.variations.forEach((variation) => {
        if (variation.items && variation.items.length > 0) {
          variation.items.forEach((item) => {
            if (item.comboProduct) {
              return;
            }

            itemNames.push(getTranslatedData(item.name));
          });
        } else {
          itemNames.push(getTranslatedData(variation.name));
        }
      });
    }

    return itemNames.join(', ');
  };

  const comboItems = [];
  let comboItemsTotal = 0;

  if (product.variations) {
    product.variations.forEach((variation) => {
      if (variation.items && variation.items.length > 0) {
        variation.items.forEach((item) => {
          if (!item.comboProduct) {
            return;
          }

          comboItems.push(item);
          comboItemsTotal += (item.price || 0) * product.quantity;
        });
      }
    });
  }

  const renderComboProducts = () => {
    if (comboItems.length === 0) {
      return null;
    }

    return comboItems.map((item, ind) => {
      const itemName = getComboItemName(item);

      if (fromCheckout) {
        return (
          <div className="mt-2">
            {renderCheckoutItem({
              image: item.comboProduct.listImageUrl,
              name: itemName,
              price: (item.price || 0) * product.quantity,
              quantity: product.quantity,
            })}
          </div>
        );
      } else {
        return (
          <div
            kay={`i-${ind}`}
            className="flex flex-row justify-between items-start border-b border-border pb-3 mb-4"
          >
            <div className="flex flex-row mr-2 flex-1 items-center">
              <BsArrowReturnRight className="text-bland ml-2 mr-4" size={16} />
              <div className="font-primaryMedium text-primary text-base w-7 mr-1 flex-shrink-0">
                {product.quantity}
              </div>
              <div className="">
                <div className="font-primaryMedium text-primary text-base">{itemName}</div>
              </div>
            </div>

            <div className="flex flex-row items-center">
              <div className="font-primaryMedium text-primary text-base whitespace-nowrap">
                {((item.price || 0) * product.quantity).toFixed(2)} {currencySymbol}
              </div>
            </div>
          </div>
        );
      }
    });
  };

  const renderCheckoutItem = ({ image, name, price, quantity, variationText }) => {
    return (
      <div className="flex flex-row justify-between items-center border border-input p-5 rounded-md">
        <div className="flex flex-row mr-8 items-center">
          {!!image && (
            <div>
              <div
                className="w-12 h-12 rounded-md bg-no-repeat bg-center bg-cover mr-3"
                style={{ backgroundImage: `url(${image})` }}
              />
            </div>
          )}
          <div>
            <div className="font-primaryMedium text-base text-primary">{name}</div>
            {!!variationText && (
              <div className="font-primaryRegular text-sm text-bland mt-1">{variationText}</div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="font-primaryMedium text-primary text-base mr-5 whitespace-nowrap">
            {(price || 0).toFixed(2)} {currencySymbol}
          </div>
          <div className="w-10 h-10 bg-[#F6F6F6] flex items-center justify-center font-primaryMedium text-primary text-base">
            {quantity}
          </div>
        </div>
      </div>
    );
  };

  const variationText = renderVariations();

  if (fromCheckout) {
    return (
      <>
        {renderCheckoutItem({
          image: product.listImageUrl,
          name: getTranslatedData(product.name),
          price: (product.total || 0) - comboItemsTotal,
          quantity: product.quantity,
          variationText,
        })}
        {renderComboProducts()}
      </>
    );
  }

  return (
    <>
      <div className="flex flex-row justify-between items-start border-b border-border pb-3 mb-4">
        <div className="flex flex-row mr-2 flex-1">
          <div className="font-primaryMedium text-primary text-base w-7 mr-1 flex-shrink-0">
            {product.quantity}
          </div>
          <div className="">
            <div className="font-primaryMedium text-primary text-base">
              {getTranslatedData(product.name)}
            </div>
            {!!variationText && (
              <div className="font-primaryMedium text-bland text-sm mt-1">{variationText}</div>
            )}
          </div>
        </div>

        <div className="flex flex-row items-center">
          <div className="font-primaryMedium text-primary text-base whitespace-nowrap">
            {((product.total || 0) - comboItemsTotal).toFixed(2)} {currencySymbol}
          </div>
          {!!onRemove && (
            <div
              role="button"
              className="ml-3 bg-[#F6F6F6] rounded-full w-7 h-7 flex justify-center items-center"
              onClick={(e) => {
                e.stopPropagation();

                onRemove(product.productId, product.index);
              }}
            >
              <MdClose size={14} className="text-primary" />
            </div>
          )}
        </div>
      </div>
      {renderComboProducts()}
    </>
  );
};

export default CartItem;
