import i18next from 'i18next';
import {
  currentUtcMillis,
  getDateInCustomTimezone,
  getFullDateInCustomTimezone,
  getTimeInCustomTimezone,
} from '../../../utils/dateHelpers';

export const buildRestaurantAddress = (restaurant) => {
  const parts = [];
  if (restaurant.street) {
    parts.push(restaurant.street);
  }

  if (restaurant.city) {
    parts.push(restaurant.city);
  }

  if (restaurant.country) {
    parts.push(restaurant.country);
  }

  if (restaurant.postalCode) {
    parts.push(restaurant.postalCode);
  }

  return parts.join(', ');
};

export const getDeliveryTimeText = ({ orderDate, deliveryDate, deliveryDateDelta, timeZone }) => {
  // Delivery date should always exist, in case it doesnt, add 30 min to order date
  deliveryDate = deliveryDate || orderDate + 30 * 60000;

  let earlyDeliveryDate;
  let lateDeliveryDate;
  if (deliveryDateDelta > 0) {
    earlyDeliveryDate = deliveryDate;
    lateDeliveryDate = deliveryDate + deliveryDateDelta * 60000;
  } else if (deliveryDateDelta < 0) {
    earlyDeliveryDate = deliveryDate - Math.abs(deliveryDateDelta) * 60000;
    lateDeliveryDate = deliveryDate;
  } else {
    earlyDeliveryDate = deliveryDate;
    lateDeliveryDate = deliveryDate;
  }

  const currentDate = getDateInCustomTimezone(currentUtcMillis(), timeZone);
  let formattedDateTime = getFullDateInCustomTimezone(earlyDeliveryDate, timeZone);

  if (lateDeliveryDate && earlyDeliveryDate !== lateDeliveryDate) {
    const formattedLateTime = getTimeInCustomTimezone(lateDeliveryDate, timeZone);
    formattedDateTime = `${formattedDateTime} - ${formattedLateTime}`;
  }

  if (formattedDateTime.includes(currentDate)) {
    formattedDateTime = formattedDateTime.replace(currentDate, i18next.t('delivery:days.today'));
  }

  return formattedDateTime;
};
