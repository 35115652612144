import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { MdCreditCard, MdLock, MdOpenInNew } from 'react-icons/md';
import { BiLinkExternal } from 'react-icons/bi';
import MainButton from '../../../components/buttons/main';
import { useCurrency } from '../../../hooks/useCurrency';
import StripeFullPaymentContainer from './integrations/stripeFullPaymentContainer';
import PayntPaymentContainer from './integrations/payntPaymentContainer';
import ZenPaymentContainer from './integrations/zenPaymentContainer';

const CheckoutPayment = ({
  paymentOption,
  onPay,
  disabled,
  total,
  paymentKey,
  onFail,
  onCancel,
  isDelivery, // This is in general, if this is a delivery type website, which will always be true
  deliveryOrderId,
  isTakeAway,
  selectedDeliveryPayment,
  onSelectDeliveryOption,
  cardFormSubmitRef,
  isValidOnSubmit,
  updatePaymentBeforeSubmit,
}) => {
  const { t } = useTranslation(['translation', 'delivery']);
  const { currencyCode } = useCurrency();

  const [cardFormVisible, setCardFormVisible] = useState(false);

  if (isDelivery && !isTakeAway && !deliveryOrderId) {
    return (
      <div>
        <p className="font-primaryMedium text-primary text-base">
          {t('delivery:checkout.provideDeliveryAddressForPayment')}
        </p>
      </div>
    );
  }

  if (!paymentOption || (!paymentOption.canPayBank && !paymentOption.canPayCard)) {
    return (
      <div>
        <p className="font-primaryMedium text-primary text-base">
          {t('translation:checkout.paymentNotSetup')}
        </p>
      </div>
    );
  }

  const renderDeliveryOption = (providerId, icon, title, subtitle, onClick, selected) => {
    if (
      isTakeAway &&
      providerId &&
      paymentOption.hiddenTakeawayOptions &&
      paymentOption.hiddenTakeawayOptions.includes(providerId)
    ) {
      return null;
    }

    if (
      !isTakeAway &&
      providerId &&
      paymentOption.hiddenDeliveryOptions &&
      paymentOption.hiddenDeliveryOptions.includes(providerId)
    ) {
      return null;
    }

    return (
      <div
        role="button"
        onClick={onClick}
        className={`flex flex-row justify-between items-center
          border ${
            selected ? 'border-primary bg-successLight' : 'border-input'
          } px-5 py-3 rounded-md min-h-[4.375rem]`}
      >
        <div className="flex flex-row items-center">
          <div>{icon}</div>
          <div>
            <div className="font-primarySemiBold text-base text-primary">{title}</div>
            {!!subtitle && <div className="font-primaryRegular text-sm text-bland">{subtitle}</div>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {!isDelivery && (
        <p className="font-primaryMedium text-primary text-base">
          {t('translation:checkout.choosePaymentMethod')}
        </p>
      )}
      {!isDelivery && (
        <p className="font-primaryMedium text-bland text-sm mt-1">
          {t('translation:checkout.securePayment')}{' '}
          <MdLock className="inline -mt-[3px]" color="#0C7F48" />
        </p>
      )}
      <div className={`${isDelivery ? '' : 'mt-8 max-w-xs mx-auto'} w-full`}>
        {paymentOption && paymentOption.canPayBank && (
          <>
            {isDelivery ? (
              renderDeliveryOption(
                paymentOption.bankProviderId,
                <BiLinkExternal className="text-primary mr-5" size={26} />,
                t('translation:buttons.bankTransfer'),
                t('delivery:checkout.bankExamples'),
                () => {
                  onSelectDeliveryOption('Bank');
                  if (paymentOption.cardProvider === 'Stripe') {
                    setCardFormVisible(false);
                  }

                  if (paymentOption.cardProvider === 'Paynt') {
                    setCardFormVisible(false);
                  }

                  if (paymentOption.cardProvider === 'Zen' && !paymentOption.zenUseRedirect) {
                    setCardFormVisible(false);
                  }
                },
                selectedDeliveryPayment === 'Bank',
              )
            ) : (
              <MainButton
                icon={<MdOpenInNew className="inline-block mr-2 -mt-[2px]" />}
                text={t('translation:buttons.bankTransfer')}
                onClick={() =>
                  onPay({ paymentType: 'Bank', paymentAccountId: paymentOption.bankProviderId })
                }
                disabled={disabled}
              />
            )}

            <div className="mt-3" />
          </>
        )}
        {paymentOption && paymentOption.canPayCard && (
          <>
            {isDelivery ? (
              renderDeliveryOption(
                paymentOption.cardProviderId,
                <BiLinkExternal className="text-primary mr-5" size={26} />,
                t('translation:buttons.cardPayment'),
                null,
                () => {
                  onSelectDeliveryOption('Card');
                  if (paymentOption.cardProvider === 'Stripe') {
                    setCardFormVisible(true);
                  }

                  if (paymentOption.cardProvider === 'Paynt') {
                    setCardFormVisible(true);
                  }

                  if (paymentOption.cardProvider === 'Zen' && !paymentOption.zenUseRedirect) {
                    setCardFormVisible(true);
                  }
                },
                selectedDeliveryPayment === 'Card',
              )
            ) : (
              <MainButton
                icon={<MdCreditCard className="inline-block mr-2 -mt-[3px]" />}
                text={t('translation:buttons.cardPayment')}
                onClick={() => {
                  if (paymentOption.cardProvider === 'Stripe') {
                    setCardFormVisible((prev) => !prev);
                  } else if (paymentOption.cardProvider === 'Paynt') {
                    setCardFormVisible((prev) => !prev);
                  } else if (
                    paymentOption.cardProvider === 'Zen' &&
                    !paymentOption.zenUseRedirect
                  ) {
                    setCardFormVisible((prev) => !prev);
                  } else {
                    onPay({ paymentType: 'Card', paymentAccountId: paymentOption.cardProviderId });
                  }
                }}
                disabled={disabled}
                outline={cardFormVisible}
              />
            )}
            <div className="mt-3" />
          </>
        )}
        {(paymentOption.cardProvider === 'Stripe' ||
          paymentOption.additionalWalletProvider === 'Stripe') && (
          <StripeFullPaymentContainer
            allowCards={paymentOption.stripeAllowCards}
            allowWallets={paymentOption.stripeAllowWallets}
            amount={total}
            cardFormVisible={cardFormVisible}
            currencyCode={currencyCode}
            onPay={({ paymentMethodId }) => {
              let stripePaymentAccountId;
              if (paymentOption.cardProvider === 'Stripe') {
                stripePaymentAccountId = paymentOption.cardProviderId;
              } else if (paymentOption.additionalWalletProvider === 'Stripe') {
                stripePaymentAccountId = paymentOption.additionalWalletProviderId;
              }

              onPay({
                paymentType: 'Card',
                paymentMethodId,
                paymentAccountId: stripePaymentAccountId,
              });
            }}
            onFail={onFail}
            publicKey={paymentOption.stripePublicKey}
            isConnected={paymentOption.stripeIsConnected}
            connectedId={paymentOption.stripeConnectedId}
            paymentKey={paymentKey}
            disabled={disabled}
            cardFormSubmitRef={cardFormSubmitRef}
            isDelivery={isDelivery}
            isValidOnSubmit={isValidOnSubmit}
          />
        )}
        {paymentOption.cardProvider === 'Paynt' && (
          <PayntPaymentContainer
            cardFormVisible={cardFormVisible}
            disabled={disabled}
            onPay={({ paymentTrackId }) => {
              onPay({
                paymentType: 'Card',
                dontDisable: true,
                paymentTrackId,
                paymentAccountId: paymentOption.cardProviderId,
              });
            }}
            paymentKey={paymentKey}
            isValidOnSubmit={isValidOnSubmit}
            amount={total}
            updatePaymentBeforeSubmit={updatePaymentBeforeSubmit}
          />
        )}
        {paymentOption.cardProvider === 'Zen' && !paymentOption.zenUseRedirect && (
          <ZenPaymentContainer
            amount={total}
            cardFormVisible={cardFormVisible}
            onPay={({ paymentTrackId }) => {
              onPay({
                paymentType: 'Card',
                dontDisable: true,
                paymentTrackId,
                paymentAccountId: paymentOption.cardProviderId,
              });
            }}
            paymentKey={paymentKey}
            disabled={disabled}
            onSetButtonLoading={(val) => {
              if (!val) {
                onCancel();
              }
            }}
            cardFormSubmitRef={cardFormSubmitRef}
          />
        )}
      </div>
      {!isDelivery && (
        <div className="flex justify-center">
          <button
            type="button"
            className="font-primaryMedium text-primary text-sm mt-2"
            onClick={() => {}}
          >
            <Trans i18nKey="checkout.agreeTerms">
              By checking out you agree with our{' '}
              <a
                className="underline"
                target="_blank"
                href="https://drive.google.com/file/d/1vYkyVHjaMx0e9qsToZh5S6-WBtcIXnyc/view"
                rel="noreferrer"
              >
                terms & conditions
              </a>{' '}
              and{' '}
              <a
                className="underline"
                target="_blank"
                href="https://drive.google.com/file/d/1Et5cXjKQ6vaNWElDDnEZB4K3Rz4Ch8D8/view"
                rel="noreferrer"
              >
                privacy policy
              </a>
            </Trans>
          </button>
        </div>
      )}
    </div>
  );
};

export default CheckoutPayment;
