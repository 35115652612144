import React, { useState, useEffect } from 'react';
import { getTranslatedData } from '../../localization';
import { useDeliveryColors } from '../../hooks/useDeliveryColors';

const RestaurantSelectCard = ({ restaurant, onSelect }) => {
  const [noBanner, setNoBanner] = useState(!!restaurant.imageUrl);
  const colors = useDeliveryColors();

  useEffect(() => {
    if (restaurant) {
      setNoBanner(!restaurant.imageUrl);
    }
  }, [restaurant]);

  const renderSpecialTag = () => {
    if (noBanner || !restaurant.specialTag || Object.keys(restaurant.specialTag).length === 0) {
      return null;
    }

    return (
      <div
        className="absolute top-7 left-0 py-2 px-4 font-primaryMedium text-xs text-white rounded-tr-full rounded-br-full bg-primary"
        style={{ backgroundColor: colors.hasCustomPrimary ? colors.primary : undefined }}
      >
        {getTranslatedData(restaurant.specialTag)}
      </div>
    );
  };

  return (
    <div
      className="rounded-xl border-input border pb-5 cursor-pointer hover:scale-103 transition duration-300 ease-in-out hover:shadow-sm relative"
      role="button"
      onClick={onSelect}
    >
      {!noBanner && (
        <div className="max-h-48 w-full overflow-hidden rounded-t-xl mb-5">
          <img
            className="object-cover object-center w-full"
            src={restaurant.imageUrl}
            alt="Restaurant"
            onError={() => {
              setNoBanner(true);
            }}
          />
        </div>
      )}
      {noBanner && <div className="mt-5" />}
      <div className="mx-8">
        <div className="font-primarySemiBold text-xl text-primary">
          {getTranslatedData(restaurant.title)}
        </div>
        <div className="mt-1 font-primaryRegular text-sm text-bland">
          {getTranslatedData(restaurant.description)}
        </div>
      </div>
      {renderSpecialTag()}
    </div>
  );
};

export default RestaurantSelectCard;
