import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Context as SettingsContext } from '../context/settings';
import { Context as DeliveryContext } from '../context/delivery';
import { Context as RestaurantContext } from '../context/restaurant';
import { Context as CartContext } from '../context/cart';
import { DomainConfig } from '../configs/domain';
import { useInterval } from './useInterval';

export function useDeliveryPageInit() {
  const navigate = useNavigate();
  const { restaurantSlug } = useParams();

  const { setIsDeliveryLayout } = useContext(SettingsContext);
  const {
    state: deliveryState,
    getDeliveryRestaurants,
    setSelectedRestaurant,
    setDefaultDeliveryType,
  } = useContext(DeliveryContext);
  const { setDeliveryRestaurant } = useContext(RestaurantContext);
  const { initDeliveryCart } = useContext(CartContext);

  const [isLoading, setIsLoading] = useState(!deliveryState.slug);
  const [isSelectedRestaurantInit, setIsSelectedRestaurantInit] = useState(
    !!deliveryState.selectedRestaurantId,
  );

  const selectedRestaurant = useMemo(() => {
    if (!deliveryState.restaurants || !deliveryState.selectedRestaurantId) {
      return null;
    }

    return deliveryState.restaurants.find((x) => x.id === deliveryState.selectedRestaurantId);
  }, [deliveryState.restaurants, deliveryState.selectedRestaurantId, isSelectedRestaurantInit]);

  useEffect(() => {
    if (deliveryState.slug) {
      return;
    }

    getRestaurants();
  }, []);

  useEffect(() => {
    if (!restaurantSlug) {
      setSelectedRestaurant(null);
      return;
    }

    // check if restaurant is already selected
    if (deliveryState.selectedRestaurantId) {
      setIsSelectedRestaurantInit(true);
      return;
    }

    // before trying to init restaurant, wait until restaurants are loaded
    if (isLoading) {
      return;
    }

    if (!deliveryState.restaurants || deliveryState.restaurants.length === 0) {
      navigate('/', { replace: true });
      return;
    }

    const initRestaurant = deliveryState.restaurants.find(
      (x) => x.slug === restaurantSlug || x.id === restaurantSlug,
    );
    if (!initRestaurant) {
      navigate('/', { replace: true });
      return;
    }

    setSelectedRestaurant(initRestaurant.id, null);
    setDeliveryRestaurant(initRestaurant.data);

    setDefaultDeliveryType(undefined, initRestaurant.hasTakeaway, initRestaurant.hasDelivery);

    setIsSelectedRestaurantInit(true);
  }, [isLoading]);

  const getRestaurants = async () => {
    const host = DomainConfig.getHost();
    const { success, restaurants } = await getDeliveryRestaurants({
      companyHost: host,
    });

    if (!success) {
      setIsDeliveryLayout(false);
      navigate('/', { replace: true });

      return;
    }

    initDeliveryCart(host);
    setDefaultDeliveryType();

    setIsDeliveryLayout(true);
    setIsLoading(false);

    if (restaurants && restaurants.length === 1) {
      navigateToOnlyRestaurant(restaurants[0]);
    }
  };

  const refreshRestaurantsInBackground = async () => {
    const host = DomainConfig.getHost();
    await getDeliveryRestaurants({
      companyHost: host,
      inBackground: true,
    });
  };

  useEffect(() => {
    if (!deliveryState.restaurants || deliveryState.restaurants.length !== 1) {
      return;
    }

    navigateToOnlyRestaurant(deliveryState.restaurants[0]);
  }, []);

  // If there is only 1 restaurant, navigate to it instantly
  const navigateToOnlyRestaurant = (singleRestaurant) => {
    if (restaurantSlug) {
      return;
    }

    navigate(`/${singleRestaurant.slug || singleRestaurant.id}`, { replace: true });
  };

  useInterval(refreshRestaurantsInBackground, 30000, false);

  return { isLoadingDeliveryInit: isLoading, isSelectedRestaurantInit, selectedRestaurant };
}
