import createDataContext from './_init_/createDataContext';
import {
  getMenu as getMenuApi,
  getProductsMenu,
  getProductsVariations as getProductsVariationsApi,
} from '../api/menu';
import { getTableByQrCode } from '../api/table';
import { getOrderByTable } from '../api/order';
import { cacheTable, removeTableFromCache } from '../services/tableService';
import { sseHandler } from '../services/serverEventsHandler';
import { parseSafeJSON } from '../utils/jsonHelpers';
import { restaurantTypes } from '../enums/restaurantTypes';
import { mapApiToDataProductsOnAutoRefresh } from '../api/mappers/products/products';
import { DiscountCartService } from '../services/discountCartService/discountCartService';
import { getRestaurantEvents, getRestaurantPromotions } from '../api/restaurant';

const currentAutoRefresh = {
  value: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_restaurant':
      return { ...state, restaurant: action.payload };
    case 'set_menu_restaurant':
      return { ...state, menuRestaurantId: action.payload };
    case 'set_table':
      return { ...state, table: action.payload, tableGot: true };
    case 'set_sse_handler_open':
      return { ...state, sseTableId: action.payload.tableId };
    case 'set_menu':
      return { ...state, menu: action.payload, menuGot: !!action.payload };
    case 'set_events':
      return { ...state, events: action.payload, eventsGot: !!action.payload };
    case 'set_promotions':
      return { ...state, promotions: action.payload, promotionsGot: !!action.payload };
    case 'set_products':
      return {
        ...state,
        productCategories: action.payload.categories,
        apiDiscounts: action.payload.apiDiscounts, // for auto refresher
        apiProducts: action.payload.apiProducts, // for auto refresher
        apiCategories: action.payload.apiCategories, // for auto refresher
        apiTimezone: action.payload.timezone, // for auto refresher
        apiTable: action.payload.apiTable, // for auto refresher
        apiVariationJoins: action.payload.apiVariationJoins, // for auto refresher
        menuGot: !!action.payload,
      };
    case 'set_products_variations':
      if (action.payload) {
        const updatedProductCategories = [...(state.productCategories || [])];

        Object.entries(action.payload).forEach(([productId, productVariations]) => {
          updatedProductCategories.forEach((category) => {
            if (!category.products || category.products.length === 0) {
              return;
            }

            category.products.forEach((product) => {
              if (product.id !== productId) {
                return;
              }

              product.variations = productVariations;
              product.variationsLoaded = true;
            });
          });
        });

        return {
          ...state,
          productCategories: updatedProductCategories,
        };
      }

      return {
        ...state,
      };
    case 'set_auto_refreshed_products':
      return {
        ...state,
        productCategories: action.payload.categories,
      };
    case 'set_discount_menu_service':
      return { ...state, discountMenuService: action.payload };
    case 'set_discount_cart_service':
      return { ...state, discountCartService: action.payload };
    case 'set_payment_option':
      return { ...state, paymentOption: action.payload };
    case 'set_orders':
      return { ...state, orders: action.payload, ordersGot: !!action.payload };
    case 'set_loading':
      return { ...state, [action.key]: action.payload };
    case 'set_active_order':
      return { ...state, activeOrder: action.payload };
    case 'reset_on_new_table':
      currentAutoRefresh.value = 0;
      return {
        ...state,
        menu: null,
        orders: null,
        productCategories: null,
        apiDiscounts: null,
        apiProducts: null,
        apiCategories: null,
        apiTimezone: null,
        activeOrder: null,
        apiVariationJoins: null,
        menuGot: false,
        ordersGot: false,
        discountMenuService: null,
        discountCartService: null,
      };
    default:
      return state;
  }
};

const getTable =
  (dispatch) =>
  async ({ tableQr }) => {
    if (lock.tableLocked) {
      return { locked: true };
    }

    lock.tableLocked = true;

    dispatch({ type: 'set_loading', key: 'loadingTable', payload: true });

    const { table, restaurant, paymentOption } = await getTableByQrCode({ tableQr });
    dispatch({ type: 'reset_on_new_table' });

    dispatch({ type: 'set_restaurant', payload: restaurant });
    dispatch({ type: 'set_table', payload: table });
    dispatch({ type: 'set_payment_option', payload: paymentOption });

    dispatch({ type: 'set_loading', key: 'loadingTable', payload: false });

    if (table) {
      cacheTable({ tableId: table.id, tableQr, currency: restaurant.currency });

      /* moved to tryOpenTableSseHandler
      if (sseHandler.handler && table.eventsUserToken) {
        sseHandler.handler.handlers.tableMessageHandler = tableMessageHandler(dispatch);
        sseHandler.handler.open({
          token: table.eventsUserToken,
          userId: table.eventsUser,
          userRecordId: table.id,
        });
      } */
    } else {
      removeTableFromCache();
    }

    lock.tableLocked = false;

    return { table, restaurant };
  };

const tryOpenTableSseHandler = (dispatch, state) => async () => {
  const { table, sseTableId } = state;

  if (lock.tableSseOpenLocked || !table || table.id === sseTableId) {
    return;
  }

  lock.tableSseOpenLocked = true;

  if (sseHandler.handler && table.eventsUserToken) {
    sseHandler.handler.handlers.tableMessageHandler = tableMessageHandler(dispatch);
    sseHandler.handler.open({
      token: table.eventsUserToken,
      userId: table.eventsUser,
      userRecordId: table.id,
    });

    dispatch({ type: 'set_sse_handler_open', payload: { tableId: table.id } });
  }

  lock.tableSseOpenLocked = false;
};

const autoRefreshProductsLocal = (dispatch, state) => async () => {
  if (currentAutoRefresh.value >= 4) {
    if (state.apiTable && state.restaurant) {
      const { categories } = await getMenuWithCustomParams(dispatch)({
        tableId: state.apiTable.id,
        tableSection: state.apiTable.section,
        timezone: state.restaurant.timeZone,
        companyId: state.restaurant.companyId,
        integrationId: state.restaurant.integrationId,
        provider: state.restaurant.provider,
        restaurantId: state.restaurant.id,
        useMenuOptimizations: state.restaurant.useMenuOptimizations,
        restaurantType: restaurantTypes.OrderAndPay,
        isAutoRefresh: true,
      });

      dispatch({
        type: 'set_auto_refreshed_products',
        payload: { categories: categories || [] },
      });

      return { refreshed: true, categories };
    }
  }

  if (!state.apiProducts || state.apiProducts.length === 0) {
    return { refreshed: false };
  }

  const { categories } = mapApiToDataProductsOnAutoRefresh({
    products: state.apiProducts,
    initialCategories: state.apiCategories,
    discounts: state.apiDiscounts,
    timezone: state.apiTimezone,
    discountMenuService: state.discountMenuService,
    restaurantId: state.restaurant ? state.restaurant.id : null,
    currentProductCategories: state.productCategories,
    variationJoins: state.apiVariationJoins,
  });

  dispatch({
    type: 'set_auto_refreshed_products',
    payload: { categories: categories || [] },
  });
  currentAutoRefresh.value += 1;

  return { refreshed: true, categories };
};

const getMenu = (dispatch, state) => async () => {
  return getMenuWithCustomParams(dispatch)({
    tableId: state.table.id,
    tableSection: state.table.section,
    timezone: state.restaurant.timeZone,
    companyId: state.restaurant.companyId,
    integrationId: state.restaurant.integrationId,
    provider: state.restaurant.provider,
    restaurantId: state.restaurant.id,
    restaurantType: state.restaurant.type,
    useMenuOptimizations: state.restaurant.useMenuOptimizations,
  });
};

const getMenuWithCustomParams =
  (dispatch) =>
  async ({
    tableId,
    tableSection,
    timezone,
    provider,
    integrationId,
    restaurantId,
    companyId,
    restaurantType,
    isAutoRefresh,
    useMenuOptimizations,
  }) => {
    if (lock.menuLocked) {
      return { locked: true };
    }

    lock.menuLocked = true;

    if (!isAutoRefresh) {
      dispatch({ type: 'set_loading', key: 'loadingMenu', payload: true });
    }

    let menu = null;
    let discountCartService = null;

    const getProductsMenuFunc = async () => {
      const {
        categories,
        apiDiscounts,
        apiProducts,
        apiCategories,
        apiTable,
        apiVariationJoins,
        discountMenuService,
      } = await getProductsMenu({
        tableId,
        tableSection,
        timezone,
        provider,
        integrationId,
        restaurantId,
        useOptimizations: useMenuOptimizations,
      });
      currentAutoRefresh.value = 0;

      discountCartService = new DiscountCartService(apiDiscounts);
      discountCartService.setActiveTable({
        company: companyId,
        provider,
        integrationId,
        restaurant: restaurantId,
        section: tableSection,
        timezone,
      });

      dispatch({
        type: 'set_products',
        payload: {
          categories: categories || [],
          apiDiscounts,
          apiProducts,
          apiCategories,
          timezone,
          apiTable,
          apiVariationJoins,
        },
      });
      dispatch({ type: 'set_discount_menu_service', payload: discountMenuService });
      dispatch({ type: 'set_discount_cart_service', payload: discountCartService });

      return { categories };
    };

    let categories;
    if (restaurantType === restaurantTypes.OrderAndPay) {
      const { categories: categoriesTemp } = await getProductsMenuFunc();
      categories = categoriesTemp;
    } else {
      menu = await getMenuApi({ restaurantId });

      if (menu && menu.type === 'Products') {
        await getProductsMenuFunc();
      }

      dispatch({ type: 'set_menu', payload: menu });
    }

    if (!isAutoRefresh) {
      dispatch({ type: 'set_loading', key: 'loadingMenu', payload: false });
      dispatch({ type: 'set_menu_restaurant', payload: restaurantId });
    }

    lock.menuLocked = false;

    return { categories, menu, discountCartService };
  };

const getProductsVariations =
  (dispatch, state) =>
  async ({ productIds }) => {
    const { mappedVariationsByProduct } = await getProductsVariationsApi({
      productIds,
      variationJoins: state.apiVariationJoins,
      allProducts: state.apiProducts,
    });

    dispatch({
      type: 'set_products_variations',
      payload: mappedVariationsByProduct,
    });
  };

// cant use state here
const getOrders =
  (dispatch) =>
  async ({ tableId, userId, restaurantType, inBackground }) => {
    if (lock.ordersLocked) {
      return { locked: true };
    }

    lock.ordersLocked = true;

    if (!inBackground) {
      dispatch({ type: 'set_loading', key: 'loadingOrders', payload: true });
    }

    const orders = await getOrderByTable({
      tableId,
      userId,
      restaurantType,
    });
    dispatch({ type: 'set_orders', payload: orders });

    if (!inBackground) {
      dispatch({ type: 'set_loading', key: 'loadingOrders', payload: false });
    }

    lock.ordersLocked = false;

    return { orders };
  };

const setActiveOrder = (dispatch) => async (orderId) => {
  dispatch({ type: 'set_active_order', payload: orderId });
};

const setDeliveryRestaurant = (dispatch) => async (deliveryRestaurant) => {
  dispatch({ type: 'set_restaurant', payload: deliveryRestaurant });
};

const tableMessageHandler = (dispatch) => async (payload, userId) => {
  const data = parseSafeJSON(payload.data ? payload.data.message : '{}');
  const action = payload.data && payload.data.meta ? payload.data.meta.action : null;

  if (action === 'ordersUpdated' && data.tableQr) {
    getOrders(dispatch)({
      tableId: data.tableId,
      restaurantType: data.restaurantType,
      userId,
      inBackground: true,
    });
  }
};

const getEvents =
  (dispatch, state) =>
  async ({ restaurantId }) => {
    if (lock.eventsLocked || state.eventsGot) {
      return;
    }

    lock.eventsLocked = true;

    dispatch({ type: 'set_loading', key: 'loadingEvents', payload: true });

    const { mappedEvents } = await getRestaurantEvents({ restaurantId });

    dispatch({
      type: 'set_events',
      payload: mappedEvents,
    });

    dispatch({ type: 'set_loading', key: 'loadingEvents', payload: false });

    lock.eventsLocked = false;
  };

const getPromotions =
  (dispatch, state) =>
  async ({ restaurantId }) => {
    if (lock.promotionsLocked || state.promotionsGot) {
      return;
    }

    lock.promotionsLocked = true;

    dispatch({ type: 'set_loading', key: 'loadingPromotions', payload: true });

    const { mappedPromotions } = await getRestaurantPromotions({ restaurantId });

    dispatch({
      type: 'set_promotions',
      payload: mappedPromotions,
    });

    dispatch({ type: 'set_loading', key: 'loadingPromotions', payload: false });

    lock.promotionsLocked = false;
  };

const lock = {
  tableLocked: false,
  menuLocked: false,
  ordersLocked: false,
  tableSseOpenLocked: false,
  eventsLocked: false,
  promotionsLocked: false,
};

const initialData = {
  restaurant: null,
  table: null,
  sseTableId: null,
  paymentOption: null,
  menu: null,
  orders: null,
  productCategories: null,

  events: null,
  promotions: null,

  apiDiscounts: null,
  apiProducts: null,
  apiCategories: null,
  apiTimezone: null,
  apiTable: null,
  apiVariationJoins: null,

  activeOrder: null,

  tableGot: false,
  menuGot: false,
  ordersGot: false,
  eventsGot: false,
  promotionsGot: false,

  loadingTable: false,
  loadingMenu: false,
  loadingOrders: false,
  loadingEvents: false,
  loadingPromotions: false,

  discountMenuService: null,
  discountCartService: null,

  menuRestaurantId: null,
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    getMenu,
    getMenuWithCustomParams,
    getProductsVariations,
    getTable,
    tryOpenTableSseHandler,
    getOrders,
    setActiveOrder,
    tableMessageHandler,
    autoRefreshProductsLocal,
    setDeliveryRestaurant,
    getEvents,
    getPromotions,
  },
  { ...initialData },
);
