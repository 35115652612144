/* eslint-disable no-nested-ternary */
import React from 'react';

const ProductDiscountTag = ({ discount, isRelative, isDelivery }) => {
  if (!discount) {
    return null;
  }

  return (
    <div
      className={`border-[1px] border-white bg-white py-[1px]
    font-primaryMedium text-primary rounded-[20px] inline-block
    ${isRelative ? '' : isDelivery ? 'absolute top-2 right-2' : 'absolute -bottom-2 -left-1'}
    ${isDelivery ? 'text-xs px-1' : 'text-sm px-2'}
    shadow-sm`}
    >
      -{Math.abs(discount)}%
    </div>
  );
};

export default ProductDiscountTag;
