/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import Loader from '../../../../components/misc/loader';
import { useScript } from '../../../../hooks/useScript';
import { usePayntPayButtonWrapper } from '../../hooks/usePayntPayButtonWrapper';
import { makeId } from '../../../../utils/stringHelper';

const PayntPaymentContainer = ({
  cardFormVisible,
  onPay,
  paymentKey,
  isValidOnSubmit,
  disabled,
  amount,
  updatePaymentBeforeSubmit,
}) => {
  const [oldPaymentKey, setOldPaymentKey] = useState(null);

  const [paymentId, setPaymentId] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [, setIsTest] = useState(false);
  const [currentScript, setCurrentScript] = useState(false);
  const [currentPaymentTrackId, setCurrentPaymentTrackId] = useState(null);

  useScript(currentScript);

  const { onFormCancel: onGPayFormCancel } = usePayntPayButtonWrapper({
    wrapperId: 'gpay-button-wrapper',
    payButtonClass: 'GOOGLEPAY',
    disabled,
    isValidOnSubmit,
    updatePaymentBeforeSubmit,
  });

  const { onFormCancel: onApplePayFormCancel } = usePayntPayButtonWrapper({
    wrapperId: 'apple-button-wrapper',
    payButtonClass: 'APPLEPAY',
    disabled,
    isValidOnSubmit,
    updatePaymentBeforeSubmit,
  });

  useEffect(() => {
    if (cardFormVisible) {
      setPaymentId(null);
      setRedirectUrl(null);

      // When amount is changed, use latest one
      const paymentTrackId = makeId(10);
      setCurrentPaymentTrackId(paymentTrackId);

      onPay({ paymentTrackId });
    }
  }, [cardFormVisible, amount]);

  useEffect(() => {
    if (paymentKey && currentPaymentTrackId) {
      if (!paymentKey.startsWith(currentPaymentTrackId)) {
        return;
      }

      const adjustedPaymentKey = paymentKey.replace(`${currentPaymentTrackId}~`, '');

      // If payment key was not updated
      if (adjustedPaymentKey === oldPaymentKey) {
        return;
      }

      // cache this payment key
      setOldPaymentKey(adjustedPaymentKey);

      const parts = adjustedPaymentKey.split('_||_');
      const merchantId = parts[3];
      const googleMerchantId = parts[4];

      const currentLanguage = availableLanguages.includes(i18next.language || 'en')
        ? i18next.language || 'en'
        : 'en';

      global.wpwlOptions = {
        style: 'plain',
        locale: currentLanguage,
        iframeStyles: {
          'card-number-placeholder': {
            'font-family': 'Helvetica',
            height: '1.1em',
            'font-size': '1em',
            border: 'none',
            margin: 0,
            padding: 0,
            color: '#9CA3AF',
          },
          'cvv-placeholder': {
            'font-family': 'Helvetica',
            height: '1.1em',
            'font-size': '1em',
            border: 'none',
            margin: 0,
            padding: 0,
            color: '#9CA3AF',
          },
        },
        disableSubmitOnEnter: true,
        showCVVHint: true,
        googlePay: {
          gatewayMerchantId: merchantId,
          merchantId: googleMerchantId,
          buttonType: 'pay',
          emailRequired: false,
          shippingOptionRequired: false,
          billingAddressRequired: false,
          onCancel: () => {
            console.log('Google pay canceled');
            onGPayFormCancel();
          },
        },
        applePay: {
          merchantIdentifier: merchantId,
          buttonSource: 'js',
          buttonStyle: 'black',
          onCancel: () => {
            console.log('Apple pay canceled');
            onApplePayFormCancel();
          },
        },
      };

      setPaymentId(parts[0]);
      setRedirectUrl(parts[1]);
      setIsTest(parts[2] === 'true');

      // store payment id for order button confirmation
      const paymentIdHolderElem = global.document.getElementById('paynt-payment-id');
      if (paymentIdHolderElem) {
        paymentIdHolderElem.dataset.payment = parts[0];
      } else {
        const newHolderElem = global.document.createElement('div');
        newHolderElem.id = 'paynt-payment-id';
        newHolderElem.dataset.payment = parts[0];
        global.document.body.appendChild(newHolderElem);
      }

      setCurrentScript(
        `https://eu-${
          parts[2] === 'true' ? 'test' : 'prod'
        }.oppwa.com/v1/paymentWidgets.js?checkoutId=${parts[0]}`,
      );
    }
  }, [paymentKey]);

  return (
    <div id="paynt-widget-container">
      {cardFormVisible && (
        <div className="mt-7 mb-5">
          <div
            key={oldPaymentKey}
            className="paynt-widget"
            style={{ display: paymentId ? 'block' : 'none' }}
          >
            <form
              action={redirectUrl}
              className="paymentWidgets"
              data-brands="MASTER VISA GOOGLEPAY APPLEPAY"
            />
          </div>
          {!paymentId && (
            <div>
              <Loader small />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PayntPaymentContainer;

const availableLanguages = [
  'ar',
  'be',
  'bg',
  'ca',
  'cn',
  'cz',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'eu',
  'fi',
  'fr',
  'gr',
  'hr',
  'hu',
  'id',
  'it',
  'ja',
  'ko',
  'lt',
  'lv',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sv',
  'tr',
  'zh',
];
