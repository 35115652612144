import { code, db } from 'codemash';
import i18next from 'i18next';
import { Config } from '../configs';
import { manageApiUnavailableError, manageFunctionResponse } from './error/errors';
import { mapApiToDataPayment } from './mappers/order';
import { currentUtcMillis } from '../utils/dateHelpers';
import { mapApiToDataDiscountCoupon } from './mappers/discount';
import { functionRequest, resourceRequest } from './client/requests';
import { apiActionChooser } from '../utils/apiActionChooser';
import { DomainConfig } from '../configs/domain';

export const initiatePayment = async ({
  token,
  paymentType,
  orderId,
  subtotal,
  tips,
  items,
  restaurantType,
  restaurantId,
  tableId,
  paymentMethodId,
  paymentAccountId,
  alertController,
  cart,
  note,
  coupon,
  onlyTips,
  discount,
  totalGuests,

  isTakeout,
  isDelivery,
  deliveryName,
  deliveryEmail,
  deliveryPhone,
  deliveryAddress,
  deliveryCity,
  deliveryZipCode,
  noteForDriver,
  roomNumber,
  isDeliveryNow,
  deliveryDate,
  deliveryDateDelta,
  prepareDate,
  prepareDateDelta,
  deliveryId,
  deliveryRestaurantId,
  deliveryHost,

  couponDiscount,
  couponCode,

  deliveryOrderId,

  employeeId,
}) => {
  try {
    let response;

    const formedFunctionData = {
      token,
      subtotal,
      discount,
      tips,
      items,
      paymentType,
      orderId,
      restaurantType,
      restaurantId,
      tableId,
      paymentMethodId,
      paymentAccountId,
      cart,
      note,
      coupon,
      onlyTips,
      totalGuests,

      isTakeout,
      isDelivery,
      deliveryName,
      deliveryEmail,
      deliveryPhone,
      deliveryAddress,
      deliveryCity,
      deliveryZipCode,
      noteForDriver,
      roomNumber,
      isDeliveryNow,
      deliveryDate,
      deliveryDateDelta,
      prepareDate,
      prepareDateDelta,
      deliveryId,
      deliveryRestaurantId,
      deliveryHost,

      couponDiscount,
      couponCode,

      deliveryOrderId,

      employeeId,
      language: i18next.language,
    };

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await code.executeFunction({
          functionId: Config.DATA.FUNCTIONS.INITIATE_PAYMENT,
          data: formedFunctionData,
        });
      },
      async () => {
        delete formedFunctionData.token;

        response = await functionRequest({
          functionName: 'InitiatePayment',
          data: JSON.stringify(formedFunctionData),
        });
      },
    );

    const managedResponse = manageFunctionResponse({
      response,
      alertController,
    });

    if (managedResponse.isError) {
      if (!managedResponse.handled) {
        if (managedResponse.message === 'no_order') {
          alertController.error({ text: i18next.t('errors.orderNotFound') });
        } else if (managedResponse.message === 'invalid_amount') {
          alertController.error({ text: i18next.t('errors.invalidPayAmount') });
        } else if (managedResponse.message === 'invalid_status') {
          alertController.error({ text: i18next.t('errors.invalidOrderStatus') });
        } else if (managedResponse.message === 'already_has_payments') {
          alertController.error({ text: i18next.t('errors.alreadyHasPayments') });
        } else if (managedResponse.message === 'discount_coupon_not_found') {
          alertController.error({ text: i18next.t('delivery:errors.noDiscountCode') });
        } else if (managedResponse.message === 'cart_empty') {
          alertController.error({ text: i18next.t('delivery:errors.initiatePaymentCartEmpty') });
        } else if (managedResponse.message === 'discount_mismatch') {
          alertController.error({ text: i18next.t('errors.discountHasChanged') });

          return {
            status: 'discountChange',
            actualDiscount: managedResponse.raw ? managedResponse.raw.actualDiscount || 0 : 0,
          };
        } else if (managedResponse.message === 'coupon_discount_mismatch') {
          alertController.error({ text: i18next.t('errors.discountHasChanged') });

          return {
            status: 'couponDiscountChange',
          };
        } else {
          alertController.error({ text: i18next.t('errors.unknown') });
        }
      }
    } else {
      return {
        status: managedResponse.status,
        paymentLink: managedResponse.paymentLink,
        paymentKey: managedResponse.paymentKey,
      };
    }

    return null;
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to initiate payment', e);
    }
  }

  return null;
};

export const updatePendingPayment = async ({
  externalPaymentId,
  isTakeout,
  isDelivery,
  deliveryName,
  deliveryEmail,
  deliveryPhone,
  deliveryAddress,
  deliveryCity,
  deliveryZipCode,
  noteForDriver,
  roomNumber,
  isDeliveryNow,
  deliveryDate,
  deliveryDateDelta,
  prepareDate,
  prepareDateDelta,
}) => {
  try {
    let response;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await code.executeFunction({
          functionId: Config.DATA.FUNCTIONS.UPDATE_PENDING_PAYMENT,
          data: {
            externalPaymentId,
            isTakeout,
            isDelivery,
            deliveryName,
            deliveryEmail,
            deliveryPhone,
            deliveryAddress,
            deliveryCity,
            deliveryZipCode,
            noteForDriver,
            roomNumber,
            isDeliveryNow,
            deliveryDate,
            deliveryDateDelta,
            prepareDate,
            prepareDateDelta,
          },
        });
      },
      async () => {
        response = await functionRequest({
          functionName: 'UpdatePendingPayment',
          data: JSON.stringify({
            externalPaymentId,
            isTakeout,
            isDelivery,
            deliveryName,
            deliveryEmail,
            deliveryPhone,
            deliveryAddress,
            deliveryCity,
            deliveryZipCode,
            noteForDriver,
            roomNumber,
            isDeliveryNow,
            deliveryDate,
            deliveryDateDelta,
            prepareDate,
            prepareDateDelta,
          }),
        });
      },
    );

    const managedResponse = manageFunctionResponse({
      response,
    });

    if (managedResponse.isError) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to update payment', e);
    }
  }

  return null;
};

export const getPayment = async ({ paymentId, isCmPaymentId, userId }) => {
  try {
    let response;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await db.executeAggregate({
          id: isCmPaymentId
            ? Config.DATA.AGGREGATES.GET_DELIVERY_PAYMENT
            : Config.DATA.AGGREGATES.GET_DELIVERY_PAYMENT_BY_EXTERNAL,
          collectionName: Config.DATA.COLLECTIONS.PAYMENTS,
          database: Config.CODEMASH.DB_STORAGE_ID,
          tokens: {
            paymentId,
            userId,
          },
        });
      },
      async () => {
        response = await resourceRequest({
          data: {
            id: isCmPaymentId ? 'ClientGetDeliveryPayment' : 'ClientGetDeliveryPaymentByExternal',
            tokens: {
              paymentId,
            },
          },
          action: 'aggregate',
        });
      },
    );

    if (response && response.length > 0) {
      return mapApiToDataPayment({ paymentData: response[0] });
    }
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get table orders', e);
    }
  }

  return null;
};

export const getPaymentInvoice = async ({ paymentId, email, isDownload, alertController }) => {
  try {
    let response;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await code.executeFunction({
          functionId: Config.DATA.FUNCTIONS.GET_INVOICE,
          data: {
            paymentId,
            email,
            isDownload,
          },
        });
      },
      async () => {
        response = await functionRequest({
          functionName: 'SendCompletedReceipt',
          data: JSON.stringify({
            paymentId,
            email,
            isDownload,
            language: i18next.language,
          }),
        });
      },
    );

    const managedResponse = manageFunctionResponse({
      response,
      alertController,
    });

    if (managedResponse.isError) {
      if (!managedResponse.handled) {
        if (managedResponse.message === 'no_payment') {
          alertController.error({ text: i18next.t('errors.noPayment') });
        } else if (managedResponse.message === 'no_invoice') {
          alertController.error({ text: i18next.t('errors.noInvoice') });
        } else {
          alertController.error({ text: i18next.t('errors.unknown') });
        }
      }
    } else {
      return {
        emailSent: managedResponse.emailSent,
        downloadLink: managedResponse.downloadLink,
      };
    }

    return null;
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get invoice', e);
    }
  }

  return null;
};

export const chargeAuthorizedPayment = async ({ token, externalPaymentId, alertController }) => {
  try {
    let response;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await code.executeFunction({
          secretKey: token,
          functionId: Config.DATA.FUNCTIONS.CHARGE_AUTHORIZED_PAYMENT,
          data: {
            token,
            externalPaymentId,
          },
        });
      },
      async () => {
        response = await functionRequest({
          functionName: 'ChargeAuthorizedOrder',
          data: JSON.stringify({ externalPaymentId }),
        });
      },
    );

    const managedResponse = manageFunctionResponse({
      response,
      alertController,
    });

    if (managedResponse.isError) {
      if (!managedResponse.handled) {
        if (managedResponse.message === 'no_payment') {
          alertController.error({ text: i18next.t('errors.noPayment') });
        } else if (managedResponse.message === 'bad_status') {
          // ignore
        } else {
          alertController.error({ text: i18next.t('errors.unknown') });
        }
      }
    } else {
      return {
        status: managedResponse.status,
        errorCode: managedResponse.errorCode,
      };
    }

    return null;
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get invoice', e);
    }
  }

  return null;
};

export const getDiscountCoupon = async ({ restaurantId, coupon }) => {
  try {
    let response;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await db.executeAggregate({
          id: Config.DATA.AGGREGATES.GET_DISCOUNT_COUPON,
          collectionName: Config.DATA.COLLECTIONS.RESTAURANTS,
          database: Config.CODEMASH.DB_STORAGE_ID,
          tokens: {
            restaurantId,
            coupon,
            currentDate: currentUtcMillis(),
          },
        });
      },
      async () => {
        response = await resourceRequest({
          data: {
            id: 'ClientGetCoupon',
            tokens: {
              restaurantId,
              coupon,
              currentDate: currentUtcMillis(),
            },
          },
          action: 'aggregate',
        });
      },
    );

    if (response && response.length > 0) {
      return mapApiToDataDiscountCoupon({ coupon: response[0] });
    }
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get discount coupon', e);
    }
  }

  return null;
};
