import React, { useState, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import TextInput, { inputType } from '../../../components/inputs/textInput';
import MainButton from '../../../components/buttons/main';
import { Context as AlertContext } from '../../../context/alert';
import { Context as CheckoutContext } from '../../../context/checkout';
import BottomModal from '../../../components/modal/bottomModal';
import { useBackPress } from '../../../hooks/useBackPress';

const PaymentReceipt = ({ payment }) => {
  const { t } = useTranslation();

  const { alertController } = useContext(AlertContext);
  const { downloadPaymentInvoice } = useContext(CheckoutContext);
  const [email, setEmail] = useState('');

  const [modalOpen, setModalOpen] = useState(false);

  const { handleBackOnModalOpen, handleBackOnModalClose } = useBackPress('/payment', () => {
    setModalOpen(false);
  });

  const renderInputModal = () => {
    return (
      <div className="mb-5">
        <div className="w-full max-w-xs mx-auto">
          <TextInput
            id="receipt-email"
            onChange={setEmail}
            value={email}
            type={inputType.centerText}
            placeholder={t('payment.emailAddress')}
          />
        </div>
        <div className="w-full mt-3 max-w-xs mx-auto">
          <MainButton
            text="Send"
            onClick={() => {
              downloadPaymentInvoice({
                isDownload: false,
                paymentId: payment.id,
                email,
                alertController,
              });

              setModalOpen(false);
              handleBackOnModalClose();
            }}
            disabled={!email}
          />
        </div>
        <p className="font-primaryMedium text-primary text-base mt-4 text-center">
          <Trans i18nKey="payment.downloadToDevice">
            Or{' '}
            <button
              type="button"
              onClick={() => {
                downloadPaymentInvoice({
                  isDownload: true,
                  paymentId: payment.id,
                  alertController,
                });

                setModalOpen(false);
                handleBackOnModalClose();
              }}
              className={`underline `}
            >
              download to your device
            </button>
          </Trans>
        </p>
      </div>
    );
  };

  return (
    <div className="mt-4 flex flex-col items-center">
      <button
        type="button"
        className="font-primaryMedium text-primary text-base underline"
        onClick={() => {
          setModalOpen(true);
          handleBackOnModalOpen();
        }}
      >
        {t('payment.getReceiptTitle')}
      </button>

      <BottomModal
        title={t('payment.receipt')}
        content={renderInputModal()}
        onClose={() => {
          setModalOpen(false);
          handleBackOnModalClose();
        }}
        onOutClick={() => {
          setModalOpen(false);
          handleBackOnModalClose();
        }}
        open={modalOpen}
      />
    </div>
  );
};

export default PaymentReceipt;
