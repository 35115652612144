import { useContext } from 'react';
import { Context as DeliveryContext } from '../context/delivery';
import { defaultColors } from '../configs';

export function useDeliveryColors() {
  const { state: deliveryState } = useContext(DeliveryContext);
  return deliveryState && deliveryState.settings
    ? deliveryState.settings.colors || {}
    : defaultColors;
}
