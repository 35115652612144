import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { useNavigate, useParams } from 'react-router-dom';
import visa from '../../../../assets/images/footer/visa.png';
import mastercard from '../../../../assets/images/footer/mastercard.png';
import MainButton from '../../../../components/buttons/main';
import { useCurrency } from '../../../../hooks/useCurrency';

const StripePaymentForm = ({
  amount,
  onPay,
  onFail,
  clientSecret,
  disabled,
  cardFormSubmitRef,
}) => {
  const { currencySymbol } = useCurrency();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { restaurantSlug } = useParams();

  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const buildRedirectUrl = () => {
    if (restaurantSlug) {
      return `/${restaurantSlug}/payment`;
    }

    return '/payment';
  };

  const handleConfirmation = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
      return_url: `${global.window.location.origin}${buildRedirectUrl()}`,
      setup_future_usage: 'on_session',
    });

    if (error) {
      let errorMessage = error.message;
      if (error.decline_code === 'insufficient_funds') {
        errorMessage = t('errors.insufficientFunds');
      }

      onFail(errorMessage);
      setIsLoading(false);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      navigate(`${buildRedirectUrl()}?paymentId=${paymentIntent.id}&statusGroup=succeeded`);
    }
  };

  useEffect(() => {
    if (clientSecret && isLoading) {
      handleConfirmation();
    }
  }, [clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    onPay();
  };

  return (
    <form ref={cardFormSubmitRef} onSubmit={handleSubmit}>
      <div className="bg-lightGrayBg p-5 rounded-lg">
        <div className="flex justify-between items-center">
          <p className="font-primaryMedium text-primary text-base">{t('checkout.cardFormTitle')}</p>
          <div className="flex flex-row items-center">
            <img className="mr-3 max-h-5" src={visa} alt="Visa" />
            <img className="max-h-4" src={mastercard} alt="Mastercard" />
          </div>
        </div>
        <div className="mb-4" />
        <div className="border border-input bg-white px-5 py-4 rounded-md">
          <CardNumberElement />
        </div>
        <div className="mb-3" />
        <div className="flex w-full">
          <div className="border border-input bg-white px-5 py-4 rounded-md flex-1">
            <CardExpiryElement />
          </div>
          <div className="w-3" />
          <div className="border border-input bg-white px-5 py-4 rounded-md flex-1">
            <CardCvcElement />
          </div>
        </div>
        {!cardFormSubmitRef && (
          <>
            <div className="mb-4" />
            <MainButton
              text={`${t('buttons.pay')} ${(amount || 0).toFixed(2)} ${currencySymbol}`}
              disabled={(amount || 0) <= 0 || disabled}
              isSubmit
              smallerHeight
            />
          </>
        )}
      </div>
    </form>
  );
};

export default StripePaymentForm;
