import React, { useRef, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { RiMenu3Fill } from 'react-icons/ri';
import { getTranslatedData } from '../../../localization';
import { useDeliveryColors } from '../../../hooks/useDeliveryColors';

const MenuBar = ({ menu, productCategories, selected, onSelect, isDelivery }) => {
  const { t } = useTranslation();
  const colors = useDeliveryColors();

  const scroller = useRef();
  const categoryRefs = useRef({}).current;

  useEffect(() => {
    const updateScrollPosition = (e) => {
      e.preventDefault();

      // eslint-disable-next-line no-undef
      document.getElementById('menu-bar').scrollLeft += e.deltaY;
    };

    if (scroller && scroller.current) {
      scroller.current.addEventListener('wheel', updateScrollPosition);

      return () => {
        if (scroller && scroller.current) {
          scroller.current.removeEventListener('wheel', updateScrollPosition);
        }
      };
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (selected) {
      const categoryRef = categoryRefs[selected];

      if (categoryRef) {
        scroller.current.scroll({
          left: categoryRef.offsetLeft - 25,
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [selected]);

  const renderCategoryItem = (id, text, ind, totalItems, isVertical) => {
    if (isVertical) {
      return (
        <Menu.Item key={id}>
          {({ close }) => renderCategoryItemButton(id, text, ind, totalItems, isVertical, close)}
        </Menu.Item>
      );
    }

    return renderCategoryItemButton(id, text, ind, totalItems, isVertical);
  };

  const renderCategoryItemButton = (id, text, ind, totalItems, isVertical, clickFunc) => {
    return (
      <button
        key={id}
        type="button"
        className={`inline-block ${isVertical ? 'w-full text-left overflow-x-hidden pr-2' : ''}`}
        onClick={() => {
          if (clickFunc) {
            clickFunc();
          }

          const scrollerHeight = scroller.current.offsetHeight;
          onSelect(id, scrollerHeight);
        }}
      >
        <div
          className={`text-primary border rounded-[50px] inline-block
          py-2 px-4  font-primaryMedium text-sm whitespace-nowrap
          ${isVertical ? 'overflow-hidden text-ellipsis max-w-full' : ''}
          ${selected === id ? 'border-primary' : 'border-transparent'}
          ${isDelivery && selected === id ? 'text-white bg-primary' : 'bg-transparent'}
          ${
            isDelivery && selected !== id
              ? 'hover:text-bland transition duration-300 ease-in-out'
              : ''
          }
          ${isDelivery && ind === 0 ? 'mr-1' : ''}
          ${isDelivery && ind === totalItems - 1 ? 'ml-1' : ''}
          ${isDelivery && (isVertical || (ind > 0 && ind < totalItems - 1)) ? 'mx-1' : ''}`}
          style={{
            background: isDelivery && selected === id ? colors.primary : undefined,
            borderColor: isDelivery && selected === id ? colors.primary : undefined,
          }}
          ref={(el) => {
            if (!el) {
              return;
            }

            categoryRefs[id] = el;
          }}
        >
          {id === '_other' ? t('products.categoryOther') : text}
        </div>
      </button>
    );
  };

  return (
    <div className="relative pr-20">
      <div
        id="menu-bar"
        ref={scroller}
        className={`overflow-x-auto w-full bg-white ${
          isDelivery ? 'py-4' : 'my-2 py-2'
        } text-center whitespace-nowrap scrollbar-h`}
      >
        <div className="inline-block">
          {!!menu &&
            menu.categories.map((category, ind) => {
              const imagesByLang = getTranslatedData(category.images) || [];

              if (!imagesByLang || imagesByLang.length === 0) {
                return null;
              }

              return renderCategoryItem(
                category.id,
                getTranslatedData(category.name),
                ind,
                menu.categories.length,
              );
            })}
          {!!productCategories &&
            productCategories.map((category, ind) => {
              return renderCategoryItem(
                category.id,
                getTranslatedData(category.name),
                ind,
                productCategories.length,
              );
            })}
        </div>
      </div>

      <Menu as="div" className="absolute top-0 bottom-0 -right-5 flex items-center">
        <Menu.Button className="w-full h-full pl-8 pr-8">
          <RiMenu3Fill className="text-primary text-2xl" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-6 top-20 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto max-h-[calc(100vh-200px)]">
            <div className="py-2 px-2">
              {!!productCategories &&
                productCategories.map((category, ind) => {
                  return renderCategoryItem(
                    category.id,
                    getTranslatedData(category.name),
                    ind,
                    productCategories.length,
                    true,
                  );
                })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default MenuBar;
