import React from 'react';
import { formFileUrlFromFileArray } from '../../../../utils/fileHelper';

const PromotionCard = ({ promotion }) => {
  return (
    <a
      target="_blank"
      href={promotion.externalLink}
      className="h-72 lg:h-96 rounded-xl overflow-hidden shadow-lg cursor-pointer hover:scale-103 transition duration-300 ease-in-out hover:shadow-xl"
      rel="noreferrer"
    >
      <div className="h-72 lg:h-[28rem]">
        <img
          className="w-full h-full object-cover object-center"
          alt=""
          src={formFileUrlFromFileArray(promotion.imageCover)}
        />
      </div>
    </a>
  );
};

export default PromotionCard;
