import { currentDayMinutes, currentUtcMillis, getWeekDay } from '../../../utils/dateHelpers';
import { roundDecimal } from '../../../utils/roundingHelpers';

const daysNumbers = { Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6, Sun: 7 };

const isRangeDateValid = (coupon) => {
  const currentMillis = currentUtcMillis();
  if (coupon.validFrom && coupon.validUntil) {
    return coupon.validFrom <= currentMillis && coupon.validUntil > currentMillis;
  }

  if (coupon.validFrom && !coupon.validUntil) {
    return coupon.validFrom <= currentMillis;
  }

  if (!coupon.validFrom && coupon.validUntil) {
    return coupon.validUntil > currentMillis;
  }

  return false;
};

const isDayValid = (coupon, timeZone) => {
  if (!coupon.days || coupon.days.length === 0) {
    return true;
  }

  const weekDay = getWeekDay(timeZone);

  const hasAvailableDay = coupon.days.find((x) => daysNumbers[x] === weekDay);
  return !!hasAvailableDay;
};

const isDayTimeValid = (coupon, timeZone) => {
  const dayMinutes = currentDayMinutes(timeZone);
  if (
    coupon.dayMinutesFrom != null &&
    coupon.dayMinutesUntil != null &&
    coupon.dayMinutesFrom !== undefined &&
    coupon.dayMinutesUntil !== undefined &&
    coupon.dayMinutesFrom >= 0 &&
    coupon.dayMinutesUntil >= 0
  ) {
    return coupon.dayMinutesFrom <= dayMinutes && coupon.dayMinutesUntil > dayMinutes;
  }

  return true;
};

// + Tested here and in server
export const isCouponApplicable = ({ coupon, timeZone }) => {
  if (!isRangeDateValid(coupon)) {
    return false;
  }

  if (!isDayValid(coupon, timeZone)) {
    return false;
  }

  if (!isDayTimeValid(coupon, timeZone)) {
    return false;
  }

  return true;
};

// + Tested here and in server
export const calculateCouponOnOrder = ({ coupon, total }) => {
  if (!coupon || coupon.onProducts) {
    return 0;
  }

  return calculatePercentOrFixedTotal({
    total,
    useFixed: coupon.useFixed,
    fixed: coupon.fixed,
    percent: coupon.percent,
  });
};

const recalculateProductApplicableCouponTotal = ({
  total,
  quantity,
  minQuantity,
  applicableQuantity,
  canRepeatQuantity,
}) => {
  let correctedTotal = total;
  let correctedQuantity = quantity || 1;
  if (applicableQuantity > 0) {
    if (canRepeatQuantity) {
      const correctedMinQuantity = !minQuantity || minQuantity <= 0 ? 1 : minQuantity;
      correctedQuantity = Math.trunc(quantity / correctedMinQuantity) * applicableQuantity;
    } else {
      correctedQuantity = applicableQuantity;
    }

    correctedTotal = roundDecimal((correctedTotal / quantity) * correctedQuantity);
  }

  return { correctedQuantity, correctedTotal };
};

const calculatePercentOrFixedTotal = ({ total, quantity, useFixed, fixed, percent }) => {
  let totalCouponDiscount = 0;

  if (useFixed) {
    totalCouponDiscount = (quantity || 1) * (fixed || 0);
  } else {
    totalCouponDiscount = roundDecimal(total * ((percent || 0) / 100));
  }

  if (totalCouponDiscount > total) {
    totalCouponDiscount = total;
  }

  return totalCouponDiscount;
};

const groupCartProductsForCouponCheck = ({ cart }) => {
  if (!cart || !cart.cart) {
    return [];
  }

  const grouped = {};
  cart.cart.forEach((item) => {
    if (!grouped[item.productId]) {
      grouped[item.productId] = [];
    }
    grouped[item.productId].push(item);
  });

  return Object.values(grouped);
};

export const calculateCouponOnProducts = ({ coupon, cart }) => {
  if (!coupon || !coupon.onProducts) {
    return 0;
  }

  if (!coupon.applicableProducts || coupon.applicableProducts.length === 0) {
    return 0;
  }

  // Group products by ID
  const groupedProducts = groupCartProductsForCouponCheck({ cart });

  let totalCouponDiscount = 0;
  if (groupedProducts) {
    groupedProducts.forEach((cartProductGroup) => {
      // Get coupon data by product id, then by category id if not found by product
      const productCoupon =
        coupon.applicableProducts.find(
          (x) => x.products && x.products.includes(cartProductGroup[0].productId),
        ) ||
        coupon.applicableProducts.find(
          (x) =>
            x.categories &&
            x.categories.some(
              (y) => cartProductGroup[0].categories && cartProductGroup[0].categories.includes(y),
            ),
        );

      if (productCoupon) {
        let qntTotal = 0;
        let priceTotal = 0;
        cartProductGroup.forEach((cartProduct) => {
          qntTotal += cartProduct.quantity;
          priceTotal = roundDecimal(priceTotal + cartProduct.total);
        });

        // Check if minimum quantity is reached
        if (productCoupon.minQuantity > 0 && qntTotal < productCoupon.minQuantity) {
          return;
        }

        // Correct qnt and total based on parameters
        const recalculatedResult = recalculateProductApplicableCouponTotal({
          total: priceTotal,
          quantity: qntTotal,
          applicableQuantity: productCoupon.applyOnQuantity,
          minQuantity: productCoupon.minQuantity,
          canRepeatQuantity: productCoupon.canRepeat,
        });
        qntTotal = recalculatedResult.correctedQuantity;
        priceTotal = recalculatedResult.correctedTotal;

        // Calculate discount
        const totalProductCouponDiscount = calculatePercentOrFixedTotal({
          total: priceTotal,
          quantity: qntTotal,
          fixed: productCoupon.fixed,
          percent: productCoupon.percent,
          useFixed: productCoupon.useFixed,
        });

        totalCouponDiscount = roundDecimal(totalCouponDiscount + totalProductCouponDiscount);
      }
    });
  }

  return totalCouponDiscount;
};
