import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeExpressForm from './stripeExpressForm';

const StripeExpressContainer = ({
  publicKey,
  isConnected,
  connectedId,
  amount,
  currency,
  onPay,
  onFail,
  paymentKey,
  isDelivery,
  disabled,
  isValidOnSubmit,
}) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (publicKey) {
      setStripePromise(
        loadStripe(publicKey, isConnected ? { stripeAccount: connectedId } : undefined),
      );
    }
  }, []);

  const options = {
    mode: 'payment',
    amount: parseInt((amount || 1) * 100, 10), // cant have 0
    currency: currency.toLowerCase(),
    appearance: {
      variables: {
        borderRadius: isDelivery ? '0.375rem' : '50px',
      },
    },
    // Turning this on does not allow this setting: automatic_payment_methods[enabled]
    // paymentMethodTypes: ['card'],
  };

  return (
    <div>
      {!!stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          <StripeExpressForm
            amount={amount}
            onPay={onPay}
            onFail={onFail}
            clientSecret={paymentKey}
            disabled={disabled}
            isValidOnSubmit={isValidOnSubmit}
          />
        </Elements>
      )}
    </div>
  );
};

export default StripeExpressContainer;
