import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentForm from './stripePaymentForm';

const StripePaymentContainer = ({
  publicKey,
  isConnected,
  connectedId,
  amount,
  onPay,
  onFail,
  paymentKey,
  disabled,
  cardFormSubmitRef,
}) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (publicKey) {
      setStripePromise(
        loadStripe(publicKey, isConnected ? { stripeAccount: connectedId } : undefined),
      );
    }
  }, []);

  return (
    <div>
      {!!stripePromise && (
        <Elements stripe={stripePromise}>
          <StripePaymentForm
            amount={amount}
            onPay={onPay}
            onFail={onFail}
            clientSecret={paymentKey}
            disabled={disabled}
            cardFormSubmitRef={cardFormSubmitRef}
          />
        </Elements>
      )}
    </div>
  );
};

export default StripePaymentContainer;
