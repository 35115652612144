import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslatedData } from '../../../../localization';
import { useCurrency } from '../../../../hooks/useCurrency';
import { RadioButton } from '../../../../components/inputs/radioButton';
import { CheckBox } from '../../../../components/inputs/checkBox';
import { capitalizeString } from '../../../../utils/stringHelper';
import { useDeliveryColors } from '../../../../hooks/useDeliveryColors';
import { getComboItemName } from '../../services/products';

const Variation = ({ variation, onItemsChange, onlyDisplay }) => {
  const { t } = useTranslation();
  const { currencySymbol } = useCurrency();
  const colors = useDeliveryColors();

  const [variationOptions, setVariationOptions] = useState(undefined);

  const correctedMaxOfMulti = useMemo(() => {
    if (!variation.items || variation.items.length === 0) {
      return 0;
    }

    if (variation.items.length > variation.maxOfMulti) {
      return variation.maxOfMulti;
    }

    return 0;
  }, [variation.maxOfMulti]);

  const correctedMinOfMulti = useMemo(() => {
    if (!variation.items || variation.items.length === 0) {
      return 0;
    }

    if (variation.minOfMulti === 1 && variation.required) {
      return 0;
    }

    return variation.minOfMulti;
  }, [variation.minOfMulti]);

  const renderRequiredTag = (isRequired) => {
    if (!isRequired) {
      return null;
    }

    return (
      <div
        className="border-[1px] border-primaryOrange bg-primaryOrange px-2 py-[1px]
          font-primaryMedium text-white text-sm rounded-[50px] inline-block -top-[1px] relative"
      >
        {t('products.requiredTag')}
      </div>
    );
  };

  const renderVariationQuantityRange = () => {
    if (correctedMinOfMulti && correctedMaxOfMulti) {
      return `${capitalizeString(t('products.atLeastAmount'))} ${correctedMinOfMulti}, ${t(
        'products.atMostAmount',
      )} ${correctedMaxOfMulti}`;
    }

    if (correctedMinOfMulti) {
      return `${capitalizeString(t('products.atLeastAmount'))} ${correctedMinOfMulti}`;
    }

    if (correctedMaxOfMulti) {
      return `${capitalizeString(t('products.atMostAmount'))} ${correctedMaxOfMulti}`;
    }

    return '';
  };

  const renderVariationItem = (item) => {
    let disabledVariationSelection = false;
    if (variation.type === 'multi_selection_max' && correctedMaxOfMulti > 0) {
      disabledVariationSelection =
        variationOptions && variationOptions.length >= correctedMaxOfMulti;
    }

    const itemName = getComboItemName(item);

    return (
      <div key={item.id} className="flex flex-row items-center justify-between flex-wrap">
        {variation.type === 'one_selection' && (
          <div>
            <RadioButton
              value={variationOptions}
              checked={variationOptions === item.id}
              onClick={(checked) => {
                const newVal = checked ? item.id : undefined;
                setVariationOptions(newVal);
                onItemsChange(newVal);
              }}
              title={itemName}
              hideCheck={onlyDisplay}
              disabled={onlyDisplay}
              customColor={colors.hasCustomPrimary ? colors.primary : undefined}
            />
          </div>
        )}
        {variation.type === 'multi_selection_max' && (
          <div>
            <CheckBox
              checked={variationOptions && variationOptions.includes(item.id)}
              onClick={(checked) => {
                setVariationOptions((prev) => {
                  let newArray = prev || [];
                  if (checked) {
                    newArray.push(item.id);
                  } else {
                    newArray = newArray.filter((x) => x !== item.id);
                  }

                  const newVal = [...newArray];
                  onItemsChange(newVal);
                  return newVal;
                });
              }}
              isSquare
              title={itemName}
              disabled={
                (!(variationOptions && variationOptions.includes(item.id)) &&
                  disabledVariationSelection) ||
                onlyDisplay
              }
              hideCheck={onlyDisplay}
              customColor={colors.hasCustomPrimary ? colors.primary : undefined}
            />
          </div>
        )}
        {!!item.price && (
          <div className="font-primaryMedium text-bland text-base ml-1 tracking-wider whitespace-nowrap">
            {`+${(item.priceWithDiscount || item.price || 0).toFixed(2)} ${currencySymbol}`}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mb-3">
      <div className="mb-1">
        <div>
          {getTranslatedData(variation.name) && (
            <div className="font-primaryMedium text-primary text-lg mr-2 inline-block">
              {getTranslatedData(variation.name)}
            </div>
          )}
          {renderRequiredTag(variation.required)}
        </div>
        {getTranslatedData(variation.hint) && (
          <div className="font-primaryMedium text-bland text-sm">
            {getTranslatedData(variation.hint)}
          </div>
        )}
        {variation.type === 'multi_selection_max' &&
          (!!correctedMinOfMulti || !!correctedMaxOfMulti) && (
            <div className="font-primaryRegular text-bland text-sm mb-1">
              {renderVariationQuantityRange()}
            </div>
          )}
      </div>

      {variation.items && variation.items.map((item) => renderVariationItem(item))}
    </div>
  );
};

export default Variation;
