import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { chargeAuthorizedPayment, getPayment } from '../api/payment';
import { Context as AuthContext } from '../context/auth';
import { Context as AlertContext } from '../context/alert';
import { Context as CartContext } from '../context/cart';
import { restaurantTypes } from '../enums/restaurantTypes';
import { useInterval } from './useInterval';

export function usePaymentHandler({ holdProcessing, isDelivery, deliveryRestaurantId }) {
  const { search } = useLocation();
  const navigate = useNavigate();

  const { state: authState } = useContext(AuthContext);
  const { alertController } = useContext(AlertContext);
  const { clearCart, clearDeliveryCart } = useContext(CartContext);

  const [isProcessing, setIsProcessing] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentData, setPaymentData] = useState(null);

  let paymentId = new URLSearchParams(search).get('paymentId');
  if (!paymentId) {
    paymentId = new URLSearchParams(search).get('payment_intent');
  }

  let statusGroup = new URLSearchParams(search).get('statusGroup');
  if (!statusGroup) {
    statusGroup = new URLSearchParams(search).get('redirect_status');
  }

  // Walleto
  let unchargedPaymentId = new URLSearchParams(search).get('order_id');
  if (!unchargedPaymentId) {
    const resourcePath = new URLSearchParams(search).get('resourcePath');
    if (resourcePath) {
      unchargedPaymentId = new URLSearchParams(search).get('id');
    }
  }

  const status = new URLSearchParams(search).get('status');
  const isCmPaymentId = new URLSearchParams(search).get('custom') === 'true';

  const isSuccess =
    statusGroup === 'completed' || statusGroup === 'succeeded' || !!unchargedPaymentId;
  const isCanceled = statusGroup === 'failed' && status === 'CANC';

  const processAuthorizedPayment = async () => {
    await chargeAuthorizedPayment({
      token: authState.token,
      externalPaymentId: unchargedPaymentId,
      alertController,
    });
  };

  useEffect(() => {
    if (unchargedPaymentId && authState.token) {
      processAuthorizedPayment();
    }
  }, [unchargedPaymentId, authState.token]);

  const getPaymentData = async () => {
    const result = await getPayment({
      paymentId: paymentId || unchargedPaymentId,
      isCmPaymentId,
      userId: authState.user ? authState.user.id : null,
    });

    // If it's order and pay, navigate to order page
    if (
      isSuccess &&
      result &&
      result.processed &&
      result.restaurant &&
      result.orderId &&
      result.restaurant.type === restaurantTypes.OrderAndPay
    ) {
      if (isDelivery) {
        clearDeliveryCart(deliveryRestaurantId);
        setIsProcessing(false);
        setIsLoading(false);
        setPaymentData(result);
        // navigate(`/order/${result.orderId}`); // TODO
      } else {
        clearCart();
        navigate(`/order/${result.orderId}`);
      }
      return true;
    }

    if (isSuccess && result && result.processed) {
      if (isDelivery) {
        clearDeliveryCart(deliveryRestaurantId);
      } else {
        clearCart();
      }
    }

    setPaymentData(result);

    // Loading on first check
    setIsLoading(false);

    if (result && (result.processed || result.processingFailed)) {
      setIsProcessing(false);
      return true;
    }

    if (!isSuccess && (!result || result.status === 'Failed')) {
      setIsProcessing(false);
      return true;
    }

    return false;
  };

  useInterval(
    getPaymentData,
    3000,
    true,
    isDelivery ? [holdProcessing] : undefined,
    isDelivery ? holdProcessing : false,
  );

  return { isCanceled, isSuccess, isProcessing, isLoading, paymentData };
}
