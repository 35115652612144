import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslatedData } from '../../localization';

const CategorySelectCard = ({ category, onSelect }) => {
  const { t } = useTranslation(['delivery']);

  const [noBanner, setNoBanner] = useState(!!category.imageUrl);

  useEffect(() => {
    if (category) {
      setNoBanner(!category.imageUrl);
    }
  }, [category]);

  return (
    <div
      className="max-w-[13rem] w-full rounded-xl pb-4 border-input border cursor-pointer hover:scale-103 transition duration-300 ease-in-out hover:shadow-sm"
      role="button"
      onClick={onSelect}
    >
      {!noBanner && (
        <div className="max-h-48 w-full overflow-hidden rounded-t-xl mb-2">
          <img
            className="object-cover object-center w-full"
            src={category.imageUrl}
            alt="Category"
            onError={() => {
              setNoBanner(true);
            }}
          />
        </div>
      )}
      <div className="mx-5">
        <div className="font-primarySemiBold text-lg leading-6 text-primary">
          {getTranslatedData(category.name)}
        </div>
        <div className="mt-1 font-primaryRegular text-sm text-bland">
          {(category.restaurants || []).length} {t('delivery:home.totalPlacesInCategory')}
        </div>
      </div>
    </div>
  );
};

export default CategorySelectCard;
