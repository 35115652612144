import React from 'react';

export const useLocalStorage = (storageKey, fallbackState) => {
  const [value, setValueInner] = React.useState(
    // eslint-disable-next-line no-undef
    JSON.parse(localStorage.getItem(storageKey)) || fallbackState,
  );
  const valueRef = React.useRef({
    // eslint-disable-next-line no-undef
    value: JSON.parse(localStorage.getItem(storageKey)) || fallbackState,
  }).current;

  const setValue = (newVal) => {
    valueRef.value = newVal;
    setValueInner(newVal);
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [valueRef.value, setValue, valueRef];
};
