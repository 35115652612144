import { sortObjectArray } from '../../utils/arrayHelpers';
import { makeId } from '../../utils/stringHelper';
import { appLanguages } from '../../localization';

export const mapApiToDataMenu = ({ menu }) => {
  if (!menu) {
    return null;
  }

  const formedMenu = {
    id: menu._id,
    type: menu.type, // Images, Link, Products
    externalLink: menu.external_link,
    categories: menu.categories
      ? menu.categories.map((categ) => {
          const categoryImages = menu.category_images
            ? (menu.category_images.filter((x) => x.category_id === categ.category_id) || []).map(
                (x) => ({ languages: x.languages, images: x.images }),
              )
            : [];

          const imagesByLang = {};

          appLanguages.forEach((lang) => {
            // try find by lang
            let imagesForLang = categoryImages.find(
              (x) => x.languages && x.languages.includes(lang.langCode),
            );

            // try find for all lang
            if (!imagesForLang) {
              imagesForLang = categoryImages.find(
                (x) => x.languages && x.languages.includes('all'),
              );
            }

            if (imagesForLang) {
              imagesByLang[lang.langCode] = imagesForLang.images;
            }
          });

          return {
            id: categ.category_id || makeId(10),
            name: categ.name,
            images: imagesByLang,
            order: categ.order,
          };
        })
      : [],
  };

  if (formedMenu.categories) {
    sortObjectArray(formedMenu.categories, 'order', 1, true);
  }

  return formedMenu;
};
