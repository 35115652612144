import {
  cacheCart,
  cacheDeliveryCart,
  calculateCartSum,
  getCachedCart,
  getCachedDeliveryCart,
} from '../services/cartService';
import { activeTable } from '../services/tableService';
import { roundDecimal } from '../utils/roundingHelpers';
import { makeId } from '../utils/stringHelper';
import createDataContext from './_init_/createDataContext';

const reducer = (state, action) => {
  switch (action.type) {
    case 'init_cart': {
      return {
        ...state,
        cart: action.payload.cart || [],
        lastUpdated: action.payload.lastUpdated,
      };
    }
    case 'init_delivery_cart': {
      return {
        ...state,
        deliveryCart: action.payload.cart || {},
        deliveryCartSlug: action.payload.slug,
      };
    }
    case 'add_product_to_cart': {
      const { cart } = state;
      const newProducts = combineProducts([...(cart || []), action.payload]);

      const cartDiscounts = action.discountCartService.applyDiscounts({
        cart: newProducts,
        cartTotal: calculateCartSum({ cart: newProducts, usePriceWithoutDiscount: true }),
      });

      const totalDiscounted = reduceDiscountsSum(cartDiscounts);

      const lastUpdated = cacheCart(activeTable.id, newProducts);

      return {
        ...state,
        cart: newProducts,
        totalDiscounted,
        lastUpdated,
      };
    }
    case 'add_product_to_delivery_cart': {
      const { deliveryCart, deliveryCartSlug } = state;
      const cartData = deliveryCart[action.payload.restaurantId] || {};

      const newProducts = combineProducts([...(cartData.cart || []), action.payload.product]);

      const cartDiscounts = action.discountCartService.applyDiscounts({
        cart: newProducts,
        cartTotal: calculateCartSum({ cart: newProducts, usePriceWithoutDiscount: true }),
      });

      const totalDiscounted = reduceDiscountsSum(cartDiscounts);

      const lastUpdated = cacheDeliveryCart(
        deliveryCartSlug,
        action.payload.restaurantId,
        newProducts,
      );

      return {
        ...state,
        deliveryCart: {
          ...deliveryCart,
          [action.payload.restaurantId]: { cart: newProducts, lastUpdated, totalDiscounted },
        },
      };
    }
    case 'remove_product_from_cart': {
      const { cart } = state;
      if (action.payload.index !== undefined) {
        const newCart = [...(cart || [])];

        const finalNewCart = newCart.filter((x) => x.index !== action.payload.index);

        const cartDiscounts = action.discountCartService.applyDiscounts({
          cart: finalNewCart,
          cartTotal: calculateCartSum({ cart: finalNewCart, usePriceWithoutDiscount: true }),
        });

        const totalDiscounted = reduceDiscountsSum(cartDiscounts);

        const lastUpdated = cacheCart(activeTable.id, finalNewCart);

        return {
          ...state,
          cart: finalNewCart,
          totalDiscounted,
          lastUpdated,
        };
      }

      const filteredCart = [
        ...(cart || []).filter((x) => x.productId !== action.payload.productId),
      ];

      const cartDiscounts = action.discountCartService.applyDiscounts({
        cart: filteredCart,
        cartTotal: calculateCartSum({ cart: filteredCart, usePriceWithoutDiscount: true }),
      });

      const totalDiscounted = reduceDiscountsSum(cartDiscounts);

      return {
        ...state,
        cart: filteredCart,
        totalDiscounted,
      };
    }
    case 'remove_product_from_delivery_cart': {
      const { deliveryCart, deliveryCartSlug } = state;
      const cartData = deliveryCart[action.payload.restaurantId] || {};

      if (action.payload.index !== undefined) {
        const newCart = [...(cartData.cart || [])];

        const finalNewCart = newCart.filter((x) => x.index !== action.payload.index);

        const cartDiscounts = action.discountCartService.applyDiscounts({
          cart: finalNewCart,
          cartTotal: calculateCartSum({ cart: finalNewCart, usePriceWithoutDiscount: true }),
        });

        const totalDiscounted = reduceDiscountsSum(cartDiscounts);

        const lastUpdated = cacheDeliveryCart(
          deliveryCartSlug,
          action.payload.restaurantId,
          finalNewCart,
        );

        return {
          ...state,
          deliveryCart: {
            ...deliveryCart,
            [action.payload.restaurantId]: { cart: finalNewCart, lastUpdated, totalDiscounted },
          },
        };
      }

      const filteredCart = [
        ...(cartData.cart || []).filter((x) => x.productId !== action.payload.productId),
      ];

      const cartDiscounts = action.discountCartService.applyDiscounts({
        cart: filteredCart,
        cartTotal: calculateCartSum({ cart: filteredCart, usePriceWithoutDiscount: true }),
      });

      const totalDiscounted = reduceDiscountsSum(cartDiscounts);

      return {
        ...state,
        deliveryCart: {
          ...deliveryCart,
          [action.payload.restaurantId]: {
            cart: filteredCart,
            lastUpdated: cartData.lastUpdated,
            totalDiscounted,
          },
        },
      };
    }
    case 'clear_cart': {
      const lastUpdated = cacheCart(activeTable.id, []);

      return { ...state, cart: [], totalDiscounted: 0, lastUpdated };
    }
    case 'clear_delivery_cart': {
      const { deliveryCart, deliveryCartSlug } = state;

      const lastUpdated = cacheDeliveryCart(deliveryCartSlug, action.payload.restaurantId, []);

      return {
        ...state,
        deliveryCart: {
          ...deliveryCart,
          [action.payload.restaurantId]: {
            cart: [],
            lastUpdated,
            totalDiscounted: 0,
          },
        },
      };
    }
    case 'set_total_discounted':
      return { ...state, totalDiscounted: action.payload };
    case 'set_delivery_total_discounted': {
      const { deliveryCart } = state;

      return {
        ...state,
        fistDeliveryDiscountApplied: true,
        deliveryCart: {
          ...deliveryCart,
          [action.payload.restaurantId]: {
            ...(deliveryCart[action.payload.restaurantId] || {}),
            totalDiscounted: action.payload.totalDiscounted,
          },
        },
      };
    }
    case 'set_auto_refreshed_cart': {
      cacheCart(activeTable.id, action.payload.cart, true);

      return {
        ...state,
        cart: action.payload.cart,
        totalDiscounted: action.payload.totalDiscounted,
      };
    }
    case 'set_auto_refreshed_delivery_cart': {
      const { deliveryCart, deliveryCartSlug } = state;

      const lastUpdated = cacheDeliveryCart(
        deliveryCartSlug,
        action.payload.restaurantId,
        action.payload.cart,
        true,
      );

      return {
        ...state,
        deliveryCart: {
          ...deliveryCart,
          [action.payload.restaurantId]: {
            cart: action.payload.cart,
            lastUpdated,
            totalDiscounted: action.payload.totalDiscounted,
          },
        },
      };
    }
    default:
      return state;
  }
};

const initCart = (dispatch) => (tableId) => {
  const response = getCachedCart(tableId);

  dispatch({
    type: 'init_cart',
    payload: {
      cart: response ? response.cart : null,
      lastUpdated: response ? response.lastUpdated : null,
    },
  });
};

const initDeliveryCart = (dispatch) => (slug) => {
  const response = getCachedDeliveryCart(slug);

  dispatch({
    type: 'init_delivery_cart',
    payload: {
      cart: response,
      slug,
    },
  });
};

const addProductToCart = (dispatch) => (product, discountCartService) => {
  dispatch({ type: 'add_product_to_cart', payload: product, discountCartService });
};

const addProductToDeliveryCart = (dispatch) => (restaurantId, product, discountCartService) => {
  dispatch({
    type: 'add_product_to_delivery_cart',
    payload: { restaurantId, product },
    discountCartService,
  });
};

const autoRefreshCartLocal = (dispatch, state) => (discountCartService, productCategories) => {
  const { cart } = state;

  if (cart && cart.length > 0) {
    const adjustedCart = [];

    // Adjust after schedule change
    cart.forEach((cartItem) => {
      if (productCategories && productCategories.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const category of productCategories) {
          if (category.products) {
            const product = category.products.find((x) => x.id === cartItem.productId);

            if (product) {
              adjustedCart.push(cartItem);
              return;
            }
          }
        }
      }
    });

    // Adjust after discount schedule change
    const { totalDiscounted } = calculateCartDiscountInner(adjustedCart, discountCartService);

    dispatch({ type: 'set_auto_refreshed_cart', payload: { cart: adjustedCart, totalDiscounted } });

    return { refreshed: true, totalDiscounted, cart: adjustedCart };
  }

  return { refreshed: false };
};

const autoRefreshDeliveryCartLocal =
  (dispatch, state) => (restaurantId, discountCartService, productCategories) => {
    const { deliveryCart } = state;

    const cartData = deliveryCart[restaurantId] || {};
    const { cart } = cartData;

    if (cart && cart.length > 0) {
      const adjustedCart = [];

      // Adjust after schedule change
      cart.forEach((cartItem) => {
        if (productCategories && productCategories.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const category of productCategories) {
            if (category.products) {
              const product = category.products.find((x) => x.id === cartItem.productId);

              if (product) {
                adjustedCart.push(cartItem);
                return;
              }
            }
          }
        }
      });

      // Adjust after discount schedule change
      const { totalDiscounted } = calculateCartDiscountInner(adjustedCart, discountCartService);

      dispatch({
        type: 'set_auto_refreshed_delivery_cart',
        payload: { restaurantId, cart: adjustedCart, totalDiscounted },
      });

      return { refreshed: true, totalDiscounted, cart: adjustedCart };
    }

    return { refreshed: false };
  };

const applyDiscounts = (dispatch, state) => (discountCartService) => {
  const { cart } = state;

  const { totalDiscounted } = calculateCartDiscountInner(cart, discountCartService);

  dispatch({ type: 'set_total_discounted', payload: totalDiscounted });

  return { totalDiscounted };
};

const applyDeliveryDiscounts = (dispatch, state) => (restaurantId, discountCartService) => {
  const { deliveryCart } = state;
  const cartData = deliveryCart[restaurantId] || {};

  const { totalDiscounted } = calculateCartDiscountInner(cartData.cart || [], discountCartService);

  dispatch({ type: 'set_delivery_total_discounted', payload: { restaurantId, totalDiscounted } });

  return { totalDiscounted };
};

const calculateCartDiscountInner = (cart, discountCartService) => {
  const cartDiscounts = discountCartService.applyDiscounts({
    cart,
    cartTotal: calculateCartSum({ cart, usePriceWithoutDiscount: true }),
  });

  const totalDiscounted = reduceDiscountsSum(cartDiscounts);

  return { totalDiscounted };
};

const removeProductFromCart = (dispatch) => (productId, index, discountCartService) => {
  dispatch({
    type: 'remove_product_from_cart',
    payload: { productId, index },
    discountCartService,
  });
};

const removeProductFromDeliveryCart =
  (dispatch) => (restaurantId, productId, index, discountCartService) => {
    dispatch({
      type: 'remove_product_from_delivery_cart',
      payload: { productId, index, restaurantId },
      discountCartService,
    });
  };

const clearCart = (dispatch) => () => {
  dispatch({
    type: 'clear_cart',
  });
};

const clearDeliveryCart = (dispatch) => (restaurantId) => {
  dispatch({
    type: 'clear_delivery_cart',
    payload: {
      restaurantId,
    },
  });
};

const combineProducts = (products) => {
  if (!products || products.length === 0) {
    return products;
  }

  products.forEach((prod) => {
    prod.index = makeId(15);
  });

  const indexesToRemove = [];
  for (let i = 0; i < products.length; i += 1) {
    const prod1 = products[i];

    for (let j = i + 1; j < products.length; j += 1) {
      const prod2 = products[j];

      const areEqual = areProductsEqual(prod1, prod2);
      if (areEqual) {
        prod1.quantity += prod2.quantity || 0;
        prod1.total += prod2.total;
        indexesToRemove.push(prod2.index);
      }
    }
  }

  if (indexesToRemove.length > 0) {
    return products.filter((x) => !indexesToRemove.includes(x.index));
  }

  return products;
};

const areProductsEqual = (prod1, prod2) => {
  if (!prod1 || !prod2 || prod1.productId !== prod2.productId) {
    return false;
  }

  // if both have no variations
  if (
    (!prod1.variations || prod1.variations.length === 0) &&
    (!prod2.variations || prod2.variations.length === 0)
  ) {
    return true;
  }

  // if different variations length
  if ((prod1.variations || []).length !== (prod2.variations || []).length) {
    return false;
  }

  for (let i = 0; i < prod1.variations.length; i += 1) {
    const var1 = prod1.variations[i];
    const var2 = prod2.variations.find((x) => x.id === var1.id);

    if (!var2 || (var1.items || []).length !== (var2.items || []).length) {
      return false;
    }

    if ((var1.items || []).length === 0 && (var2.items || []).length === 0) {
      return true;
    }

    for (let j = 0; j < var1.items.length; j += 1) {
      const item1 = var1.items[j];
      const item2 = var2.items.find((x) => x.id === item1.id);

      if (!item2) {
        return false;
      }
    }
  }

  return true;
};

const reduceDiscountsSum = (discounts) => {
  let discountTotal = 0;

  if (discounts && discounts.length > 0) {
    discounts.forEach((calculatedDiscount) => {
      discountTotal += calculatedDiscount.sum;
    });

    discountTotal = roundDecimal(discountTotal);
  }

  return discountTotal;
};

const initialData = {
  cart: [],
  totalDiscounted: 0,
  lastUpdated: null,

  deliveryCartSlug: null,
  deliveryCart: {},
  fistDeliveryDiscountApplied: false,
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    initCart,
    initDeliveryCart,
    addProductToCart,
    addProductToDeliveryCart,
    removeProductFromCart,
    removeProductFromDeliveryCart,
    clearCart,
    clearDeliveryCart,
    applyDiscounts,
    applyDeliveryDiscounts,
    autoRefreshCartLocal,
    autoRefreshDeliveryCartLocal,
  },
  { ...initialData },
);
