import { useState, useEffect, useRef } from 'react';

export const useDeliveryMenuBarScroll = () => {
  const searchBlockRef = useRef();
  const menuBarRef = useRef();

  const [menuBarReached, setMenuBarReached] = useState(false);
  const menuBarReachedRef = useRef({ value: false }).current;

  const handleScroll = () => {
    const scrollPosition = global.window.scrollY;
    const blockFromTop =
      searchBlockRef && searchBlockRef.current ? searchBlockRef.current.offsetTop : 0;
    const blockHeight =
      searchBlockRef && searchBlockRef.current ? searchBlockRef.current.offsetHeight : 0;

    if (scrollPosition >= blockFromTop + blockHeight && !menuBarReachedRef.value) {
      setMenuBarReached(true);
      menuBarReachedRef.value = true;
    } else if (scrollPosition < blockFromTop + blockHeight && menuBarReachedRef.value) {
      setMenuBarReached(false);
      menuBarReachedRef.value = false;
    }
  };

  useEffect(() => {
    handleScroll();
    global.window.addEventListener('scroll', handleScroll);

    return () => {
      global.window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    searchBlockRef,
    menuBarRef,
    menuBarReached,
  };
};
