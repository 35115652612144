import { Config } from '../configs';
import { currentUtcMillis } from '../utils/dateHelpers';
import { formFileUrlFromFileArray } from '../utils/fileHelper';
import { parseSafeJSON } from '../utils/jsonHelpers';

// From menu product selection modal
export const formatProductForCart = ({ product, quantity, variationOptions }) => {
  const formedData = {
    productId: product.id,
    name: product.name,
    quantity,
    classifications: product.classifications,
    categories: product.categories,
    variations: [],
    price: product.price,
    total: 0,
    discountTotal: 0, // calculate outside if needed
    listImageUrl: formFileUrlFromFileArray(product.listImage),
    isAgeRestricted: product.isAgeRestricted,
  };

  if (variationOptions) {
    Object.entries(variationOptions).forEach(([variationId, itemIdOrIds]) => {
      const variationData = product.variations
        ? product.variations.find((x) => x.id === variationId)
        : null;
      const variationItemsData = variationData ? variationData.items : null;
      if (!variationItemsData) {
        return;
      }

      if (variationData.isJoin) {
        const formedVariationsFromJoin = extractVariationsFromJoin({
          itemIdOrIds,
          joinData: variationData,
          itemsData: variationItemsData,
          quantity,
        });

        if (formedVariationsFromJoin && formedVariationsFromJoin.length > 0) {
          formedVariationsFromJoin.forEach((formedVariation) => {
            if (formedVariation.items && formedVariation.items.length > 0) {
              formedData.variations.push(formedVariation);
            }
          });
        }
      } else {
        const formedVariation = {
          id: variationId,
          name: variationData.name,
          items: [],
        };

        if (Array.isArray(itemIdOrIds)) {
          itemIdOrIds.forEach((itemId) => {
            const data = variationItemsData.find((x) => x.id === itemId);
            if (data) {
              formedVariation.items.push({
                id: itemId,
                price: data.priceWithDiscount || data.price,
                priceWithoutDiscount: data.price,
                name: data.name,
                comboProduct: data.comboProduct
                  ? formatProductForCart({ product: data.comboProduct, quantity })
                  : null,
              });
            }
          });
        } else {
          const data = variationItemsData.find((x) => x.id === itemIdOrIds);
          if (data) {
            formedVariation.items.push({
              id: itemIdOrIds,
              price: data.priceWithDiscount || data.price,
              priceWithoutDiscount: data.price,
              name: data.name,
              comboProduct: data.comboProduct
                ? formatProductForCart({ product: data.comboProduct, quantity })
                : null,
            });
          }
        }

        if (formedVariation.items && formedVariation.items.length > 0) {
          formedData.variations.push(formedVariation);
        }
      }
    });
  }

  formedData.total = calculateCartItemSum({ product: formedData });

  return formedData;
};

const extractVariationsFromJoin = ({ itemIdOrIds, joinData, itemsData, quantity }) => {
  const formedVariationsObj = {};

  const itemIdOrIdsArray = Array.isArray(itemIdOrIds) ? itemIdOrIds : [itemIdOrIds];

  itemIdOrIdsArray.forEach((itemId) => {
    const data = itemsData.find((x) => x.id === itemId);
    if (!data) {
      return;
    }

    if (!formedVariationsObj[data.variationId]) {
      formedVariationsObj[data.variationId] = {
        id: data.variationId,
        name: joinData.name,
        items: [],
        isJoin: true,
      };
    }

    formedVariationsObj[data.variationId].items.push({
      id: itemId,
      price: data.priceWithDiscount || data.price,
      priceWithoutDiscount: data.price,
      name: data.name,
      comboProduct: data.comboProduct
        ? formatProductForCart({ product: data.comboProduct, quantity })
        : null,
    });
  });

  return Object.values(formedVariationsObj) || [];
};

// From order page, input - ordered product data
export const formatProductForCartFromOrder = ({ product }) => {
  const formedData = {
    productId: product.id,
    name: product.name,
    quantity: product.quantity,
    price: product.totalSingle,
    total: product.total,
    classifications: product.classifications,
    categories: product.categories,
    variations: product.variations
      ? product.variations.map((variation) => {
          const formed = {
            id: variation.id,
            name: variation.name,
            items: variation.items
              ? variation.items.map((item) => {
                  const formedItem = {
                    id: item.id,
                    price: item.price,
                    name: item.name,
                  };

                  return formedItem;
                })
              : [],
          };

          return formed;
        })
      : [],
  };

  return formedData;
};

// Form product to cart item to display percentage discount amount in menu, ignore variations
export const formatProductForCartItemForDiscountDisplay = ({ product }) => {
  const formedData = {
    productId: product.id,
    name: product.name,
    quantity: 1,
    classifications: product.classifications,
    categories: product.categories,
    price: product.price,
    total: product.price,
  };

  return formedData;
};

// cart for initiate order
export const formatCartForBill = ({ cart }) => {
  const formedData = [];

  if (!cart || cart.length === 0) {
    return formedData;
  }

  cart.forEach((product) => {
    formedData.push({
      id: product.productId,
      quantity: product.quantity,
      variations: product.variations
        ? product.variations.map((variation) => ({
            id: variation.id,
            isJoin: variation.isJoin,
            items: variation.items ? variation.items.map((item) => ({ id: item.id })) : [],
          }))
        : [],
    });
  });

  return formedData;
};

export const discardProductDiscount = ({ product }) => {
  const productWithoutDiscount = {
    ...product,
    price: product.priceBefore || product.price,
    discountPercent: 0,
  };

  return productWithoutDiscount;
};

export const calculateCartSum = ({ cart, usePriceWithoutDiscount }) => {
  let price = 0;

  if (!cart || cart.length === 0) {
    return price;
  }

  cart.forEach((product) => {
    price +=
      ((usePriceWithoutDiscount ? product.priceWithoutDiscount || product.price : product.price) ||
        0) * product.quantity;

    if (!product.variations || product.variations.length === 0) {
      return;
    }

    product.variations.forEach((variation) => {
      if (!variation.items || variation.items.length === 0) {
        return;
      }

      variation.items.forEach((item) => {
        price +=
          ((usePriceWithoutDiscount ? item.priceWithoutDiscount || item.price : item.price) || 0) *
          product.quantity;
      });
    });
  });

  return price;
};

export const calculateCartItemSum = ({ product, usePriceWithoutDiscount }) => {
  let price = 0;

  price +=
    ((usePriceWithoutDiscount ? product.priceWithoutDiscount || product.price : product.price) ||
      0) * product.quantity;

  if (!product.variations || product.variations.length === 0) {
    return price;
  }

  product.variations.forEach((variation) => {
    if (!variation.items || variation.items.length === 0) {
      return;
    }

    variation.items.forEach((item) => {
      price +=
        ((usePriceWithoutDiscount ? item.priceWithoutDiscount || item.price : item.price) || 0) *
        product.quantity;
    });
  });

  return price;
};

export const calculateTotalItemsInCart = ({ cart }) => {
  let qnt = 0;

  if (!cart || cart.length === 0) {
    return qnt;
  }

  cart.forEach((product) => {
    qnt += product.quantity || 0;
  });

  return qnt;
};

export const getCachedCart = (tableId) => {
  const cartString = global.localStorage.getItem(Config.CACHE_KEYS.CART);

  const cart = parseSafeJSON(cartString);

  if (cart && cart.lastUpdated) {
    // Delete cart later than 2h
    const clearCartEarlierThan = currentUtcMillis() - 2 * 3600000;

    if (clearCartEarlierThan > cart.lastUpdated) {
      global.localStorage.removeItem(Config.CACHE_KEYS.CART);
      return null;
    }
  }

  if (cart && cart.tableId === tableId) {
    return { cart: cart.cart, lastUpdated: cart.lastUpdated };
  }

  global.localStorage.removeItem(Config.CACHE_KEYS.CART);

  return null;
};

export const cacheCart = (tableId, cart, dontChangeLastUpdated) => {
  let lastUpdated = currentUtcMillis();

  if (dontChangeLastUpdated) {
    const currentCart = parseSafeJSON(global.localStorage.getItem(Config.CACHE_KEYS.CART));
    lastUpdated = currentCart.lastUpdated;
  }

  global.localStorage.setItem(
    Config.CACHE_KEYS.CART,
    JSON.stringify({ tableId, cart, lastUpdated }),
  );

  return lastUpdated;
};

export const getCachedDeliveryCart = (slug) => {
  const cartString = global.localStorage.getItem(Config.CACHE_KEYS.CART_DELIVERY + slug);

  const companyCart = parseSafeJSON(cartString);
  if (!companyCart) {
    return null;
  }

  const restaurantCartsToClear = [];
  if (companyCart) {
    Object.entries(companyCart).forEach((restaurantId, cart) => {
      if (cart && cart.lastUpdated) {
        // Delete cart later than 24h
        const clearCartEarlierThan = currentUtcMillis() - 24 * 3600000;

        if (clearCartEarlierThan > cart.lastUpdated) {
          restaurantCartsToClear.push(restaurantId);
        }
      }
    });
  }

  restaurantCartsToClear.forEach((restaurantId) => {
    delete companyCart[restaurantId];
  });

  if (restaurantCartsToClear.length > 0) {
    global.localStorage.setItem(
      Config.CACHE_KEYS.CART_DELIVERY + slug,
      JSON.stringify(companyCart),
    );
  }

  return companyCart;
};

export const cacheDeliveryCart = (slug, restaurantId, cart, dontChangeLastUpdated) => {
  let lastUpdated = currentUtcMillis();

  const companyCart =
    parseSafeJSON(global.localStorage.getItem(Config.CACHE_KEYS.CART_DELIVERY + slug)) || {};

  if (dontChangeLastUpdated) {
    lastUpdated = (companyCart[restaurantId] || {}).lastUpdated || lastUpdated;
  }

  if (!companyCart[restaurantId]) {
    companyCart[restaurantId] = {};
  }

  companyCart[restaurantId].cart = cart;
  companyCart[restaurantId].lastUpdated = lastUpdated;

  global.localStorage.setItem(Config.CACHE_KEYS.CART_DELIVERY + slug, JSON.stringify(companyCart));

  return lastUpdated;
};
