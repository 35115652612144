import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../layouts/mainLayout';
import TopBar from '../../layouts/delivery/topBar';
import SuccessPayment from '../../features/payments/components/successPayment';
import Loader from '../../components/misc/loader';
import FailPayment from '../../features/payments/components/failPayment';
import { usePaymentHandler } from '../../hooks/usePaymentHandler';
import { useDeliveryPageInit } from '../../hooks/useDeliveryPageInit';
import ContentWrapper from '../../layouts/delivery/contentWrapper';

const PaymentDeliveryPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoadingDeliveryInit, isSelectedRestaurantInit, selectedRestaurant } =
    useDeliveryPageInit();

  const canStartProcessingPayment =
    !isLoadingDeliveryInit && isSelectedRestaurantInit && !!selectedRestaurant;

  const { isCanceled, isLoading, isProcessing, paymentData } = usePaymentHandler({
    isDelivery: true,
    deliveryRestaurantId: selectedRestaurant ? selectedRestaurant.id : null,
    holdProcessing: !canStartProcessingPayment,
  });

  useEffect(() => {
    if (!canStartProcessingPayment) {
      return;
    }

    // Cancelled payment
    if (isCanceled) {
      navigate(`/${selectedRestaurant.slug}`, { replace: true });
    }
  }, [canStartProcessingPayment]);

  if (isLoadingDeliveryInit || !isSelectedRestaurantInit || !selectedRestaurant) {
    return <Loader />;
  }

  // Cancelled payment
  if (isCanceled) {
    return null;
  }

  const renderContent = () => {
    if (isLoading || isProcessing) {
      return (
        <div className="pt-32 pb-28">
          <Loader customHeight text={t('checkout.processingPaymentLoader')} />
        </div>
      );
    }

    if (!isLoading && !isProcessing && !paymentData) {
      return (
        <ContentWrapper>
          <div className="mt-6 ">
            <div className="font-primaryMedium text-lg text-primary">{t('errors.noPayment')}</div>
          </div>
        </ContentWrapper>
      );
    }

    if (paymentData && paymentData.status === 'Paid' && paymentData.processed) {
      return (
        <ContentWrapper>
          <div className="py-6 ">
            <SuccessPayment
              payment={paymentData}
              canRate={false}
              isDelivery
              onDeliveryPress={() => {
                navigate(`/${selectedRestaurant.slug}`);
              }}
              deliveryRestaurant={selectedRestaurant}
            />
          </div>
        </ContentWrapper>
      );
    }

    // Payment might have succeeded, but failed to create order
    if (paymentData /* && paymentData.status !== 'Paid' */ && paymentData.processingFailed) {
      return (
        <ContentWrapper>
          <FailPayment
            payment={paymentData}
            isDelivery
            onDeliveryPress={() => {
              navigate(`/${selectedRestaurant.slug}`);
            }}
          />
        </ContentWrapper>
      );
    }

    return null;
  };

  return (
    <MainLayout>
      <TopBar
        customTitle={t('header.payment')}
        onBack={() => {
          navigate(`/${selectedRestaurant.slug}`);
        }}
      />
      {renderContent()}
    </MainLayout>
  );
};

export default PaymentDeliveryPage;
