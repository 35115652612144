import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { RiSearchLine } from 'react-icons/ri';
import { PhoneInput } from 'react-international-phone';
import { useCurrency } from '../../hooks/useCurrency';
// eslint-disable-next-line import/no-unresolved
import 'react-international-phone/style.css';

export const inputType = {
  price: 'price',
  centerText: 'centerText',
  longText: 'longText',
  formField: 'formField',
  phoneFormField: 'phoneFormField',
  search: 'search',
};

const TextInput = ({
  id,
  label,
  placeholder,
  value,
  max,
  onChange,
  onBlur,
  type,
  required,
  isDelivery,
  defaultCountryCode,
  isError,
}) => {
  const { currencySymbol } = useCurrency();

  const renderPriceInput = () => {
    return (
      <div className="relative mb-6 border border-input rounded-full py-4 px-7">
        <div className="flex flex-row items-center">
          <p className="font-primaryMedium text-xl text-primary mr-3">{currencySymbol}</p>
          <CurrencyInput
            id={id}
            placeholder={placeholder}
            value={value === 0 ? undefined : value}
            decimalsLimit={2}
            disableAbbreviations
            disableGroupSeparators
            decimalSeparator="."
            onValueChange={(val) => {
              if (val === undefined || val === null || val === '0' || val === '00') {
                onChange(0);
                return;
              }

              if (val.startsWith('-')) {
                return;
              }

              const parsedVal = parseFloat(val);
              if (max && !Number.isNaN(parsedVal) && parsedVal > max) {
                onChange(max);
                return;
              }

              onChange(val);
            }}
            className="font-primaryMedium text-xl text-primary outline-none w-full"
          />
        </div>
      </div>
    );
  };

  const renderLongText = () => {
    return (
      <div className="relative border border-input rounded-2xl py-3 px-3">
        <div className="flex flex-row items-center">
          <textarea
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            rows={3}
            className="font-primaryMedium text-base text-primary outline-none w-full"
          />
        </div>
      </div>
    );
  };

  const renderCenterText = () => {
    return (
      <div className="relative border border-input rounded-full py-5 px-3">
        <div className="flex flex-row items-center">
          <input
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            className="font-primaryMedium text-base text-primary outline-none w-full text-center"
          />
        </div>
      </div>
    );
  };

  const getFormInputContainerClass = () => {
    return `relative border ${isError ? 'border-primaryOrange' : 'border-input'} ${
      isDelivery ? 'rounded-md' : 'rounded-3xl'
    } py-3 px-5`;
  };

  const getFormInputLabelClass = () => {
    return 'block mb-1 text-base font-primarySemiBold text-primary';
  };

  const getFormInputInputClass = () => {
    return 'font-primaryMedium text-base text-primary outline-none w-full';
  };

  const renderRequiredTag = () => {
    return <span className="text-primaryOrange"> *</span>;
  };

  const renderFormInput = () => {
    return (
      <div className={getFormInputContainerClass()}>
        {label && isDelivery ? (
          <label htmlFor={id} className={getFormInputLabelClass()}>
            {label}
            {required ? renderRequiredTag() : ''}
          </label>
        ) : null}
        <div className="flex flex-row items-center">
          <input
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            className={getFormInputInputClass()}
            onBlur={onBlur}
          />
        </div>
      </div>
    );
  };

  const renderPhoneFormInput = () => {
    return (
      <div className={getFormInputContainerClass()}>
        {label && isDelivery ? (
          <label htmlFor={id} className={getFormInputLabelClass()}>
            {label}
            {required ? renderRequiredTag() : ''}
          </label>
        ) : null}
        <div className="flex flex-row items-center">
          <div id={id} className={getFormInputInputClass()}>
            <PhoneInput
              defaultCountry={defaultCountryCode}
              value={value}
              onChange={(phone, { country }) => {
                const dialCode = country && country.dialCode ? `+${country.dialCode}` : '';
                onChange(phone, dialCode);
              }}
              placeholder={placeholder}
              className="phone-input-container"
              inputClassName="phone-input"
              forceDialCode
              disableCountryGuess
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSearchInput = () => {
    return (
      <div className="relative border border-input rounded-3xl py-3 px-5">
        <div className="flex flex-row items-center">
          <input
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            className="font-primaryMedium text-base text-primary outline-none w-full"
          />
          <RiSearchLine className="text-bland text-xl" />
        </div>
      </div>
    );
  };

  return (
    <div>
      {label && !isDelivery ? (
        <label htmlFor={id} className="block mb-2 text-md font-primaryMedium text-primary ml-4">
          {label}
          {required ? renderRequiredTag() : ''}
        </label>
      ) : null}
      {type === inputType.price && renderPriceInput()}
      {type === inputType.longText && renderLongText()}
      {type === inputType.centerText && renderCenterText()}
      {type === inputType.formField && renderFormInput()}
      {type === inputType.phoneFormField && renderPhoneFormInput()}
      {type === inputType.search && renderSearchInput()}
    </div>
  );
};

export default TextInput;
