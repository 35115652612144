import React, { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Context as DeliveryContext } from '../../../../context/delivery';
import { Context as RestaurantContext } from '../../../../context/restaurant';
import CheckoutPayment from '../payment';
import CheckoutTotal from '../total';
import ContentWrapper from '../../../../layouts/delivery/contentWrapper';
import ConfirmButton from '../../../../components/buttons/confirmButton';
import CartItem from '../../../cart/components/cartItem';
import DeliveryMethod from './deliveryMethod';
import TextInput, { inputType } from '../../../../components/inputs/textInput';
import TopBar from '../../../../layouts/delivery/topBar';
import { useDeliveryCheckoutForm } from '../../hooks/useDeliveryCheckoutForm';
import { useDeliveryColors } from '../../../../hooks/useDeliveryColors';
import { useDeliveryPricing } from '../../hooks/useDeliveryPricing';
import { getDeliveryDateOnDeliverNow } from '../../services/deliveryTimeHelpers';
import { getUtcFromTimezoneMillis } from '../../../../utils/dateHelpers';
import { useValidator, validationTypes } from '../../../../hooks/useValidator';
import { updatePendingPayment } from '../../../../api/payment';
import DiscountCoupon from './discountCoupon';
import { useDeliveryPromise } from '../../hooks/useDeliveryPromise';
import { getDefaultPhoneCountryCodeByDefaultLanguage } from '../../../../localization';
import { CheckBox } from '../../../../components/inputs/checkBox';

const CheckoutBillDelivery = ({
  deliveryRestaurant,
  bill,
  onPay,
  paymentOption,
  disabled,
  onFail,
  paymentKey,
  onSetInitiating,
}) => {
  const { t } = useTranslation(['translation', 'delivery']);

  const navigate = useNavigate();
  const colors = useDeliveryColors();

  const {
    onBannerHeightChange,
    isCheckoutAbsoluteAtBottom,
    isCheckoutFixed,
    contentWrapperRef,
    movableCheckoutBoxRef,
    checkoutMarginsRef,
  } = useDeliveryCheckoutForm();

  const { state: deliveryState } = useContext(DeliveryContext);
  const { state: restaurantState } = useContext(RestaurantContext);

  // think no tips?
  const [tipsAmount] = useState(0);

  const [appliedCoupon, setAppliedCoupon] = useState(null);

  const [isTakeAway, setIsTakeAway] = useState(
    deliveryRestaurant.hasTakeaway && deliveryState.defaultDeliveryType !== 'delivery',
  );
  const [isDeliverNow, setIsDeliverNow] = useState(true);
  const [deliveryDateMillis, setDeliveryDateMillis] = useState(0);

  const [selectedPaymentMethodType, setSelectedPaymentMethodType] = useState(null);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneDialCode, setPhoneDialCode] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [noteForDriver, setNoteForDriver] = useState('');
  const [roomNumber, setRoomNumber] = useState('');
  const [conditionsAgreed, setConditionsAgreed] = useState(false);
  const [alcoholAgreed, setAlcoholAgreed] = useState(false);

  const [invalidChildOptions, setInvalidChildOptions] = useState({});

  const cardFormSubmitRef = useRef();
  const areOrdersDisabled = deliveryRestaurant.disableOrders;

  const needConfirmConditions =
    !conditionsAgreed &&
    deliveryRestaurant.data &&
    deliveryRestaurant.data.requireConfirmConditions;

  const hasAnyAlcoholProducts = bill && bill.cart && !!bill.cart.find((x) => x.isAgeRestricted);
  const needConfirmAlcohol =
    !alcoholAgreed &&
    deliveryRestaurant.data &&
    deliveryRestaurant.data.requireConfirmAlcohol &&
    hasAnyAlcoholProducts;

  const showAnyConfirmationCheckbox =
    (!!deliveryRestaurant.data &&
      !!deliveryRestaurant.data.requireConfirmAlcohol &&
      hasAnyAlcoholProducts) ||
    (!!deliveryRestaurant.data && !!deliveryRestaurant.data.requireConfirmConditions);

  let isPhoneRequired = true;
  let isEmailRequired = false;
  let isPhoneHidden = false;

  if (isTakeAway) {
    if (deliveryRestaurant.takeawayEmailRequired) {
      isEmailRequired = true;
    }
    if (deliveryRestaurant.takeawayPhoneOptional) {
      isPhoneRequired = false;
    }
    if (deliveryRestaurant.takeawayPhoneHidden) {
      isPhoneHidden = true;
    }
  } else {
    if (deliveryRestaurant.deliveryEmailRequired) {
      isEmailRequired = true;
    }
    if (deliveryRestaurant.deliveryPhoneOptional) {
      isPhoneRequired = false;
    }
    if (deliveryRestaurant.deliveryPhoneHidden) {
      isPhoneHidden = true;
    }
  }

  const {
    tryGetDeliveryPromise,
    isDeliveryProviderMissing,
    deliveryPromiseErrorCode,
    // deliveryPromiseErrorFields,
    isLoadingDeliveryPromise,
    isDeliveryAddressValid,
    providerDeliveryPrice,
    ignoreProviderPrice,
    deliveryAddressErrorCode,
    deliveryOrderId,
  } = useDeliveryPromise(deliveryRestaurant, deliveryAddress, city, zipCode);

  const {
    subtotal,
    total,
    totalDeliveryPrice,
    totalDiscount,
    totalPackagingPrice,
    totalReachMinimalPrice,
    totalCouponDiscount,
  } = useDeliveryPricing({
    bill,
    deliveryRestaurant,
    isTakeAway,
    productCategories: restaurantState.productCategories,
    tipsAmount,
    appliedCoupon,
    providerDeliveryPrice,
    ignoreProviderPrice,
    isDeliveryPriceReceived: !!isDeliveryAddressValid,
  });

  const { isValid: isEmailValid, validate: validateEmail } = useValidator({
    type: validationTypes.email,
    value: email,
    required: isEmailRequired,
  });
  const { isValid: isPhoneValid, validate: validatePhone } = useValidator({
    type: validationTypes.phone,
    value: phone,
    required: isPhoneRequired && !isPhoneHidden,
  });

  const requireRoomNumber = restaurantState.restaurant
    ? restaurantState.restaurant.requireRoomNumber || false
    : false;

  if (!bill) {
    return null;
  }

  const getDeliveryErrorTextByCode = () => {
    if (deliveryPromiseErrorCode === 'outside_area') {
      return t('delivery:errors.deliveryOutsideArea');
    }

    if (deliveryPromiseErrorCode === 'scheduled_invalid') {
      return t('delivery:errors.deliveryScheduledInvalidTime');
    }

    if (deliveryPromiseErrorCode === 'outside_hours') {
      return t('delivery:errors.deliveryOutsideHours');
    }

    if (deliveryPromiseErrorCode === 'validation') {
      return t('delivery:errors.deliveryValidation');
    }

    return t('delivery:errors.deliveryAddressUnknown');
  };

  // 1: canPlace, 2: everything selected except payment method, 2: errorMessage
  const canPlaceAnOrder = () => {
    if (invalidChildOptions.noAvailableDeliveryTypeOptions) {
      return {
        isOrderDisabled: true,
        onlyNeedPaymentMethod: false,
        orderButtonError: t('delivery:checkout.noDeliveryTypes'),
      };
    }

    if (invalidChildOptions.noAvailableDeliveryOptions) {
      return {
        isOrderDisabled: true,
        onlyNeedPaymentMethod: false,
        orderButtonError: t('delivery:checkout.noDeliveryTimes'),
      };
    }

    if (!isTakeAway && isDeliveryProviderMissing) {
      return {
        isOrderDisabled: true,
        onlyNeedPaymentMethod: false,
        orderButtonError: t('delivery:checkout.noDeliveryProvider'),
        hideOrderButtonOnError: true,
      };
    }

    if (!isTakeAway && !!deliveryPromiseErrorCode) {
      return {
        isOrderDisabled: true,
        onlyNeedPaymentMethod: false,
        orderButtonError: getDeliveryErrorTextByCode(),
        hideOrderButtonOnError: true,
      };
    }

    if (!isTakeAway && !isDeliveryAddressValid) {
      return { isOrderDisabled: true, onlyNeedPaymentMethod: false };
    }

    if (!isTakeAway && !deliveryOrderId) {
      return { isOrderDisabled: true, onlyNeedPaymentMethod: false };
    }

    if (!total) {
      return { isOrderDisabled: true, onlyNeedPaymentMethod: false };
    }

    if (isTakeAway && (!isPhoneValid || !isEmailValid)) {
      return { isOrderDisabled: true, onlyNeedPaymentMethod: false };
    }

    if (
      !isTakeAway &&
      (!deliveryAddress ||
        !city ||
        // !zipCode ||
        !isPhoneValid ||
        !isEmailValid ||
        !fullName)
    ) {
      return { isOrderDisabled: true, onlyNeedPaymentMethod: false };
    }

    if (requireRoomNumber && !roomNumber) {
      return { isOrderDisabled: true, onlyNeedPaymentMethod: false };
    }

    if (!selectedPaymentMethodType) {
      return { isOrderDisabled: true, onlyNeedPaymentMethod: true };
    }

    if (needConfirmConditions || needConfirmAlcohol) {
      return { isOrderDisabled: true, onlyNeedPaymentMethod: false };
    }

    return { isOrderDisabled: false };
  };

  const { isOrderDisabled, onlyNeedPaymentMethod, orderButtonError, hideOrderButtonOnError } =
    canPlaceAnOrder();

  const isValidOnSubmit = () => {
    const isEmailValidOnSubmit = validateEmail();
    const isPhoneValidOnSubmit = validatePhone();

    if (!isEmailValidOnSubmit || !isPhoneValidOnSubmit) {
      return false;
    }

    return true;
  };

  const tryGetDeliveryPromiseWrapper = (overrides) => {
    // updates before state changes
    const { updatedDeliverNow, updatedDeliveryDateVal } = overrides || {};

    const isDeliverNowToUse = updatedDeliverNow !== undefined ? updatedDeliverNow : isDeliverNow;
    const deliveryDateMillisToUse =
      updatedDeliveryDateVal !== undefined ? updatedDeliveryDateVal : deliveryDateMillis;

    tryGetDeliveryPromise({
      deliveryAddress,
      city,
      zipCode,
      isDeliverNow: isDeliverNowToUse,
      deliveryDate: isDeliverNowToUse
        ? undefined
        : getUtcFromTimezoneMillis(deliveryDateMillisToUse, deliveryRestaurant.data.timeZone),
      orderPrice: (subtotal || 0) + (totalPackagingPrice || 0) + (totalReachMinimalPrice || 0),
    });
  };

  const formDeliveryPaymentData = () => {
    let deliveryDate;
    let deliveryDateDelta;

    if (isDeliverNow) {
      const { deliveryDateUtc, lowerMinutes } = getDeliveryDateOnDeliverNow({
        deliveryRestaurant,
        isTakeAway,
      });

      deliveryDate = deliveryDateUtc;
      deliveryDateDelta = lowerMinutes;
    } else {
      deliveryDate = getUtcFromTimezoneMillis(deliveryDateMillis, deliveryRestaurant.data.timeZone);
      deliveryDateDelta = 15;
    }

    let prepareDate;
    let prepareDateDelta;

    if (isTakeAway) {
      prepareDate = deliveryDate;
      prepareDateDelta = deliveryDateDelta;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isDeliverNow) {
        const { deliveryDateUtc, lowerMinutes } = getDeliveryDateOnDeliverNow({
          deliveryRestaurant,
          isTakeAway: true,
        });

        prepareDate = deliveryDateUtc;
        prepareDateDelta = lowerMinutes;
      } else {
        prepareDate = deliveryDate - 20 * 60000;
        prepareDateDelta = 15;
      }
    }

    return {
      isTakeAway,
      name: fullName,
      email,
      phone: phone ? phone.replace(/[^\d+]/g, '') : '',
      address: deliveryAddress,
      city,
      zipCode,
      noteForDriver,
      roomNumber,
      isDeliveryNow: isDeliverNow,
      deliveryDate,
      deliveryDateDelta,
      prepareDate,
      prepareDateDelta,
      couponDiscount: totalCouponDiscount,
      couponCode: appliedCoupon ? appliedCoupon.coupon : null,
      deliveryOrderId,
    };
  };

  const onPayWrapper = ({
    paymentType,
    paymentMethodId,
    dontDisable,
    paymentTrackId,
    paymentAccountId,
  }) => {
    if (!dontDisable) {
      if (!isValidOnSubmit()) {
        return;
      }
    }

    onPay({
      paymentType,
      tips: tipsAmount,
      paymentMethodId,
      employeeId: null,
      delivery: formDeliveryPaymentData(),
      dontDisable,
      paymentTrackId,
      paymentAccountId,
    });
  };

  const updatePaymentBeforeSubmit = async ({ externalPaymentId }) => {
    if (!externalPaymentId) {
      return false;
    }

    const deliveryPaymentData = formDeliveryPaymentData();
    const deliveryDataUpdated = await updatePendingPayment({
      externalPaymentId,
      isTakeout: deliveryPaymentData.isTakeAway,
      isDelivery: !deliveryPaymentData.isTakeAway,
      deliveryName: deliveryPaymentData.name,
      deliveryEmail: deliveryPaymentData.email,
      deliveryPhone: deliveryPaymentData.phone,
      deliveryAddress: deliveryPaymentData.address,
      deliveryCity: deliveryPaymentData.city,
      deliveryZipCode: deliveryPaymentData.zipCode,
      noteForDriver: deliveryPaymentData.noteForDriver,
      roomNumber: deliveryPaymentData.roomNumber,
      isDeliveryNow: deliveryPaymentData.isDeliveryNow,
      deliveryDate: deliveryPaymentData.deliveryDate,
      deliveryDateDelta: deliveryPaymentData.deliveryDateDelta,
      prepareDate: deliveryPaymentData.prepareDate,
      prepareDateDelta: deliveryPaymentData.prepareDateDelta,
    });

    return deliveryDataUpdated;
  };

  const renderCompanyDetails = () => {
    return (
      <div className="flex flex-col items-center">
        {!!deliveryRestaurant.data.companyName && (
          <div className="font-primaryMedium text-xs text-bland mb-1">
            {restaurantState.restaurant.companyName}
          </div>
        )}
        {!!deliveryRestaurant.data.registeredCompanyAddress && (
          <div className="font-primaryMedium text-xs text-bland">
            {deliveryRestaurant.data.registeredCompanyAddress}
          </div>
        )}
      </div>
    );
  };

  const renderCheckout = (isForLargeScreen) => {
    return (
      <div
        ref={isForLargeScreen ? movableCheckoutBoxRef : undefined}
        className={
          !isForLargeScreen
            ? 'mt-12 bg-white px-8 pt-12 pb-10 border-input border rounded-md md:hidden z-20'
            : `bg-white px-8 pt-12 pb-10 border-input border
                rounded-md hidden md:block md:w-[308px] lg:w-[400px] z-20
                ${isCheckoutFixed ? 'fixed' : 'absolute'}`
        }
        style={
          isForLargeScreen
            ? {
                // eslint-disable-next-line no-nested-ternary
                top: isCheckoutAbsoluteAtBottom
                  ? checkoutMarginsRef.topAtBottom
                  : isCheckoutFixed
                  ? 20
                  : checkoutMarginsRef.top,
                right: checkoutMarginsRef.right,
              }
            : undefined
        }
      >
        <CheckoutTotal
          subtotal={subtotal}
          total={total}
          tips={tipsAmount}
          discount={totalDiscount}
          delivery={totalDeliveryPrice}
          minimalRemaining={totalReachMinimalPrice}
          packaging={totalPackagingPrice}
          couponCode={appliedCoupon ? appliedCoupon.coupon : null}
          couponDiscount={totalCouponDiscount}
          isDelivery={!isTakeAway}
          isDeliveryPriceLoading={isLoadingDeliveryPromise}
          isDeliveryRequiresAddress={!isDeliveryAddressValid}
          isDeliveryProviderMissing={isDeliveryProviderMissing}
        />
        <div className="mt-10" />
        {!!areOrdersDisabled && (
          <p className="font-primaryMedium text-primaryOrange text-base text-center">
            {t('delivery:checkout.noOrderingTemporarily')}
          </p>
        )}
        {isOrderDisabled && hideOrderButtonOnError && (
          <p className="font-primaryMedium text-primaryOrange text-base text-center">
            {orderButtonError}
          </p>
        )}
        {!areOrdersDisabled && !hideOrderButtonOnError && (
          <ConfirmButton
            onClick={async () => {
              if (selectedPaymentMethodType === 'Card' && paymentOption.cardProvider === 'Stripe') {
                if (!cardFormSubmitRef.current) {
                  return;
                }

                cardFormSubmitRef.current.requestSubmit();
              } else if (
                selectedPaymentMethodType === 'Card' &&
                paymentOption.cardProvider === 'Paynt'
              ) {
                if (!isValidOnSubmit()) {
                  return;
                }

                onSetInitiating(true);

                // update delivery info before submit (Paynt Card)
                const deliveryDataUpdated = await updatePaymentBeforeSubmit({
                  externalPaymentId:
                    global.document.getElementById('paynt-payment-id').dataset.payment,
                });

                if (!deliveryDataUpdated) {
                  onSetInitiating(false);
                  return;
                }

                global.document.getElementsByClassName('wpwl-button-pay')[0].click();

                // in case something fails
                setTimeout(() => {
                  onSetInitiating(false);
                }, 1000);
              } else if (
                selectedPaymentMethodType === 'Card' &&
                paymentOption.cardProvider === 'Zen' &&
                !paymentOption.zenUseRedirect
              ) {
                if (!isValidOnSubmit()) {
                  return;
                }

                if (!cardFormSubmitRef.current) {
                  return;
                }

                onSetInitiating(true);
                cardFormSubmitRef.current.requestSubmit();
              } else {
                onPayWrapper({
                  paymentType: selectedPaymentMethodType,
                  paymentMethodId: selectedPaymentMethodId,
                  paymentAccountId:
                    selectedPaymentMethodType === 'Card'
                      ? paymentOption.cardProviderId
                      : paymentOption.bankProviderId,
                });
              }
            }}
            customText={orderButtonError || t('delivery:buttons.orderAndPay')}
            noShadow
            noSpacing
            disabled={isOrderDisabled}
            loading={disabled}
            customColor={colors.hasCustomPrimary ? colors.primary : undefined}
          />
        )}
        {!!deliveryRestaurant.data.showCompanyDetails && (
          <div className="mt-4">{renderCompanyDetails()}</div>
        )}
      </div>
    );
  };

  const renderDeliveryAddressError = () => {
    if (isDeliveryAddressValid || !deliveryAddressErrorCode) {
      return null;
    }

    let text;
    if (deliveryAddressErrorCode === 'outside_area') {
      text = t('delivery:errors.deliveryOutsideArea');
    } else if (deliveryAddressErrorCode === 'inaccurate') {
      text = t('delivery:errors.deliveryAddressInaccurate');
    } else {
      text = t('delivery:errors.deliveryAddressUnknown');
    }

    return <div className="font-primaryMedium text-primaryOrange text-base mt-2">{text}</div>;
  };

  const renderPhoneAndEmail = () => {
    let renderedFields = [
      <TextInput
        id="order-phone"
        onChange={(newPhone, newDialCode) => {
          setPhone(newPhone === newDialCode ? '' : newPhone);
          setPhoneDialCode(newDialCode);
        }}
        value={phone}
        type={inputType.phoneFormField}
        label={t('delivery:checkout.phone')}
        defaultCountryCode={getDefaultPhoneCountryCodeByDefaultLanguage(
          deliveryRestaurant.data.defaultLanguageCode,
        )}
        required={isPhoneRequired}
        isDelivery
        isError={!isPhoneValid}
      />,
      <div className="mt-5" />,
      <TextInput
        id="order-email"
        onChange={setEmail}
        value={email}
        type={inputType.formField}
        label={t('delivery:checkout.email')}
        isDelivery
        isError={!isEmailValid}
        required={isEmailRequired}
      />,
    ];

    if (isPhoneHidden) {
      renderedFields[0] = null;
      renderedFields[1] = null;
      renderedFields = renderedFields.filter((x) => !!x);
    }

    if (isEmailRequired && !isPhoneRequired) {
      renderedFields.reverse();
    }

    return <div>{renderedFields}</div>;
  };

  return (
    <>
      <TopBar
        customTitle={t('delivery:checkout.title')}
        onBack={() => {
          navigate(`/${deliveryRestaurant.slug || deliveryRestaurant.id}`, { replace: true });
        }}
        onBannerHeightChange={onBannerHeightChange}
      />
      <ContentWrapper>
        <div
          className="flex flex-col lg:flex-row justify-between items-start"
          ref={contentWrapperRef}
        >
          <div className="md:max-w-sm lg:max-w-lg xl:max-w-xl w-full mr-9">
            <h3 className="font-primarySemiBold text-primary text-xl mt-14 mb-5">
              {t('delivery:checkout.selectedItems')}
            </h3>
            <div>
              {bill &&
                bill.cart &&
                bill.cart.map((item) => (
                  <div key={`${item.productId}-${item.index}`} className="mb-2">
                    <CartItem product={item} fromCheckout />
                  </div>
                ))}
            </div>
            <button
              type="button"
              onClick={() => {
                navigate(`/${deliveryRestaurant.slug || deliveryRestaurant.id}`, {
                  replace: true,
                });
              }}
              className="font-primaryMedium text-base text-primary mt-3"
            >
              {t('delivery:checkout.addMoreItems')} +
            </button>
            <h3 className="font-primarySemiBold text-primary text-xl mt-12 mb-5">
              {t('delivery:checkout.deliveryMethodAndTime')}
            </h3>
            <DeliveryMethod
              isTakeAway={isTakeAway}
              onChange={(isNewTakeAway) => {
                setIsTakeAway(isNewTakeAway);

                if (!isNewTakeAway) {
                  tryGetDeliveryPromiseWrapper();
                }
              }}
              onTimeChange={(isDeliverNowVal, deliveryDateVal) => {
                setIsDeliverNow(isDeliverNowVal);
                setDeliveryDateMillis(deliveryDateVal);

                if (!isTakeAway) {
                  tryGetDeliveryPromiseWrapper({
                    updatedDeliverNow: isDeliverNowVal,
                    updatedDeliveryDateVal: deliveryDateVal,
                  });
                }
              }}
              onTimeSelectModalClose={() => {
                if (!isTakeAway) {
                  tryGetDeliveryPromiseWrapper();
                }
              }}
              deliveryRestaurant={deliveryRestaurant}
              onInvalidOptions={(key, val) => {
                setInvalidChildOptions((prev) => ({ ...prev, [key]: val }));
              }}
              isDeliveryProviderMissing={isDeliveryProviderMissing}
              bill={bill}
              productCategories={restaurantState.productCategories}
            />
            <h3 className="font-primarySemiBold text-primary text-xl mt-12 mb-5">
              {t('delivery:checkout.yourContactDetails')}:
            </h3>
            <div>
              {renderPhoneAndEmail()}
              {!isTakeAway && (
                <div>
                  <div className="mt-5" />
                  <TextInput
                    id="order-fullname"
                    onChange={setFullName}
                    value={fullName}
                    type={inputType.formField}
                    label={t('delivery:checkout.deliveryName')}
                    required
                    isDelivery
                  />
                  <div className="mt-5" />
                  <TextInput
                    id="order-address"
                    onChange={setDeliveryAddress}
                    value={deliveryAddress}
                    type={inputType.formField}
                    label={t('delivery:checkout.deliveryAddress')}
                    required
                    isDelivery
                    onBlur={tryGetDeliveryPromiseWrapper}
                  />
                  <div className="mt-5" />
                  <TextInput
                    id="order-city"
                    onChange={setCity}
                    value={city}
                    type={inputType.formField}
                    label={t('delivery:checkout.city')}
                    required
                    isDelivery
                    onBlur={tryGetDeliveryPromiseWrapper}
                  />
                  <div className="mt-5" />
                  <TextInput
                    id="order-zip"
                    onChange={setZipCode}
                    value={zipCode}
                    type={inputType.formField}
                    label={t('delivery:checkout.zipCode')}
                    // required
                    isDelivery
                    onBlur={tryGetDeliveryPromiseWrapper}
                  />
                  {!isDeliveryAddressValid &&
                    !!deliveryAddressErrorCode &&
                    renderDeliveryAddressError()}
                  <div className="mt-5" />
                  <TextInput
                    id="order-note"
                    onChange={setNoteForDriver}
                    value={noteForDriver}
                    type={inputType.formField}
                    label={t('delivery:checkout.noteForDriver')}
                    isDelivery
                  />
                </div>
              )}
              {requireRoomNumber && (
                <>
                  <div className="mt-5" />
                  <TextInput
                    id="room-number"
                    onChange={setRoomNumber}
                    value={roomNumber}
                    type={inputType.formField}
                    label={t('delivery:checkout.roomNumber')}
                    isDelivery
                    required
                  />
                </>
              )}
            </div>
            {!!deliveryRestaurant.allowCoupons && (
              <>
                <h3 className="font-primarySemiBold text-primary text-xl mt-12 mb-5">
                  {t('delivery:checkout.applyDiscountCode')}:
                </h3>
                <DiscountCoupon
                  restaurantId={deliveryRestaurant.id}
                  onApplied={setAppliedCoupon}
                  timeZone={deliveryRestaurant.data.timeZone}
                />
              </>
            )}
            {!areOrdersDisabled && (
              <>
                <h3 className="font-primarySemiBold text-primary text-xl mt-12 mb-5">
                  {t('delivery:checkout.choosePaymentMethod')}:
                </h3>

                {showAnyConfirmationCheckbox && (
                  <div className="flex flex-col space-y-1 mb-6">
                    {!!deliveryRestaurant.data &&
                      !!deliveryRestaurant.data.requireConfirmAlcohol &&
                      hasAnyAlcoholProducts && (
                        <div>
                          <CheckBox
                            checked={alcoholAgreed}
                            onClick={(checked) => setAlcoholAgreed(checked)}
                            title={
                              <span>
                                {t('products.ageConfirmationTitle', {
                                  ageNumber: deliveryRestaurant.data.restrictionsAge || 18,
                                })}{' '}
                                {t('products.ageConfirmationDescription')}
                              </span>
                            }
                            isSquare
                            textSize="sm"
                          />
                        </div>
                      )}
                    {!!deliveryRestaurant.data &&
                      !!deliveryRestaurant.data.requireConfirmConditions && (
                        <div>
                          <CheckBox
                            checked={conditionsAgreed}
                            onClick={(checked) => setConditionsAgreed(checked)}
                            title={
                              <Trans i18nKey="checkout.agreeTerms">
                                By checking out you agree with our{' '}
                                <a
                                  className="underline"
                                  target="_blank"
                                  href="https://drive.google.com/file/d/1vYkyVHjaMx0e9qsToZh5S6-WBtcIXnyc/view"
                                  rel="noreferrer"
                                >
                                  terms & conditions
                                </a>{' '}
                                and{' '}
                                <a
                                  className="underline"
                                  target="_blank"
                                  href="https://drive.google.com/file/d/1Et5cXjKQ6vaNWElDDnEZB4K3Rz4Ch8D8/view"
                                  rel="noreferrer"
                                >
                                  privacy policy
                                </a>
                              </Trans>
                            }
                            isSquare
                            textSize="sm"
                          />
                        </div>
                      )}
                  </div>
                )}
                <CheckoutPayment
                  paymentOption={paymentOption}
                  // non redirect link payments only
                  onPay={({
                    paymentType,
                    paymentMethodId,
                    dontDisable,
                    paymentTrackId,
                    paymentAccountId,
                  }) => {
                    onPayWrapper({
                      paymentType,
                      paymentMethodId,
                      dontDisable,
                      paymentTrackId,
                      paymentAccountId,
                    });
                  }}
                  total={total}
                  onFail={onFail}
                  onCancel={() => onSetInitiating(false)}
                  paymentKey={paymentKey}
                  disabled={
                    disabled ||
                    (isOrderDisabled && !onlyNeedPaymentMethod) ||
                    needConfirmConditions ||
                    needConfirmAlcohol
                  }
                  loading={disabled}
                  isDelivery
                  isTakeAway={isTakeAway}
                  deliveryOrderId={deliveryOrderId}
                  selectedDeliveryPayment={selectedPaymentMethodType}
                  onSelectDeliveryOption={(paymentType, paymentMethodId) => {
                    setSelectedPaymentMethodType(paymentType);
                    setSelectedPaymentMethodId(paymentMethodId);
                  }}
                  cardFormSubmitRef={cardFormSubmitRef}
                  isValidOnSubmit={isValidOnSubmit}
                  updatePaymentBeforeSubmit={updatePaymentBeforeSubmit}
                />
              </>
            )}

            {renderCheckout(false)}
          </div>
        </div>
        {renderCheckout(true)}
      </ContentWrapper>
    </>
  );
};

export default CheckoutBillDelivery;
