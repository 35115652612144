import dateLib from 'date-and-time';
import timezone from 'date-and-time/plugin/timezone';
import moment from 'moment';
import 'moment-timezone';

dateLib.plugin(timezone);

export const timeStringFromMillis = (timestamp, zone) => {
  if (!timestamp) {
    return '';
  }

  const date = new Date(timestamp);

  let result;
  if (zone) {
    result = dateLib.formatTZ(date, 'HH:mm', zone);
  } else {
    result = dateLib.format(date, 'HH:mm', true);
  }

  return result;
};

export const unspecifiedDateToMillis = (date) => {
  const momentUtcDate = moment(date);
  const dateMillis = momentUtcDate.valueOf() + momentUtcDate.utcOffset() * 60 * 1000;

  return dateMillis;
};

export function getDateInCustomTimezone(date, timeZone) {
  return moment(date)
    .tz(timeZone || 'utc')
    .format('YYYY-MM-DD');
}

export function getFullDateInCustomTimezone(date, timeZone) {
  return moment(date)
    .tz(timeZone || 'utc')
    .format('YYYY-MM-DD HH:mm');
}

export function getTimeInCustomTimezone(date, timeZone) {
  return moment(date)
    .tz(timeZone || 'utc')
    .format('HH:mm');
}

export function getDayInCustomTimezone(date, timeZone, format = 'dddd') {
  return moment(date)
    .tz(timeZone || 'utc')
    .locale('en')
    .format(format);
}

export function currentDayMinutes(timeZone) {
  let currentDate;
  if (timeZone) {
    currentDate = moment().utc().tz(timeZone);
  } else {
    currentDate = moment().utc();
  }

  const currentHour = currentDate.get('h');
  const currentMinute = currentDate.get('minute');
  return currentHour * 60 + currentMinute;
}

export function getUtcFromTimezoneMillis(date, timeZone) {
  const timezoneMoment = moment.tz(date, timeZone || 'utc');
  const offsetMinutes = timezoneMoment.utcOffset();

  return timezoneMoment.utc().valueOf() - offsetMinutes * 60 * 1000;
}

export function currentUtcMillis() {
  return moment().utc().valueOf();
}

export function currentTimeZoneMillis(timeZone) {
  if (timeZone) {
    const momentUtcDate = moment().utc();
    const tzOffset = momentUtcDate.tz(timeZone).utcOffset();
    const dateMillis = momentUtcDate.valueOf() + tzOffset * 60 * 1000;

    return dateMillis;
  }

  return currentUtcMillis();
}

export function getDateMillisOfDeltaDay(timeZone, daysToAdd) {
  let currentDate;
  if (timeZone) {
    currentDate = moment().utc().tz(timeZone);
  } else {
    currentDate = moment().utc();
  }

  if (daysToAdd > 0) {
    currentDate.add(daysToAdd, 'd');
  }

  const dayMillis = currentDate.startOf('day');

  return dayMillis.valueOf();
}

export function formatDayMinutes(dayMinutes) {
  let hours = parseInt(dayMinutes / 60, 10);
  const minutes = dayMinutes % 60;

  if (hours >= 24) {
    hours -= 24;
  }

  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
}

export const getWeekDay = (timeZone) => {
  let currentDate;
  if (timeZone) {
    currentDate = moment().utc().tz(timeZone);
  } else {
    currentDate = moment().utc();
  }

  return currentDate.isoWeekday();
};
