import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAutosizeTextArea } from '../../../hooks/useAutosizeTextArea';

const WaiterNote = ({ note, onChange, isDelivery }) => {
  const { t } = useTranslation(['translation', 'delivery']);

  const noteRef = useRef(null);
  useAutosizeTextArea(noteRef.current, note);

  return (
    <div>
      <label htmlFor="note" className="font-primaryMedium text-primary text-base w-full">
        {isDelivery
          ? t('delivery:cart.leaveNoteForKitchen')
          : t('translation:products.leaveNoteForWaiter')}
      </label>
      <textarea
        ref={noteRef}
        id="note"
        name="note"
        value={note}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={
          isDelivery
            ? t('delivery:cart.kitchenNotePlaceholder')
            : t('translation:products.waiterNotePlaceholder')
        }
        className="font-primaryMedium text-base text-bland outline-none w-full resize-none min-h-[3rem]"
        rows={1}
        maxLength={245}
      />
    </div>
  );
};

export default WaiterNote;
