import React from 'react';
import { HiOutlineClock } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { getTranslatedData } from '../../../../localization';
import { useCurrency } from '../../../../hooks/useCurrency';
import ProductVeganIcon from './components/productVeganIcon';
import ProductSpicyIcon from './components/productSpicyIcon';
import ProductPopularTag from './components/productPopularTag';
import ProductNewTag from './components/productNewTag';
import ProductDiscountTag from './components/productDiscountTag';
import ProductPreparationTime, {
  hasProductPreparationTime,
} from './components/productPreparationTime';
import ProductImage from './components/productImage';
import { useDeliveryColors } from '../../../../hooks/useDeliveryColors';
import ProductCustomTags from './components/productCustomTags';

const ProductDelivery = ({ product, onSelect, totalInCart, shouldContainImage }) => {
  const { currencySymbol } = useCurrency();
  const colors = useDeliveryColors();
  const { t } = useTranslation(['delivery']);

  const hasImage = product.listImage && product.listImage.length > 0;

  return (
    <div
      role="button"
      onClick={onSelect}
      className="flex flex-col-reverse xs:flex-row border border-input rounded-xl px-4 py-4 relative hover:scale-103 transition duration-300 ease-in-out hover:shadow-sm"
    >
      <div className="flex-1">
        <div className="font-primaryMedium text-lg text-primary inline-block">
          <div className="inline mr-2">{getTranslatedData(product.name) || '-'}</div>
          <div className="inline-block relative -top-[2px]">
            <ProductSpicyIcon spicyLevel={product.spicyLevel} />
            {!!product.spicyLevel && product.isVegetarian && <div className="ml-1 inline-block" />}
            <ProductVeganIcon isVegan={product.isVegetarian} />
          </div>
        </div>
        {(product.isPopular ||
          product.isNew ||
          hasProductPreparationTime(product) ||
          (!!product.tags && product.tags.length > 0)) && (
          <div className="flex flex-row flex-wrap">
            <ProductPopularTag isPopular={product.isPopular} isDelivery />
            {product.isPopular && product.isNew && <div className="inline-block mr-1" />}
            <ProductNewTag isNew={product.isNew} isDelivery />
            {(product.isPopular || product.isNew) && <div className="inline-block mr-1" />}
            <ProductCustomTags isDelivery tags={product.tags} />
            {hasProductPreparationTime(product) && (
              <div className="mt-2 font-primaryMedium text-bland text-xs flex items-center">
                <HiOutlineClock className="text-bland mr-1" size={16} />
                <div>
                  <ProductPreparationTime product={product} />
                </div>
              </div>
            )}
          </div>
        )}
        {!!getTranslatedData(product.nutrition) && (
          <div className="font-primaryRegular text-sm text-bland mt-2">
            {getTranslatedData(product.nutrition)}
          </div>
        )}
        <div className="flex flex-row flex-wrap items-center justify-between mt-2">
          <div className="flex flex-row flex-wrap items-center">
            {!!product.price && (
              <p className="font-primarySemiBold text-primary text-lg mr-2 whitespace-nowrap">
                {(product.price || 0).toFixed(2)} {currencySymbol}
              </p>
            )}
            {!product.price && !!product.minimumPrice && (
              <p className="font-primarySemiBold text-primary text-lg mr-2 whitespace-nowrap">
                {t('delivery:menu.fromPrice')} {(product.minimumPrice || 0).toFixed(2)}{' '}
                {currencySymbol}
              </p>
            )}
            {!!product.priceBefore && product.priceBefore !== product.price && (
              <p className="font-primaryRegular text-bland text-sm line-through whitespace-nowrap">
                {(product.priceBefore || 0).toFixed(2)} {currencySymbol}
              </p>
            )}
          </div>

          <div className="xs:hidden -mt-1">
            <ProductDiscountTag discount={product.discountPercent} isDelivery isRelative />
          </div>
        </div>
      </div>
      <div className="">
        {hasImage && (
          <div className="relative xs:ml-4 mb-4 xs:mb-0">
            <ProductImage product={product} isDelivery shouldContainImage={shouldContainImage} />
            <div className="hidden xs:block">
              <ProductDiscountTag discount={product.discountPercent} isDelivery />
            </div>
          </div>
        )}
        {!hasImage && (
          <div className="hidden xs:block xs:ml-4">
            <ProductDiscountTag discount={product.discountPercent} isRelative isDelivery />
          </div>
        )}
      </div>
      {totalInCart > 0 && (
        <div
          className={`absolute -right-2 -top-2 bg-primary
            font-primaryMedium text-white text-sm rounded-full w-6 h-6
            flex items-center justify-center`}
          style={{
            background: colors.hasCustomPrimary ? colors.primary : undefined,
          }}
        >
          {totalInCart}
        </div>
      )}
    </div>
  );
};

export default ProductDelivery;
