import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { formFileUrlFromFileArray } from '../../../utils/fileHelper';
import { getTranslatedData } from '../../../localization';
import { useCurrency } from '../../../hooks/useCurrency';

const CartRecommendations = ({ productCategories, onSelect }) => {
  const { t } = useTranslation();
  const { currencySymbol } = useCurrency();

  const scrollContainerRef = useRef(null);

  const recommendedProducts = useMemo(() => {
    if (!productCategories) {
      return null;
    }

    const filteredProducts = [];

    productCategories.forEach((category) => {
      if (category.products) {
        category.products.forEach((product) => {
          if (product.isRecommended) {
            filteredProducts.push(product);
          }
        });
      }
    });

    return filteredProducts;
  }, [productCategories]);

  if (!recommendedProducts || recommendedProducts.length === 0) {
    return null;
  }

  const renderImage = (image) => {
    if (!image) {
      return null;
    }

    const imageUrl = formFileUrlFromFileArray(image);
    if (!imageUrl) {
      return null;
    }

    return (
      <div
        className="w-24 h-24 rounded-lg bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
    );
  };

  const renderDiscountTag = (discount, isRelative) => {
    if (!discount) {
      return null;
    }

    return (
      <div
        className={`border-[1px] border-white bg-white px-2 py-[1px]
      font-primaryMedium text-primary text-sm rounded-[20px] inline-block
      ${isRelative ? '' : 'absolute -bottom-2 -left-1'}
      shadow-sm`}
      >
        -{Math.abs(discount)}%
      </div>
    );
  };

  const renderProductCard = (product, ind) => {
    const hasImage = product.listImage && product.listImage.length > 0;

    return (
      <div
        key={product.id}
        className={`min-w-[9rem] ${
          ind !== recommendedProducts.length - 1 ? 'mr-3' : ''
        } cursor-pointer`}
        onClick={() => {
          onSelect(product);
        }}
      >
        {hasImage && (
          <div className="relative mb-2">
            {renderImage(product.listImage)}
            {renderDiscountTag(product.discountPercent)}
          </div>
        )}
        <div className="font-primaryMedium text-base text-primary">
          {getTranslatedData(product.name) || '-'}
        </div>
        <div className="flex flex-row flex-wrap items-center">
          {!!product.price && (
            <p className="font-primaryMedium text-primary text-lg mr-2 whitespace-nowrap">
              {(product.price || 0).toFixed(2)} {currencySymbol}
            </p>
          )}
          {!!product.priceBefore && product.priceBefore !== product.price && (
            <p className="font-primaryMedium text-bland text-base line-through whitespace-nowrap">
              {(product.priceBefore || 0).toFixed(2)} {currencySymbol}
            </p>
          )}
        </div>
      </div>
    );
  };

  const scrollLeft = () => {
    scrollContainerRef.current.scrollTo({
      left: scrollContainerRef.current.scrollLeft - scrollContainerRef.current.offsetWidth,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollTo({
      left: scrollContainerRef.current.scrollLeft + scrollContainerRef.current.offsetWidth,
      behavior: 'smooth',
    });
  };

  return (
    <div className="mt-5">
      <div className="flex flex-row justify-between items-center mb-5 relative">
        <p className="font-primaryMedium text-2xl text-primary">{t('products.recommendations')}</p>
        <div className="flex flex-row items-center top-[2px] relative">
          <div className="w-6 h-8 flex justify-center" onClick={scrollLeft}>
            <SlArrowLeft className="text-primary text-base cursor-pointer h-full" />
          </div>
          <div className="w-6 h-8 flex justify-end" onClick={scrollRight}>
            <SlArrowRight className="text-primary text-base cursor-pointer h-full" />
          </div>
        </div>
      </div>
      <div ref={scrollContainerRef} className="flex flex-row overflow-auto pb-2 scrollbar-h">
        {recommendedProducts.map((product, ind) => renderProductCard(product, ind))}
      </div>
    </div>
  );
};

export default CartRecommendations;
