import { db } from 'codemash';
import { Config } from '../configs';
import { mapApiToDataPaymentOption, mapApiToDataRestaurant } from './mappers/restaurant';
import { mapApiToDataTable } from './mappers/table';
import { manageApiUnavailableError } from './error/errors';

// Not used
export const getTableByQrCode = async ({ tableQr }) => {
  try {
    if (!tableQr) {
      return { table: null, restaurant: null, paymentOption: null };
    }

    const response = await db.executeAggregate({
      id: Config.DATA.AGGREGATES.GET_TABLE,
      collectionName: Config.DATA.COLLECTIONS.TABLES,
      database: Config.CODEMASH.DB_STORAGE_ID,
      tokens: {
        qr: tableQr,
      },
    });

    if (response && response.length > 0) {
      return {
        table: mapApiToDataTable({ table: response[0] }),
        restaurant: mapApiToDataRestaurant({ restaurant: response[0].restaurant }),
        paymentOption: mapApiToDataPaymentOption({ options: response[0].payment_options }),
      };
    }
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get table by qr code', e);
    }
  }

  return { table: null, restaurant: null, paymentOption: null };
};
