import { useEffect, useRef } from 'react';

export function useScrollbarHide(disabled, hasPreviousModalOpen) {
  const scrolRef = useRef({}).current;

  function disableScroll() {
    global.document.body.classList.add('scrollbar-hide');

    // Get the current page scroll position
    scrolRef.scrollTop = global.window.pageYOffset || global.document.documentElement.scrollTop;
    scrolRef.scrollLeft = global.window.pageXOffset || global.document.documentElement.scrollLeft;

    // if any scroll is attempted, set this to the previous value
    global.window.onscroll = () => {
      global.window.scrollTo(scrolRef.scrollLeft, scrolRef.scrollTop);
    };
  }

  function enableScroll() {
    global.document.body.classList.remove('scrollbar-hide');
    global.window.onscroll = () => {};
  }

  useEffect(() => {
    if (disabled) {
      disableScroll();
    } else if (!hasPreviousModalOpen) {
      enableScroll();
    }
  }, [disabled]);

  return { disableScroll };
}
