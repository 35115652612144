/* eslint-disable no-undef */

import createDataContext from './_init_/createDataContext';
import { registerGuest as registerGuestApi, getUser as getUserApi } from '../api/auth';
import { isHostAllowedForV2 } from '../utils/apiActionChooser';

const userTokenKey = '@UserToken';
const userTokenKeyV2 = '@UserTokenV2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_user':
      return { ...state, user: action.payload };
    case 'set_token': {
      localStorage.setItem(isHostAllowedForV2() ? userTokenKeyV2 : userTokenKey, action.payload);

      return { ...state, token: action.payload };
    }
    default:
      return state;
  }
};

const initUser = (dispatch) => async () => {
  if (lock.getUserLock) {
    return;
  }

  lock.getUserLock = true;

  const token = localStorage.getItem(isHostAllowedForV2() ? userTokenKeyV2 : userTokenKey);
  if (token) {
    const { user } = await getUserApi({ token });
    dispatch({ type: 'set_user', payload: user });
    dispatch({ type: 'set_token', payload: token });
  }

  lock.getUserLock = false;
};

const registerGuest = (dispatch, state) => async () => {
  if (state.token && state.user && state.user.id) {
    return { token: state.token, user: state.user };
  }

  if (lock.registerLock) {
    return {};
  }

  lock.registerLock = true;

  const { token, user } = await registerGuestApi();

  // this is needed
  if (isHostAllowedForV2()) {
    localStorage.setItem(userTokenKeyV2, token);
  }

  dispatch({ type: 'set_user', payload: user });
  dispatch({ type: 'set_token', payload: token });

  lock.registerLock = false;

  return { token, user };
};

const lock = {
  getUserLock: false,
  registerLock: false,
};

const initialData = {
  user: null,
  token: null,
};

export const { Context, Provider } = createDataContext(
  reducer,
  { initUser, registerGuest },
  { ...initialData },
);
