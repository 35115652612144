import createDataContext from './_init_/createDataContext';

const reducer = (state, action) => {
  switch (action.type) {
    case 'show':
      return {
        ...state,
        isVisible: true,
        text: action.payload.text,
        type: action.payload.type,
      };
    case 'hide':
      return { ...state, isVisible: false };
    default:
      return state;
  }
};

const show = (dispatch) => (text, options) => {
  dispatch({
    type: 'show',
    payload: { text, options },
  });

  setTimeout(() => {
    dispatch({ type: 'hide' });
  }, (options || {}).duration || 3000);
};

const error =
  (dispatch) =>
  ({ text }) => {
    dispatch({
      type: 'show',
      payload: { text, type: 'error' },
    });

    setTimeout(() => {
      dispatch({ type: 'hide' });
    }, 3000);
  };

const warning =
  (dispatch) =>
  ({ text }) => {
    dispatch({
      type: 'show',
      payload: { text, type: 'warning' },
    });

    setTimeout(() => {
      dispatch({ type: 'hide' });
    }, 3000);
  };

const success =
  (dispatch) =>
  ({ text }) => {
    dispatch({
      type: 'show',
      payload: { text, type: 'success' },
    });

    setTimeout(() => {
      dispatch({ type: 'hide' });
    }, 3000);
  };

const hide = (dispatch) => () => {
  dispatch({ type: 'hide' });
};

const initialData = {
  isVisible: false,
  text: null,
  type: null,
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    show,
    error,
    warning,
    success,
    hide,
  },
  { ...initialData },
  'alertController',
);
