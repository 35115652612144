import { db } from 'codemash';
import i18next from 'i18next';
import { Config } from '../configs';
import { mapApiToDataTableOrders } from './mappers/order';
import { restaurantTypes } from '../enums/restaurantTypes';
import { currentUtcMillis } from '../utils/dateHelpers';
import { manageApiUnavailableError } from './error/errors';

// Not used
export const getOrderByTable = async ({ tableId, userId, restaurantType }) => {
  try {
    let response;

    if (restaurantType === restaurantTypes.OrderAndPay) {
      const showFromMillis = currentUtcMillis() - 24 * 3600000;

      response = await db.executeAggregate({
        id: Config.DATA.AGGREGATES.GET_TABLE_USER_ORDERS,
        collectionName: Config.DATA.COLLECTIONS.ORDERS,
        database: Config.CODEMASH.DB_STORAGE_ID,
        tokens: {
          tableId,
          userId,
          fromMillis: showFromMillis,
        },
      });
    } else {
      response = await db.executeAggregate({
        id: Config.DATA.AGGREGATES.GET_TABLE_ORDER,
        collectionName: Config.DATA.COLLECTIONS.ORDERS,
        database: Config.CODEMASH.DB_STORAGE_ID,
        tokens: {
          tableId,
          userId,
        },
      });
    }

    if (response) {
      return mapApiToDataTableOrders({ orders: response });
    }
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get table orders', e);
    }
  }

  return null;
};

// Not used
export const rateOrder = async ({
  token,
  restaurantId,
  orderId,
  paymentId,
  userId,
  rating,
  comment,
}) => {
  try {
    await db.replaceRecordWithFilter({
      secretKey: token,
      collectionName: Config.DATA.COLLECTIONS.RATINGS,
      database: Config.CODEMASH.DB_STORAGE_ID,
      filter: {
        payment: { $oid: paymentId },
        client_user: userId,
      },
      document: {
        restaurant: restaurantId,
        order: orderId,
        client_user: userId,
        payment: paymentId,
        rating,
        comment,
      },
      isUpsert: true,
    });
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to update rating', e);
    }
  }
};

// Not used
export const rateRestaurant = async ({
  token,
  restaurantId,
  userId,
  rating,
  comment,
  alertController,
}) => {
  try {
    await db.insertRecord({
      secretKey: token,
      collectionName: Config.DATA.COLLECTIONS.RATINGS,
      database: Config.CODEMASH.DB_STORAGE_ID,
      document: {
        restaurant: restaurantId,
        client_user: userId,
        rating,
        comment,
      },
    });

    if (alertController) {
      alertController.success({ text: i18next.t('payment.reviewCreated') });
    }
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to insert rating', e);
    }
  }
};
