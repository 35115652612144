import { Config } from '../configs';
import { apiActionChooser } from '../utils/apiActionChooser';
import { postData } from './httpClient/httpClient';
import { Config as ConfigV2 } from './client/server';
import { DomainConfig } from '../configs/domain';

export const createDeliveryPromise = async ({
  deliveryRestaurantId,
  restaurantId,
  venueId,
  street,
  city,
  zipCode,
  isDeliverNow,
  deliveryDate,
  orderPrice,
}) => {
  try {
    if (!street || !city /*|| !zipCode */) {
      return { error: { errorCode: 'unknown' } };
    }

    let response;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await postData(`${Config.TRACKING.URL}/api/delivery/promise`, {
          deliveryRestaurantId,
          restaurantId,
          venueId,
          street,
          city,
          postCode: zipCode,
          isDeliverNow,
          deliveryDate,
          orderPrice,
        });
      },
      async () => {
        response = await postData(`${ConfigV2.apiUrl}/api/delivery/promise`, {
          deliveryRestaurantId,
          restaurantId,
          venueId,
          street,
          city,
          postCode: zipCode,
          isDeliverNow,
          deliveryDate,
          orderPrice,
        });
      },
    );

    if (!response.error && !response.response) {
      return { error: { errorCode: 'unknown' } };
    }

    return { error: response.error, response: response.response };
  } catch (e) {
    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get delivery promise', e);
    }
  }

  return { error: { errorCode: 'unknown' } };
};
