import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeliveryColors } from '../../../../../hooks/useDeliveryColors';

const ProductPopularTag = ({ isPopular, isDelivery }) => {
  const { t } = useTranslation();
  const colors = useDeliveryColors();

  if (!isPopular) {
    return null;
  }

  return (
    <div
      className={`${
        isDelivery ? 'text-xs font-primaryMedium' : 'text-sm font-primaryMedium'
      } border-[1px] border-primary px-2 py-[1px]
         text-primary rounded-full inline-block mt-2`}
      style={{
        color: colors.hasCustomPrimary ? colors.primary : undefined,
        borderColor: colors.hasCustomPrimary ? colors.primary : undefined,
      }}
    >
      {t('products.popularTag')}
    </div>
  );
};

export default ProductPopularTag;
