import { DomainConfig } from '../configs/domain';

const v2Hosts = [
  'local.waitless.lt',
  'staging.waitless.lt',
  'staging.waitless.lv',
  'casalafamilia.waitless.lt',
  'staging-dev.waitless.lt',
  'formosa.waitless.lt',
  'piliesuostas.waitless.lt',
  'miami.waitless.lt',
  'pupele.waitless.lt',
  'smarttek.waitless.lt',
  'tonipizza.waitless.lt',
  'tonipizza.lt',
  'liucia.waitless.lt',
  'liuks.waitless.lt',
  'discoburger.waitless.lt',
  'wraperia.waitless.lt',
  'papafredo.waitless.lt',
  'sventasdumas.waitless.lt',
  'buffalowings.waitless.lt',
  'pizzarella.waitless.lt',
  'eljefe.waitless.lt',
  'peledine.waitless.lt',
  'laura.waitless.lt',
  'tikitaka.waitless.lt',
  'niamniam.waitless.lt',
  'berneliuuzeiga.waitless.lt',
  'mabela.waitless.lt',
  'bocmanas.waitless.so',
  'bocmanas.waitless.lt',
  'pratesimas.waitless.lt',
  'dcity.waitless.lv',
  'sirene.waitless.lt',
  'aussie.waitless.lv',
  'kebabine.waitless.lt',
  'berlin.waitless.lt',
  'chiwawa.waitless.lt',
  'guacamole.waitless.lt',
  'pizzajourney.waitless.lt',
  'menu.pizzajourney.lt',
  'meniu.pizzajourney.lt',
  'bluelotus.waitless.lt',
  'cilipizza.waitless.lt',
  'florreti.waitless.lt',
  'trendypizza.waitless.lt',
  'buksotus.waitless.lt',
  'crispychick.waitless.lt',
  'sushilaikas.waitless.lt',
  'caina.waitless.lt',
  'sushibu.waitless.lt',
  'kuhne.waitless.lt',
  'teikakingu.waitless.lt',
  'teikakingu.waitless.lv',
];

export const apiActionChooser = async (host, v1Action, v2Action) => {
  const useV2Api = true;

  // if (host && v2Hosts.includes(host)) {
  //   useV2Api = true;
  // }

  if (useV2Api) {
    // console.log('Calling V2 API');
    await v2Action();
  } else {
    // console.warn('Calling V1 API');
    await v1Action();
  }
};

export const isHostAllowedForV2 = () => {
  return true;
  // const hostName = DomainConfig.getHost();
  // return v2Hosts.includes(hostName);
};
