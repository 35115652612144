import { formFileUrlFromFileArray } from '../../utils/fileHelper';
import { sortObjectArray } from '../../utils/arrayHelpers';
import { restaurantProviders } from '../../enums/restaurantProviders';
import { mapApiToDataPaymentOption } from './restaurant';
import { Config, defaultColors } from '../../configs';
import { parseSafeJSON } from '../../utils/jsonHelpers';

export const mapDeliverySettings = ({ settings }) => {
  if (!settings) {
    return null;
  }

  const mappedCategories = settings.categories
    ? settings.categories.map((category) => {
        return {
          id: category.id,
          imageUrl: formFileUrlFromFileArray(category.image),
          name: category.name,
          order: category.order || 9999,
          restaurants: category.restaurants,
        };
      })
    : null;

  if (mappedCategories) {
    sortObjectArray(mappedCategories, 'order', 1, true);
  }

  return {
    deliveryId: settings._id,
    title: settings.title,
    description: settings.description,
    bannerUrl: formFileUrlFromFileArray(settings.banner),
    logoUrl: formFileUrlFromFileArray(settings.logo),
    categories: mappedCategories,
    colors: formatDeliverySettingsColors(settings),
  };
};

const formatDeliverySettingsColors = (settings) => {
  const settingsColors = settings.colors && settings.colors.length > 0 ? settings.colors[0] : null;

  const formed = {};

  if (settingsColors) {
    formed.primary = settingsColors.primary || defaultColors.primary;
    formed.hasCustomPrimary = !!settingsColors.primary;

    if (formed.primary && !formed.primary.startsWith('#')) {
      formed.primary = `#${formed.primary}`;
    }
  }

  return formed;
};

export const mapDeliveryRestaurants = ({ restaurants }) => {
  if (!restaurants || restaurants.length === 0) {
    return null;
  }

  const addWorkTime = (arr, day, from, until) => {
    if (from >= 0 && until >= 0 && from <= 1440 && until <= 1440) {
      let correctedUntil = until;

      // If until is less than from. Add 1440 minutes
      if (correctedUntil < from) {
        correctedUntil += 1440;
      }

      arr.push({ day, from, until: correctedUntil });
    }
  };

  const mappedRestaurants = restaurants
    .filter((restaurant) => !!restaurant.table && !!restaurant.data)
    .map((restaurant) => {
      const workTimes = [];

      const schedule =
        restaurant.schedule && restaurant.schedule.length > 0 ? restaurant.schedule[0] : {};

      addWorkTime(workTimes, 1, schedule.mon_from, schedule.mon_to);
      addWorkTime(workTimes, 2, schedule.tue_from, schedule.tue_to);
      addWorkTime(workTimes, 3, schedule.wed_from, schedule.wed_to);
      addWorkTime(workTimes, 4, schedule.thu_from, schedule.thu_to);
      addWorkTime(workTimes, 5, schedule.fri_from, schedule.fri_to);
      addWorkTime(workTimes, 6, schedule.sat_from, schedule.sat_to);
      addWorkTime(workTimes, 7, schedule.sun_from, schedule.sun_to);

      const { hasTestDeliveryAccess } = checkTestAccessPermissions({
        restaurantId: restaurant.restaurant,
      });

      return {
        id: restaurant.restaurant, // restaurant id
        deliveryId: restaurant._id, // delivery restaurant id
        disableOrders: restaurant.disable_orders,
        title: restaurant.title,
        slug: restaurant.slug,
        description: restaurant.description,
        specialTag: restaurant.special_tag,
        order: restaurant.order,
        phone: restaurant.phone,
        email: restaurant.email,
        hasDelivery:
          restaurant.has_delivery &&
          // If blocked for clients, check if enabled for dev
          (!restaurant.block_delivery_for_public || hasTestDeliveryAccess),
        hasTakeaway: restaurant.has_takeaway,
        allowCoupons: restaurant.allow_coupons,
        imageUrl: formFileUrlFromFileArray(restaurant.image),
        bannerUrl: formFileUrlFromFileArray(restaurant.banner),
        workTimes,
        takeawayNowMin: restaurant.takeaway_now_min,
        takeawayNowMax: restaurant.takeaway_now_max,
        deliveryNowMin: restaurant.delivery_now_min,
        deliveryNowMax: restaurant.delivery_now_max,
        deliveryPrice: restaurant.delivery_price || 0,
        deliveryPricePercent: restaurant.delivery_price_percent || 0,
        minDeliveryOrder: restaurant.min_delivery_order || 0,
        minTakeawayOrder: restaurant.min_takeaway_order || 0,
        maxOrderDaysDelta: mapMaxOrderInFutureToDeltaDays(restaurant.max_order_in_future),
        takewayUntilClosure: restaurant.takeaway_order_until_closure || 0,
        deliveryUntilClosure: restaurant.delivery_order_until_closure || 0,
        displayPromotions: restaurant.display_promotions,
        displayEvents: restaurant.display_events,
        takeawayEmailRequired: restaurant.takeaway_email_required,
        takeawayPhoneOptional: restaurant.takeaway_phone_optional,
        takeawayPhoneHidden: restaurant.takeaway_phone_hidden,
        deliveryEmailRequired: restaurant.delivery_email_required,
        deliveryPhoneOptional: restaurant.delivery_phone_optional,
        deliveryPhoneHidden: restaurant.delivery_phone_hidden,
        table: {
          id: restaurant.table._id,
          qr: restaurant.table.qr,
          section: restaurant.table.area_name,
        },
        data: {
          id: restaurant.data._id,
          companyId: restaurant.data.company ? restaurant.data.company._id : null,
          companyName: restaurant.data.company ? restaurant.data.company.name : null,
          name: restaurant.data.name,
          phone: restaurant.data.phone,
          logo: restaurant.data.logo,
          country: restaurant.data.country,
          city: restaurant.data.city,
          street: restaurant.data.street,
          postalCode: restaurant.data.postal_code,
          timeZone: restaurant.data.zone,
          integrationId: restaurant.data.integration_id,
          provider: restaurant.data.rkeeper_code
            ? restaurantProviders.RKeeper
            : restaurantProviders.Iiko,
          type: restaurant.data.type,
          restrictionsAge: restaurant.data.restrictions_age,
          defaultLanguageCode: restaurant.data.default_language_code,
          availableAppLanguages: restaurant.data.available_app_languages
            ? restaurant.data.available_app_languages.split(',')
            : null,
          requireRoomNumber: restaurant.data.require_room_number,
          useMenuOptimizations: restaurant.data.use_menu_optimizations,
          requireConfirmConditions: restaurant.data.require_confirm_conditions,
          requireConfirmAlcohol: restaurant.data.require_confirm_alcohol,
          showCompanyDetails: restaurant.data.show_company_details,
          registeredCompanyAddress: restaurant.data.registered_company_address,
        },
        paymentOption: mapApiToDataPaymentOption({ options: restaurant.payment_options }),
        deliveryOptions: restaurant.delivery_options
          ? restaurant.delivery_options.map((x) => ({
              id: x.venue,
              ignorePrice: !!x.ignore_price,
            }))
          : null,
      };
    });

  if (mappedRestaurants) {
    sortObjectArray(mappedRestaurants, 'order', 1, true);
  }

  return mappedRestaurants;
};

const mapMaxOrderInFutureToDeltaDays = (type) => {
  if (type === 'Only_Today') {
    return 0;
  }
  if (type === '1_Day') {
    return 1;
  }
  if (type === '2_Day') {
    return 2;
  }
  if (type === '3_Day') {
    return 3;
  }
  if (type === '4_Day') {
    return 4;
  }
  if (type === '5_Day') {
    return 5;
  }

  return 6;
};

const checkTestAccessPermissions = ({ restaurantId }) => {
  const sessionTestAccess = !restaurantId
    ? null
    : global.sessionStorage.getItem(`${Config.CACHE_KEYS.TEST_FEATURES_ACCESS}${restaurantId}`);

  let hasTestDeliveryAccess = false;
  if (sessionTestAccess) {
    const parsedTestAccess = parseSafeJSON(sessionTestAccess);
    if (parsedTestAccess && parsedTestAccess.allow_delivery) {
      hasTestDeliveryAccess = true;
    }
  }

  return { hasTestDeliveryAccess };
};
