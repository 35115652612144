import React from 'react';

const Loader = ({ text, small, tiny, customHeight }) => {
  if (tiny) {
    return (
      <div className="flex items-center justify-center flex-col">
        <span className="loader" style={{ height: 24, width: 24 }} />
      </div>
    );
  }

  if (small) {
    return (
      <div className="flex items-center justify-center flex-col">
        <span className="loader" style={{ height: 40, width: 40 }} />
      </div>
    );
  }

  return (
    <div className={`flex items-center justify-center ${customHeight ? '' : 'h-screen'}  flex-col`}>
      <span className="loader" />
      {!!text && <p className="font-primaryMedium text-base text-bland mt-4 text-center">{text}</p>}
    </div>
  );
};

export default Loader;
