/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/jsx-no-constructed-context-values */

import React, { useReducer } from 'react';

export default (reducer, actions, initialState, functionWrapper, initFunction) => {
  const Context = React.createContext();

  const Provider = ({ children, props }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    let boundActions = {};

    for (const key in actions) {
      boundActions[key] = actions[key](dispatch, state);
    }

    if (functionWrapper) {
      boundActions = { [functionWrapper]: { ...boundActions } };
    }

    if (initFunction && !state.initialized) {
      initFunction(dispatch)(props);
    }

    return <Context.Provider value={{ state, ...boundActions }}>{children}</Context.Provider>;
  };

  return { Context, Provider };
};
