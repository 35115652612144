import React from 'react';
import spicyIcon from '../../../../../assets/images/icons/spicy-icon.png';

const ProductSpicyIcon = ({ spicyLevel }) => {
  if (!spicyLevel) {
    return null;
  }

  const image = <img src={spicyIcon} alt="spicy" className="inline-block" />;

  return (
    <div className="inline-block">
      {spicyLevel > 0 && image}
      {spicyLevel > 1 && image}
      {spicyLevel > 2 && image}
    </div>
  );
};

export default ProductSpicyIcon;
