/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect, useContext } from 'react';
import { RiWalkFill } from 'react-icons/ri';
import { BsChevronRight } from 'react-icons/bs';
import { MdDeliveryDining } from 'react-icons/md';
import { AiFillClockCircle } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeliveryModal from '../../../../components/modal/deliveryModal';
import { Context as DeliveryContext } from '../../../../context/delivery';
import { useBackPress } from '../../../../hooks/useBackPress';
import DeliveryModalContentWrapper from '../../../../components/modal/deliveryModalContentWrapper';
import { CheckBox } from '../../../../components/inputs/checkBox';
import BasicSelectInput from '../../../../components/inputs/basicSelectInput';
import {
  formatDayMinutes,
  getDateMillisOfDeltaDay,
  getWeekDay,
  unspecifiedDateToMillis,
} from '../../../../utils/dateHelpers';
import { useInterval } from '../../../../hooks/useInterval';
import {
  IsRestaurantOpenAndCanOrderNow,
  adjustOrderDaysForLongDeliveries,
  getDayOptions,
  getTimeOptions,
} from '../../services/deliveryTimeHelpers';

// atsiemimas -
//    atidarymas: open hour + 30min
//    uzdarymas: close hour - 15min

// pristatyms -
//    atidarymas: open hour + 45min
//    uzdarymas: close hour

// siandienos laikas -
//    atsiemimas: eiti iki artimiausio laiko + 40 min
//    pristatymas: eiti iki artimiausio laiko + 60 min

const DeliveryMethod = ({
  deliveryRestaurant,
  isTakeAway,
  onChange,
  onTimeChange,
  onInvalidOptions,
  isDeliveryProviderMissing,
  onTimeSelectModalClose,
  productCategories,
  bill,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation(['delivery']);

  const [timeOptionsRefresher, setTimeOptionsRefresher] = useState(0);

  const [timeDialogOpen, setTimeDialogOpen] = useState(false);
  const [isDeliverNow, setIsDeliverNow] = useState(true);
  const [deliveryDayDelta, setDeliveryDayDelta] = useState(0);
  const [deliveryTime, setDeliveryTime] = useState(0);
  const [noAvailableDeliveryOptions, setNoAvailableDeliveryOptions] = useState(false);
  const [noAvailableDeliveryTypeOptions, setNoAvailableDeliveryTypeOptions] = useState(false);
  const [timeSelectKey, setTimeSelectKey] = useState(1);

  const { setDefaultDeliveryType } = useContext(DeliveryContext);

  const { handleBackOnModalOpen, handleBackOnModalClose } = useBackPress(
    pathname,
    () => setTimeDialogOpen(false),
    'schedule=true',
  );

  useInterval(
    () => {
      setTimeOptionsRefresher((prev) => prev + 1);
    },
    30000,
    false,
  );

  // Get day options
  const { dayOptions, canOrderNow } = useMemo(() => {
    let dayOptionsTemp = getDayOptions({ deliveryRestaurant, isTakeAway });
    let canOrderNowTemp = IsRestaurantOpenAndCanOrderNow({ deliveryRestaurant, isTakeAway });

    // Adjust for long deliveries
    const longDeliveryAdjustmentResult = adjustOrderDaysForLongDeliveries({
      productCategories,
      bill,
      dayOptions: dayOptionsTemp,
    });

    if (longDeliveryAdjustmentResult) {
      dayOptionsTemp = longDeliveryAdjustmentResult.dayOptions;
      canOrderNowTemp = longDeliveryAdjustmentResult.canOrderNow;
    }

    return { dayOptions: dayOptionsTemp, canOrderNow: canOrderNowTemp };
  }, [timeOptionsRefresher, productCategories, bill, isTakeAway]);

  // Get time options
  const { timeOptions } = useMemo(() => {
    const timeOptionsTemp = getTimeOptions({ deliveryRestaurant, deliveryDayDelta, isTakeAway });

    return {
      timeOptions: timeOptionsTemp,
    };
  }, [timeOptionsRefresher, isTakeAway, deliveryDayDelta]);

  // Update selected delivery time (for current day)
  useEffect(() => {
    if (isDeliverNow || deliveryDayDelta > 0) {
      return;
    }

    const currentWeekDay = getWeekDay(deliveryRestaurant.data.timeZone);
    const dayWorkTime = deliveryRestaurant.workTimes.find((x) => x.day === currentWeekDay);

    // is not day available
    if (!dayWorkTime || !dayOptions.find((x) => x.value === 0)) {
      setNextDayAvailableTime();
    }
    // Current day is still available, check if time is available
    else {
      const bestNewDeliveryTimeOption = findClosestDeliveryTime(deliveryTime, timeOptions);
      if (bestNewDeliveryTimeOption) {
        setDeliveryTime(bestNewDeliveryTimeOption.value);
      } else {
        setNextDayAvailableTime();
      }
    }
  }, [timeOptionsRefresher, isTakeAway]);

  // Update selected delivery time (for future days)
  useEffect(() => {
    if (isDeliverNow || deliveryDayDelta === 0) {
      return;
    }

    const bestNewDeliveryTimeOption = findClosestDeliveryTime(deliveryTime, timeOptions);
    if (bestNewDeliveryTimeOption) {
      setDeliveryTime(bestNewDeliveryTimeOption.value);
    } else {
      setNextDayAvailableTime();
    }
  }, [isTakeAway]);

  const findClosestDeliveryTime = (currentDeliveryTime, newTimeOptions) => {
    if (!newTimeOptions || newTimeOptions.length === 0) {
      return null;
    }

    let currentDiff = 999999;
    let currentTimeOption;

    // eslint-disable-next-line no-restricted-syntax
    for (const timeOption of newTimeOptions) {
      const tempDiff = Math.abs(timeOption.value - currentDeliveryTime);
      if (tempDiff < currentDiff) {
        currentDiff = tempDiff;
        currentTimeOption = timeOption;
      } else {
        return currentTimeOption;
      }
    }

    return currentTimeOption;
  };

  // Update selected delivery time WHEN its selected to deliver now, but not possible anymore
  useEffect(() => {
    if (!isDeliverNow || deliveryDayDelta > 0) {
      return;
    }

    if (!canOrderNow) {
      setNextDayAvailableTime();
    } else if (noAvailableDeliveryOptions) {
      setNoAvailableDeliveryOptions(false);
      onInvalidOptions('noAvailableDeliveryOptions', false);
    }
  }, [timeOptionsRefresher, isTakeAway, isDeliverNow, canOrderNow]);

  // On load, check if current day is possible
  useEffect(() => {
    const anyCriticalErrors = checkIfHaveAvailableOptions();
    if (anyCriticalErrors) {
      return;
    }

    const currentWeekDay = getWeekDay(deliveryRestaurant.data.timeZone);
    const dayWorkTime = deliveryRestaurant.workTimes.find((x) => x.day === currentWeekDay);

    const isOpen = IsRestaurantOpenAndCanOrderNow({ deliveryRestaurant, isTakeAway });

    // is not day available
    if (!dayWorkTime || !dayOptions.find((x) => x.value === 0) || !isOpen) {
      setNextDayAvailableTime();
    }
  }, []);

  // Update parent on any time change
  useEffect(() => {
    // Dont update if no days available at all
    if (noAvailableDeliveryOptions) {
      return;
    }

    let dateMillis;
    if (isDeliverNow) {
      const timeOptionsTemp = getTimeOptions({
        deliveryRestaurant,
        deliveryDayDelta: 0,
        isTakeAway,
      });

      if (timeOptionsTemp.length === 0) {
        return;
      }

      dateMillis =
        unspecifiedDateToMillis(getDateMillisOfDeltaDay(deliveryRestaurant.data.timeZone, 0)) +
        timeOptionsTemp[0].value * 60000;
    } else {
      dateMillis =
        unspecifiedDateToMillis(
          getDateMillisOfDeltaDay(deliveryRestaurant.data.timeZone, deliveryDayDelta),
        ) +
        deliveryTime * 60000;
    }

    onTimeChange(isDeliverNow, dateMillis);
  }, [isDeliverNow, deliveryTime, deliveryDayDelta]);

  const checkIfHaveAvailableOptions = () => {
    let anyCriticalErrors = false;
    if (!deliveryRestaurant.hasDelivery && !deliveryRestaurant.hasTakeaway) {
      setNoAvailableDeliveryTypeOptions(true);
      onInvalidOptions('noAvailableDeliveryTypeOptions', true);
      anyCriticalErrors = true;
    }

    if (!dayOptions || dayOptions.length === 0) {
      setNoAvailableDeliveryOptions(true);
      onInvalidOptions('noAvailableDeliveryOptions', true);
      anyCriticalErrors = true;
    } else if (noAvailableDeliveryOptions) {
      setNoAvailableDeliveryOptions(false);
      onInvalidOptions('noAvailableDeliveryOptions', false);
    }

    return anyCriticalErrors;
  };

  const setNextDayAvailableTime = () => {
    const anyCriticalErrors = checkIfHaveAvailableOptions();
    if (anyCriticalErrors) {
      return;
    }

    setDeliveryDayDelta(dayOptions[0].value);
    setIsDeliverNow(false);

    const timeOptionsTemp = getTimeOptions({
      deliveryRestaurant,
      deliveryDayDelta: dayOptions[0].value,
      isTakeAway,
    });

    setDeliveryTime(timeOptionsTemp && timeOptionsTemp.length > 0 ? timeOptionsTemp[0].value : 0);
  };

  const renderCard = (icon, title, subtitle, rightIcon, onClick, isError) => {
    return (
      <div
        role={onClick ? 'button' : undefined}
        onClick={onClick}
        className={`flex flex-row justify-between items-center border ${
          isError ? 'border-primaryOrange' : 'border-input'
        } px-5 py-3 rounded-md`}
      >
        <div className="flex flex-row items-center">
          <div>{icon}</div>
          <div>
            <div className="font-primarySemiBold text-base text-primary">{title}</div>
            <div
              className={`font-primaryMedium text-base ${
                isError ? 'text-primaryOrange' : 'text-bland'
              }`}
            >
              {subtitle}
            </div>
          </div>
        </div>
        <div>{rightIcon}</div>
      </div>
    );
  };

  const formatPreparationTime = () => {
    const fromMinutes = isTakeAway
      ? deliveryRestaurant.takeawayNowMin
      : deliveryRestaurant.deliveryNowMin;
    const toMinutes = isTakeAway
      ? deliveryRestaurant.takeawayNowMax
      : deliveryRestaurant.deliveryNowMax;

    if (!fromMinutes && !toMinutes) {
      return null;
    }

    const parts = [];

    if (fromMinutes) {
      parts.push(fromMinutes);
    }

    if (toMinutes) {
      parts.push(toMinutes);
    }

    return isTakeAway
      ? `${t('delivery:menu.preparedInTime')} ${parts.join(' - ')} ${t('delivery:menu.minutes')}`
      : `${t('delivery:menu.deliveredInTime')} ${parts.join(' - ')} ${t('delivery:menu.minutes')}`;
  };

  const preparationNowText = formatPreparationTime();

  const checkIfCanOrderLater = () => {
    // no days
    if (!dayOptions || dayOptions.length === 0) {
      return false;
    }

    // 1 day, no times
    if ((!timeOptions || timeOptions.length === 0) && dayOptions.length === 1) {
      return false;
    }

    return true;
  };

  const renderTimeSelect = () => {
    const canOrderLater = checkIfCanOrderLater();

    return (
      <div>
        {canOrderNow && (
          <div
            role="button"
            onClick={() => setIsDeliverNow(true)}
            className="flex flex-row items-center hover:bg-lightGrayBg py-4 -mt-4 -mx-10 px-10"
          >
            <div className="mr-5">
              <CheckBox checked={isDeliverNow} disabled />
            </div>
            <div>
              <div className="font-primarySemiBold text-base text-primary">
                {t('delivery:checkout.deliveryNow')}
              </div>
              {!!preparationNowText && (
                <div className="font-primaryMedium text-base text-bland">{preparationNowText}</div>
              )}
            </div>
          </div>
        )}
        {canOrderLater && (
          <div
            role="button"
            onClick={() => {
              // on first change, init times
              if (isDeliverNow && deliveryDayDelta === 0 && deliveryTime === 0) {
                if (dayOptions && dayOptions.length > 0) {
                  setDeliveryDayDelta(dayOptions[0].value);
                }

                if (timeOptions && timeOptions.length > 0) {
                  setDeliveryTime(timeOptions[0].value);
                }
              }

              setIsDeliverNow(false);
            }}
            className={`hover:bg-lightGrayBg py-4 -mb-4 -mx-10 px-10 ${
              !canOrderNow ? '-mt-4' : ''
            }`}
          >
            <div className="flex flex-row items-center">
              <div className="mr-5">
                <CheckBox checked={!isDeliverNow} disabled />
              </div>
              <div>
                <div className="font-primarySemiBold text-base text-primary">
                  {t('delivery:checkout.orderForLater')}
                </div>
              </div>
            </div>
            <div className="pl-5 mt-5 flex flex-row w-full">
              <div className="w-full max-w-[40%] mr-5">
                <BasicSelectInput
                  options={dayOptions}
                  onChange={(val) => {
                    const newDeliveryDayDelta = parseInt(val, 10) || 0;
                    setDeliveryDayDelta(newDeliveryDayDelta);

                    // Update time on day change
                    const timeOptionsTemp = getTimeOptions({
                      deliveryRestaurant,
                      deliveryDayDelta: newDeliveryDayDelta,
                      isTakeAway,
                    });

                    const earliestTimeOption =
                      timeOptionsTemp && timeOptionsTemp.length > 0 ? timeOptionsTemp[0] : null;
                    const latestTimeOption =
                      timeOptionsTemp && timeOptionsTemp.length > 0
                        ? timeOptionsTemp[timeOptionsTemp.length - 1]
                        : null;
                    if (earliestTimeOption && earliestTimeOption.value > deliveryTime) {
                      setDeliveryTime(earliestTimeOption.value);
                      setTimeSelectKey((prev) => prev + 1);
                    } else if (latestTimeOption && latestTimeOption.value < deliveryTime) {
                      setDeliveryTime(latestTimeOption.value);
                      setTimeSelectKey((prev) => prev + 1);
                    } else if (
                      timeOptionsTemp &&
                      timeOptionsTemp.length > 0 &&
                      !timeOptionsTemp.find((x) => x.value === deliveryTime)
                    ) {
                      setDeliveryTime(timeOptionsTemp[0].value);
                      setTimeSelectKey((prev) => prev + 1);
                    }
                  }}
                  defaultValue={deliveryDayDelta}
                />
              </div>
              <div className="w-[30%]">
                <BasicSelectInput
                  key={`${timeSelectKey}`}
                  options={timeOptions}
                  onChange={(val) => {
                    setDeliveryTime(parseInt(val, 10) || 0);
                  }}
                  defaultValue={deliveryTime}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const formatTimeCardTitle = () => {
    if (noAvailableDeliveryOptions) {
      return t('delivery:checkout.unavailable');
    }

    if (isDeliverNow) {
      return t('delivery:checkout.deliveryNow');
    }

    const dayOption = dayOptions.find((x) => x.value === deliveryDayDelta);
    if (dayOption) {
      if (deliveryTime >= 1440) {
        return `${dayOption.label} (${t('delivery:checkout.deliveryNextDay')})`;
      }

      return dayOption.label;
    }

    return '';
  };

  const formatTimeCardSubtitle = () => {
    if (noAvailableDeliveryOptions) {
      return t('delivery:checkout.noDeliveryTimes');
    }

    if (isDeliverNow) {
      return preparationNowText;
    }

    if (deliveryTime) {
      return formatDayMinutes(deliveryTime);
    }

    return '';
  };

  const formatDeliveryCardTitle = () => {
    if (noAvailableDeliveryTypeOptions) {
      return t('delivery:checkout.unavailable');
    }

    return isTakeAway ? t('delivery:menu.takeaway') : t('delivery:menu.delivery');
  };

  const formatDeliveryCardSubtitle = () => {
    if (noAvailableDeliveryTypeOptions) {
      return t('delivery:checkout.noDeliveryTypes');
    }

    if (!isTakeAway && isDeliveryProviderMissing) {
      return t('delivery:checkout.noDeliveryProvider');
    }

    return isTakeAway
      ? t('delivery:checkout.takeawayExplanation')
      : t('delivery:checkout.deliveryExplanation');
  };

  return (
    <>
      {renderCard(
        isTakeAway ? (
          <RiWalkFill className="text-primary mr-5" size={26} />
        ) : (
          <MdDeliveryDining className="text-primary mr-5" size={26} />
        ),
        formatDeliveryCardTitle(),
        formatDeliveryCardSubtitle(),
        !noAvailableDeliveryTypeOptions &&
          deliveryRestaurant.hasDelivery &&
          deliveryRestaurant.hasTakeaway ? (
          <BsChevronRight className="text-primary" size={20} />
        ) : null,
        !noAvailableDeliveryTypeOptions &&
          deliveryRestaurant.hasDelivery &&
          deliveryRestaurant.hasTakeaway
          ? () => {
              onChange(!isTakeAway);
              setDefaultDeliveryType(isTakeAway ? 'delivery' : 'takeaway');
            }
          : undefined,
        noAvailableDeliveryTypeOptions || (!isTakeAway && isDeliveryProviderMissing),
      )}
      <div className="mt-5" />
      {renderCard(
        <AiFillClockCircle className="text-primary mr-5" size={26} />,
        formatTimeCardTitle(),
        formatTimeCardSubtitle(),
        noAvailableDeliveryOptions ? null : (
          <div className="font-primaryMedium text-sm text-primary">
            {t('delivery:buttons.change')}
          </div>
        ),
        noAvailableDeliveryOptions
          ? undefined
          : () => {
              setTimeDialogOpen(true);
              handleBackOnModalOpen();
            },
        noAvailableDeliveryOptions,
      )}

      <DeliveryModal
        content={
          <DeliveryModalContentWrapper
            title={
              isTakeAway
                ? t('delivery:checkout.selectPickupTime')
                : t('delivery:checkout.selectDeliveryTime')
            }
            onClose={() => {
              setTimeDialogOpen(false);
              handleBackOnModalClose();
              onTimeSelectModalClose();
            }}
            onConfirm={() => {
              setTimeDialogOpen(false);
              handleBackOnModalClose();
              onTimeSelectModalClose();
            }}
            confirmTitle={t('delivery:buttons.done')}
          >
            {renderTimeSelect()}
          </DeliveryModalContentWrapper>
        }
        onOutClick={() => {
          setTimeDialogOpen(false);
          handleBackOnModalClose();
          onTimeSelectModalClose();
        }}
        open={timeDialogOpen}
      />
    </>
  );
};

export default DeliveryMethod;
