import React from 'react';

export const RadioButton = ({ checked, onClick, title, disabled, hideCheck, customColor }) => {
  return (
    <div
      role={hideCheck ? undefined : 'button'}
      onClick={() => {
        if (onClick && !disabled) {
          onClick(!checked);
        }
      }}
      className="flex flex-row items-center py-1"
    >
      {!hideCheck && (
        <div
          className="w-5 h-5 border rounded-full bg-white flex justify-center items-center shrink-0"
          style={{
            background: checked ? customColor || '#000000' : '#ffffff',
            borderColor: checked ? customColor || '#000000' : '#000000',
          }}
        >
          {checked && <div className="w-2 h-2 rounded-full bg-white" />}
        </div>
      )}
      {title && (
        <div
          className={`${hideCheck ? '' : 'ml-4'} ${
            hideCheck ? 'font-primaryRegular' : 'font-primaryMedium'
          } text-black text-base`}
        >
          {title}
        </div>
      )}
    </div>
  );
};
