import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import MainButton from '../buttons/main';

const ConfirmationModal = ({ title, text, open, onConfirm, onClose }) => {
  const { t } = useTranslation();

  const renderContent = () => {
    return (
      <div id="confirmation-modal">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[80] outline-none focus:outline-none px-5">
          <div className="relative w-auto my-6 mx-auto max-w-md">
            {/*content*/}
            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-6 py-5">
              {/*header*/}
              <h3 className="font-primaryMedium text-primary text-2xl text-center">{title}</h3>
              {/*body*/}
              <p className="font-primaryMedium text-primary text-base text-center mt-3">{text}</p>
              {/*footer*/}
              <div className="flex flex-col mt-8">
                <MainButton text={t('buttons.confirmationYes')} onClick={() => onConfirm()} />
                <div className="mt-3" />
                <MainButton text={t('buttons.confirmationNo')} onClick={() => onClose()} outline />
              </div>
            </div>
          </div>
        </div>
        {/*backdrop*/}
        <div className="opacity-25 fixed inset-0 z-[70] bg-black" />
      </div>
    );
  };

  // eslint-disable-next-line no-undef
  return open ? createPortal(renderContent(), document.body) : null;
};

export default ConfirmationModal;
