import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import BG from './bg/translations.json';
import BG_DELIVERY from './bg/translations-delivery.json';
import DE from './de/translations.json';
import DE_DELIVERY from './de/translations-delivery.json';
import DK from './dk/translations.json';
import DK_DELIVERY from './dk/translations-delivery.json';
import EE from './ee/translations.json';
import EE_DELIVERY from './ee/translations-delivery.json';
import EN from './en/translations.json';
import EN_DELIVERY from './en/translations-delivery.json';
import ES from './es/translations.json';
import ES_DELIVERY from './es/translations-delivery.json';
import FI from './fi/translations.json';
import FI_DELIVERY from './fi/translations-delivery.json';
import LT from './lt/translations.json';
import LT_DELIVERY from './lt/translations-delivery.json';
import LV from './lv/translations.json';
import LV_DELIVERY from './lv/translations-delivery.json';
import NO from './no/translations.json';
import NO_DELIVERY from './no/translations-delivery.json';
import PL from './pl/translations.json';
import PL_DELIVERY from './pl/translations-delivery.json';
import RO from './ro/translations.json';
import RO_DELIVERY from './ro/translations-delivery.json';
import RU from './ru/translations.json';
import RU_DELIVERY from './ru/translations-delivery.json';
import SE from './se/translations.json';
import SE_DELIVERY from './se/translations-delivery.json';

export const appLanguages = [
  { langCode: 'en', name: 'EN', flagName: 'GB' },
  { langCode: 'lt', name: 'LT', flagName: 'LT' },
  { langCode: 'lv', name: 'LV', flagName: 'LV' },
  { langCode: 'ru', name: 'RU', flagName: 'RU' },
  { langCode: 'pl', name: 'PL', flagName: 'PL' },
  { langCode: 'ee', name: 'EE', flagName: 'EE' },
  { langCode: 'bg', name: 'BG', flagName: 'BG' },
  { langCode: 'de', name: 'DE', flagName: 'DE' },
  { langCode: 'dk', name: 'DK', flagName: 'DK' },
  { langCode: 'es', name: 'ES', flagName: 'ES' },
  { langCode: 'fi', name: 'FI', flagName: 'FI' },
  { langCode: 'no', name: 'NO', flagName: 'NO' },
  { langCode: 'ro', name: 'RO', flagName: 'RO' },
  { langCode: 'se', name: 'SE', flagName: 'SE' },
];

export const getDefaultPhoneCountryCodeByDefaultLanguage = (defaultLanguageCode) => {
  if (!defaultLanguageCode || defaultLanguageCode === 'lt') {
    return 'lt';
  }

  if (defaultLanguageCode === 'lv') {
    return 'lv';
  }

  if (defaultLanguageCode === 'de') {
    return 'de';
  }

  return 'lt';
};

const defaultAppLang = 'en';

i18next
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: defaultAppLang,
    supportedLngs: appLanguages.map((x) => x.langCode),
    debug: false,
    cleanCode: true,
    lowerCaseLng: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      bg: {
        translation: BG,
        delivery: BG_DELIVERY,
      },
      de: {
        translation: DE,
        delivery: DE_DELIVERY,
      },
      dk: {
        translation: DK,
        delivery: DK_DELIVERY,
      },
      ee: {
        translation: EE,
        delivery: EE_DELIVERY,
      },
      en: {
        translation: EN,
        delivery: EN_DELIVERY,
      },
      es: {
        translation: ES,
        delivery: ES_DELIVERY,
      },
      fi: {
        translation: FI,
        delivery: FI_DELIVERY,
      },
      lt: {
        translation: LT,
        delivery: LT_DELIVERY,
      },
      lv: {
        translation: LV,
        delivery: LV_DELIVERY,
      },
      no: {
        translation: NO,
        delivery: NO_DELIVERY,
      },
      pl: {
        translation: PL,
        delivery: PL_DELIVERY,
      },
      ro: {
        translation: RO,
        delivery: RO_DELIVERY,
      },
      ru: {
        translation: RU,
        delivery: RU_DELIVERY,
      },
      se: {
        translation: SE,
        delivery: SE_DELIVERY,
      },
    },
  });

export const getTranslatedData = (translations, options) => {
  const { dontUseOtherLanguage, dontFillWithOtherLanguage } = options || {};

  if (!translations) {
    return '';
  }

  if (translations[i18next.language]) {
    return translations[i18next.language];
  }

  if (dontUseOtherLanguage) {
    return '';
  }

  if (translations[defaultAppLang]) {
    return translations[defaultAppLang];
  }

  if (dontFillWithOtherLanguage) {
    return '';
  }

  if (Object.keys(translations).length > 0) {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in, no-unreachable-loop
    for (const key in translations) {
      return translations[key];
    }
  }

  return '';
};

export const getNumberCorrectSuffix = (numb) => {
  if (numb === undefined || numb === null) {
    return 'One';
  }

  const numbString = numb.toString();

  if (numbString[numbString.length - 1] === '0') {
    return 'Tens';
  }

  if (numbString.length >= 2) {
    const parsedLastTwo = parseInt(
      `${numbString[numbString.length - 2]}${numbString[numbString.length - 1]}`,
      10,
    );

    if (parsedLastTwo > 10 && parsedLastTwo < 20) {
      return 'Tens';
    }
  }

  if (numbString[numbString.length - 1] === '1') {
    return 'One';
  }

  return 'Few';
};

export default i18next;
