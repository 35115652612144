import React from 'react';
import { createPortal } from 'react-dom';
import { useScrollbarHide } from '../../hooks/useScrollbarHide';

const DeliveryModal = ({ content, open, hasPreviousModalOpen, onOutClick }) => {
  useScrollbarHide(open, hasPreviousModalOpen);

  const renderContent = () => {
    return (
      <div id="delivery-modal">
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-[65] outline-none focus:outline-none px-5"
          onClick={onOutClick}
        >
          <div
            className="relative w-full my-6 mx-auto max-w-xl"
            style={{ maxHeight: 'min(62.5rem, 100vh - 40px)' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{ maxHeight: 'min(62.5rem, 100vh - 40px)' }}
              className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none z-[70] overflow-y-auto scrollbar-v"
            >
              {content}
            </div>
          </div>
        </div>
        {/*backdrop*/}
        <div className="opacity-50 fixed inset-0 z-[60] bg-black" />
      </div>
    );
  };

  // eslint-disable-next-line no-undef
  return open ? createPortal(renderContent(), document.body) : null;
};

export default DeliveryModal;
