import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/misc/loader';
import { useDeliveryPageInit } from '../../hooks/useDeliveryPageInit';
import TextInput, { inputType } from '../../components/inputs/textInput';
import MainButton from '../../components/buttons/main';
import { getTranslatedData } from '../../localization';
import { testFeaturesLogin } from '../../api/auth';
import { Config } from '../../configs';

const TestLoginDeliveryPage = () => {
  const navigate = useNavigate();
  const { selectedRestaurant } = useDeliveryPageInit();

  const [accessToken, setAccessToken] = useState('');
  const [isLoading, setIsLoading] = useState('');

  if (!selectedRestaurant) {
    return <Loader />;
  }

  const onLogin = async () => {
    setIsLoading(true);

    const response = await testFeaturesLogin({ accessToken, restaurantId: selectedRestaurant.id });

    if (response) {
      global.sessionStorage.setItem(
        `${Config.CACHE_KEYS.TEST_FEATURES_ACCESS}${selectedRestaurant.id}`,
        JSON.stringify({ ...response }),
      );
      navigate(`/${selectedRestaurant.slug || selectedRestaurant.id}`, { replace: true });
      navigate(0);
      return;
    }

    setIsLoading(false);
  };

  return (
    <div className="p-6">
      <h2 className="font-primarySemiBold text-xl mb-5">Login to unlock Test Features</h2>
      <div className="max-w-sm">
        <div className="font-primaryMedium text-base mb-2">
          Restaurant: {getTranslatedData(selectedRestaurant.title)}
        </div>
        <TextInput
          id="order-fullname"
          onChange={setAccessToken}
          value={accessToken}
          type={inputType.formField}
          label="Access Token"
          required
          isDelivery
        />
        <div className="mt-5" />
        <MainButton
          text="Login"
          onClick={() => {
            onLogin();
          }}
          loading={isLoading}
          disabled={isLoading}
          smallerHeight
          customClass="rounded-sm max-w-[200px]"
        />
      </div>
    </div>
  );
};

export default TestLoginDeliveryPage;
