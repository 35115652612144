import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MdCheckCircle } from 'react-icons/md';
import MainButton from '../../../components/buttons/main';
import { useCurrency } from '../../../hooks/useCurrency';
import PaymentRating from './paymentRating';
import PaymentReceipt from './receiptDownload';
import { restaurantTypes } from '../../../enums/restaurantTypes';
import { buildRestaurantAddress, getDeliveryTimeText } from '../services/paymentHelpers';

const SuccessPayment = ({
  payment,
  canRate,
  onUpdateRating,
  reviewLink,
  isDelivery,
  onDeliveryPress,
  deliveryRestaurant,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation', 'delivery']);
  const { currencySymbol } = useCurrency();

  const totalPaidFees = (payment || {}).paidAdditional || 0;
  const totalPaidTips = (payment || {}).paidTips || 0;
  const totalPaidWithTips = ((payment || {}).paidAmount || 0) + totalPaidFees + totalPaidTips;
  const orderData = payment.order;
  const deliveryOrderData = payment.deliveryOrder;

  const renderDeliveryInfo = () => {
    if (!deliveryRestaurant || !orderData) {
      return null;
    }

    const restaurantAddress = buildRestaurantAddress(deliveryRestaurant.data);

    return (
      <div className="mt-6 flex flex-col items-center font-primaryMedium text-center text-primary text-base">
        <div className="text-2xl font-medium mb-4">
          {t('delivery:payment.orderPlacedSubject')}
          <span className="text-4xl">
            {' '}
            {orderData.externalNumber ? orderData.externalNumber || '' : orderData.number || '-'}
          </span>
        </div>

        {!!orderData.isTakeAway && (
          <>
            <div>
              {t('delivery:payment.estimatedTakeawayReadyTime', {
                deliveryTime: getDeliveryTimeText({
                  orderDate: orderData.orderDate,
                  deliveryDate: orderData.deliveryDate,
                  deliveryDateDelta: orderData.deliveryDateDelta,
                  timeZone: deliveryRestaurant.data.timeZone,
                }),
              })}
            </div>
            <div className="underline mb-4">{t('delivery:payment.takeawayWarning')}</div>
            {!!restaurantAddress && (
              <div>
                {deliveryRestaurant.data.name}. {restaurantAddress}
              </div>
            )}
          </>
        )}

        {!!orderData.isDelivery && (
          <div>
            {t('delivery:payment.estimatedDeliveryTime', {
              deliveryTime: getDeliveryTimeText({
                orderDate: orderData.orderDate,
                deliveryDate: orderData.deliveryDate,
                deliveryDateDelta: orderData.deliveryDateDelta,
                timeZone: deliveryRestaurant.data.timeZone,
              }),
            })}
          </div>
        )}

        {!!orderData.isDelivery && !!deliveryOrderData && !!deliveryOrderData.trackingUrl && (
          <div className="mt-2">
            <a
              className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
              target="_blank"
              href={deliveryOrderData.trackingUrl}
              rel="noreferrer"
            >
              {t('delivery:payment.trackDeliveryProgress')}
            </a>
          </div>
        )}
      </div>
    );
  };

  const renderHeader = () => {
    if (payment.restaurant) {
      if (payment.tipsOnly) {
        return (
          <div className="mt-6 flex flex-col items-center">
            <h1 className="font-primaryMedium text-center text-primary text-2xl mb-3">
              {t('translation:payment.successTitleForTipsType')}
            </h1>
            <MdCheckCircle className="text-[#D1F0C6] text-6xl" />
            <p className="font-primaryMedium text-center text-primary text-2xl mt-2 mb-3">
              {t('translation:payment.youLeftTips', {
                amount: `${((payment || {}).paidTips || 0).toFixed(2)} ${currencySymbol}`,
              })}
            </p>
            <div className="w-full mt-5 max-w-xs mx-auto">
              <MainButton
                text={t('translation:buttons.viewMenu')}
                outline
                onClick={() => navigate('/menu')}
              />
              <div className="mt-3" />
              <MainButton
                text={t('translation:buttons.leaveMoreTips')}
                onClick={() => navigate('/checkout-tips')}
              />
            </div>
          </div>
        );
      }

      return (
        <div className="mt-6 flex flex-col items-center">
          <h1 className="font-primaryMedium text-center text-primary text-2xl mb-3">
            {t('translation:payment.successTitle')}
          </h1>
          <p className="font-primaryMedium text-center text-primary text-2xl mb-3">
            {t('translation:payment.youPaid')} {totalPaidWithTips.toFixed(2)} {currencySymbol}
          </p>
          {totalPaidTips > 0 && (
            <p className="font-primaryMedium text-center text-bland text-base -mt-2 mb-3">
              {t('translation:payment.tipsIncludedInPayment')} {totalPaidTips.toFixed(2)}{' '}
              {currencySymbol}
            </p>
          )}
          <MdCheckCircle className="text-[#D1F0C6] text-6xl" />
          {payment.restaurant.type !== restaurantTypes.OrderAndPay && (
            <p className="font-primaryMedium text-center text-primary text-base mt-3">
              {t('translation:payment.leftToPay')} {((payment || {}).leftToPay || 0).toFixed(2)}{' '}
              {currencySymbol}
            </p>
          )}

          {renderDeliveryInfo()}
          <div className="w-full mt-5 max-w-xs mx-auto">
            {!isDelivery && (
              <MainButton
                text={
                  payment.restaurant.type === restaurantTypes.OrderAndPay
                    ? t('translation:buttons.seeOrder')
                    : t('translation:buttons.viewTableBill')
                }
                outline
                onClick={() =>
                  payment.restaurant.type === restaurantTypes.OrderAndPay
                    ? navigate(`/order/${payment.orderId}`)
                    : navigate('/bill')
                }
              />
            )}
            {isDelivery && (
              <MainButton
                text={t('translation:buttons.viewMenu')}
                outline
                onClick={onDeliveryPress}
              />
            )}
          </div>
        </div>
      );
    }

    return undefined;
  };

  return (
    <div>
      {renderHeader()}
      {canRate && (
        <PaymentRating
          payment={payment}
          restaurantName={(payment.restaurant || {}).name || ''}
          onUpdateRating={onUpdateRating}
          reviewLink={reviewLink}
        />
      )}
      <PaymentReceipt payment={payment} />
    </div>
  );
};

export default SuccessPayment;
