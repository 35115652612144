import React, { useEffect, useState } from 'react';
import StripePaymentContainer from './stripePaymentContainer';
import StripeExpressContainer from './stripeExpressContainer';

const StripeFullPaymentContainer = ({
  allowCards,
  allowWallets,
  cardFormVisible,
  publicKey,
  isConnected,
  connectedId,
  amount,
  onPay,
  onFail,
  paymentKey,
  currencyCode,
  disabled,
  cardFormSubmitRef,
  isDelivery,
  isValidOnSubmit,
}) => {
  const [activeType, setActiveType] = useState(null); // card or wallet

  const [oldPaymentKey, setOldPaymentKey] = useState(null);

  const [cardPaymentKey, setCardPaymentKey] = useState(null);
  const [walletPaymentKey, setWalletPaymentKey] = useState(null);

  useEffect(() => {
    if (paymentKey) {
      // If payment key was not updated
      if (paymentKey === oldPaymentKey) {
        return;
      }

      // cache this payment key
      setOldPaymentKey(paymentKey);

      if (activeType === 'card') {
        setCardPaymentKey(paymentKey);
      } else if (activeType === 'wallet') {
        setWalletPaymentKey(paymentKey);
      }
    }
  }, [paymentKey]);

  const onPayClick = (type) => {
    setCardPaymentKey(null);
    setWalletPaymentKey(null);

    setActiveType(type);
  };

  return (
    <div>
      {allowCards && cardFormVisible && (
        <div className="mt-7 mb-5">
          <StripePaymentContainer
            publicKey={publicKey}
            isConnected={isConnected}
            connectedId={connectedId}
            amount={amount}
            onPay={(paymentMethodId) => {
              onPayClick('card');
              onPay({ paymentMethodId });
            }}
            paymentKey={cardPaymentKey}
            onFail={onFail}
            disabled={disabled}
            cardFormSubmitRef={cardFormSubmitRef}
          />
        </div>
      )}
      {allowWallets && (
        <StripeExpressContainer
          publicKey={publicKey}
          isConnected={isConnected}
          connectedId={connectedId}
          amount={amount}
          currency={currencyCode}
          onPay={() => {
            onPayClick('wallet');
            onPay({});
          }}
          paymentKey={walletPaymentKey}
          onFail={onFail}
          isDelivery={isDelivery}
          disabled={disabled}
          isValidOnSubmit={isValidOnSubmit}
        />
      )}
    </div>
  );
};

export default StripeFullPaymentContainer;
