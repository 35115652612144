import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context as CartContext } from '../../../../../context/cart';
import { Context as RestaurantContext } from '../../../../../context/restaurant';
import { Context as DeliveryContext } from '../../../../../context/delivery';
import { useMenuPositions } from '../../../hooks/useMenuPositions';
import MenuBar from '../../menuBar';
import { getSelectedCategory } from '../../../services/categories';
import ProductModal from '../productModal';
import { getTranslatedData } from '../../../../../localization';
import { useBackPress } from '../../../../../hooks/useBackPress';
import { useLocalStorage } from '../../../../../hooks/useLocalStorage';
import MenuInfoDelivery from './menuInfo';
import ProductDelivery from '../../product/productDelivery';
import CartPopupDelivery from '../../../../cart/components/delivery/cartPopupDelivery';
import TopBar from '../../../../../layouts/delivery/topBar';
import DeliveryModal from '../../../../../components/modal/deliveryModal';
import { useDeliveryMenuBarScroll } from '../../../hooks/useDeliveryMenuBarScroll';
import { useDeliveryCartTotal } from '../../../../../hooks/useDeliveryCartTotal';
import { useCurrency } from '../../../../../hooks/useCurrency';
import DeliveryViewOrder from '../../../../../components/buttons/deliveryViewOrder';
import Product from '../../product/product';
import EventsList from '../../events/eventsList';
import PromotionsList from '../../promotions/promotionsList';

const OrderDeliveryMenu = ({
  deliveryRestaurant,
  productCategories,
  onlyDisplay,
  isEvents,
  isPromotions,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation', 'delivery']);
  const { currencySymbol } = useCurrency();

  const [cartVisible, setCartVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProductCategories, setFilteredProductCategories] = useState(productCategories);

  useEffect(() => {
    if (searchTerm) {
      filterProductsBySearchTerm(searchTerm);
    } else {
      setFilteredProductCategories(productCategories);
    }
  }, [productCategories]);

  const { windowHeight, scrollPosition, categoriesY, categoriesRef, onImagePositionChange } =
    useMenuPositions(filteredProductCategories);

  const { state: cartState, addProductToDeliveryCart } = useContext(CartContext);
  const { state: restaurantState } = useContext(RestaurantContext);
  const { state: deliveryState } = useContext(DeliveryContext);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productDialogOpen, setProductDialogOpen] = useState(false);

  const [topCartLineHeight, setTopCartLineHeight] = useState(68);

  useEffect(() => {
    if (
      (isEvents && !deliveryRestaurant.displayEvents) ||
      (isPromotions && !deliveryRestaurant.displayPromotions)
    ) {
      navigate(`/${deliveryRestaurant.slug || deliveryRestaurant.id}`, {
        replace: true,
      });
    }
  }, []);

  const { handleBackOnModalOpen, handleBackOnModalClose } = useBackPress(
    `/${deliveryRestaurant.slug || deliveryRestaurant.id}`,
    () => setProductDialogOpen(false),
    'product=true',
  );

  const {
    handleBackOnModalOpen: handleBackOnCartOpen,
    handleBackOnModalClose: handleBackOnCartClose,
  } = useBackPress(
    `/${deliveryRestaurant.slug || deliveryRestaurant.id}`,
    () => setCartVisible(false),
    'cart=true',
  );

  const [ageConfirmed, setAgeConfirmed] = useLocalStorage(
    `age-confirmed-${deliveryRestaurant.id}`,
    false,
  );

  const { searchBlockRef, menuBarRef, menuBarReached } = useDeliveryMenuBarScroll();
  const { totalAfterDiscount, totalInCart } = useDeliveryCartTotal();

  const isDekstopForm = global.window.innerWidth > 767;
  const onManualSelect = (categoryId) => {
    const categoryPosition = categoriesY[categoryId || ''];

    // eslint-disable-next-line no-undef
    window.scrollTo({
      top:
        categoryPosition -
        (menuBarRef && menuBarRef.current ? menuBarRef.current.offsetHeight : 0) -
        20 -
        (isDekstopForm ? 68 : 0),
      behavior: 'smooth',
    });
  };

  const shouldContainProductImage = () => {
    if (
      restaurantState &&
      restaurantState.restaurant &&
      restaurantState.restaurant.id &&
      [
        '66b365b7962d258142a5d6f0', // Čili Pizza (Fabijoniškių g.)
        '66b4c593962d258142a75bac', // Čili Pizza (Vingis)
        '66b5361d962d258142a7de52', // Čili Pizza (Mada)
        '66b6070b962d258142a89a8b', // Čili Pizza (Jeruzalės g.)
        '66b60f67962d258142a8a9ac', // Čili Pizza (Žirmūnų g.)
        '66b6134d962d258142a8b01b', // Čili Pizza (Nordika)
      ].includes(restaurantState.restaurant.id)
    ) {
      return true;
    }

    return false;
  };

  const renderCategoryMenu = (category, ind) => {
    const searchRegex = new RegExp(`.*${searchTerm}.*`, 'i');
    const showMobileProducts = global.window.innerWidth < 640;

    const restaurantCartData = cartState.deliveryCart[deliveryRestaurant.id] || {};
    const { cart } = restaurantCartData;
    const containImage = shouldContainProductImage();

    return (
      <div
        key={`${ind}`}
        ref={(el) => {
          if (!el) {
            return;
          }

          const oldPos = categoriesRef.current[category.id];
          categoriesRef.current[category.id] = { offsetTop: el ? el.offsetTop : 0 };

          // Position change on initial load
          if (oldPos && oldPos.offsetTop !== el.offsetTop && ind === productCategories.length - 1) {
            onImagePositionChange();
          }
        }}
      >
        <h3 className="font-primaryBold text-primary text-2xl mb-6">
          {category.id === '_other'
            ? t('translation:products.categoryOther')
            : getTranslatedData(category.name)}
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6 mb-10">
          {(category.products || []).map((product) => {
            let totalProductInCart = 0;
            if (cart) {
              cart.forEach((cartItem) => {
                if (cartItem.productId === product.id) {
                  totalProductInCart += cartItem.quantity;
                }
              });
            }

            if (searchTerm && !searchRegex.test(getTranslatedData(product.name))) {
              return null;
            }

            if (showMobileProducts) {
              return (
                <Product
                  key={product.id}
                  product={product}
                  onSelect={() => {
                    setSelectedProduct(product);
                    setProductDialogOpen(true);
                    handleBackOnModalOpen();
                  }}
                  totalInCart={totalProductInCart}
                  shouldContainImage={containImage}
                />
              );
            }

            return (
              <ProductDelivery
                key={product.id}
                product={product}
                onSelect={() => {
                  setSelectedProduct(product);
                  setProductDialogOpen(true);
                  handleBackOnModalOpen();
                }}
                totalInCart={totalProductInCart}
                shouldContainImage={containImage}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const selected = getSelectedCategory({
    windowHeight,
    scrollPosition,
    categoriesY,
    selectFirstIfNone: true,
    customThresholdFunc: () => {
      return (
        scrollPosition +
        (menuBarRef && menuBarRef.current ? menuBarRef.current.offsetHeight : 0) +
        50 +
        (isDekstopForm ? 50 : 0)
      );
    },
  });

  const filterProductsBySearchTerm = (newSearchTerm) => {
    if (newSearchTerm) {
      const searchRegex = new RegExp(`.*${newSearchTerm}.*`, 'i');
      const filteredCategories = (productCategories || []).filter((category) => {
        const productsToShow = category.products.filter((product) =>
          searchRegex.test(getTranslatedData(product.name)),
        );

        return productsToShow && productsToShow.length > 0;
      });

      setFilteredProductCategories(filteredCategories);
    } else {
      setFilteredProductCategories(productCategories);
    }
  };

  return (
    <>
      <div className={(isEvents || isPromotions) && `bg-lightGrayBg2 h-full -mb-6`}>
        <TopBar
          onBack={
            (deliveryState.restaurants && deliveryState.restaurants.length > 1) ||
            isEvents ||
            isPromotions
              ? () => {
                  if (isEvents || isPromotions) {
                    navigate(`/${deliveryRestaurant.slug || deliveryRestaurant.id}`, {
                      replace: true,
                    });
                  } else {
                    navigate('/', { replace: true });
                  }
                }
              : undefined
          }
          showOrder
          onShowCart={() => {
            setCartVisible(true);
            handleBackOnCartOpen();
          }}
          showTypeTabs
          useStickyNav
          onTopCartLineHeightChange={(valH) => {
            setTopCartLineHeight(valH);
          }}
          hasEvents={deliveryRestaurant.displayEvents}
          hasPromotions={deliveryRestaurant.displayPromotions}
          hideBanner={isEvents || isPromotions}
          restaurantSlug={deliveryRestaurant.slug || deliveryRestaurant.id}
        />

        {isEvents && <EventsList restaurantId={deliveryRestaurant.data.id} />}
        {isPromotions && <PromotionsList restaurantId={deliveryRestaurant.data.id} />}

        {!isEvents && !isPromotions && (
          <>
            <div ref={searchBlockRef}>
              <MenuInfoDelivery
                deliveryRestaurant={deliveryRestaurant}
                onSearch={(newSearchTerm) => {
                  filterProductsBySearchTerm(newSearchTerm);
                  setSearchTerm(newSearchTerm);
                }}
              />
            </div>

            <div
              ref={menuBarRef}
              className={`sticky bg-white z-10 ${menuBarReached ? 'shadow-md' : ''}`}
              style={{ top: topCartLineHeight }}
            >
              <div className="px-5">
                <MenuBar
                  productCategories={filteredProductCategories || []}
                  selected={selected}
                  onSelect={onManualSelect}
                  isDelivery
                />
              </div>
            </div>

            <div className="px-5">
              <div className="mt-6 max-w-6xl mx-auto w-full">
                {filteredProductCategories && filteredProductCategories.length > 0 ? (
                  (filteredProductCategories || []).map((category, ind) => {
                    return renderCategoryMenu(category, ind);
                  })
                ) : (
                  <div className="font-primaryMedium text-lg text-primary">
                    {t(`delivery:menu.noItems`)}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {totalInCart > 0 && !isEvents && !isPromotions && (
        <div className="px-5 sticky bottom-4 z-20">
          <DeliveryViewOrder
            currencySymbol={currencySymbol}
            onShowCart={() => {
              setCartVisible(true);
              handleBackOnCartOpen();
            }}
            totalAfterDiscount={totalAfterDiscount}
            totalInCart={totalInCart}
          />
        </div>
      )}

      <CartPopupDelivery
        isVisible={cartVisible}
        onClose={() => {
          setCartVisible(false);
          handleBackOnCartClose();
        }}
        onSelectRecommendation={(product) => {
          setSelectedProduct(product);
          setProductDialogOpen(true);
          handleBackOnModalOpen();
        }}
        deliveryRestaurant={deliveryRestaurant}
      />

      <DeliveryModal
        content={
          <ProductModal
            product={selectedProduct}
            onAdd={(data) => {
              addProductToDeliveryCart(
                deliveryRestaurant.id,
                data,
                restaurantState.discountCartService,
              );
              setProductDialogOpen(false);
              handleBackOnModalClose();
            }}
            refresher={productDialogOpen}
            onClose={() => {
              setProductDialogOpen(false);
              handleBackOnModalClose();
            }}
            discountMenuService={restaurantState.discountMenuService}
            onlyDisplay={onlyDisplay}
            needConfirmAge={!ageConfirmed}
            restrictionsAge={deliveryRestaurant.data.restrictionsAge}
            onAgeConfirmed={() => setAgeConfirmed(true)}
            roundedCorners
            scrollable
          />
        }
        onOutClick={() => {
          setProductDialogOpen(false);
          handleBackOnModalClose();
        }}
        open={productDialogOpen}
        hasPreviousModalOpen={cartVisible}
      />
    </>
  );
};

export default OrderDeliveryMenu;
