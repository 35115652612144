import React, { useContext, useEffect } from 'react';
import { Context as RestaurantContext } from '../../../../context/restaurant';
import PromotionCard from './promotionsCard';
import Loader from '../../../../components/misc/loader';

const PromotionsList = ({ restaurantId }) => {
  const { state: restaurantState, getPromotions } = useContext(RestaurantContext);

  useEffect(() => {
    getPromotions({ restaurantId });
  }, []);

  return (
    <div className="px-5 xl:px-0 pb-20 md:pb-24 pt-28 md:pt-8 max-w-6xl mx-auto">
      {!restaurantState.loadingPromotions &&
        !!restaurantState.promotions &&
        restaurantState.promotions.length > 0 && (
          <div className="grid grid-cols-1 xs:grid-cols-2 gap-x-6 md:gap-x-12 gap-y-10">
            {restaurantState.promotions.map((promotion) => (
              <PromotionCard key={promotion.id} promotion={promotion} />
            ))}
          </div>
        )}
      {restaurantState.loadingPromotions && <Loader customHeight="h-96" />}
    </div>
  );
};

export default PromotionsList;
