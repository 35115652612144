import { restaurantProviders } from '../../enums/restaurantProviders';
import { currentTimeZoneMillis } from '../../utils/dateHelpers';
import { formatProductForCartItemForDiscountDisplay } from '../cartService';

// used to apply discounts to items in menu page (not cart)
export class DiscountMenuService {
  constructor({ discounts, section, provider, integrationId, timezone, restaurant }) {
    this.discounts = this._filterApplicableDiscounts(discounts);
  }

  _filterApplicableDiscounts(allDiscounts) {
    const filtered = [];
    return filtered;
  }

  // returns { percent, discountedPrice, fullPrice }
  applyProductDiscount({ product }) {
    if (!this.discounts || this.discounts.length === 0) {
      return null;
    }

    const cartItem = formatProductForCartItemForDiscountDisplay({ product });

    return this.applyFormedCartItemDiscount({ cartItem });
  }

  applyFormedCartItemDiscount({ cartItem }) {
    if (!this.discounts || this.discounts.length === 0) {
      return null;
    }

    // Do logic here

    return null;
  }
}
