import React from 'react';
import { config } from 'codemash';
import { Routes, Route } from 'react-router-dom';
import { Config as ProjectConfig } from './configs';

import './localization';
import { initTableOnLoad } from './services/tableService';
import ServerEventsHandler, { sseHandler } from './services/serverEventsHandler';

import ScrollToTop from './components/misc/scrollToTop';

import { Provider as RestaurantProvider } from './context/restaurant';
import { Provider as CheckoutProvider } from './context/checkout';
import { Provider as AlertProvider } from './context/alert';
import { Provider as AuthProvider } from './context/auth';
import { Provider as CartProvider } from './context/cart';
import { Provider as SettingsProvider } from './context/settings';
import { Provider as DeliveryProvider } from './context/delivery';

import NotFoundPage from './pages/noPage';

// order in place
import InitPage from './pages/init';

import RestaurantSelectPage from './pages/delivery/restaurantSelect';
import MenuDeliveryPage from './pages/delivery/menuDelivery';
import CheckoutDeliveryPage from './pages/delivery/checkoutDelivery';
import PaymentDeliveryPage from './pages/delivery/paymentDelivery';
import ServiceUnavailablePage from './pages/serviceUnavailable';
import TestLoginDeliveryPage from './pages/delivery/testLoginDelivery';

import { createExternalNavigation } from './services/navigationService';

config.init(
  {
    secretKey: ProjectConfig.CODEMASH.SECRET_KEY,
    projectId: ProjectConfig.CODEMASH.PROJECT_ID,
  },
  process.env.NODE_ENV,
);

const App = () => {
  initTableOnLoad();
  createExternalNavigation();

  sseHandler.handler = new ServerEventsHandler();

  return (
    <SettingsProvider>
      <AlertProvider>
        <AuthProvider>
          <DeliveryProvider>
            <RestaurantProvider>
              <CheckoutProvider>
                <CartProvider>
                  <InitPage>
                    <ScrollToTop />
                    <Routes>
                      <Route path="/" element={<RestaurantSelectPage />} />
                      <Route path="/unavailable" element={<ServiceUnavailablePage />} />
                      {[
                        '/:restaurantSlug',
                        '/:restaurantSlug/events',
                        '/:restaurantSlug/promotions',
                      ].map((path) => (
                        <Route key={path} path={path} element={<MenuDeliveryPage />} />
                      ))}
                      <Route path="/:restaurantSlug/checkout" element={<CheckoutDeliveryPage />} />
                      <Route path="/:restaurantSlug/payment" element={<PaymentDeliveryPage />} />
                      <Route path="/:restaurantSlug/~admin" element={<TestLoginDeliveryPage />} />
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                  </InitPage>
                </CartProvider>
              </CheckoutProvider>
            </RestaurantProvider>
          </DeliveryProvider>
        </AuthProvider>
      </AlertProvider>
    </SettingsProvider>
  );
};

export default App;
