import React, { useState, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdArrowBackIos } from 'react-icons/md';
import { Context as CartContext } from '../../../../context/cart';
import { Context as RestaurantContext } from '../../../../context/restaurant';
import { Context as CheckoutContext } from '../../../../context/checkout';
import { Context as DeliveryContext } from '../../../../context/delivery';
import CartItem from '../cartItem';
import CheckoutTotal from '../../../checkout/components/total';
import { calculateCartSum } from '../../../../services/cartService';
import CartRecommendations from '../cartRecommendations';
import WaiterNote from '../waiterNote';
import ConfirmButton from '../../../../components/buttons/confirmButton';
import { useScrollbarHide } from '../../../../hooks/useScrollbarHide';
import { useDeliveryColors } from '../../../../hooks/useDeliveryColors';

const CartPopupDelivery = ({ isVisible, onClose, onSelectRecommendation, deliveryRestaurant }) => {
  const { t } = useTranslation(['translation', 'delivery']);

  const navigate = useNavigate();
  const colors = useDeliveryColors();

  useScrollbarHide(isVisible);

  const [note, setNote] = useState('');

  const { state: cartState, removeProductFromDeliveryCart } = useContext(CartContext);
  const { state: restaurantState } = useContext(RestaurantContext);
  const { setOrderBill } = useContext(CheckoutContext);
  const { state: deliveryState } = useContext(DeliveryContext);

  const { cart, totalDiscounted } = useMemo(() => {
    if (!deliveryState.selectedRestaurantId) {
      return { cart: {}, totalDiscounted: 0 };
    }

    const restaurantCartData = cartState.deliveryCart[deliveryState.selectedRestaurantId] || {};
    return { cart: restaurantCartData.cart, totalDiscounted: restaurantCartData.totalDiscounted };
  }, [cartState.deliveryCart]);

  if (!isVisible) {
    return null;
  }

  const total = calculateCartSum({ cart });
  const totalAfterDiscount = total - (totalDiscounted || 0);

  const onRemove = (productId, index) => {
    removeProductFromDeliveryCart(
      deliveryState.selectedRestaurantId,
      productId,
      index,
      restaurantState.discountCartService,
    );
  };

  const isContinueDisabled = () => {
    if (!total) {
      return true;
    }

    return false;
  };

  return (
    <div
      className="fixed bg-[rgba(24,24,24,0.5)] h-full w-full z-50 cursor-default"
      role="button"
      onClick={onClose}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="bg-white absolute right-0 top-0 bottom-0 max-w-[28rem] w-full px-8 py-8 overflow-auto h-full flex flex-col"
      >
        <div>
          <p className="font-primaryMedium text-2xl text-primary">{t('translation:header.cart')}</p>

          <button
            type="button"
            onClick={() => {
              onClose();
            }}
            className="flex flex-row justify-center items-center py-2 cursor-pointer mt-1"
          >
            <MdArrowBackIos />{' '}
            <div className="ml-2 font-primaryMedium text-primary text-base">
              {t('translation:buttons.close')}
            </div>
          </button>

          <div className="mb-7" />

          {cart &&
            cart.map((item) => (
              <CartItem
                key={`${item.productId}-${item.index}`}
                product={item}
                onRemove={onRemove}
              />
            ))}

          <div className="mt-20">
            <CartRecommendations
              productCategories={restaurantState.productCategories}
              onSelect={(product) => {
                onSelectRecommendation(product);
              }}
            />
          </div>

          <div className="mt-11">
            <CheckoutTotal total={totalAfterDiscount} discount={totalDiscounted} noDivider />
          </div>
        </div>

        <div className="mt-auto">
          <div className="mt-5">
            <WaiterNote note={note} onChange={setNote} isDelivery />
          </div>

          <div className="mt-10">
            <ConfirmButton
              onClick={() => {
                setOrderBill({
                  subtotal: total,
                  discount: totalDiscounted,
                  cart,
                  note,
                  totalGuests: 1,
                });
                navigate(`/${deliveryRestaurant.slug || deliveryRestaurant.id}/checkout`);
              }}
              customText={t('translation:buttons.continueToPayment')}
              noShadow
              noSpacing
              disabled={isContinueDisabled()}
              customColor={colors.hasCustomPrimary ? colors.primary : undefined}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPopupDelivery;
