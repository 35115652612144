import React from 'react';
import veganIcon from '../../../../../assets/images/icons/vegan-icon.png';

const ProductVeganIcon = ({ isVegan }) => {
  if (!isVegan) {
    return null;
  }

  return (
    <div className="inline-block relative top-[2px]">
      <img src={veganIcon} alt="vegan" />
    </div>
  );
};

export default ProductVeganIcon;
