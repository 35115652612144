/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function sortObjectArray(items, key, val, forceNumbers) {
  if (!items) {
    return items;
  }

  items.sort((a, b) => {
    let comparator = null;
    if (typeof a[key] === 'string') {
      comparator = compareString;
    } else if (typeof a[key] === 'number') {
      comparator = compareNumber;
    }

    if (comparator === null) {
      return 0;
    }

    if (forceNumbers && typeof a[key] === 'number' && typeof b[key] !== 'number') {
      return -1;
    }
    if (forceNumbers && typeof a[key] !== 'number' && typeof b[key] === 'number') {
      return 1;
    }

    return val === 1 ? comparator(a[key], b[key]) : comparator(b[key], a[key]);
  });

  return items;
}

function compareString(a, b) {
  if (!a) {
    return 1;
  }

  return a.localeCompare(b);
}

function compareNumber(a, b) {
  return a - b;
}

export function splitArray(array, newSize) {
  const arrayOfArrays = [];
  for (let i = 0; i < array.length; i += newSize) {
    arrayOfArrays.push(array.slice(i, i + newSize));
  }

  return arrayOfArrays;
}

export function createEmptyArray(size) {
  const array = [];
  for (let i = 0; i < size; i += 1) {
    array.push('');
  }
  return array;
}
