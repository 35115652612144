import React from 'react';
import { MdClose } from 'react-icons/md';
import MainButton from '../buttons/main';

const DeliveryModalContentWrapper = ({ onClose, onConfirm, title, confirmTitle, children }) => {
  const renderClose = () => {
    return (
      <div
        role="button"
        onClick={onClose}
        className="w-11 h-11 bg-[#F5F5F5] rounded-full flex justify-center items-center"
      >
        <MdClose size={20} className="text-primary" />
      </div>
    );
  };

  return (
    <div className="px-10 pt-5 pb-9 overflow-y-auto scrollbar-v">
      <div className="flex flex-row justify-end mb-2">{renderClose()}</div>
      <div className="font-primaryBold text-primary text-2xl text-center">{title}</div>
      <div className="mt-9 mb-16">{children}</div>
      <MainButton onClick={onConfirm} text={confirmTitle} />
    </div>
  );
};

export default DeliveryModalContentWrapper;
