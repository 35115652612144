import { useMemo } from 'react';
import { roundDecimal } from '../../../utils/roundingHelpers';
import {
  calculateCouponOnOrder,
  calculateCouponOnProducts,
} from '../services/deliveryCouponService';

export const useDeliveryPricing = ({
  isTakeAway,
  bill,
  productCategories,
  deliveryRestaurant,
  tipsAmount,
  appliedCoupon,
  providerDeliveryPrice,
  ignoreProviderPrice,
  isDeliveryPriceReceived,
}) => {
  if (!productCategories || !bill || !deliveryRestaurant) {
    return {
      total: 0,
      subtotal: 0,
      totalDiscount: 0,
      totalPackagingPrice: 0,
      totalDeliveryPrice: 0,
      totalReachMinimalPrice: 0,
      totalCouponDiscount: 0,
    };
  }

  const subtotal = (bill || {}).subtotal || 0;
  const totalDiscount = (bill || {}).discount || 0;

  const totalPackagingPrice = useMemo(() => {
    let combinedTemp = 0;
    if (
      !bill.cart ||
      bill.cart.length === 0 ||
      !productCategories ||
      productCategories.length === 0
    ) {
      return combinedTemp;
    }

    bill.cart.forEach((cartItem) => {
      productCategories.forEach((category) => {
        if (!category.products || category.products.length === 0) {
          return;
        }

        category.products.forEach((product) => {
          if (product.id === cartItem.productId) {
            let packagingPriceToUse = product.packagingPrice;
            if (!isTakeAway && product.packagingPriceDelivery > 0) {
              packagingPriceToUse = product.packagingPriceDelivery;
            }

            combinedTemp += (packagingPriceToUse || 0) * cartItem.quantity;
          }
        });
      });
    });

    return roundDecimal(combinedTemp);
  }, [productCategories, bill, isTakeAway]);

  const totalDeliveryPrice = useMemo(() => {
    let totalDeliveryPriceTemp;
    // Takeaway
    if (isTakeAway) {
      totalDeliveryPriceTemp = 0;
    }
    // If havent yet received delivery price
    else if (!isDeliveryPriceReceived) {
      totalDeliveryPriceTemp = 0;
    } else {
      totalDeliveryPriceTemp = providerDeliveryPrice || 0;
    }

    return totalDeliveryPriceTemp;
  }, [
    deliveryRestaurant,
    isTakeAway,
    isDeliveryPriceReceived,
    ignoreProviderPrice,
    providerDeliveryPrice,
  ]);

  let totalReachMinimalPrice = 0;

  if (isTakeAway) {
    totalReachMinimalPrice =
      deliveryRestaurant.minTakeawayOrder > subtotal
        ? (deliveryRestaurant.minTakeawayOrder || 0) - subtotal
        : 0;
  } else {
    totalReachMinimalPrice =
      deliveryRestaurant.minDeliveryOrder > subtotal
        ? (deliveryRestaurant.minDeliveryOrder || 0) - subtotal
        : 0;
  }

  let totalCouponDiscount = 0;
  if (appliedCoupon) {
    if (appliedCoupon.onProducts) {
      totalCouponDiscount = calculateCouponOnProducts({ coupon: appliedCoupon, cart: bill });
    } else {
      totalCouponDiscount = calculateCouponOnOrder({
        coupon: appliedCoupon,
        total: roundDecimal(subtotal + totalReachMinimalPrice + totalPackagingPrice),
      });
    }
  }

  let total =
    subtotal +
    (tipsAmount || 0) -
    totalDiscount +
    totalDeliveryPrice +
    totalReachMinimalPrice +
    totalPackagingPrice -
    totalCouponDiscount;

  if (total < 0) {
    total = 0;
  }

  return {
    total,
    subtotal,
    totalDiscount,
    totalPackagingPrice,
    totalDeliveryPrice,
    totalReachMinimalPrice,
    totalCouponDiscount,
  };
};
