import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TbClockHour5 } from 'react-icons/tb';
import TextInput, { inputType } from '../../../../../components/inputs/textInput';
import { formatDayMinutes, getWeekDay } from '../../../../../utils/dateHelpers';
import { useInterval } from '../../../../../hooks/useInterval';
import { useDeliveryColors } from '../../../../../hooks/useDeliveryColors';
import { IsRestaurantOpen } from '../../../../checkout/services/deliveryTimeHelpers';

const MenuInfoDelivery = ({ onSearch, deliveryRestaurant }) => {
  const { t } = useTranslation(['translation', 'delivery']);
  const colors = useDeliveryColors();

  const [isClosed, setIsClosed] = useState(false);
  const [timeOptionsRefresher, setTimeOptionsRefresher] = useState(0);

  useInterval(
    () => {
      setTimeOptionsRefresher((prev) => prev + 1);
    },
    30000,
    false,
  );

  // On load, check if current day is possible
  useEffect(() => {
    const isOpen = IsRestaurantOpen({ deliveryRestaurant });
    setIsClosed(!isOpen);
  }, [timeOptionsRefresher, deliveryRestaurant]);

  const [searchTerm, setSearchTerm] = useState('');

  const formatOpenHoursText = () => {
    if (isClosed) {
      return t(`delivery:menu.restaurantClosed`);
    }

    const currentWeekDay = getWeekDay(deliveryRestaurant.data.timeZone);
    const dayWorkTime = deliveryRestaurant.workTimes.find((x) => x.day === currentWeekDay);

    if (!dayWorkTime) {
      return t(`delivery:menu.restaurantClosed`);
    }

    return `${t(`delivery:menu.restaurantOpen`)} ${formatDayMinutes(
      dayWorkTime.from,
    )} - ${formatDayMinutes(dayWorkTime.until)}`;
  };

  return (
    <div className="py-5 px-5 border-b-[1px] border-[#DCDAE2]">
      <div className="max-w-6xl mx-auto">
        <div className="flex xs:flex-row flex-col xs:items-center justify-between">
          <div
            className="flex flex-row flex-wrap font-primaryMedium text-base"
            style={{ color: colors.primary }}
          >
            <div className="mr-10 flex flex-row items-center">
              <TbClockHour5 className="mr-1 text-xl" /> <span>{formatOpenHoursText()}</span>
            </div>
            {/* <div className="mr-10 flex flex-row items-center cursor-pointer">
              <TbInfoCircle className="mr-1 text-xl" /> <span>{t(`delivery:menu.seeMoreInformation`)}</span>
              </div> */}
          </div>
          <div className="xs:w-72 mt-4 xs:mt-0">
            <TextInput
              id="menu-search"
              placeholder={t(`delivery:menu.search`)}
              onChange={(val) => {
                setSearchTerm(val);
                onSearch(val);
              }}
              value={searchTerm}
              type={inputType.search}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuInfoDelivery;
