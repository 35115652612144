import { useNavigate } from 'react-router-dom';

export const navigateRef = {
  navigate: null,
  push: (page, ...rest) => navigateRef.navigate(page, ...rest),
};

export const createExternalNavigation = () => {
  navigateRef.navigate = useNavigate();
};
