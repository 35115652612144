export const mapApiToDataTable = ({ table }) => {
  if (!table) {
    return null;
  }

  return {
    id: table._id,
    number: table.number,
    type: table.type,
    eventsChannel: table.events_channel,
    eventsGroup: table.events_group,
    eventsUser: table.events_user,
    eventsUserToken: table.events_user_token,
    section: table.area_name,
  };
};
