import { DiscountMenuService } from '../../../services/discountMenuService/discountMenuService';
import { sortObjectArray } from '../../../utils/arrayHelpers';
import { roundDecimal } from '../../../utils/roundingHelpers';
import { isProductInSchedule } from './schedule';

export const mapApiToDataProducts = ({
  products,
  initialCategories,
  discounts,
  timezone,
  provider,
  integrationId,
  section,
  restaurantId,
  variationsLoaded,
  variationJoins,
}) => {
  if (!products) {
    return {};
  }

  const discountMenuService = new DiscountMenuService({
    discounts,
    section,
    provider,
    integrationId,
    timezone,
    restaurant: restaurantId,
  });

  const categories = groupProducts({
    products,
    initialCategories,
    timezone,
    discountMenuService,
    restaurantId,
    variationsLoaded,
    variationJoins,
  });

  return { categories, discountMenuService };
};

// This is local refresh, not calling db
export const mapApiToDataProductsOnAutoRefresh = ({
  products,
  initialCategories,
  discounts,
  timezone,
  discountMenuService,
  restaurantId,
  currentProductCategories,
  variationJoins,
}) => {
  // reset applicable discounts
  discountMenuService.discounts = discountMenuService._filterApplicableDiscounts(discounts);

  const categories = groupProducts({
    products,
    initialCategories,
    timezone,
    discountMenuService,
    restaurantId,
    currentProductCategories,
    variationJoins,
  });

  return { categories };
};

export const mapApiToDataProductVariations = ({
  productIds,
  variations,
  variationJoins,
  allProducts,
}) => {
  const mappedVariationsByProduct = {};

  if (!variations || variations.length === 0) {
    if (productIds) {
      productIds.forEach((productId) => {
        mappedVariationsByProduct[productId] = [];
      });
    }

    return { mappedVariationsByProduct };
  }

  const groupedByProducts = variations.reduce((prevState, currentVal) => {
    prevState[currentVal.product] = prevState[currentVal.product] || [];
    prevState[currentVal.product].push(currentVal);
    return prevState;
  }, Object.create(null));

  Object.entries(groupedByProducts).forEach(([productId, productVariations]) => {
    const productVariationJoins = variationJoins ? variationJoins[productId] : null;

    let mappedVariations = [];
    if (productVariationJoins && productVariationJoins.length > 0) {
      productVariationJoins.forEach((productJoinsVariation) => {
        const mappedVariation = mapSingleJoinsVariation({
          joinsVariation: productJoinsVariation,
          variations: productVariations,
          allProducts,
        });

        if (mappedVariation) {
          mappedVariations.push(mappedVariation);
        }
      });
    } else {
      mappedVariations = productVariations
        ? productVariations
            .filter((x) => !x.hidden && !x.hidden_delivery)
            .map((variation) =>
              mapSingleVariation({ variation, allProducts: variation.combo_products }),
            )
        : null;
    }

    if (mappedVariations) {
      sortObjectArray(mappedVariations, 'order', 1, true);
    }

    mappedVariationsByProduct[productId] = mappedVariations;
  });

  return { mappedVariationsByProduct };
};

const mapSingleProduct = ({
  product,
  discountMenuService,
  allProducts,
  variationsLoaded,
  currentMappedProduct,
  isComboProductMapping,
  variationJoins,
}) => {
  const formedProduct = {
    id: product._id,
    name: product.name,
    listImage: product.list_image,
    listImageResized: product.list_image_resized,
    listImageResizedBig: product.list_image_resized_big,
    coverImage: product.cover_image,
    coverImageResized: product.cover_image_resized,
    description: product.description,
    nutrition: product.nutrition,
    prepareFrom: product.prepare_from,
    prepareTo: product.prepare_to,
    isLongDelivery: product.is_long_delivery,
    longDeliveryPrepare: product.long_delivery_prepare,
    isNew: product.is_new,
    isPopular: product.is_popular,
    isVegetarian: product.is_vegetarian,
    isAgeRestricted: product.is_age_restricted,
    isRecommended: product.is_recommended,
    price: product.prime_cost, // calculated below
    priceBefore: product.prime_cost, // calculated below
    packagingPrice: product.package_cost,
    packagingPriceDelivery: product.package_cost_delivery,
    spicyLevel: product.spicy,
    order: product.order || 0,
    classifications: product.classifications,
    categories: product.categories,
    tags: product.tags,
    discountPercent: 0, // calculated below
    variationsLoaded: false, // resolved below
    variations: null, // resolved below
    schedule: product.schedule,
  };

  const productVariationJoins = variationJoins ? variationJoins[product._id] : null;

  // resolve variations
  if (isComboProductMapping) {
    formedProduct.variationsLoaded = true;
    formedProduct.variations = [];
  } else if (currentMappedProduct) {
    formedProduct.variationsLoaded = currentMappedProduct.variationsLoaded || false;
    formedProduct.variations = currentMappedProduct.variations;
  } else if (productVariationJoins && productVariationJoins.length > 0) {
    formedProduct.variationsLoaded = variationsLoaded;
    const mappedVariations = [];

    productVariationJoins.forEach((productJoinsVariation) => {
      const mappedVariation = mapSingleJoinsVariation({
        joinsVariation: productJoinsVariation,
        variations: product.variations,
        allProducts,
      });

      if (mappedVariation) {
        mappedVariations.push(mappedVariation);
      }
    });

    formedProduct.variations = mappedVariations;
  } else {
    formedProduct.variationsLoaded = variationsLoaded;
    formedProduct.variations = product.variations
      ? product.variations
          .filter((x) => !x.hidden && !x.hidden_delivery)
          .map((variation) => mapSingleVariation({ variation, allProducts }))
      : null;
  }

  // Check discounts
  const appliedDiscounts = discountMenuService
    ? discountMenuService.applyProductDiscount({ product: formedProduct })
    : null;

  if (appliedDiscounts && appliedDiscounts.length > 0) {
    let totalDiscounted = 0;

    appliedDiscounts.forEach((discount) => {
      totalDiscounted += discount.sum;
    });

    if (totalDiscounted > 0) {
      formedProduct.discountPercent = roundDecimal((totalDiscounted * 100) / formedProduct.price);
      formedProduct.price = roundDecimal(formedProduct.price - totalDiscounted);
    }
  }

  formedProduct.minimumPrice = calculateMinimumProductPrice(formedProduct);

  if (formedProduct.variations) {
    sortObjectArray(formedProduct.variations, 'order', 1, true);
  }

  return formedProduct;
};

const mapSingleJoinsVariation = ({ joinsVariation, variations, allProducts }) => {
  const mapped = {
    id: joinsVariation._id,
    isJoin: true,
    type: joinsVariation.variation_type,
    required: joinsVariation.required,
    order: joinsVariation.order,
    name: joinsVariation.name,
    hint: joinsVariation.hint,
    minOfMulti: joinsVariation.min_of_multi,
    maxOfMulti: joinsVariation.max_of_multi,
    items: joinsVariation.items
      ? joinsVariation.items
          .filter((x) => !x.hidden && !x.hidden_delivery)
          .map((item) => {
            if (item.is_product) {
              const comboProduct =
                item.combo_product && allProducts
                  ? allProducts.find((x) => x._id === item.combo_product)
                  : null;

              if (item.combo_product && !comboProduct) {
                return null;
              }

              if (comboProduct && comboProduct.is_out_of_stock) {
                return null;
              }

              const mappedComboProduct = comboProduct
                ? mapSingleProduct({
                    product: comboProduct,
                    discountMenuService: null,
                    allProducts,
                    isComboProductMapping: true,
                  })
                : null;

              if (!mappedComboProduct) {
                return null;
              }

              const priceToUse = item.override_price
                ? item.price || 0
                : mappedComboProduct.priceBefore || 0;

              return {
                id: item.id,
                name: item.name,
                price: priceToUse,
                comboProduct: mappedComboProduct,
                variationId: joinsVariation._id,
              };
            }

            const actualVariation = (variations || []).find(
              (x) => x.rkeeper_group_id === item.rkeeper_group_id,
            );
            if (!actualVariation) {
              return null;
            }

            const actualVariationItem = (actualVariation.items || []).find(
              (x) => x.rkeeper_code === item.rkeeper_code,
            );
            if (!actualVariationItem) {
              return null;
            }

            if (actualVariationItem.is_out_of_stock) {
              return null;
            }

            const priceToUse = item.override_price
              ? item.price || 0
              : actualVariationItem.price || 0;

            return {
              id: actualVariationItem.id,
              name: item.name,
              price: priceToUse,
              variationId: actualVariation._id,
            };
          })
          .filter((x) => !!x)
      : null,
  };

  if (!mapped.items || mapped.items.length === 0) {
    return null;
  }

  return mapped;
};

const mapSingleVariation = ({ variation, allProducts }) => {
  return {
    id: variation._id,
    type: variation.variation_type,
    required: variation.required,
    order: variation.order,
    name: variation.name,
    hint: variation.hint,
    minOfMulti: variation.min_of_multi,
    maxOfMulti: variation.max_of_multi,
    items: variation.items
      ? variation.items
          .filter((x) => !x.hidden && !x.hidden_delivery && !x.is_out_of_stock)
          .map((item) => {
            const comboProduct =
              item.combo_product && allProducts
                ? allProducts.find((x) => x._id === item.combo_product)
                : null;

            if (item.combo_product && !comboProduct) {
              return null;
            }

            if (comboProduct && comboProduct.is_out_of_stock) {
              return null;
            }

            return {
              id: item.id,
              name: item.name,
              price: item.price,
              comboProduct: comboProduct
                ? mapSingleProduct({
                    product: comboProduct,
                    discountMenuService: null,
                    allProducts,
                    isComboProductMapping: true,
                  })
                : null,
            };
          })
          .filter((x) => !!x)
      : null,
  };
};

const mapSingleCategory = ({ category, isEmpty }) => {
  if (isEmpty) {
    return {
      id: '_other',
      name: {},
      order: 9999999,
      products: [],
    };
  }

  const formedCategory = {
    id: category._id,
    name: category.name,
    order: category.order || 9999998,
    products: [],
  };

  if (category.group_category && category.group_category._id) {
    formedCategory.group_category = category.group_category;
  }

  return formedCategory;
};

const groupProducts = ({
  products,
  initialCategories,
  timezone,
  discountMenuService,
  restaurantId,
  variationsLoaded,
  currentProductCategories,
  variationJoins,
}) => {
  if (!products || products.length === 0) {
    return null;
  }

  const allCategories = [];
  let otherCategory = null;

  products.forEach((product) => {
    /*
    if (!product.prime_cost) {
      return;
    } */

    // TODO: get hidden products from aggregate
    if (product.hidden || product.is_out_of_stock || product.display_only_as_variation) {
      return;
    }

    if (
      product.hidden_in_restaurants &&
      product.hidden_in_restaurants.length > 0 &&
      restaurantId &&
      product.hidden_in_restaurants.includes(restaurantId)
    ) {
      return;
    }

    if (!isProductInSchedule(product, timezone)) {
      return;
    }

    if (product.categories && product.categories.length > 0) {
      product.categories.forEach((categoryId) => {
        const category = initialCategories
          ? initialCategories.find((x) => x._id === categoryId)
          : null;

        if (!category || category.hidden) {
          return;
        }

        let currentCategory = allCategories.find((x) => x.id === category._id);

        if (!currentCategory) {
          currentCategory = mapSingleCategory({ category });
          allCategories.push(currentCategory);
        }

        currentCategory.products.push(
          mapSingleProduct({
            product,
            discountMenuService,
            allProducts: products,
            variationsLoaded,
            currentMappedProduct: retrieveProductFromAlreadyMapped({
              productCategories: currentProductCategories,
              categoryId,
              productId: product._id,
            }),
            variationJoins,
          }),
        );
      });
    } else {
      if (!otherCategory) {
        otherCategory = mapSingleCategory({ isEmpty: true });
      }

      otherCategory.products.push(
        mapSingleProduct({
          product,
          discountMenuService,
          allProducts: products,
          variationsLoaded,
          currentMappedProduct: retrieveProductFromAlreadyMapped({
            productCategories: currentProductCategories,
            categoryId: '_other',
            productId: product._id,
          }),
          variationJoins,
        }),
      );
    }
  });

  // can't extract grouping categories at first, because want to sort inside them the containing categories
  // by their own order
  sortObjectArray(allCategories, 'name', 1, false);
  sortObjectArray(allCategories, 'order', 1, true);

  const finalAllCategories = [];

  // go over categories again, extract grouping categories
  allCategories.forEach((mappedCategory) => {
    if (mappedCategory.group_category && mappedCategory.group_category._id) {
      const categoryToUse = mappedCategory.group_category;
      if (categoryToUse.hidden) {
        return;
      }

      let currentCategory = finalAllCategories.find((x) => x.id === categoryToUse._id);

      if (!currentCategory) {
        currentCategory = mapSingleCategory({ category: categoryToUse });
        finalAllCategories.push(currentCategory);
      }

      currentCategory.products = [
        ...(currentCategory.products || []),
        ...(mappedCategory.products || []),
      ];
    } else {
      finalAllCategories.push(mappedCategory);
    }
  });

  sortObjectArray(finalAllCategories, 'name', 1, false);
  sortObjectArray(finalAllCategories, 'order', 1, true);

  if (otherCategory) {
    finalAllCategories.push(otherCategory);
  }

  finalAllCategories.forEach((category) => {
    sortObjectArray(category.products, 'order', 1, true);
  });

  return finalAllCategories;
};

const retrieveProductFromAlreadyMapped = ({ productCategories, categoryId, productId }) => {
  if (!productCategories || productCategories.length === 0) {
    return null;
  }

  const currentCategory = productCategories.find((x) => x.id === categoryId);
  if (!currentCategory || !currentCategory.products) {
    return null;
  }

  const currentProduct = currentCategory.products.find((x) => x.id === productId);
  return currentProduct;
};

// If product price is 0, calculate minimum price from
const calculateMinimumProductPrice = (product) => {
  if (product.price > 0) {
    return 0;
  }

  let total = 0;
  if (product.variations && product.variations.length > 0) {
    product.variations.forEach((variation) => {
      if (!variation.required) {
        return;
      }

      if (!variation.items || variation.items.length === 0) {
        return;
      }

      let minPrice = 999999;
      variation.items.forEach((item) => {
        if (item.price < minPrice) {
          minPrice = item.price;
        }
      });

      if (minPrice !== 999999) {
        total += minPrice;
      }
    });
  }

  return total;
};
