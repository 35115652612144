import React from 'react';
import { useTranslation } from 'react-i18next';
import Divider from '../../../components/misc/divider';
import { PriceUnderline } from '../../../components/svg/textDecorations';
import { useCurrency } from '../../../hooks/useCurrency';
import Loader from '../../../components/misc/loader';

const CheckoutTotal = ({
  subtotal,
  total,
  discount,
  tips,
  delivery,
  minimalRemaining,
  packaging,
  noDivider,
  couponCode,
  couponDiscount,

  isDelivery,
  isDeliveryPriceLoading,
  isDeliveryRequiresAddress,
  isDeliveryProviderMissing,
}) => {
  const { t } = useTranslation(['translation', 'delivery']);
  const { currencySymbol } = useCurrency();

  const renderDeliveryRow = () => {
    if (isDeliveryPriceLoading) {
      return (
        <div>
          <Loader tiny />
        </div>
      );
    }

    if (isDeliveryRequiresAddress) {
      return (
        <p className="font-primaryMedium text-base text-primary whitespace-nowrap">
          <span className="text-primaryOrange whitespace-normal text-right inline-block">
            {t('delivery:checkout.provideDeliveryAddress')}
          </span>
        </p>
      );
    }

    return (
      <p className="font-primaryMedium text-base text-primary whitespace-nowrap">
        {(delivery || 0).toFixed(2)} {currencySymbol}
      </p>
    );
  };

  return (
    <div>
      {subtotal > 0 && (
        <div className="flex flex-row justify-between items-center">
          <p className="font-primaryMedium text-base text-primary pr-2">
            {t('translation:checkout.subtotal')}
          </p>
          <p className="font-primaryMedium text-base text-primary whitespace-nowrap">
            {subtotal.toFixed(2)} {currencySymbol}
          </p>
        </div>
      )}
      {/* !!discountCode && (
        <div className="flex flex-row justify-between items-center mt-2">
          <p className="font-primaryMedium text-base text-primary">
            {t('translation:checkout.discountCode')} ({discountCode})
          </p>
          <p className="font-primaryMedium text-base text-primary whitespace-nowrap">
            -{(discount || 0).toFixed(2)} {currencySymbol}
          </p>
        </div>
      ) */}
      {minimalRemaining > 0 && (
        <div className="flex flex-row justify-between items-center mt-2">
          <p className="font-primaryMedium text-base text-primary pr-2">
            {t('delivery:checkout.priceToReachMin')}
          </p>
          <p className="font-primaryMedium text-base text-primary whitespace-nowrap">
            {(minimalRemaining || 0).toFixed(2)} {currencySymbol}
          </p>
        </div>
      )}
      {packaging > 0 && (
        <div className="flex flex-row justify-between items-center mt-2">
          <p className="font-primaryMedium text-base text-primary pr-2">
            {t('delivery:checkout.pricePackaging')}
          </p>
          <p className="font-primaryMedium text-base text-primary whitespace-nowrap">
            {(packaging || 0).toFixed(2)} {currencySymbol}
          </p>
        </div>
      )}
      {isDelivery && !isDeliveryProviderMissing && (
        <div className="flex flex-row justify-between items-center mt-2">
          <p className="font-primaryMedium text-base text-primary pr-2">
            {t('delivery:checkout.priceForDelivery')}
          </p>
          {renderDeliveryRow()}
        </div>
      )}
      {discount > 0 && (
        <div className="flex flex-row justify-between items-center mt-2">
          <p className="font-primaryMedium text-base text-primary pr-2">
            {t('translation:checkout.discount')}
          </p>
          <p className="font-primaryMedium text-base text-primary whitespace-nowrap">
            -{(discount || 0).toFixed(2)} {currencySymbol}
          </p>
        </div>
      )}
      {couponDiscount > 0 && (
        <div className="flex flex-row justify-between items-center mt-2">
          <p className="font-primaryMedium text-base text-primary pr-2">
            {t('delivery:checkout.discountCode')} ({couponCode})
          </p>
          <p className="font-primaryMedium text-base text-primary whitespace-nowrap">
            -{(couponDiscount || 0).toFixed(2)} {currencySymbol}
          </p>
        </div>
      )}
      {tips > 0 && (
        <div className="flex flex-row justify-between items-center mt-2">
          <p className="font-primaryMedium text-base text-primary pr-2">
            {t('translation:checkout.tip')}
          </p>
          <p className="font-primaryMedium text-base text-primary whitespace-nowrap">
            {(tips || 0).toFixed(2)} {currencySymbol}
          </p>
        </div>
      )}
      {(subtotal > 0 || discount > 0 || tips > 0) && <Divider className="mt-5 mb-5" />}
      <div className="flex flex-row justify-between items-center mt-2">
        <p className="font-primaryMedium text-2xl text-primary">
          {t('translation:checkout.total')}
        </p>
        <p className="font-primaryMedium text-2xl text-primary whitespace-nowrap">
          <span className="relative">
            {total.toFixed(2)} {currencySymbol}
            <PriceUnderline />
          </span>
        </p>
      </div>
      {!noDivider && <Divider className="mt-5 mb-4" />}
    </div>
  );
};

export default CheckoutTotal;
