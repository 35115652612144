import { useState, useEffect, useRef } from 'react';

export const useDeliveryCheckoutForm = () => {
  const contentWrapperRef = useRef();
  const movableCheckoutBoxRef = useRef();

  const [, setBannerHeight] = useState(true);
  const [, setBannerWidth] = useState(true);
  const checkoutMarginsRef = useRef({ top: 0, right: 0, topAtBottom: 0 }).current;

  const [isCheckoutFixed, setIsCheckoutFixed] = useState(false);
  const [isCheckoutAbsoluteAtBottom, setIsCheckoutAbsoluteAtBottom] = useState(false);
  const isCheckoutFixedRef = useRef({ value: false }).current;

  const handleScroll = () => {
    const scrollPosition = global.window.scrollY;
    const endOfContent = contentWrapperRef.current.offsetHeight;
    const checkoutHeight = movableCheckoutBoxRef.current.offsetHeight;

    const contentEndFromTop = contentWrapperRef.current.offsetTop + endOfContent;
    const checkoutEndFromTop = scrollPosition + checkoutHeight + 20;

    // position at bottom
    if (checkoutEndFromTop >= contentEndFromTop) {
      setIsCheckoutFixed(false);
      setIsCheckoutAbsoluteAtBottom(true);
      isCheckoutFixedRef.value = false;
      checkoutMarginsRef.topAtBottom = contentEndFromTop - checkoutHeight;
    }
    // fixed position while scrolling
    else if (scrollPosition >= checkoutMarginsRef.top && !isCheckoutFixedRef.value) {
      setIsCheckoutFixed(true);
      setIsCheckoutAbsoluteAtBottom(false);
      isCheckoutFixedRef.value = true;
    }
    // position at top
    else if (scrollPosition < checkoutMarginsRef.top && isCheckoutFixedRef.value) {
      setIsCheckoutFixed(false);
      setIsCheckoutAbsoluteAtBottom(false);
      isCheckoutFixedRef.value = false;
    }
  };

  useEffect(() => {
    handleScroll();
    global.window.addEventListener('scroll', handleScroll);

    return () => {
      global.window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onBannerHeightChange = (newHeight, newWidth) => {
    checkoutMarginsRef.top = parseInt((newHeight * 155) / 229, 10);

    const rightMargin = parseInt((newWidth - 1152) / 2, 10);
    checkoutMarginsRef.right = rightMargin < 20 ? 20 : rightMargin;

    setBannerHeight(newHeight);
    setBannerWidth(newWidth);
  };

  return {
    onBannerHeightChange,
    contentWrapperRef,
    movableCheckoutBoxRef,
    checkoutMarginsRef,
    isCheckoutFixed,
    isCheckoutAbsoluteAtBottom,
  };
};
