export const Config = {
  CODEMASH: {
    PROJECT_ID: process.env.REACT_APP_CODEMASH_PROJECT_ID,
    SECRET_KEY: process.env.REACT_APP_CODEMASH_SECRET_KEY,
    FILE_ACCOUNT_ID: process.env.REACT_APP_CODEMASH_FILE_ID,
    DB_STORAGE_ID: process.env.REACT_APP_CODEMASH_DB_STORAGE_ID,
  },
  TRACKING: {
    URL: process.env.REACT_APP_TRACKING_URL,
  },
  SHOW_LOGS: true,
  DEFAULTS: {
    LANGUAGE: 'en',
    PAGE_SIZE: 20,
    TAXONOMY_PAGE_SIZE: 20,
  },
  LANGUAGES: { LT: 'lt', EN: 'en', RU: 'ru' },
  AVAILABLE_LANGUAGES: ['lt', 'en', 'ru'],
  ORDERED_AVAILABLE_LANGUAGES: ['en', 'lt', 'ru'],
  DATA: {
    LOADING_STATUS: {
      NOT_LOADING: 0,
      INITIAL_LOAD: 1,
      PAGING_LOAD: 2,
    },
    COLLECTIONS: {
      MENU: 'menu',
      TABLES: 'tables',
      ORDERS: 'orders',
      RATINGS: 'client-reviews',
      PAYMENTS: 'order-payments-v2',
      RESTAURANTS: 'restaurants',
      PRODUCT_VARIATIONS: 'product-variations',
      DELIVERY_SETTINGS: 'delivery-settings',
      RESTAURANT_EVENTS: 'restaurant-events',
      RESTAURANT_PROMOTIONS: 'restaurant-promotions',
    },
    TAXONOMIES: {},
    AGGREGATES: {
      GET_TABLE: '309c4e75-1d04-4efc-b74a-c84cb82afd6f',
      GET_TABLE_ORDER: '3c8ca8bc-5dc0-47e6-8201-3874c7034e1a',
      GET_TABLE_USER_ORDERS: '70919738-fdde-4b0d-967f-7e6d1a11c9ae',
      GET_DELIVERY_PAYMENT: 'fe80cc8b-faca-4c77-8847-2fa7002f2271',
      GET_DELIVERY_PAYMENT_BY_EXTERNAL: '97e95a2f-0355-490e-8b06-698c71cb3d0d',
      GET_DELIVERY_MENU_PRODUCTS: 'c8afcbf2-3570-4581-922f-0cc8e60398f5',
      GET_DELIVERY_MENU_PRODUCTS_OPTIMIZED: 'ad477d60-ac92-4ed5-ad5d-475cf5a0cecc',
      GET_PRODUCTS_VARIATIONS: '21267e67-8d09-436b-8d54-7cbf8b627166',
      GET_COMPANY_RESTAURANTS_BY_HOST: '4d3134a2-f2a6-4e7e-bb6a-08e1630218e6',
      GET_DISCOUNT_COUPON: '13a3c649-5954-463c-950b-f28e711fd5d0',
      GET_VARIATION_JOINS: '62f37ea9-8dda-4350-8cd6-f1844ae77a16',
    },
    FUNCTIONS: {
      INITIATE_PAYMENT: '6afe8093-744d-49a8-a51c-620c762278b4',
      UPDATE_PENDING_PAYMENT: '3a50d23d-c9a1-4f82-9975-91545b67aa35',
      CHARGE_AUTHORIZED_PAYMENT: '4674920c-ba99-43e2-9d5a-196f4a2fe89f',
      GET_INVOICE: '4047f23c-ebf2-4eb4-809f-65d29412caf9',
      CHECK_TEST_ACCESS_TOKEN: '9430e7a3-118a-40cc-93a7-ebb1d0101c53',
    },
  },
  CACHE_KEYS: {
    CART: '@Waitless-Cart',
    CART_DELIVERY: '@Waitless-Cart-Delivery-',
    DEFAULT_DELIVERY_TYPE: '@Waitless-Default-Delivery-Type',
    SELECTED_APP_LANGUAGE: '@Waitless-App-Lang',
    TEST_FEATURES_ACCESS: '@Waitless-Test-Access-',
  },
};

export const defaultColors = {
  primary: '#181818',
};
