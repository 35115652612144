import React from 'react';

const Alert = ({ type, message, onClose }) => {
  const successIcon = () => {
    return (
      <div className="inline-flex items-center justify-center flex-shrink-0 w-5 h-8 text-success rounded-lg">
        <svg
          aria-hidden="true"
          className="w-5 h-5 mt-[1px]"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    );
  };

  const warningIcon = () => {
    return (
      <div className="inline-flex items-center justify-center flex-shrink-0 w-5 h-8 text-orange-300">
        <svg
          aria-hidden="true"
          className="w-5 h-5 mt-[1px]"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    );
  };

  const errorIcon = () => {
    return (
      <div className="inline-flex items-center justify-center flex-shrink-0 w-5 h-8 text-red-300">
        <svg
          aria-hidden="true"
          className="w-5 h-5 mt-[1px]"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    );
  };

  const renderIcon = () => {
    if (type === 'warning') {
      return warningIcon();
    }

    if (type === 'error') {
      return errorIcon();
    }

    return successIcon();
  };

  return (
    <div className="fixed flex justify-center bottom-0 left-1/2 -translate-x-1/2 w-full px-5 sm:w-auto z-[9999]">
      <div
        className="flex items-center w-full max-w-sm p-4 mb-4 text-white bg-primary rounded-lg shadow border border-white"
        role="alert"
      >
        {renderIcon()}
        <div className="ml-3 mr-3 text-sm font-primaryRegular">{message}</div>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 inline-flex h-8 w-8 text-white hover:text-white bg-gray-800 hover:bg-gray-700"
          data-dismiss-target="#toast-success"
          aria-label="Close"
          onClick={onClose}
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Alert;
