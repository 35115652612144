import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useElements, useStripe, ExpressCheckoutElement } from '@stripe/react-stripe-js';
import Loader from '../../../../components/misc/loader';

const StripeExpressForm = ({ amount, onPay, onFail, clientSecret, disabled, isValidOnSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { restaurantSlug } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  const [isClicked, setIsClicked] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const onReady = ({ availablePaymentMethods }) => {
    if (!availablePaymentMethods) {
      // No buttons will show
    } else {
      // Optional: Animate in the Element
      setIsLoaded(true);
    }
  };

  // 1. On button click
  const onClick = ({ resolve }) => {
    if (!isValidOnSubmit()) {
      return;
    }

    setIsClicked(true);

    const options = {
      emailRequired: false,
      phoneNumberRequired: false,
      shippingAddressRequired: false,
      billingAddressRequired: false,
    };

    resolve(options);
  };

  // On payment cancel
  const onCancel = () => {
    setIsClicked(false);
  };

  // 2. On confirmed payment outside of Waitless
  const onConfirmOutside = async () => {
    if (!stripe) {
      return;
    }

    setIsConfirming(true);
    setIsClicked(false);

    // Create payment intent
    onPay();
  };

  const buildRedirectUrl = () => {
    if (restaurantSlug) {
      return `/${restaurantSlug}/payment`;
    }

    return '/payment';
  };

  // 3. After payment intent created, confirm it
  const handlePaymentIntent = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${global.window.location.origin}${buildRedirectUrl()}`,
      },
    });

    if (error) {
      const errorMessage = error.message;

      onFail(errorMessage);
      setIsConfirming(false);
    } else {
      // Customer is redirected to `return_url`
    }
  };

  useEffect(() => {
    if (clientSecret && isConfirming) {
      handlePaymentIntent();
    }
  }, [clientSecret]);

  return (
    <div>
      {!isLoaded && <Loader small />}
      {isClicked && (
        <div className="fixed z-[100] bg-white opacity-80 top-0 bottom-0 left-0 right-0 h-full">
          <Loader />
        </div>
      )}
      <div
        className={`mt-3 mb-5 ${amount <= 0 || disabled ? 'opacity-60 pointer-events-none' : ''}`}
        style={{ visibility: isLoaded ? 'initial' : 'hidden' }}
      >
        <ExpressCheckoutElement
          onConfirm={onConfirmOutside}
          onReady={onReady}
          onClick={onClick}
          onCancel={onCancel}
          options={{
            wallets: {
              applePay: 'always',
              googlePay: 'always',
            },
            buttonHeight: 44,
            buttonTheme: {
              googlePay: 'black',
              applePay: 'black',
            },
            buttonType: {
              googlePay: 'plain',
              applePay: 'plain',
            },
            layout: {
              type: 'vertical',
              visibleButtonCount: 4,
            },
            paymentMethodOrder: ['googlePay', 'applePay', 'paypal', 'link'],
          }}
        />
      </div>
    </div>
  );
};

export default StripeExpressForm;
