export const mapApiToDataDiscountCoupon = ({ coupon }) => {
  if (!coupon) {
    return null;
  }

  return {
    id: coupon._id,
    coupon: coupon.coupon,
    percent: coupon.percent,
    fixed: coupon.fixed,
    useFixed: coupon.use_fixed,
    validFrom: coupon.valid_from,
    validUntil: coupon.valid_until,
    days: coupon.days,
    dayMinutesFrom: coupon.day_minutes_from,
    dayMinutesUntil: coupon.day_minutes_until,
    onProducts: coupon.apply_on_products,
    applicableProducts: coupon.applicable_products
      ? coupon.applicable_products.map((x) => {
          return {
            products: x.products,
            categories: x.categories,
            percent: x.percent,
            useFixed: x.use_fixed,
            fixed: x.fixed,
            minQuantity: x.min_quantity,
            applyOnQuantity: x.apply_on_quantity,
            canRepeat: x.can_repeat_quantity_apply,
          };
        })
      : null,
  };
};
