import React from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';

const NumberInput = ({ onDown, onUp, number, customColor }) => {
  const renderButton = (symbol, onClick) => {
    return (
      <button
        type="button"
        className="w-9 h-9 bg-white flex items-center justify-center rounded-full"
        onClick={onClick}
      >
        {symbol}
      </button>
    );
  };

  return (
    <div
      className={`relative w-full rounded-[50px] border border-black
         h-16 bg-black flex flex-row items-center px-2`}
      style={{
        background: customColor || undefined,
        borderColor: customColor || undefined,
      }}
    >
      {renderButton(
        <HiMinus size={18} className="text-primary" style={{ color: customColor || undefined }} />,
        onDown,
      )}
      <div className="font-primaryMedium text-base text-white w-8 text-center">{number}</div>
      {renderButton(
        <HiPlus size={18} className="text-primary" style={{ color: customColor || undefined }} />,
        onUp,
      )}
    </div>
  );
};

export default NumberInput;
