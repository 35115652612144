import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeId } from '../../../../utils/stringHelper';

const ZenPaymentContainer = ({ onPay, paymentKey, onSetButtonLoading, cardFormSubmitRef }) => {
  const [oldPaymentKey, setOldPaymentKey] = useState(null);
  const navigate = useNavigate();
  const { restaurantSlug } = useParams();

  const waitlessPaymentIdRef = useRef({ value: null }).current;
  const [currentPaymentTrackId, setCurrentPaymentTrackId] = useState(null);

  const buildRedirectUrl = () => {
    if (restaurantSlug) {
      return `/${restaurantSlug}/payment`;
    }

    return '/payment';
  };

  useEffect(() => {
    if (paymentKey && currentPaymentTrackId) {
      if (!paymentKey.startsWith(currentPaymentTrackId)) {
        return;
      }

      const adjustedPaymentKey = paymentKey.replace(`${currentPaymentTrackId}~`, '');

      // If payment key was not updated
      if (adjustedPaymentKey === oldPaymentKey) {
        return;
      }

      // cache this payment key
      setOldPaymentKey(adjustedPaymentKey);

      const parts = adjustedPaymentKey.split('_||_');

      const callbacks = {
        cancelCallback: () => {
          onSetButtonLoading(false);
        },
        successCallback: () => {
          navigate(
            `${buildRedirectUrl()}?paymentId=${
              waitlessPaymentIdRef.value
            }&statusGroup=succeeded&custom=true`,
          );
        },
        failureCallback: () => {
          onSetButtonLoading(false);
        },
      };

      const configParams = {
        overlayBackgroundColor: 'rgba(0,0,0,0.32)',
        containerBackgroundColor: '#F7F6F8',
        containerBorderRadius: '10px',
        containerBorderColor: '1px solid #D3D4DC',
        containerBoxShadow: '0px 4px 34px 0px rgba(0, 0, 0, 0.25)',
        topBarBackgroundColor: '#FFFFFF',
        topBarFontColor: '#181818',
        topBarCloseIconColor: '#181818',
        inputBackgroundColor: '#FFFFFF',
        inputPrefixIconColor: '#b3b3b3',
        inputErrorColor: '#FF5622',
        inputFontColor: '#181818',
        inputPlaceholderFontColor: '#a8a8a8',
        inputBorderRadius: '3px',
        inputBorderColor: '1px solid #d3d4dc',
        inputBoxShadow: 'unset',
        buttonBackgroundColor: '#181818',
        buttonFontColor: '#FFFFFF',
        buttonBorderRadius: '100px',
        buttonBorderColor: 'unset',
        buttonBoxShadow: 'unset',
        fontAndIconColor: '#89829D',
        statusSuccessIconColor: '#0C8048',
        statusFailureIconColor: '#FF5622',
        statusPendingIconColor: '#0C8048',
        statusTextColor: '#181818',
      };

      const checkoutId = parts[0];
      const waitlessPaymentId = parts[1];

      waitlessPaymentIdRef.value = waitlessPaymentId;

      const checkoutForm = new global.ZenCheckout(checkoutId, callbacks, configParams);
      checkoutForm.paymentPopup();
    }
  }, [paymentKey]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    waitlessPaymentIdRef.value = null;

    const paymentTrackId = makeId(10);
    setCurrentPaymentTrackId(paymentTrackId);

    onSetButtonLoading(true);
    onPay({ paymentTrackId });
  };

  return <form className="hidden" ref={cardFormSubmitRef} onSubmit={handleSubmit} />;
};

export default ZenPaymentContainer;
