import React, { useContext } from 'react';
import { Context as AlertContext } from '../../context/alert';
import Alert from '../../components/misc/alert';
import FooterDelivery from './footer';

const LayoutDelivery = ({ children, noFooter, noFooterMarginTop }) => {
  const { state: alertState, alertController } = useContext(AlertContext);

  return (
    <div className="flex flex-col justify-between mx-auto w-full h-screen">
      <div className="flex flex-col w-full flex-1">{children}</div>
      {alertState.isVisible && (
        <Alert
          message={alertState.text}
          type={alertState.type}
          onClose={() => alertController.hide()}
        />
      )}
      {!noFooter && <FooterDelivery noMarginTop={noFooterMarginTop} />}
    </div>
  );
};

export default LayoutDelivery;
