import { useMemo } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { activeTable } from '../services/tableService';

const defaultCurrency = 'EUR';

export function useCurrency() {
  const { symbol, code } = useMemo(() => {
    const currencyToUse = activeTable.currency || defaultCurrency;

    if (!currencyToUse) {
      return { symbol: '', currencyCode: defaultCurrency };
    }

    const currencySymbol = getSymbolFromCurrency(currencyToUse.toUpperCase());

    if (!currencySymbol) {
      return { symbol: '', currencyCode: currencyToUse.toUpperCase() };
    }

    return { symbol: currencySymbol, code: currencyToUse.toUpperCase() };
  }, [activeTable.currency]);

  return { currencySymbol: symbol, currencyCode: code };
}
