import React, { useContext, useMemo, useRef, useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowBackIos } from 'react-icons/md';
import { RiMenuFill } from 'react-icons/ri';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import LanguageDropDown from '../../components/buttons/language';
import { useCurrency } from '../../hooks/useCurrency';
import { Context as DeliveryContext } from '../../context/delivery';
import { getTranslatedData } from '../../localization';
import { useDeliveryCartTotal } from '../../hooks/useDeliveryCartTotal';
import DeliveryViewOrder from '../../components/buttons/deliveryViewOrder';
import InfoTabs from './infoTabs';

const TopBar = ({
  customTitle,
  onBack,
  showLogo,
  showOrder,
  onShowCart,
  showTypeTabs,
  onBannerHeightChange,
  onTopCartLineHeightChange,
  useStickyNav,
  hasEvents,
  hasPromotions,
  hideBanner,
  restaurantSlug,
}) => {
  const { t } = useTranslation(['translation', 'delivery']);
  const { currencySymbol } = useCurrency();

  const bannerRef = useRef(null);
  const prevBannerSizeRef = useRef({ height: 0, width: 0 }).current;

  const topCartLineRef = useRef(null);
  const prevTopCartLineRef = useRef({ height: 0 }).current;
  const topCartLineClassesApplied = useRef({ value: false }).current;

  const [topCartLineHeight, setTopCartLineHeight] = useState(68);

  // calculate banner height
  useEffect(() => {
    if (hideBanner) {
      return () => {};
    }

    const handleResize = () => {
      if (
        bannerRef.current.offsetHeight !== prevBannerSizeRef.height ||
        bannerRef.current.offsetWidth !== prevBannerSizeRef.width
      ) {
        prevBannerSizeRef.height = bannerRef.current.offsetHeight;
        prevBannerSizeRef.width = bannerRef.current.offsetWidth;

        if (onBannerHeightChange) {
          onBannerHeightChange(prevBannerSizeRef.height, prevBannerSizeRef.width);
        }
      }

      if (
        useStickyNav &&
        (topCartLineRef.current.offsetHeight !== prevTopCartLineRef.height ||
          topCartLineRef.current.offsetWidth !== prevTopCartLineRef.width)
      ) {
        prevTopCartLineRef.height = topCartLineRef.current.offsetHeight;
        prevTopCartLineRef.width = topCartLineRef.current.offsetWidth;

        setTopCartLineHeight(topCartLineRef.current.offsetHeight);

        if (onTopCartLineHeightChange) {
          onTopCartLineHeightChange(
            global.window.innerWidth <= 767 ? 0 : prevTopCartLineRef.height,
          );
        }
      }
    };

    handleResize();
    global.window.addEventListener('resize', handleResize);

    return () => {
      global.window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderMenuItem = (text, route) => {
    return (
      <Link
        key={route}
        to={route}
        className="text-primary text-base font-normal hover:opacity-80 leading-10 inline-block lg:inline w-full lg:w-auto"
      >
        {text}
      </Link>
    );
  };

  const renderMenu = () => {
    const items = [];

    if (hasPromotions || hasEvents) {
      items.push(renderMenuItem(t(`delivery:navigation.menu`), `/${restaurantSlug}`));
    }

    if (hasPromotions && restaurantSlug) {
      items.push(
        renderMenuItem(t(`delivery:navigation.promotions`), `/${restaurantSlug}/promotions`),
      );
    }

    if (hasEvents && restaurantSlug) {
      items.push(renderMenuItem(t(`delivery:navigation.events`), `/${restaurantSlug}/events`));
    }

    if (items.length === 0) {
      return null;
    }

    return (
      <>
        <div className="flex-row hidden lg:flex gap-x-4 xl:gap-x-8 mr-6">{items}</div>
        <Menu as="div" className="lg:hidden mr-4 sm:mr-6 order-1 relative h-9">
          {({ open }) => (
            <>
              <div className="flex items-center h-full">
                <Menu.Button>
                  <RiMenuFill className="text-primary text-2xl" />
                </Menu.Button>
              </div>

              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute right-0 w-36 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100
                    rounded-md shadow-lg outline-none max-h-64 overflow-auto"
                >
                  {items.map((item, ind) => {
                    return (
                      <Menu.Item
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${ind}`}
                        className="w-full py-2 px-4 hover:bg-gray-50 hover:text-primaryOrange"
                      >
                        {item}
                      </Menu.Item>
                    );
                  })}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </>
    );
  };

  const renderedMenu = renderMenu();

  useEffect(() => {
    if (!useStickyNav) {
      return () => {};
    }

    const handleScroll = () => {
      const scrollPosition = global.window.scrollY;
      const blockHeight =
        topCartLineRef && topCartLineRef.current ? topCartLineRef.current.offsetHeight : 0;

      if (scrollPosition > blockHeight) {
        if (topCartLineClassesApplied.value) {
          return;
        }

        topCartLineClassesApplied.value = true;
        if (!renderedMenu) {
          topCartLineRef.current.classList.add('bg-white');
        }
        topCartLineRef.current.classList.add('shadow-sm');
      } else {
        if (!topCartLineClassesApplied.value) {
          return;
        }

        topCartLineClassesApplied.value = false;
        if (!renderedMenu) {
          topCartLineRef.current.classList.remove('bg-white');
        }
        topCartLineRef.current.classList.remove('shadow-sm');
      }
    };

    global.window.addEventListener('scroll', handleScroll);

    return () => {
      global.window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { state: deliveryState } = useContext(DeliveryContext);

  const { totalAfterDiscount, totalInCart } = useDeliveryCartTotal();

  const selectedRestaurant = useMemo(() => {
    if (deliveryState.selectedRestaurantId && deliveryState.restaurants) {
      return deliveryState.restaurants.find((x) => x.id === deliveryState.selectedRestaurantId);
    }

    return null;
  }, [deliveryState.selectedRestaurantId]);

  const hasBanner = selectedRestaurant
    ? !!selectedRestaurant.bannerUrl
    : deliveryState.settings && deliveryState.settings.bannerUrl;
  const hasLogo = selectedRestaurant
    ? false
    : deliveryState.settings && deliveryState.settings.logoUrl;

  const renderCartButton = () => {
    if (!showOrder || totalInCart <= 0) {
      return null;
    }

    return (
      <DeliveryViewOrder
        currencySymbol={currencySymbol}
        onShowCart={onShowCart}
        totalAfterDiscount={totalAfterDiscount}
        totalInCart={totalInCart}
        isDekstop
      />
    );
  };

  const renderTitle = () => {
    if (customTitle) {
      return customTitle;
    }

    if (selectedRestaurant) {
      return getTranslatedData(selectedRestaurant.title);
    }

    if (deliveryState.settings) {
      return getTranslatedData(deliveryState.settings.title);
    }

    return '';
  };

  const renderDescription = () => {
    if (customTitle) {
      if (selectedRestaurant) {
        return getTranslatedData(selectedRestaurant.title);
      }
      if (deliveryState.settings) {
        return getTranslatedData(deliveryState.settings.title);
      }
      return '';
    }

    if (selectedRestaurant) {
      return getTranslatedData(selectedRestaurant.description);
    }

    if (deliveryState.settings) {
      return getTranslatedData(deliveryState.settings.description);
    }

    return '';
  };

  return (
    <>
      {useStickyNav && (
        <div
          className={`absolute h-16 md:h-auto w-full md:sticky top-0 z-50 px-5 transition-all duration-200 ${
            renderedMenu ? 'bg-white mb-8' : 'mt-4'
          }`}
          ref={topCartLineRef}
        >
          <div className="max-w-6xl mx-auto py-3">
            <div className="flex justify-between flex-row items-start sm:items-center min-h-[2.75rem]">
              {onBack ? (
                <button
                  type="button"
                  onClick={onBack}
                  className="flex flex-row justify-center items-center px-5 py-2 rounded-full bg-white max-h-9 cursor-pointer z-10 shadow-md"
                >
                  <MdArrowBackIos />{' '}
                  <div className="mr-4 ml-4 sm:ml-6 sm:mr-8 font-primaryMedium text-primary text-base">
                    {t(`delivery:buttons.back`)}
                  </div>
                </button>
              ) : (
                <div />
              )}
              <div className="flex flex-row items-end sm:items-center">
                {renderMenu()}
                <div className="hidden sm:block z-10">{renderCartButton()}</div>
                <div className="z-10 order-1 sm:order-2">
                  <LanguageDropDown />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!hideBanner && (
        <div
          className={`${
            useStickyNav ? 'pt-24 md:pt-7 pb-7' : 'py-7'
          } px-5 bg-no-repeat bg-center bg-cover relative`}
          style={{
            backgroundImage: hasBanner
              ? `url(${
                  selectedRestaurant
                    ? selectedRestaurant.bannerUrl
                    : deliveryState.settings.bannerUrl
                })`
              : undefined,

            marginTop: useStickyNav ? `calc(-${topCartLineHeight}px - 1rem)` : undefined,
          }}
          ref={bannerRef}
        >
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-30 z-[2]" />
          <div className="max-w-6xl mx-auto">
            {!useStickyNav && (
              <div className="absolute sm:relative left-0 right-0 px-5 sm:px-0 z-[100] flex justify-between flex-row items-start sm:items-center min-h-[2.75rem]">
                {onBack ? (
                  <button
                    type="button"
                    onClick={onBack}
                    className="flex flex-row justify-center items-center px-5 py-2 rounded-full bg-white max-h-9 cursor-pointer z-10 shadow-md"
                  >
                    <MdArrowBackIos />{' '}
                    <div className="mr-4 ml-4 sm:ml-6 sm:mr-8 font-primaryMedium text-primary text-base">
                      {t(`delivery:buttons.back`)}
                    </div>
                  </button>
                ) : (
                  <div />
                )}
                <div className="flex sm:flex-row items-end sm:items-center flex-col">
                  <div className="hidden sm:block z-10">{renderCartButton()}</div>
                  <div className="z-10 order-1 sm:order-2">
                    <LanguageDropDown />
                  </div>
                </div>
              </div>
            )}

            <div className="sm:hidden flex z-10">{renderCartButton()}</div>

            <div
              className={`${
                !useStickyNav ? 'pt-11 sm:pt-0' : ''
              } mt-20 flex flex-row justify-start items-start`}
            >
              {showLogo && hasLogo && (
                <div className="w-20 h-20 overflow-hidden rounded-full flex-shrink-0 z-10 mr-8">
                  <img
                    className="w-full h-full object-cover object-center"
                    src={deliveryState.settings.logoUrl}
                    alt="Logo"
                  />
                </div>
              )}
              <div className="z-10 overflow-hidden w-full xs:w-auto">
                <div className="font-primarySemiBold text-white text-4xl mt-2">{renderTitle()}</div>
                <div
                  className={`${
                    customTitle ? 'mt-2' : 'mt-6'
                  } font-primaryMedium text-base text-white max-w-2xl`}
                >
                  {renderDescription()}
                </div>

                {showTypeTabs &&
                selectedRestaurant &&
                (selectedRestaurant.hasDelivery || selectedRestaurant.hasTakeaway) ? (
                  <InfoTabs selectedRestaurant={selectedRestaurant} />
                ) : (
                  <div className="mb-7" />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopBar;
