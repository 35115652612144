import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context as RestaurantContext } from '../../context/restaurant';
import MainLayout from '../../layouts/mainLayout';
import Loader from '../../components/misc/loader';
import OrderDeliveryMenu from '../../features/menu/components/order/delivery/orderMenu';
import { useDeliveryPageInit } from '../../hooks/useDeliveryPageInit';
import { useDeliveryMenuInit } from '../../hooks/useDeliveryMenuInit';

const MenuDeliveryPage = () => {
  const { pathname } = useLocation();

  const isEvents = pathname.endsWith('/events');
  const isPromotions = pathname.endsWith('/promotions');

  const { isLoadingDeliveryInit, isSelectedRestaurantInit, selectedRestaurant } =
    useDeliveryPageInit();

  useDeliveryMenuInit({
    isLoadingDeliveryInit,
    isSelectedRestaurantInit,
    selectedRestaurant,
  });

  const { state: restaurantState } = useContext(RestaurantContext);

  if (
    isLoadingDeliveryInit ||
    !isSelectedRestaurantInit ||
    restaurantState.loadingMenu ||
    !restaurantState.menuGot ||
    !selectedRestaurant
  ) {
    return <Loader />;
  }

  return (
    <MainLayout noFooterMarginTop={isEvents || isPromotions}>
      <OrderDeliveryMenu
        deliveryRestaurant={selectedRestaurant}
        productCategories={restaurantState.productCategories}
        isEvents={isEvents}
        isPromotions={isPromotions}
      />
    </MainLayout>
  );
};

export default MenuDeliveryPage;
