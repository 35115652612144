import { useState, useEffect, useRef } from 'react';
import { createDeliveryPromise } from '../../../api/tracking';
import { roundDecimal } from '../../../utils/roundingHelpers';
import { useInterval } from '../../../hooks/useInterval';
import { currentUtcMillis } from '../../../utils/dateHelpers';

export const useDeliveryPromise = (deliveryRestaurant) => {
  const [selectedDeliveryProvider, setSelectedDeliveryProvider] = useState(null);
  const [isProviderMissing, setIsProviderMissing] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [deliveryOrderId, setDeliveryOrderId] = useState(null);
  const deliveryPromiseValidUntil = useRef({ value: null }).current;
  const [deliveryPromiseErrorCode, setDeliveryPromiseErrorCode] = useState(null);
  const [deliveryPromiseErrorFields, setDeliveryPromiseErrorFields] = useState(null);

  const [isDeliveryAddressValid, setIsDeliveryAddressValid] = useState(false);
  const [deliveryAddressErrorCode, setDeliveryAddressErrorCode] = useState(null);
  const [providerDeliveryPrice, setProviderDeliveryPrice] = useState(undefined);

  const currentPromiseKey = useRef({ value: null }).current;
  const currentRequestParams = useRef({ value: null }).current;

  useEffect(() => {
    if (!deliveryRestaurant || selectedDeliveryProvider) {
      return;
    }

    const deliveryProvider =
      deliveryRestaurant.deliveryOptions && deliveryRestaurant.deliveryOptions.length > 0
        ? deliveryRestaurant.deliveryOptions[0]
        : null;

    if (!deliveryProvider || !deliveryProvider.id) {
      setIsProviderMissing(true);
      return;
    }

    setSelectedDeliveryProvider(deliveryProvider);
    setIsProviderMissing(false);
  }, [deliveryRestaurant]);

  // Checks if delivery promise is still valid, regets when its not
  useInterval(
    () => {
      const validUntil = deliveryPromiseValidUntil.value;
      if (!validUntil) {
        return;
      }

      const millisNow = currentUtcMillis();
      if (millisNow < validUntil) {
        return;
      }

      getDeliveryPromiseInner({
        promiseKey: currentRequestParams.value.promiseKey,
        city: currentRequestParams.value.city,
        zipCode: currentRequestParams.value.zipCode,
        deliveryAddress: currentRequestParams.value.deliveryAddress,
        deliveryDate: currentRequestParams.value.deliveryDate,
        isDeliverNow: currentRequestParams.value.isDeliverNow,
      });
    },
    60000,
    false,
  );

  const getDeliveryPromiseInner = async ({
    promiseKey,
    city,
    zipCode,
    deliveryAddress,
    isDeliverNow,
    deliveryDate,
    orderPrice,
  }) => {
    setIsLoading(true);
    setIsDeliveryAddressValid(false);
    setDeliveryPromiseErrorCode(null);
    setDeliveryPromiseErrorFields(null);
    setDeliveryAddressErrorCode(null);
    setDeliveryOrderId(null);
    deliveryPromiseValidUntil.value = null;

    const { error, response } = await createDeliveryPromise({
      restaurantId: deliveryRestaurant.id,
      venueId: selectedDeliveryProvider.id,
      deliveryRestaurantId: deliveryRestaurant.deliveryId,
      city,
      zipCode,
      street: deliveryAddress,
      isDeliverNow,
      deliveryDate,
      orderPrice,
    });

    // Address changed while loading, ignore this response
    if (currentPromiseKey.value !== promiseKey) {
      return;
    }

    if (error) {
      if (error.errorCode === 'Validation') {
        setDeliveryPromiseErrorCode('validation');
        setDeliveryPromiseErrorFields(error.invalidFields); // TODO: show fields under order button
      } else if (error.errorCode === 'Outside_Area') {
        setDeliveryPromiseErrorCode('outside_area');
        setDeliveryPromiseErrorFields(null);
        setDeliveryAddressErrorCode('outside_area');
      } else if (error.errorCode === 'Scheduled_Invalid') {
        setDeliveryPromiseErrorCode('scheduled_invalid');
        setDeliveryPromiseErrorFields(null);
      } else if (error.errorCode === 'Outside_Hours') {
        setDeliveryPromiseErrorCode('outside_hours');
        setDeliveryPromiseErrorFields(null);
      }
      // Add more errors here
      else {
        currentPromiseKey.value = null;
        setDeliveryPromiseErrorCode('unknown');
        setDeliveryPromiseErrorFields(null);
      }
    } else if (response) {
      setDeliveryOrderId(response.deliveryOrderId);
      deliveryPromiseValidUntil.value = response.validUntil;

      if (response.isBinding) {
        setProviderDeliveryPrice(roundDecimal(response.priceCents / 100.0));
        setIsDeliveryAddressValid(true);
      } else {
        setIsDeliveryAddressValid(false);
        setDeliveryAddressErrorCode('inaccurate');
      }
    }

    setIsLoading(false);
  };

  const tryGetDeliveryPromise = async ({
    deliveryAddress,
    city,
    zipCode,
    isDeliverNow,
    deliveryDate,
    orderPrice,
  }) => {
    // console.log('Try get delivery promise');
    if (!deliveryAddress || !city /* || !zipCode */) {
      if (deliveryPromiseErrorCode) {
        setDeliveryPromiseErrorCode(null);
        setDeliveryPromiseErrorFields(null);
        setDeliveryAddressErrorCode(null);
      }
      // console.log('Not all address. Return');
      return;
    }

    if (isProviderMissing || !selectedDeliveryProvider) {
      // console.log('No provider. Return');
      return;
    }

    const promiseKey = `${deliveryAddress || ''}~${city || ''}~${zipCode || ''}~${isDeliverNow}~${
      deliveryDate || ''
    }`;

    // console.log('Promise Key:', promiseKey);
    if (promiseKey === currentPromiseKey.value) {
      // console.log('Same key. Return');
      return;
    }

    currentPromiseKey.value = promiseKey;

    // For background valid date refresher
    currentRequestParams.value = {
      promiseKey,
      city,
      zipCode,
      deliveryAddress,
      isDeliverNow,
      deliveryDate,
    };

    await getDeliveryPromiseInner({
      city,
      zipCode,
      deliveryAddress,
      deliveryDate,
      isDeliverNow,
      orderPrice,
      promiseKey,
    });
  };

  return {
    tryGetDeliveryPromise,
    isDeliveryProviderMissing: isProviderMissing,
    deliveryPromiseErrorCode,
    deliveryPromiseErrorFields,
    isLoadingDeliveryPromise: isLoading,
    isDeliveryAddressValid,
    deliveryAddressErrorCode,
    deliveryOrderId,
    providerDeliveryPrice,
    ignoreProviderPrice: !selectedDeliveryProvider || selectedDeliveryProvider.ignorePrice,
  };
};
