/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Context as AlertContext } from '../../../../context/alert';
import TextInput, { inputType } from '../../../../components/inputs/textInput';
import { useDeliveryColors } from '../../../../hooks/useDeliveryColors';
import MainButton from '../../../../components/buttons/main';
import { getDiscountCoupon } from '../../../../api/payment';
import { isCouponApplicable } from '../../services/deliveryCouponService';

const DiscountCoupon = ({ restaurantId, onApplied, timeZone }) => {
  const { t } = useTranslation(['delivery']);
  const colors = useDeliveryColors();

  const { alertController } = useContext(AlertContext);

  const [couponCode, setCouponCode] = useState('');

  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);

  const onApply = async () => {
    setIsApplyingCoupon(true);

    const foundCoupon = await getDiscountCoupon({
      coupon: couponCode.toLowerCase(),
      restaurantId,
    });

    if (foundCoupon && isCouponApplicable({ coupon: foundCoupon, timeZone })) {
      setCouponCode('');
      onApplied(foundCoupon);
    } else {
      alertController.warning({ text: t('delivery:errors.noDiscountCode') });
    }

    setIsApplyingCoupon(false);
  };

  return (
    <div>
      <TextInput
        id="discount-coupon"
        onChange={setCouponCode}
        value={couponCode}
        type={inputType.formField}
        label={t('delivery:checkout.discountCode')}
        isDelivery
      />
      <div className="mt-2 max-w-[12rem]">
        <MainButton
          onClick={onApply}
          text={t('delivery:buttons.applyDiscount')}
          disabled={!couponCode}
          loading={isApplyingCoupon}
          customColor={colors.hasCustomPrimary ? colors.primary : undefined}
          smallerHeight
          customClass="rounded-md"
        />
      </div>
    </div>
  );
};

export default DiscountCoupon;
