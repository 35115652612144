import { restaurantProviders } from '../../enums/restaurantProviders';
import { restaurantTypes } from '../../enums/restaurantTypes';
import { sortObjectArray } from '../../utils/arrayHelpers';
import { formFileUrlFromFileArray } from '../../utils/fileHelper';

export const mapApiToDataRestaurant = ({ restaurant }) => {
  if (!restaurant) {
    return null;
  }

  return {
    id: restaurant._id,
    name: restaurant.name,
    address: restaurant.address,
    companyId: restaurant.company,
    timeZone: restaurant.zone,
    allowTipsInOrder: restaurant.allow_tips || restaurant.type === restaurantTypes.TipsOnly,
    allowTipsNoOrder: restaurant.allow_tips_alone || restaurant.type === restaurantTypes.TipsOnly,
    allowReviewsNoOrder: restaurant.allow_reviews_alone,
    forceDisableTips: restaurant.force_disable_tips,
    customHomeTitle: restaurant.client_home_title,
    restrictionsAge: restaurant.restrictions_age || 18, // 18 if not set
    reviewLink: restaurant.review_link,
    currency: restaurant.currency,
    location: restaurant.location,
    bannerUrl: formFileUrlFromFileArray(restaurant.banner),
    logoUrl: formFileUrlFromFileArray(restaurant.logo),
    eventsChannel: restaurant.events_channel,
    eventsGroup: restaurant.events_group,
    type: restaurant.type,
    allowTipsWaiterSelect: restaurant.can_select_waiter,
    dontRequireGuests: restaurant.dont_require_guests,
    canTakeOut: restaurant.can_take_out,
    provider: restaurant.rkeeper_code ? restaurantProviders.RKeeper : restaurantProviders.Iiko,
    integrationId: restaurant.integration_id,
    employees: restaurant.employees
      ? restaurant.employees.map((employee) => ({
          id: employee._id,
          firstName: employee.first_name,
          lastName: employee.last_name,
          image: formFileUrlFromFileArray(employee.image),
        }))
      : [],
  };
};

export const mapApiToDataPaymentOption = ({ options }) => {
  if (!options || options.length === 0) {
    return null;
  }

  options = options.filter((x) => !x.hidden_delivery || !x.hidden_takeaway);
  if (!options || options.length === 0) {
    return null;
  }

  const cardOption = selectCorrectCardProvider(options);
  const bankOption = options.find((option) => option.types && option.types.includes('Bank'));

  const additionalWalletOption = selectAdditionalProviderForWallets(options, cardOption);

  const mapped = {
    canPayBank: !!bankOption,
    canPayCard: !!cardOption,
    bankProvider: bankOption ? bankOption.provider : null,
    bankProviderId: bankOption ? bankOption._id : null,
    cardProvider: cardOption ? cardOption.provider : null,
    cardProviderId: cardOption ? cardOption._id : null,

    // Additional for google/apple pay
    additionalWalletProvider: additionalWalletOption ? additionalWalletOption.provider : null,
    additionalWalletProviderId: additionalWalletOption ? additionalWalletOption._id : null,

    stripePublicKey: cardOption ? cardOption.stripe_public_key : null,
    stripeAllowCards: cardOption ? cardOption.stripe_allow_cards || false : false,
    stripeAllowWallets: cardOption ? cardOption.stripe_allow_wallets || false : false,
    stripeIsConnected: cardOption ? cardOption.stripe_is_connected || false : false,
    stripeConnectedId: cardOption ? cardOption.stripe_connected_id : null,
    zenUseRedirect: cardOption ? cardOption.zen_use_redirect || false : false,

    // This is just temporary, still wont be able to add for example 2 different card providers
    hiddenDeliveryOptions: options.filter((x) => !!x.hidden_delivery).map((x) => x._id),
    hiddenTakeawayOptions: options.filter((x) => !!x.hidden_takeaway).map((x) => x._id),
  };

  if (additionalWalletOption && additionalWalletOption.provider === 'Stripe') {
    mapped.stripePublicKey = additionalWalletOption.stripe_public_key;
    mapped.stripeAllowCards = false;
    mapped.stripeAllowWallets = true;
  }

  if (mapped.cardProvider === 'Stripe') {
    // show card possibility by default if nothing is selected
    if (!mapped.stripeAllowCards && !mapped.stripeAllowWallets) {
      mapped.stripeAllowCards = true;
    } else {
      mapped.canPayCard = mapped.stripeAllowCards;
    }
  }

  return mapped;
};

const selectCorrectCardProvider = (options) => {
  const allCardOptions = options.filter((option) => option.types && option.types.includes('Card'));
  if (!allCardOptions || allCardOptions.length === 0) {
    return null;
  }

  // priority providers, in case using different provider for wallets
  const providersPriority = ['Zen'];

  let selectedOption;
  providersPriority.forEach((provider) => {
    if (selectedOption) {
      return;
    }

    selectedOption = allCardOptions.find((x) => x.provider === provider);
  });

  if (!selectedOption) {
    // eslint-disable-next-line prefer-destructuring
    selectedOption = allCardOptions[0];
  }

  return selectedOption;
};

const selectAdditionalProviderForWallets = (options, cardOption) => {
  const allCardOptions = options.filter((option) => option.types && option.types.includes('Card'));
  if (!allCardOptions || allCardOptions.length === 0) {
    return null;
  }

  const allowedProviders = ['Zen'];
  if (!cardOption || !cardOption.provider || !allowedProviders.includes(cardOption.provider)) {
    return null;
  }

  // TODO: Add Paynt
  const allowedWalletProvidersPriority = ['Stripe'];

  let selectedOption;
  allowedWalletProvidersPriority.forEach((provider) => {
    if (selectedOption) {
      return;
    }

    selectedOption = allCardOptions.find((x) => x.provider === provider);
    if (selectedOption && provider === 'Stripe' && !selectedOption.stripe_allow_wallets) {
      selectedOption = null;
    }
  });

  return selectedOption;
};

export const mapApiToDataRestaurantEvents = ({ events }) => {
  if (!events) {
    return null;
  }

  const mappedEvents = events.map((event) => {
    return {
      id: event._id,
      title: event.title,
      imageCover: event.image_cover,
      externalLink: event.external_link,
      order: event.order || 0,
      createdOn: event._meta ? event._meta.createdOn : 0,
    };
  });

  sortObjectArray(mappedEvents, 'createdOn', -1, true);
  sortObjectArray(mappedEvents, 'order', 1, true);

  return mappedEvents;
};

export const mapApiToDataRestaurantPromotions = ({ promotions }) => {
  if (!promotions) {
    return null;
  }

  const mappedPromotions = promotions.map((event) => {
    return {
      id: event._id,
      imageCover: event.image_cover,
      externalLink: event.external_link,
      order: event.order || 0,
      createdOn: event._meta ? event._meta.createdOn : 0,
    };
  });

  sortObjectArray(mappedPromotions, 'createdOn', -1, true);
  sortObjectArray(mappedPromotions, 'order', 1, true);

  return mappedPromotions;
};
