import React from 'react';
import MainLayout from '../layouts/mainLayout';

// If API breaks down
const ServiceUnavailablePage = () => {
  return (
    <MainLayout>
      <div className="mx-auto mt-20">
        <h1 className="font-primaryMedium text-2xl text-primary text-center">
          <b>503</b> - Service temporarily unavailable
        </h1>
      </div>
    </MainLayout>
  );
};

export default ServiceUnavailablePage;
