import i18next from 'i18next';
import {
  currentDayMinutes,
  currentUtcMillis,
  formatDayMinutes,
  getWeekDay,
} from '../../../utils/dateHelpers';

export const getDayOptions = ({ deliveryRestaurant, isTakeAway }) => {
  const { workTimes } = deliveryRestaurant;
  if (!workTimes || workTimes.length === 0) {
    return [];
  }

  const options = [];

  const currentWeekDay = getWeekDay(deliveryRestaurant.data.timeZone);
  for (let i = 0; i < deliveryRestaurant.maxOrderDaysDelta + 1; i += 1) {
    if (i === 0) {
      const anyTimesAvailableToday =
        getTimeOptions({ deliveryDayDelta: 0, deliveryRestaurant, isTakeAway }).length > 0;
      const canOrderNow = IsRestaurantOpenAndCanOrderNow({ deliveryRestaurant, isTakeAway });
      if (!anyTimesAvailableToday && !canOrderNow) {
        continue;
      }
    }

    let dayToCheck = currentWeekDay + i;
    if (dayToCheck > 7) {
      dayToCheck -= 7;
    }

    const dayWorkTime = workTimes.find((x) => x.day === dayToCheck);

    if (dayWorkTime) {
      if (i === 0) {
        options.push({ label: i18next.t('delivery:days.today'), value: i });
      } else if (i === 1) {
        options.push({ label: i18next.t('delivery:days.tomorrow'), value: i });
      } else {
        options.push({ label: i18next.t(`delivery:days.day-${dayToCheck}`), value: i });
      }
    }
  }

  return options;
};

export const getTimeOptions = ({ deliveryRestaurant, deliveryDayDelta, isTakeAway }) => {
  const { workTimes } = deliveryRestaurant;
  if (!workTimes || workTimes.length === 0) {
    return [];
  }

  const options = [];

  const currentWeekDay = getWeekDay(deliveryRestaurant.data.timeZone);
  let selectedWeekDay = currentWeekDay + deliveryDayDelta;
  if (selectedWeekDay > 7) {
    selectedWeekDay -= 7;
  }

  const dayWorkTime = workTimes.find((x) => x.day === selectedWeekDay);

  const firstTimeFrom = isTakeAway
    ? deliveryRestaurant.takeawayNowMax || 30
    : deliveryRestaurant.deliveryNowMax || 60;

  if (dayWorkTime) {
    const firstPossibleTime = roundToNearest5(dayWorkTime.from + firstTimeFrom);

    const lastPossibleTime = roundToNearest5(
      dayWorkTime.until - getMinutesUntilClosure({ deliveryRestaurant, isTakeAway }),
    );

    const currentMinutes = currentDayMinutes(deliveryRestaurant.data.timeZone);

    for (let i = firstPossibleTime; i <= lastPossibleTime; i += 15) {
      if (deliveryDayDelta === 0 && currentMinutes + firstTimeFrom > i) {
        continue;
      }

      options.push({ label: formatDayMinutes(i), value: i });
    }
  }

  return options;
};

export const getDeliveryDateOnDeliverNow = ({ deliveryRestaurant, isTakeAway }) => {
  let minutesToAdd = 0;
  let lowerMinutes = 0;

  if (isTakeAway) {
    if (deliveryRestaurant.takeawayNowMax > 0) {
      minutesToAdd = deliveryRestaurant.takeawayNowMax;

      if (deliveryRestaurant.takeawayNowMin > 0) {
        lowerMinutes = minutesToAdd - deliveryRestaurant.takeawayNowMin;
      } else {
        lowerMinutes = minutesToAdd - 15;
      }
    } else {
      minutesToAdd = 40;
      lowerMinutes = minutesToAdd - 15;
    }
  } else if (deliveryRestaurant.deliveryNowMax > 0) {
    minutesToAdd = deliveryRestaurant.deliveryNowMax;

    if (deliveryRestaurant.deliveryNowMin > 0) {
      lowerMinutes = minutesToAdd - deliveryRestaurant.deliveryNowMin;
    } else {
      lowerMinutes = minutesToAdd - 15;
    }
  } else {
    minutesToAdd = 60;
    lowerMinutes = minutesToAdd - 15;
  }

  if (lowerMinutes < 0) {
    lowerMinutes = 0;
  }

  lowerMinutes *= -1;

  // Additional 2 minutes for order payment
  const deliveryDateUtc = currentUtcMillis() + minutesToAdd * 60000 + 2 * 60000;

  return { deliveryDateUtc, lowerMinutes };
};

export const IsRestaurantOpen = ({ deliveryRestaurant }) => {
  const { isOpen } = getResourcesForOpenCheck({ deliveryRestaurant });
  return isOpen;
};

export const IsRestaurantOpenAndCanOrderNow = ({ deliveryRestaurant, isTakeAway }) => {
  const { isOpen, dayWorkTime, dayMinutes } = getResourcesForOpenCheck({ deliveryRestaurant });
  if (!isOpen) {
    return false;
  }

  const canOrderUntil =
    dayWorkTime.until - getMinutesUntilClosure({ deliveryRestaurant, isTakeAway });

  if (dayMinutes > canOrderUntil) {
    return false;
  } else {
    return true;
  }
};

const getResourcesForOpenCheck = ({ deliveryRestaurant }) => {
  let currentWeekDay = getWeekDay(deliveryRestaurant.data.timeZone);
  let dayMinutes = currentDayMinutes(deliveryRestaurant.data.timeZone);

  let dayWorkTime = deliveryRestaurant.workTimes.find((x) => x.day === currentWeekDay);

  if (!dayWorkTime || dayMinutes < dayWorkTime.from || dayMinutes > dayWorkTime.until) {
    // Check if previous day works after midnight
    currentWeekDay = currentWeekDay === 1 ? 7 : currentWeekDay - 1;
    dayMinutes += 1440;
    dayWorkTime = deliveryRestaurant.workTimes.find((x) => x.day === currentWeekDay);

    if (!dayWorkTime || dayMinutes > dayWorkTime.until) {
      return { isOpen: false };
    } else {
      return { isOpen: true, currentWeekDay, dayMinutes, dayWorkTime };
    }
  } else {
    return { isOpen: true, currentWeekDay, dayMinutes, dayWorkTime };
  }
};

export const adjustOrderDaysForLongDeliveries = ({ productCategories, bill, dayOptions }) => {
  if (
    !bill ||
    !bill.cart ||
    bill.cart.length === 0 ||
    !productCategories ||
    productCategories.length === 0
  ) {
    return null;
  }

  let mostRequiredDays = -1;

  bill.cart.forEach((cartItem) => {
    let foundProductCategory;
    if (!cartItem.categories || cartItem.categories.length === 0) {
      foundProductCategory = productCategories.find((x) => x.id === '_other');
    } else {
      foundProductCategory = productCategories.find((x) => x.id === cartItem.categories[0]);
    }

    if (!foundProductCategory || !foundProductCategory.products) {
      return;
    }

    const foundProduct = foundProductCategory.products.find((x) => x.id === cartItem.productId);
    if (!foundProduct || !foundProduct.isLongDelivery) {
      return;
    }

    if ((foundProduct.longDeliveryPrepare || 0) > mostRequiredDays) {
      mostRequiredDays = foundProduct.longDeliveryPrepare || 0;
    }
  });

  if (mostRequiredDays === -1) {
    return null;
  }

  return { dayOptions: dayOptions.filter((x) => x.value > mostRequiredDays), canOrderNow: false };
};

const roundToNearest5 = (number) => {
  return Math.round(number / 5) * 5;
};

const getMinutesUntilClosure = ({ deliveryRestaurant, isTakeAway }) => {
  if (isTakeAway) {
    return deliveryRestaurant.takewayUntilClosure || 0;
  }

  return deliveryRestaurant.deliveryUntilClosure || 0;
};
