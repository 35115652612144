/* eslint-disable react/no-array-index-key */

import React from 'react';
import { MdStar } from 'react-icons/md';

const RaitingButton = ({ rating, onChange, disabled }) => {
  return (
    <div className="flex justify-center flex-wrap">
      {[...Array(5)].map((star, ind) => {
        return (
          <button
            type="button"
            key={`${ind + 1}`}
            className={ind + 1 <= rating ? 'text-[#FFE603]' : 'text-[#CBDCCB]'}
            onClick={() => onChange(ind + 1)}
            disabled={disabled}
          >
            <MdStar className="text-5xl" />
          </button>
        );
      })}
    </div>
  );
};

export default RaitingButton;
