import { db } from 'codemash';
import { Config } from '../configs';
import { mapDeliverySettings, mapDeliveryRestaurants } from './mappers/delivery';
import { manageApiUnavailableError } from './error/errors';
import {
  mapApiToDataRestaurantEvents,
  mapApiToDataRestaurantPromotions,
} from './mappers/restaurant';
import { resourceRequest } from './client/requests';
import { apiActionChooser } from '../utils/apiActionChooser';
import { DomainConfig } from '../configs/domain';

export const getComapnyRestaurantsBySlug = async ({ companyHost }) => {
  try {
    if (!companyHost) {
      return {};
    }

    let response;

    await apiActionChooser(
      companyHost,
      async () => {
        response = await db.executeAggregate({
          id: Config.DATA.AGGREGATES.GET_COMPANY_RESTAURANTS_BY_HOST,
          collectionName: Config.DATA.COLLECTIONS.DELIVERY_SETTINGS,
          database: Config.CODEMASH.DB_STORAGE_ID,
          tokens: {
            host: companyHost,
          },
        });
      },
      async () => {
        response = await resourceRequest({
          data: {
            id: 'ClientGetRestaurantsByHost',
            tokens: {
              host: companyHost,
            },
          },
          action: 'aggregate',
        });
      },
    );

    if (response && response.length > 0) {
      return {
        deliverySettings: mapDeliverySettings({ settings: response[0].delivery_settings }),
        restaurants: mapDeliveryRestaurants({ restaurants: response[0].restaurants }),
      };
    }
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get table by qr code', e);
    }
  }

  return {};
};

export const getRestaurantEvents = async ({ restaurantId }) => {
  try {
    let response;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await db.getRecords({
          collectionName: Config.DATA.COLLECTIONS.RESTAURANT_EVENTS,
          database: Config.CODEMASH.DB_STORAGE_ID,
          filter: {
            restaurants: { $eq: { $oid: restaurantId } },
            hidden: false,
          },
          pageNumber: 0,
          pageSize: 100,
        });
      },
      async () => {
        response = await resourceRequest({
          data: {
            resource: 'ClientRestaurantEvents',
            filter: JSON.stringify({
              restaurants: { $eq: { $oid: restaurantId } },
              hidden: false,
            }),
          },
          action: 'find',
        });
      },
    );

    const mappedEvents = mapApiToDataRestaurantEvents({
      events: (response || {}).result,
    });

    return {
      mappedEvents,
    };
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get events', e);
    }
  }

  return {};
};

export const getRestaurantPromotions = async ({ restaurantId }) => {
  try {
    let response;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await db.getRecords({
          collectionName: Config.DATA.COLLECTIONS.RESTAURANT_PROMOTIONS,
          database: Config.CODEMASH.DB_STORAGE_ID,
          filter: {
            restaurants: { $eq: { $oid: restaurantId } },
            hidden: false,
          },
          pageNumber: 0,
          pageSize: 100,
        });
      },
      async () => {
        response = await resourceRequest({
          data: {
            resource: 'ClientRestaurantPromotions',
            filter: JSON.stringify({
              restaurants: { $eq: { $oid: restaurantId } },
              hidden: false,
            }),
          },
          action: 'find',
        });
      },
    );

    const mappedPromotions = mapApiToDataRestaurantPromotions({
      promotions: (response || {}).result,
    });

    return {
      mappedPromotions,
    };
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get promotions', e);
    }
  }

  return {};
};
