import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IoMdCloseCircle } from 'react-icons/io';
import MainButton from '../../../components/buttons/main';
import { restaurantTypes } from '../../../enums/restaurantTypes';

const FailPayment = ({ payment, isDelivery, onDeliveryPress }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const renderButtons = () => {
    if (isDelivery) {
      return (
        <div className="w-full mt-5 max-w-xs mx-auto">
          <MainButton text={t('buttons.viewMenu')} outline onClick={onDeliveryPress} />
        </div>
      );
    }

    if (payment.restaurant) {
      if (payment.restaurant.type === restaurantTypes.TipsOnly) {
        return (
          <div className="w-full mt-5 max-w-xs mx-auto">
            <MainButton text={t('buttons.close')} outline onClick={() => navigate('/')} />
          </div>
        );
      }

      if (payment.restaurant.type === restaurantTypes.OrderAndPay) {
        return (
          <div className="w-full mt-5 max-w-xs mx-auto">
            <MainButton text={t('buttons.viewMenu')} outline onClick={() => navigate('/menu')} />
          </div>
        );
      }
    }

    return (
      <div className="w-full mt-5 max-w-xs mx-auto">
        <MainButton text={t('buttons.viewTableBill')} outline onClick={() => navigate('/bill')} />
      </div>
    );
  };

  return (
    <div>
      <div className="mt-6 flex flex-col items-center">
        <h1 className="font-primaryMedium text-center text-primary text-2xl mb-3">
          {t('payment.failedTitle')}
        </h1>
        <IoMdCloseCircle className="text-[#FF9494] text-6xl" />
        {renderButtons()}
      </div>
    </div>
  );
};

export default FailPayment;
