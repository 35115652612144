import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

const BasicSelectInput = ({ options, placeholder, onChange, defaultValue }) => {
  return (
    <div>
      <div className="border border-input text-primary text-sm rounded-lg w-full bg-white relative">
        <select
          onChange={(e) => {
            onChange(e.target.value, e);
          }}
          className="select-reset rounded-lg w-full py-2.5 px-3 bg-white text-primary font-sans"
          defaultValue={defaultValue}
        >
          {placeholder && <option selected>{placeholder}</option>}
          {(options || []).map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
        <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
          <FaChevronDown className="text-primary" size={10} />
        </div>
      </div>
    </div>
  );
};

export default BasicSelectInput;
