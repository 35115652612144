import React, { useEffect, useContext } from 'react';
import Loader from '../components/misc/loader';
import { Context as RestaurantContext } from '../context/restaurant';
import { Context as AuthContext } from '../context/auth';
import { Context as CartContext } from '../context/cart';
import { Context as CheckoutContext } from '../context/checkout';
import { activeTable } from '../services/tableService';
import { useInterval } from '../hooks/useInterval';
import { restaurantTypes } from '../enums/restaurantTypes';
import { calculateCartSum } from '../services/cartService';

const InitPage = ({ children }) => {
  const {
    state: restaurantState,
    getTable,
    autoRefreshProductsLocal,
  } = useContext(RestaurantContext);
  const { initUser } = useContext(AuthContext);
  const { state: cartState, initCart, autoRefreshDeliveryCartLocal } = useContext(CartContext);
  const { state: checkoutState, autoRefreshOrderBill } = useContext(CheckoutContext);

  useEffect(() => {
    if (restaurantState.tableGot) {
      return;
    }

    const getTableAsync = async () => {
      await getTable({ tableQr: activeTable.qr });
      initCart(activeTable.id);
    };

    getTableAsync();
  }, []);

  useEffect(() => {
    initUser();
  }, []);

  // Refresh product schedules and discounts
  useInterval(
    async () => {
      const { categories: updatedCategories, refreshed: productsRefreshed } =
        await autoRefreshProductsLocal();

      if (
        productsRefreshed &&
        restaurantState.restaurant &&
        restaurantState.restaurant.type === restaurantTypes.OrderAndPay
      ) {
        const {
          refreshed: refreshedTemp,
          totalDiscounted: totalDiscountedTemp,
          cart: cartTemp,
        } = autoRefreshDeliveryCartLocal(
          restaurantState.restaurant.id,
          restaurantState.discountCartService,
          updatedCategories,
        );

        const cartRefreshed = refreshedTemp;
        const updatedTotalDiscounted = totalDiscountedTemp;
        const updatedCart = cartTemp;

        if (cartRefreshed && checkoutState.bill) {
          autoRefreshOrderBill({
            subtotal: calculateCartSum({ cart: updatedCart }),
            discount: updatedTotalDiscounted,
            cart: updatedCart,
          });
        }
      }
    },
    20000,
    false,
    [
      restaurantState.apiProducts,
      restaurantState.apiCategories,
      restaurantState.apiDiscounts,
      restaurantState.apiTable,
      restaurantState.apiVariationJoins,
      restaurantState.restaurant,
      restaurantState.discountMenuService,
      restaurantState.discountCartService,
      cartState.cart,
      cartState.deliveryCart,
      checkoutState.bill,
    ],
  );

  // Loading table
  if (restaurantState.loadingTable || !restaurantState.tableGot) {
    return <Loader />;
  }

  return children;
};

export default InitPage;
