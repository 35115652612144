import { db } from 'codemash';
import { Config } from '../configs';
import { mapApiToDataMenu } from './mappers/menu';
import { mapApiToDataProductVariations, mapApiToDataProducts } from './mappers/products/products';
import { manageApiUnavailableError } from './error/errors';
import { resourceRequest } from './client/requests';
import { apiActionChooser } from '../utils/apiActionChooser';
import { DomainConfig } from '../configs/domain';

// Not used
export const getMenu = async ({ restaurantId }) => {
  try {
    const response = await db.getRecordWithFilter({
      collectionName: Config.DATA.COLLECTIONS.MENU,
      database: Config.CODEMASH.DB_STORAGE_ID,
      filter: { restaurants: { $oid: restaurantId } },
      projection: {
        _id: 1,
        type: 1,
        categories: 1,
        category_images: 1,
        external_link: 1,
      },
      excludeCulture: true,
    });

    return mapApiToDataMenu({ menu: response });
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get menu', e);
    }
  }

  return null;
};

export const getProductsMenu = async ({
  tableId,
  tableSection,
  timezone,
  provider,
  integrationId,
  restaurantId,
  useOptimizations,
}) => {
  try {
    let response;
    let variationJoinsResponse;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await db.executeAggregate({
          id: useOptimizations
            ? Config.DATA.AGGREGATES.GET_DELIVERY_MENU_PRODUCTS_OPTIMIZED
            : Config.DATA.AGGREGATES.GET_DELIVERY_MENU_PRODUCTS,
          collectionName: Config.DATA.COLLECTIONS.TABLES,
          database: Config.CODEMASH.DB_STORAGE_ID,
          tokens: {
            tableId,
          },
        });

        variationJoinsResponse = await db.executeAggregate({
          id: Config.DATA.AGGREGATES.GET_VARIATION_JOINS,
          collectionName: Config.DATA.COLLECTIONS.RESTAURANTS,
          database: Config.CODEMASH.DB_STORAGE_ID,
          tokens: {
            restaurantId,
          },
        });
      },
      async () => {
        response = await resourceRequest({
          data: {
            id: useOptimizations
              ? 'ClientGetDeliveryMenuProductsOptimized'
              : 'ClientGetDeliveryMenuProducts',
            tokens: {
              tableId,
            },
          },
          action: 'aggregate',
        });

        variationJoinsResponse = await resourceRequest({
          data: {
            id: 'ClientGetVariationJoins',
            tokens: {
              type: 'Delivery',
              tableId,
              restaurantId,
            },
          },
          action: 'aggregate',
        });
      },
    );

    if (variationJoinsResponse) {
      for (let i = 0; i < variationJoinsResponse.length; i += 1) {
        if (variationJoinsResponse[i].joins) {
          variationJoinsResponse[i].joins = variationJoinsResponse[i].joins.filter(
            (x) => !x.hidden && !x.hidden_delivery,
          );
        }
      }
    }

    const variationJoins = (variationJoinsResponse || []).reduce(
      (a, v) => ({ ...a, [v._id]: v.joins || [] }),
      {},
    );

    if (response && response.length > 0) {
      const { categories, discountMenuService } = mapApiToDataProducts({
        products: response[0].products,
        initialCategories: response[0].categories,
        discounts: response[0].discounts,
        timezone,
        provider,
        integrationId,
        section: tableSection,
        restaurantId,
        variationsLoaded: !useOptimizations,
        variationJoins,
      });

      return {
        categories,
        apiDiscounts: response[0].discounts,
        apiProducts: response[0].products,
        apiCategories: response[0].categories,
        apiTable: {
          id: tableId,
          section: tableSection,
        },
        apiVariationJoins: variationJoins,
        discountMenuService,
      };
    }
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get table orders', e);
    }
  }

  return {};
};

export const getProductsVariations = async ({ productIds, variationJoins, allProducts }) => {
  try {
    let response;

    await apiActionChooser(
      DomainConfig.getHost(),
      async () => {
        response = await db.executeAggregate({
          id: Config.DATA.AGGREGATES.GET_PRODUCTS_VARIATIONS,
          collectionName: Config.DATA.COLLECTIONS.PRODUCT_VARIATIONS,
          database: Config.CODEMASH.DB_STORAGE_ID,
          tokens: {
            productIds: JSON.stringify(productIds.map((x) => ({ $oid: x }))),
          },
        });
      },
      async () => {
        response = await resourceRequest({
          data: {
            id: 'ClientGetProductVariations',
            tokens: {
              productIds: JSON.stringify(productIds.map((x) => ({ $oid: x }))),
            },
          },
          action: 'aggregate',
        });
      },
    );

    const { mappedVariationsByProduct } = mapApiToDataProductVariations({
      productIds,
      variations: response,
      variationJoins,
      allProducts,
    });

    return {
      mappedVariationsByProduct,
    };
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get product variations', e);
    }
  }

  return {};
};
