import React from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../components/buttons/main';
import { useCurrency } from '../../../../hooks/useCurrency';
import NumberInput from '../../../../components/inputs/numberInput';
import { useDeliveryColors } from '../../../../hooks/useDeliveryColors';

const AddCartButton = ({ price, quantity, onAdd, onChangeQuantity, disabled, min, max }) => {
  const { t } = useTranslation();
  const { currencySymbol } = useCurrency();
  const colors = useDeliveryColors();

  return (
    <div className="py-5 px-5 mt-4 shadow-[0_0_17px_rgba(17,24,39,0.1)] -mx-5">
      <div className="mx-5 flex flex-row">
        <div className="mr-3">
          <NumberInput
            number={quantity}
            onDown={() => onChangeQuantity(quantity - 1 < min ? min : quantity - 1)}
            onUp={() => onChangeQuantity(quantity + 1 > max ? max : quantity + 1)}
            customColor={colors.hasCustomPrimary ? colors.primary : undefined}
          />
        </div>
        <div className="flex-1">
          <MainButton
            onClick={onAdd}
            text={`${t('buttons.addToCart')} ${(price || 0).toFixed(2)} ${currencySymbol}`}
            disabled={disabled}
            customColor={colors.hasCustomPrimary ? colors.primary : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCartButton;
