import React, { useContext } from 'react';
import { Context as SettingsContext } from '../context/settings';
import LayoutPlace from './place/layout';
import LayoutDelivery from './delivery/layout';

const MainLayout = ({ children, noFooter, showPayments, noFooterMarginTop }) => {
  const { state: settingsState } = useContext(SettingsContext);

  return settingsState.isDeliveryLayout ? (
    <LayoutDelivery noFooterMarginTop={noFooterMarginTop}>{children}</LayoutDelivery>
  ) : (
    <LayoutPlace noFooter={noFooter} showPayments={showPayments}>
      {children}
    </LayoutPlace>
  );
};

export default MainLayout;
