import React from 'react';
import { formFileUrlFromFileArray } from '../../../../utils/fileHelper';
import { getTranslatedData } from '../../../../localization';

const EventCard = ({ event }) => {
  return (
    <a
      target="_blank"
      href={event.externalLink}
      className="h-72 md:h-96 rounded-xl overflow-hidden shadow-lg cursor-pointer hover:scale-103 transition duration-300 ease-in-out hover:shadow-xl max-w-sm"
      rel="noreferrer"
    >
      <div className="h-56 md:h-80">
        <img
          className="w-full h-full object-cover object-center"
          alt=""
          src={formFileUrlFromFileArray(event.imageCover)}
        />
      </div>
      <div className="h-16 px-4 md:px-8 bg-white flex items-center">
        <div className="overflow-hidden text-ellipsis whitespace-nowrap  text-primary font-medium text-base md:text-lg">
          {getTranslatedData(event.title)}
        </div>
      </div>
    </a>
  );
};

export default EventCard;
