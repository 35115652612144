import React from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/images/footer/logo.png';
import visa from '../../assets/images/footer/visa.png';
import mastercard from '../../assets/images/footer/mastercard.png';
import googlePay from '../../assets/images/footer/google-pay.png';
import applePay from '../../assets/images/footer/apple-pay.png';

const FooterPlace = ({ showPayments }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center">
      {showPayments && (
        <div className="flex flex-row  items-center mix-blend-luminosity">
          <img className="mr-3 max-h-5" src={visa} alt="Visa" />
          <img className="mr-3 max-h-4" src={mastercard} alt="Mastercard" />
          <img className="mr-3 max-h-4" src={applePay} alt="Apple Pay" />
          <img className="max-h-4" src={googlePay} alt="Google Pay" />
        </div>
      )}
      <div className="mt-2 flex flex-row items-center">
        <span className="font-primaryMedium text-xs text-bland">{t('common.poweredByApp')}</span>{' '}
        <img className="ml-1 h-3  mb-[2px]" src={logo} alt="Waitless" />
      </div>
    </div>
  );
};

export default FooterPlace;
