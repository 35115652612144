import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeliveryColors } from '../../../../../hooks/useDeliveryColors';

const ProductNewTag = ({ isNew, isDelivery }) => {
  const { t } = useTranslation();
  const colors = useDeliveryColors();

  if (!isNew) {
    return null;
  }

  return (
    <div
      className={`${
        isDelivery
          ? 'text-xs font-primaryRegular flex justify-center items-center'
          : 'text-sm font-primaryMedium'
      } border-[1px] border-bland opacity-70 bg-bland px-2 py-[1px]
         text-white rounded-full inline-block mt-2`}
      style={{
        background: colors.hasCustomPrimary ? colors.primary : undefined,
        borderColor: colors.hasCustomPrimary ? colors.primary : undefined,
      }}
    >
      {t('products.newTag')}
    </div>
  );
};

export default ProductNewTag;
