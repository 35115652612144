// used to apply discounts to items in cart
export class DiscountCartService {
  constructor(discounts) {
    this.discounts = discounts;
  }

  setActiveTable({ company, restaurant, provider, integrationId, section, timezone }) {
    //
  }

  applyDiscounts({ cart, cartTotal, coupon }) {
    return [];
  }
}
