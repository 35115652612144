export const parseSafeJSON = (string, returnNullOnErr) => {
  let adjusted = string;

  if (!adjusted) {
    return returnNullOnErr ? null : {};
  }
  if (adjusted.includes('~array~:')) {
    adjusted = adjusted.replace('~array~:', '');
  }

  try {
    return JSON.parse(adjusted);
  } catch (e) {
    try {
      const eachLine = adjusted.split('\n');
      let joinedString = '';

      eachLine.forEach((x) => {
        joinedString += x;
      });
      return JSON.parse(joinedString);
    } catch (e2) {
      return returnNullOnErr ? null : {};
    }
  }
};

export const parseCmFunctionResponse = (resp) => {
  return parseSafeJSON(resp ? resp.response : null);
};
