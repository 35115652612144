import i18next from 'i18next';
import createDataContext from './_init_/createDataContext';
import { getComapnyRestaurantsBySlug } from '../api/restaurant';
import { Config } from '../configs';

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_slug':
      return { ...state, slug: action.payload };
    case 'set_delivery_settings':
      return { ...state, settings: action.payload };
    case 'set_restaurants':
      return { ...state, restaurants: action.payload };
    case 'set_selected_restaurant':
      return { ...state, selectedRestaurantId: action.payload };
    case 'set_selected_categoryId':
      return { ...state, selectedCategoryId: action.payload };
    case 'set_default_delivery_type': {
      global.localStorage.setItem(Config.CACHE_KEYS.DEFAULT_DELIVERY_TYPE, action.payload);
      return { ...state, defaultDeliveryType: action.payload };
    }
    case 'reset':
      return {
        ...state,
        company: null,
        restaurants: null,
      };
    default:
      return state;
  }
};

const getDeliveryRestaurants =
  (dispatch) =>
  async ({ companyHost, inBackground }) => {
    const { restaurants, deliverySettings } = await getComapnyRestaurantsBySlug({ companyHost });

    if (!restaurants || restaurants.length === 0) {
      return { success: false };
    }

    // Set default restaurant language if user has not changed any
    if (!inBackground) {
      const selectedAppLanguage = global.localStorage.getItem(
        Config.CACHE_KEYS.SELECTED_APP_LANGUAGE,
      );

      if (!selectedAppLanguage) {
        let defaultLanguageCode;

        // Take first restaurant language
        restaurants.forEach((restaurant) => {
          if (!defaultLanguageCode && restaurant.data.defaultLanguageCode) {
            defaultLanguageCode = restaurant.data.defaultLanguageCode;
          }
        });

        if (defaultLanguageCode) {
          i18next.changeLanguage(defaultLanguageCode);
        }
      }
    }

    if (!inBackground) {
      dispatch({ type: 'set_slug', payload: companyHost });
    }

    dispatch({ type: 'set_delivery_settings', payload: deliverySettings });
    dispatch({ type: 'set_restaurants', payload: restaurants });

    return { success: true, restaurants };
  };

const setSelectedRestaurant = (dispatch) => async (restaurantId, categoryId) => {
  dispatch({ type: 'set_selected_restaurant', payload: restaurantId });
  dispatch({ type: 'set_selected_categoryId', payload: categoryId });
};

const setDefaultDeliveryType = (dispatch) => async (type, hasTakeaway, hasDelivery) => {
  if (!type) {
    const cachedType = global.localStorage.getItem(Config.CACHE_KEYS.DEFAULT_DELIVERY_TYPE);

    if (cachedType) {
      type = cachedType;
    } else {
      type = 'takeaway';
    }
  }

  if (hasTakeaway || hasDelivery) {
    if (type === 'takeaway' && !hasTakeaway) {
      type = 'delivery';
    } else if (type === 'delivery' && !hasDelivery) {
      type = 'takeaway';
    }
  }

  dispatch({ type: 'set_default_delivery_type', payload: type });
};

const initialData = {
  slug: null,
  settings: null,
  restaurants: null,
  selectedCategoryId: null,
  defaultDeliveryType: null,
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    getDeliveryRestaurants,
    setSelectedRestaurant,
    setDefaultDeliveryType,
  },
  { ...initialData },
);
