import React from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from './main';

const ConfirmButton = ({
  onClick,
  customText,
  disabled,
  sticky,
  noShadow,
  noSpacing,
  loading,
  customColor,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`
        ${noShadow ? '' : 'shadow-[0_0_17px_rgba(17,24,39,0.1)] -mx-5 md:-mx-0'}
        ${sticky ? 'sticky bottom-0 z-10 bg-white ' : ''}
        ${noSpacing ? '' : 'py-5 px-5 mt-4'}
      `}
    >
      <MainButton
        onClick={onClick}
        text={customText || t('buttons.confirmSplit')}
        disabled={disabled}
        loading={loading}
        customColor={customColor}
      />
    </div>
  );
};

export default ConfirmButton;
