/* eslint-disable no-param-reassign */

import { parseSafeJSON } from '../../utils/jsonHelpers';
import { roundDecimal } from '../../utils/roundingHelpers';

function mapSingleOrder(orderToMap) {
  const orderMenu = orderToMap.menu || {};

  const mapped = {
    id: orderToMap._id,
    number: orderToMap.number,
    isInProgress: orderToMap.status !== 'Completed',
    isCompleted: orderToMap.status === 'Completed',
    price: {
      total: orderMenu.order_sum,
      discount: orderMenu.discount,
      surcharge: orderMenu.surcharge,
    },
    payments: (orderToMap.payments || [])
      .filter((payment) => payment.status === 'Paid')
      .map((payment) => {
        return {
          id: payment._id,
          type: payment.type,
          amount: payment.amount,
          status: payment.status,
          paidOn: payment.paid_on,
          rating: payment.rating,
          items: payment.items
            ? payment.items.map((item) => ({
                productId: item.dish_id,
                quantity: item.quantity,
              }))
            : [],
        };
      }),
    products:
      (orderMenu.products || []).map((product) => {
        const variations = parseSafeJSON(product.variations_json, true);

        const formedProduct = {
          id: product.rkeeper_dish_code,
          name: product.product_name,
          quantity: product.quantity,
          basePrice: product.price,
          totalSingle: roundDecimal(product.full_price / product.quantity),
          total: product.full_price,
          variations: variations
            ? variations.map((variation) => {
                return {
                  id: variation.id,
                  name: variation.name,
                  items: variation.items
                    ? variation.items.map((item) => {
                        return {
                          id: item.id,
                          name: item.name,
                          price: item.price,
                        };
                      })
                    : [],
                };
              })
            : [],
        };

        return formedProduct;
      }) || [],
  };

  calculateLeftToPay(mapped);

  return mapped;
}

const calculateLeftToPay = (order) => {
  if (!order.payments || order.payments.length === 0) {
    order.price.leftToPay = order.price.total;
    return;
  }

  let totalPaid = 0;
  order.payments.forEach((payment) => {
    if (payment.status === 'Paid') {
      totalPaid += payment.amount;
    }
  });

  order.price.leftToPay = (order.price || {}).total - totalPaid;

  if (order.price.leftToPay < 0) {
    order.price.leftToPay = 0;
  }
};

export const mapApiToDataTableOrders = ({ orders }) => {
  if (!orders || orders.length === 0) {
    return null;
  }

  const mappedOrders = [];
  orders.forEach((order) => {
    const mappedOrder = mapSingleOrder(order);

    if (mappedOrder) {
      mappedOrders.push(mappedOrder);
    }
  });

  return mappedOrders;
};

export const mapApiToDataPayment = ({ paymentData }) => {
  if (!paymentData) {
    return null;
  }

  const mapped = {
    id: paymentData._id,
    externalId: paymentData.external_payment_id,
    orderId: (paymentData.order || {})._id,
    order: (paymentData.order || {}).data
      ? {
          id: paymentData.order.data._id,
          number: paymentData.order.data.number,
          externalNumber: paymentData.order.external_number,
          orderDate: paymentData.order.data.date_utc,
          deliveryDate: paymentData.order.data.delivery_date,
          deliveryDateDelta: paymentData.order.data.delivery_date_delta,
          isDelivery: paymentData.order.data.is_delivery,
          isTakeAway: paymentData.order.data.is_takeout,
        }
      : null,
    deliveryOrder: paymentData.deliveryOrder
      ? {
          id: paymentData.deliveryOrder._id,
          trackingUrl: paymentData.deliveryOrder.tracking_url,
        }
      : null,
    restaurant: paymentData.restaurant
      ? {
          id: paymentData.restaurant._id,
          name: paymentData.restaurant.name,
          type: paymentData.restaurant.type,
        }
      : null,
    rating: paymentData.rating
      ? {
          id: paymentData.rating._id,
          rating: paymentData.rating.rating,
          comment: paymentData.rating.comment,
        }
      : null,
    paidAmount: paymentData.amount - (paymentData.discount || 0),
    paidTips: paymentData.tips,
    paidAdditional:
      (paymentData.packaging_price || 0) +
      (paymentData.delivery_price || 0) +
      (paymentData.reach_min_price || 0),
    totalToPay: (paymentData.order || {}).order_sum,
    leftToPay: 0,
    processed: paymentData.processed,
    processingFailed: paymentData.processing_failed,
    tipsOnly: paymentData.tips_only,
    status: paymentData.status,
  };

  if (paymentData.payments && paymentData.payments.length > 0) {
    let totalPaid = 0;
    paymentData.payments.forEach((payment) => {
      if (payment.status === 'Paid' || payment._id === paymentData._id) {
        totalPaid += payment.amount;
      }
    });
    mapped.leftToPay = roundDecimal(mapped.totalToPay - totalPaid);
    if (mapped.leftToPay < 0) {
      mapped.leftToPay = 0;
    }
  }

  return mapped;
};
