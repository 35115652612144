import { sse } from 'codemash';
import { makeId } from '../utils/stringHelper';

export const sseHandler = {
  handler: null,
};

const globalDebugLock = {
  locked: false,
};

// used to receive events from the server (for example, on order changes in POS)
class ServerEventsHandler {
  constructor() {
    this.id = makeId(10);
    this.source = null;

    this.hbInterval = null;
    this.heartbeatIntervalMs = -1;

    this.subscriptionData = null;

    this.contextReceiver = messageReceiver();
    this.handlers = {
      tableMessageHandler: null,
    };

    this.authToken = null;
    this.userId = null;
    this.userRecordId = null;

    this.lastHeartbeat = null;
    this.isOpen = false;

    this.hasInitialLock = !globalDebugLock.locked;
    globalDebugLock.locked = true;

    this.lockAquiredDate = null;
  }

  async open({ token, userId, userRecordId }) {
    console.log('OPEN ID:', this.id);
    if (!this.hasInitialLock) {
      return;
    }

    if (this.isLocked()) {
      return;
    }
    this.lock();

    this.close(true);

    this.authToken = token;
    this.userId = userId;
    this.userRecordId = userRecordId;

    try {
      const sseTokenResp = await sse.authorizeConnection({
        secretKey: token,
      });

      const sseToken = sseTokenResp && sseTokenResp.result ? sseTokenResp.result.token : null;
      const url = sse.formEventSourceUrl({ authorizationToken: sseToken });

      // eslint-disable-next-line no-undef
      this.source = new EventSource(url);
    } catch (e) {
      console.log('SSE Connect error:', e);
      setTimeout(() => {
        this.unlock();
        this.open({ token, userId, userRecordId });
      }, 5000);
      return;
    }

    this.source.addEventListener('open', () => {
      this.lastHeartbeat = new Date();
      console.log('Open SSE connection.');
    });

    this.source.addEventListener('message', (event) => {
      const handledEvent = sse.handleServerEvent({ event });
      // console.log('HANDLED EVENT: ', handledEvent);

      if (handledEvent.type === 'cmd.onConnect') {
        this.subscriptionData = handledEvent.data;

        this.heartbeatIntervalMs = parseInt(handledEvent.data.heartbeatIntervalMs, 10);

        this.hbInterval = setInterval(() => {
          if (this.subscriptionData && this.subscriptionData.id) {
            sse
              .heartbeatConnection({ subscriptionId: this.subscriptionData.id })
              .then(() => {
                this.lastHeartbeat = new Date();
              })
              .catch((e) => {
                console.log('Heartbeat error:', e);

                this.tryUnlock();
                this.open({
                  token: this.authToken,
                  userId: this.userId,
                  userRecordId: this.userRecordId,
                });
              });
          }
        }, this.heartbeatIntervalMs);
      } else if (handledEvent.type === 'cmd.onMessage') {
        if (this.contextReceiver) {
          this.contextReceiver(
            handledEvent,
            this.handlers,
            this.authToken,
            this.userId,
            this.userRecordId,
          );
        }
      }
    });

    this.source.addEventListener('error', (event) => {
      if (event.type === 'error') {
        console.log('Connection error:', event.message);
      } else if (event.type === 'exception') {
        console.log('Exception error:', event.message, event.error);
      } else {
        console.log('Error:', event.message, event.error);
      }

      this.tryUnlock();
      this.open({
        token: this.authToken,
        userId: this.userId,
        userRecordId: this.userRecordId,
      });
    });

    this.source.addEventListener('close', () => {
      console.log('Close SSE connection.');
    });
  }

  close(dontUnlock) {
    console.log('CLOSE');
    if (this.source != null) {
      this.source.close();

      console.log('REMOVE LISTENERS');
      /*
      this.source.removeEventListener('open');
      this.source.removeEventListener('message');
      this.source.removeEventListener('error');
      this.source.removeEventListener('close'); */

      if (this.hbInterval) {
        clearInterval(this.hbInterval);
        this.heartbeatIntervalMs = -1;
      }

      if (this.subscriptionData) {
        const subId = this.subscriptionData.id;
        sse.closeConnection({ subscriptionId: subId }).catch((e) => {
          console.log('ERROR. CM Close SSE connection:', e);
        });
        this.subscriptionData = null;
      }

      this.source = null;
    }

    if (!dontUnlock) {
      this.unlock();
    }
  }

  getLastHeartbeatOn() {
    if (!this.lastHeartbeat) {
      return null;
    }

    const currentDate = new Date();
    const diffSeconds = Math.abs(currentDate - this.lastHeartbeat) / 1000;

    return diffSeconds;
  }

  lock() {
    this.lockAquiredDate = new Date();
  }

  unlock() {
    this.lockAquiredDate = null;
  }

  tryUnlock() {
    if (!this.lockAquiredDate) {
      return;
    }

    const diffMillis = new Date().getTime() - this.lockAquiredDate;
    const diffSeconds = diffMillis / 1000;
    if (diffSeconds > 5) {
      this.lockAquiredDate = null;
    }
  }

  isLocked() {
    if (this.lockAquiredDate) {
      return true;
    }
    return false;
  }
}

const messageReceiver = () => async (payload, handlers, authToken, userId) => {
  console.log('RECEIVE MESSAGE', payload);
  if (payload.data && payload.data.meta && payload.data.meta.type === 'table') {
    handlers.tableMessageHandler(payload, userId);
  }
};

export default ServerEventsHandler;
