export const getSelectedCategory = ({
  scrollPosition,
  windowHeight,
  categoriesY,
  selectFirstIfNone,
  customThresholdFunc,
}) => {
  const currentNextThreshold = customThresholdFunc
    ? customThresholdFunc(scrollPosition, windowHeight)
    : scrollPosition + windowHeight * 0.4;

  let selectedCategoryId;
  Object.keys(categoriesY)
    .reverse()
    .forEach((key, ind) => {
      if (selectedCategoryId) {
        return;
      }

      const categoryStartPosition = categoriesY[key];
      if (currentNextThreshold > categoryStartPosition) {
        selectedCategoryId = key;
      } else if (selectFirstIfNone && ind === Object.keys(categoriesY).length - 1) {
        selectedCategoryId = key;
      }
    });

  return selectedCategoryId;
};
