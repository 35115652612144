import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineClock } from 'react-icons/hi';
import { getTranslatedData } from '../../../../localization';
import { useCurrency } from '../../../../hooks/useCurrency';
import ProductNewTag from './components/productNewTag';
import ProductPopularTag from './components/productPopularTag';
import { useDeliveryColors } from '../../../../hooks/useDeliveryColors';
import ProductSpicyIcon from './components/productSpicyIcon';
import ProductVeganIcon from './components/productVeganIcon';
import ProductPreparationTime, {
  hasProductPreparationTime,
} from './components/productPreparationTime';
import ProductCustomTags from './components/productCustomTags';
import ProductImage from './components/productImage';

const Product = ({ product, onSelect, totalInCart, shouldContainImage }) => {
  const { t } = useTranslation(['delivery']);
  const { currencySymbol } = useCurrency();
  const colors = useDeliveryColors();

  const renderDiscountTag = (discount, isRelative) => {
    if (!discount) {
      return null;
    }

    return (
      <div
        className={`border-[1px] border-white bg-white px-2 py-[1px]
      font-primaryMedium text-primary text-sm rounded-[20px] inline-block
      ${isRelative ? '' : 'absolute -bottom-2 -left-1'}
      shadow-sm`}
      >
        -{Math.abs(discount)}%
      </div>
    );
  };

  const hasImage = product.listImage && product.listImage.length > 0;

  return (
    <div
      role="button"
      className="flex flex-row justify-between mb-5 pb-5 border-b-[1px] border-border cursor-pointer relative"
      onClick={onSelect}
    >
      <div className="mr-8">
        <div className="font-primaryMedium text-lg text-primary mb-2 inline-block">
          <div className="inline-block mr-2">{getTranslatedData(product.name) || '-'}</div>
          <div className="inline-block relative -top-[2px]">
            <ProductSpicyIcon spicyLevel={product.spicyLevel} />
            {!!product.spicyLevel && product.isVegetarian && <div className="ml-1 inline-block" />}
            <ProductVeganIcon isVegan={product.isVegetarian} />
          </div>
        </div>
        {!!getTranslatedData(product.nutrition) && (
          <div className="font-primaryMedium text-base text-bland">
            {getTranslatedData(product.nutrition)}
          </div>
        )}
        {(product.isPopular || product.isNew || (!!product.tags && product.tags.length > 0)) && (
          <div className="flex flex-row flex-wrap">
            <ProductPopularTag isPopular={product.isPopular} />
            {product.isPopular && product.isNew && <div className="inline-block mr-1" />}
            <ProductNewTag isNew={product.isNew} />
            {(product.isPopular || product.isNew) && <div className="inline-block mr-1" />}
            <ProductCustomTags tags={product.tags} />
          </div>
        )}
        <div className="flex flex-row flex-wrap items-center mt-2">
          {!!product.price && (
            <p className="font-primaryMedium text-primary text-xl mr-2 whitespace-nowrap">
              {(product.price || 0).toFixed(2)} {currencySymbol}
            </p>
          )}
          {!product.price && !!product.minimumPrice && (
            <p className="font-primaryMedium text-primary text-xl mr-2 whitespace-nowrap">
              {t('delivery:menu.fromPrice')} {(product.minimumPrice || 0).toFixed(2)}{' '}
              {currencySymbol}
            </p>
          )}
          {!!product.priceBefore && product.priceBefore !== product.price && (
            <p className="font-primaryMedium text-bland text-lg line-through whitespace-nowrap">
              {(product.priceBefore || 0).toFixed(2)} {currencySymbol}
            </p>
          )}
        </div>
        {!hasImage && hasProductPreparationTime(product) && (
          <div className="mt-2 font-primaryMedium text-bland text-sm flex items-center">
            <HiOutlineClock className="text-bland mr-2" size={18} />
            <div>
              <ProductPreparationTime product={product} />
            </div>
          </div>
        )}
      </div>
      <div className="text-right">
        {hasImage && (
          <div className="relative">
            <ProductImage product={product} shouldContainImage={shouldContainImage} />
            {renderDiscountTag(product.discountPercent)}
          </div>
        )}
        {!hasImage && (
          <div className="mt-11">{renderDiscountTag(product.discountPercent, true)}</div>
        )}
        {hasImage && hasProductPreparationTime(product) && (
          <div className="mt-2 font-primaryMedium text-bland text-sm flex items-center">
            <HiOutlineClock className="text-bland mr-2" size={18} />
            <div>
              <ProductPreparationTime product={product} />
            </div>
          </div>
        )}
      </div>
      {totalInCart > 0 && (
        <div
          className={`absolute ${hasImage ? '-right-2 -top-2' : 'right-0 top-0'} bg-primary
            font-primaryMedium text-white text-sm rounded-full w-6 h-6
            flex items-center justify-center`}
          style={{
            background: colors.hasCustomPrimary ? colors.primary : undefined,
          }}
        >
          {totalInCart}
        </div>
      )}
    </div>
  );
};

export default Product;
