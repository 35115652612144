import { useState, useEffect, useRef } from 'react';
import { useMutationObservable } from './useMutationObservable';

export const usePayntPayButtonWrapper = ({
  wrapperId,
  payButtonClass,
  disabled,
  isValidOnSubmit,
  updatePaymentBeforeSubmit,
}) => {
  const [readyRefresher, setReadyRefresher] = useState(0);
  const [readyProcessedRefresher, setReadyProcessedRefresher] = useState(0);
  const [buttonPressRefresher, setButtonPressRefresher] = useState(0);

  const getByPartialClass = () => {
    const elemsByTarget = global.document.querySelectorAll(`form[target*='${payButtonClass}']`);
    if (elemsByTarget && elemsByTarget.length > 0) {
      return elemsByTarget[0];
    }

    const elemsByClass = global.document.querySelectorAll(`form[class*='${payButtonClass}']`);
    if (elemsByClass && elemsByClass.length > 0) {
      return elemsByClass[0];
    }

    return null;
  };

  // Discovers when button is rendered
  useMutationObservable(global.document.getElementById('paynt-widget-container'), () => {
    const payButton = getByPartialClass();
    if (payButton) {
      setReadyRefresher((prev) => prev + 1);
    }
  });

  const buttonEventListenerRef = useRef({
    func: () => {
      setButtonPressRefresher((prev) => prev + 1);
    },
  });

  useEffect(() => {
    const gpayWrapperList = global.document.querySelectorAll(`[data-waitless-id='${wrapperId}']`);
    if (!gpayWrapperList || gpayWrapperList.length === 0) {
      return;
    }

    const gpayWrapper = gpayWrapperList[0];

    if (disabled) {
      gpayWrapper.classList.add('disabled');
    } else {
      gpayWrapper.classList.remove('disabled');
    }
  }, [disabled, readyProcessedRefresher]);

  useEffect(() => {
    if (readyRefresher <= 0) {
      return () => {};
    }

    const gpayButton = getByPartialClass();
    if (!gpayButton) {
      return () => {};
    }

    const { parentNode } = gpayButton;

    parentNode.addEventListener('click', buttonEventListenerRef.current.func, false);
    parentNode.setAttribute('data-waitless-id', wrapperId);

    gpayButton.disabled = true;
    setReadyProcessedRefresher((prev) => prev + 1);

    return () => {
      parentNode.removeEventListener('click', buttonEventListenerRef.current.func, false);
    };
  }, [readyRefresher]);

  useEffect(() => {
    const clickButtonAsync = async () => {
      if (buttonPressRefresher <= 0) {
        return;
      }

      if (!isValidOnSubmit()) {
        return;
      }

      const gpayButton = getByPartialClass();
      if (!gpayButton) {
        return;
      }

      const dataUpdated = await updatePaymentBeforeSubmit({
        externalPaymentId: global.document.getElementById('paynt-payment-id').dataset.payment,
      });
      if (!dataUpdated) {
        return;
      }

      const { parentNode } = gpayButton;

      parentNode.removeEventListener('click', buttonEventListenerRef.current.func);
      gpayButton.disabled = false;
      gpayButton.click();
    };

    clickButtonAsync();
  }, [buttonPressRefresher]);

  return {
    onFormCancel: () => {
      setReadyRefresher((prev) => prev + 1);
    },
  };
};
