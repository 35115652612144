import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeliveryColors } from '../../hooks/useDeliveryColors';

const DeliveryViewOrder = ({
  onShowCart,
  totalInCart,
  totalAfterDiscount,
  currencySymbol,
  isDekstop,
}) => {
  const { t } = useTranslation(['delivery']);
  const colors = useDeliveryColors();

  return (
    <div
      role="button"
      onClick={onShowCart}
      className={`flex-row text-white font-primaryMedium text-sm
        items-center bg-primary rounded-lg py-2 px-2 z-10 order-2 sm:order-1
        ${isDekstop ? 'hidden md:flex sm:mr-8' : 'flex md:hidden'}
        transition duration-300 ease-in-out hover:shadow-sm`}
      style={{ backgroundColor: colors.primary }}
    >
      <div className="w-7 h-7 rounded-full bg-white text-primary flex justify-center items-center">
        {totalInCart}
      </div>
      <div className="ml-3 mr-8">{t('delivery:buttons.viewOrder')}</div>
      <div className="">
        {totalAfterDiscount.toFixed(2)} {currencySymbol}
      </div>
    </div>
  );
};

export default DeliveryViewOrder;
