import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Context as DeliveryContext } from '../../context/delivery';
import logo from '../../assets/images/footer/logo-white.png';
import { getTranslatedData } from '../../localization';
import { formatDayMinutes } from '../../utils/dateHelpers';

const FooterDelivery = ({ noMarginTop }) => {
  const { t } = useTranslation(['delivery']);

  const { state: deliveryState } = useContext(DeliveryContext);

  const selectedRestaurant = useMemo(() => {
    if (!deliveryState.restaurants || !deliveryState.selectedRestaurantId) {
      return null;
    }

    return deliveryState.restaurants.find((x) => x.id === deliveryState.selectedRestaurantId);
  }, [deliveryState.restaurants, deliveryState.selectedRestaurantId]);

  const restaurantAddress = useMemo(() => {
    if (!selectedRestaurant || !selectedRestaurant.data) {
      return null;
    }

    const parts = [];
    if (selectedRestaurant.data.street) {
      parts.push(selectedRestaurant.data.street);
    }

    if (selectedRestaurant.data.city) {
      parts.push(selectedRestaurant.data.city);
    }

    if (selectedRestaurant.data.country) {
      parts.push(selectedRestaurant.data.country);
    }

    if (selectedRestaurant.data.postalCode) {
      parts.push(selectedRestaurant.data.postalCode);
    }

    return parts.join(', ');
  }, [selectedRestaurant]);

  const renderTimingDay = (day, time) => {
    return (
      <div className="flex flex-nowrap flex-row items-start xl:justify-between">
        <div className="text-ellipsis w-[52%] xl:w-auto overflow-hidden mr-2">{day}</div>
        <div className="w-[43%] xl:w-auto whitespace-nowrap">{time}</div>
      </div>
    );
  };

  const renderTitle = () => {
    if (selectedRestaurant) {
      return getTranslatedData(selectedRestaurant.title);
    }

    if (deliveryState.settings) {
      return getTranslatedData(deliveryState.settings.title);
    }

    return '';
  };

  const renderDescription = () => {
    if (selectedRestaurant) {
      return getTranslatedData(selectedRestaurant.description);
    }

    if (deliveryState.settings) {
      return getTranslatedData(deliveryState.settings.description);
    }

    return '';
  };

  return (
    <div className={`${!noMarginTop ? 'mt-9' : ''} bg-primary text-white px-5`}>
      <div className="flex md:flex-row flex-col justify-between items-start pt-16 sm:pt-24 pb-10 sm:pb-24 max-w-7xl mx-auto">
        <div className="md:w-[25%] w-full">
          <div className="font-primaryBold text-xl">{renderTitle()}</div>
          <div className="mt-4 font-primaryMedium text-base">{renderDescription()}</div>
        </div>
        <div
          className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xs:gap-x-20 sm:gap-x-36 md:gap-x-16 gap-y-8 xs:gap-y-12 mb-10
            w-full md:w-[65%] xl:w-[70%] mt-12 md:mt-0"
        >
          {selectedRestaurant && !!selectedRestaurant.data.showCompanyDetails && (
            <div className="order-1">
              <div className="font-primaryMedium text-lg">{t('delivery:footer.company')}</div>
              <div className="mt-3 font-primaryRegular text-sm">
                {selectedRestaurant.data.companyName}
              </div>
              {!!selectedRestaurant.data.registeredCompanyAddress && (
                <div className="mt-3 font-primaryRegular text-sm">
                  {selectedRestaurant.data.registeredCompanyAddress}
                </div>
              )}
            </div>
          )}
          {!!restaurantAddress && (
            <div className="order-1">
              <div className="font-primaryMedium text-lg">{t('delivery:footer.address')}</div>
              <div className="mt-3 font-primaryRegular text-sm">{restaurantAddress}</div>
            </div>
          )}
          {!!selectedRestaurant && (
            <div className="xl:order-2 lg:order-4 xs:order-3 order-2">
              <div className="font-primaryMedium text-lg">{t('delivery:footer.timings')}</div>
              <div className="mt-3 font-primaryRegular text-sm w-full">
                {(selectedRestaurant.workTimes || []).map((time, i) => {
                  return (
                    <div key={time.day}>
                      {i > 0 && <div className="mt-2" />}
                      {renderTimingDay(
                        t(`delivery:days.day-${time.day}`),
                        `${formatDayMinutes(time.from)} - ${formatDayMinutes(time.until)}`,
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {!!selectedRestaurant && selectedRestaurant.data && (
            <div className="xl:order-3 lg:order-2 xs:order-2 order-3">
              <div className="font-primaryMedium text-lg">{t('delivery:footer.contact')}</div>
              <div className="mt-3 font-primaryRegular text-sm">
                {!!selectedRestaurant.phone && (
                  <div className="mb-2">{selectedRestaurant.phone}</div>
                )}
                {!!selectedRestaurant.email && <div>{selectedRestaurant.email}</div>}
              </div>
            </div>
          )}
          <div className="xl:order-4 lg:order-3 xs:order-4 order-4">
            <div className="font-primaryMedium text-lg">{t('delivery:footer.information')}</div>
            <div className="mt-3 font-primaryRegular text-sm">
              <div>
                <a
                  target="_blank"
                  href="https://drive.google.com/file/d/1Et5cXjKQ6vaNWElDDnEZB4K3Rz4Ch8D8/view"
                  rel="noreferrer"
                >
                  {t('delivery:footer.cookiesAndPrivacy')}
                </a>
              </div>
              <div className="mt-2">
                <a
                  target="_blank"
                  href="https://drive.google.com/file/d/1vYkyVHjaMx0e9qsToZh5S6-WBtcIXnyc/view"
                  rel="noreferrer"
                >
                  {t('delivery:footer.termsAndConditions')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2 mb-3 text-center">
        <span className="font-primaryMedium text-sm text-white">
          @2023 {t('delivery:footer.copyright')}
        </span>{' '}
        <img className="h-3 mb-[4px] inline-block" src={logo} alt="Waitless" />
      </div>
    </div>
  );
};

export default FooterDelivery;
