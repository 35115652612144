import { useEffect, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

export const validationTypes = {
  email: 'email',
  phone: 'phone',
};

export function useValidator({ type, value, required }) {
  const [isValid, setIsValid] = useState(true);
  const [firstValidationDone, setFirstValidationDone] = useState(false);

  const validate = () => {
    let isValidCheck = true;

    if (!required && (value === undefined || value === null || value === '')) {
      isValidCheck = true;
    } else if (validationTypes.email === type) {
      isValidCheck = isEmail(value);
    } else if (validationTypes.phone === type) {
      isValidCheck = isMobilePhone(value.replace(/[^\d+]/g, ''));
    }

    if (!firstValidationDone) {
      setFirstValidationDone(true);
    }

    setIsValid(isValidCheck);

    return isValidCheck;
  };

  useEffect(() => {
    if (firstValidationDone) {
      validate(value);
    }
  }, [value]);

  return { isValid, validate };
}
